import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp, getSystemName,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import {showGlobalModal} from '../../../data/actions/ui'
import {ArchiveBoxIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    NUMBER_OF_AXLES,
    READ_PERM,
    SCAC_CODE_FIELD_LENGTH,
    TRUCK_STATUS_TYPES,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import TrucksTableBadge from "./trucks-table-badge";
import {getSecondResource} from "../../../data/actions/secondResource";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import BulkBatchEdit from "../../../common/components/modal/bulk-batch-edit";
import LocationRadiusSearch from "../../../common/components/modal/location-radius-search";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ModalDefault from "../../../common/components/modal/modal-default";
import ButtonIntegration from "../../../common/components/button/button-integration";
import FleetShareTableMap
    from "../../../common/components/command-center-table/fleet-share-table/fleet-share-table-map";
import TrucksTableBadgeRental from "./trucks-table-badge-rental";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import {ChevronUpIcon as ChevronUpSolidIcon} from "@heroicons/react/20/solid";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import moment from "moment/moment";


class TrucksView extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1)
        this.tablePageDefaults = {
            behaviour: {
                rowSelect: checkPerm(Resources.TrucksInfo, UPDATE_PERM),
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'TruckNumber',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialog(s)
            selectedItem: null,
            confirmDialog: false,
            confirmDialogHideAnimation: false,

            // Batch actions
            selectedItems: {},
            selectedRows: {},
            batchActionDialog: false,
            batchFields: this.getBatchFields(),
            breakpoint: {},
            isTableFullScreen: false,
            isMapModalOpen: false,
            lastLocation: null
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ));
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
        this.fetchCompanyData();
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.resource.data && !this.props.resource.isLoading && prevProps.resource.isLoading) {
            const data = getProp(this.props.resource.data, 'list', [])

            if (!this.state.queryFilterFields.archived.value) {
                const rows = Object.values(Object.assign({}, this.state.selectedRows)).reduce((memo, it) => {

                    data.filter((item) => {
                        if (item.TruckID === it.TruckID) {
                            if (!item.ArchivedDate) {
                                memo[it.TruckID] = item;
                            }
                        }
                    })

                    return memo;
                }, {})
                this.setSelectedRows(rows)
            }
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchCompanyData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.Company
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'trucks_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'trucks_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_archive_truck')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {id: item[this.getPrimaryKey()]}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.TruckNumber]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))

                    if (!this.state.queryFilterFields.archived.value) {
                        const rows = Object.values(Object.assign({}, this.state.selectedRows)).reduce((memo, it) => {
                            if (it.TruckID !== item.TruckID) {
                                memo[it.TruckID] = it;
                            }
                            return memo;
                        }, {})
                        this.setSelectedRows(rows)
                    }
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_restore_truck')} ${item.TruckNumber}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item[this.getPrimaryKey()],
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Truck ${item.TruckNumber} restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleBulkEdit = (fields) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                IDs: Object.keys(this.state.selectedRows),
                Fields: FieldsManager.getFieldKeyValues(fields)
            },
            resource: Resources.TrucksBulkEdit,
            query: this.getQuery(),
            piggyResource: this.getResource()
        }))
        this.handleCloseBatchActionDialog()
        this.handleResetBatchFields()
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'TruckNumber'
        }
        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        if (name === 'radius' && value === true) {
            this.handleToggleLocationRadiusSearchDialog()
        } else {
            this.setState({
                queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
                offset: 0,
                paginationPage: 1
            }, () => {
                this.saveFilters()
                this.fetchData()
            })
        }
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleQuickView = (item) => {
        this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID))
    }

    handleActionUpdate = (item = null) => {
        this.handleLinkClick(`/trucks/info/${item[this.getPrimaryKey()]}`)
    }

    handleOnLoadClick = item => {
        this.handleLinkClick(`/loads/info/${item.OnLoad}`)
    }

    handleCreateUpdateResource = () => {
        this.handleLinkClick('/truck/create')
    }

    handleCloseBatchActionDialog = () => {
        this.setState({batchActionDialog: false})
    }

    handleResetBatchFields = () => {
        this.setState({
            selectedRows: {},
            selectedItems: {},
            batchFields: this.getBatchFields()
        })
    }

    handleToggleLocationRadiusSearchDialog = () => {
        this.setState({LocationRadiusSearchDialog: !this.state.LocationRadiusSearchDialog})
    }

    handleSelectRowClick = (item, event) => {
        const itemID = item.TruckID;

        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.TruckID])
            const lastSelectedItem = data.findIndex(it => it.TruckID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].TruckID]: data[i]})
                        selectedItems[data[i].TruckID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i].TruckID] = data[i]
                        Object.assign(selectedRows, {[data[i].TruckID]: data[i]})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.TruckID === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }
        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it.TruckID] = it
                memo[it.TruckID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.TruckID.toString())) {
                    delete selectedItems[it.TruckID]
                    delete selectedRows[it.TruckID]
                }
            })
        }

        this.setState({selectedRows})
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }
    /** Fields
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            StatusIDs: new Field('StatusIDs', '', [''], false, 'multi-select', {}, {
                isMulti: true,
                all: true
            }),

            archived: new Field('archived', '', [''], false, 'checkbox'),
            NotSoldRetired: new Field('NotSoldRetired', 1, [''], false, 'checkbox'),
            radius: new Field('radius', '', [''], false, 'checkbox'),
            TruckOwnershipTypeIDs: new Field('TruckOwnershipTypeIDs', '', [''], false, 'multi-select', {}, {
                all: true,
                isMulti: true
            }),
            OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], false, 'select-search', {}, {isClearable: true}),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            }),
        }
    }

    getFields = () => {
        return {
            TruckNumber: new Field('TruckNumber', '', ['empty']),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
                , omitSort: true
            }),
            ...(getSystemName() === 'main' && {
                StatusID: new Field('StatusID', '', [''], false, 'custom', {
                    render: (item) => (
                        <TrucksTableBadge
                            item={item}
                            translate={this.props.translate}
                        />
                    )
                })
            }),
            ...(getSystemName() === 'rental' && {
                RentStatusID: new Field('RentStatusID', '', [''], false, 'custom', {
                    render: (item) => (
                        <TrucksTableBadgeRental
                            item={item}
                            translate={this.props.translate}
                        />
                    )
                })
            }),
            LastGPSLocation: new Field('LastGPSLocation', '', [''], false, 'custom', {
                render: (item) => {
                    if (item.LocationDate) {
                        const LocationDate = moment(item.LocationDate);
                        const currentDate = moment();

                        const dateDifference = moment.duration(LocationDate.diff(currentDate)).asDays();

                        return (<CellButton
                            onClick={() => {
                                this.setState({isMapModalOpen: true, lastLocation: item})
                            }}
                        >
                            <Tippy content={this.props.translate("text.GPS_older_than_7_days")} disabled={dateDifference > -7}>
                            <div className={classNames("text-left", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                                {item?.CityName || item?.State || item?.PostalCode ?
                                    <div>{item?.CityName ? item.CityName + ", " : ""} {item?.State} {item?.PostalCode}</div> : ""}
                                {(item.LocationDate ?
                                    <div>({toFrontDateTimeFromUTC(item.LocationDate)})</div> : undefined)}
                            </div>
                            </Tippy>
                        </CellButton>)
                    } else {
                        return (<div className="flex items-center">No data</div>)
                    }
                }, omitSort: true
            }),
            SCAC: new Field('SCAC', '', ['five_char_max'], false, 'text', {hideTable: !getProp(this.props, 'secondResource.data.UseMultiSCAC', '')}),
            Manufacturer: new Field('Manufacturer', '', ['']),
            Model: new Field('Model', '', ['']),
            Year: new Field('Year', '', [''], false, 'text'),

            AssetCompanyStatus: new Field('AssetCompanyStatus', '', [''], false, 'select', {label: "NoteType"}),
            VIN: new Field('VIN', '', ['']),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', ['']),
            Office: new Field('Office', '', [''], false, 'text'),
            ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: "FleetGroupID"}),
            TruckOwnershipTypeID: new Field('TruckOwnershipTypeID', '', [''], false, 'select'),
            IsNonIFTA: new Field('IsNonIFTA', '', [''], false, 'checkbox', {addContainerClass: 'col-span-3'}),
            TruckWidth: new Field('TruckWidth', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckHeight: new Field('TruckHeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckUnloadedWeight: new Field('TruckUnloadedWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckGrossWeight: new Field('TruckGrossWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckNumberOfAxles: new Field('TruckNumberOfAxles', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                values: NUMBER_OF_AXLES
            }),
            CurrentMarkerValue: new Field('CurrentMarkerValue', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            LastCurrentMarkerValueDate: new Field('LastCurrentMarkerValueDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),

            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'datetimezone')
        }
    }

    getBatchFields = () => {
        return {
            SCAC: new Field('SCAC', '', [''], false, 'text', {}, {
                upperCaseOnly: true,
                max: SCAC_CODE_FIELD_LENGTH
            }),
            Notes: new Field('Notes', '', [], false, 'textarea'),
            Year: new Field('Year', '', [''], false, 'select'),
            Manufacturer: new Field('Manufacturer', '', ['']),
            Model: new Field('Model', '', ['']),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', ['']),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search'),
            TruckOwnershipTypeID: new Field('TruckOwnershipTypeID', '', [''], false, 'select'),
            CarbID: new Field('CarbID', '', ['']),
            CurrentMarkerValue: new Field('CurrentMarkerValue', '', [''], false, 'float_or_empty'),
            LastCurrentMarkerValueDate: new Field('LastCurrentMarkerValueDate', '', [''], false, 'date'),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search')
        }
    }

    getResource = () => {
        return Resources.Trucks
    }

    getPrimaryKey = () => {
        return 'TruckID'
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...this.state.queryFilterFields.radius.value,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                OwnedByOrganizationID: this.state.queryFilterFields.OwnedByOrganizationID.value?.value ?? "",
            })
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const integrations = getProp(resource.data, 'integrations', [])
        const count = getProp(resource.data, 'count', 0)
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length
        const hasIntegration = integrations.KeepTruckin?.hasIntegration
        const isLoading = !!resource.isLoading;

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page className="default-transition">
                    <PageHeader
                        title={translate('page.heading.trucks')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <div className="ml-auto flex">
                            <ButtonIntegration
                                id="integration-btn"
                                hasIntegration={hasIntegration}
                                history={this.props.history}
                                hasPerm={checkPerm(Resources.TrucksImport, READ_PERM)}
                                onClick={() => this.handleLinkClick(`/external-trucks`)}
                                integrationName={"GoMotive"}
                            >
                                {translate('text.ImportFromExternalSystem')}
                            </ButtonIntegration>
                        </div>
                    </PageHeader>

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            {/*<Tippy content={translate('text.download_template')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcelTemplate}
                                >
                                    <RectangleGroupIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                            selects={{
                                StatusIDs: TRUCK_STATUS_TYPES,
                                TruckOwnershipTypeIDs: getLookup('TruckOwnershipType'),
                                OwnedByOrganizationID: {
                                    api: 'api/' + Resources.OrganizationsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.LegalName,
                                        value: item.OrganizationID
                                    })
                                }
                            }}
                        />
                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(Resources.TrucksInfo, UPDATE_PERM) ? this.handleActionUpdate : null}

                            onView={this.handleQuickView}
                            onEdit={this.handleActionUpdate}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(Resources.TrucksInfo, READ_PERM)}
                            hasEditPerm={checkPerm(Resources.TrucksInfo, UPDATE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            actions={[
                                {
                                    action: (it) => {(it.StatusID >= 5) && this.archiveResource(it)},
                                    icon: ArchiveBoxIcon,
                                    visible: (it) => checkPerm(this.getResource(), DELETE_PERM) && !it.ArchivedDate,
                                    label: false,
                                    order: 30,
                                    disabled: false,
                                    title: (it) => {return it.StatusID >= 5 ? translate("btn.archive") : translate("Active asset can't be archived")},
                                    class: false,
                                    iconClass: (it) => {return it.StatusID >= 5 ? "w-5 h-5": "w-5 h-5 opacity-30"}
                                }
                            ]}

                            onSelectRow={this.handleSelectRowClick}
                            selectedRows={this.state.selectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableKey={'TruckID'}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >

                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey={'TruckID'}
                            fields={this.getFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                        >
                            <div className="flex items-center h-8">
                                <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>

                                    {checkPerm(Resources.TrucksInfo, UPDATE_PERM) && (
                                        <div className="flex items-center gap-x-1 pr-4">
                                            <Tippy content={translate('text.bulk_actions')}>
                                                <button
                                                    onClick={() => this.setState({batchActionDialog: true})}
                                                    disabled={!areSomeItemsSelected}
                                                    className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                                >
                                                    <PencilIcon
                                                        className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                                </button>
                                            </Tippy>
                                        </div>
                                    )}

                                    {checkPerm(Resources.TrucksColor, UPDATE_PERM) && (
                                        <div className="flex items-center gap-x-1 pl-4">
                                            <TableTagManager
                                                rowKey={'TruckID'}
                                                btnIconClass='w-5 h-5'
                                                customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                                selectedRows={this.state.selectedRows}
                                                resourceName={Resources.TrucksColor}
                                                piggyResourceName={this.getResource()}
                                                query={this.getQuery()}
                                                onAfterTagRows={() => {
                                                    this.setSelectedRows({})
                                                }}
                                                translate={translate}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </TableBulkActions>


                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new truck'}
                            btnLabel="Create truck"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleLinkClick(`/truck/create?prefilled=` + this.state.prefilled)
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    {this.state.batchActionDialog && (
                        <BulkBatchEdit
                            show={this.state.batchActionDialog}
                            fields={this.state.batchFields}
                            translate={translate}
                            onClose={this.handleCloseBatchActionDialog}
                            selectedItems={this.state.selectedRows}
                            itemKeyLabel={'TruckNumber'}
                            onSubmit={this.handleBulkEdit}
                        />
                    )}


                    <ModalDefault
                        show={!!this.state.LocationRadiusSearchDialog}
                        title={translate("text.SearchByRadius")}
                        widthClass="max-w-7xl"
                        onClose={this.handleToggleLocationRadiusSearchDialog}
                        hideDialogFooter={true}
                    >
                        {this.state.LocationRadiusSearchDialog && (
                            <LocationRadiusSearch
                                handleSearchByRadius={this.handleFilterInputChange}
                                {...this.props}
                                close={this.handleToggleLocationRadiusSearchDialog}
                                radius={this.state.queryFilterFields.radius.value}
                            />
                        )}
                    </ModalDefault>


                    <ModalDefault
                        show={this.state.isMapModalOpen}
                        widthClass={"max-w-7xl"}
                        title={translate("text.last_known_truck_location")}

                        closeButtonLabel={translate("btn.close")}
                        onClose={() => this.setState({isMapModalOpen: false})}
                    >
                        <FleetShareTableMap
                            translate={translate}
                            selectedLocation={this.state.lastLocation}
                        />
                    </ModalDefault>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TrucksView)
