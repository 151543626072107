import BuildingOfficeIcon from "@heroicons/react/20/solid/BuildingOfficeIcon";
import ArrowTopRightOnSquareIcon from "@heroicons/react/24/outline/ArrowTopRightOnSquareIcon";
import ArrowRightIcon from "@heroicons/react/20/solid/ArrowRightIcon";
import React from "react";
import {toFrontDate} from "../../../../../common/util/util-dates";
import Card from "../../../../../common/components/card";
import Badge from "../../../../../common/components/badge";
import {openInNewTab} from "../../../../../common/util/util-helpers";

export default function UpcomingLoadCard({upcomingLoad}) {
    return (
        <Card bodyClass="py-3 px-6 space-y-1 w-full">
            <div className="flex items-center relative">
                 <span className="text-tm-gray-600 font-bold mr-2">
                        #{upcomingLoad.LoadID}
                    </span>

                <Badge>
                    <div className="flex items-center  gap-x-2">
                        <BuildingOfficeIcon
                            className="w-5 h-5 text-tm-gray-500"
                        />
                        <div className="text-ellipsis overflow-hidden max-w-[10rem]">
                            {upcomingLoad.Customer}
                        </div>
                    </div>
                </Badge>

                <button
                    className="ml-auto"
                    onClick={() => openInNewTab("/loads/info/" + upcomingLoad.LoadID)}
                >
                    <ArrowTopRightOnSquareIcon className="btn-icon text-primary relative -top-0.5 right-1 w-8 h-8"/>
                </button>
            </div>
            <div className="flex gap-x-2 items-center">
                <div className="flex flex-col">
                    <div className="font-bold">
                        {upcomingLoad.PickupCityName}, {upcomingLoad.PickupState.split(" - ")?.[1]}
                    </div>

                    <div className="text-tm-gray-600">{toFrontDate(upcomingLoad.PickupDate)}</div>
                </div>

                <ArrowRightIcon className="w-5 h-5"/>

                <div className="flex flex-col">
                    <div className="font-bold">
                        {upcomingLoad.DestinationCityName}, {upcomingLoad.DestinationState.split(" - ")?.[1]}
                    </div>
                    <div className="text-tm-gray-600">{toFrontDate(upcomingLoad.DestinationDate)}</div>
                </div>
            </div>
        </Card>
    )
}
