import React, {useEffect, useMemo, useState} from "react";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import {classNames, cnv, getWeightUnitName,} from '../../../../../common/util/util-helpers';
import {cloneDeep, scrollErrorIntoView} from "../../../../../common/util/util-vanilla";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP
} from "../../../../../util/util-constants";
import {ScaleIcon, ViewColumnsIcon} from "@heroicons/react/20/solid";
import ClockIcon from "@heroicons/react/20/solid/ClockIcon";
import {getTimeFromServerDate, timePickerValueToServerTime, toFrontDate} from "../../../../../common/util/util-dates";
import moment from "moment";
import ModalDefault from "../../../../../common/components/modal/modal-default";
import InfoParagraph from "../../../../../common/components/info-paragraph";
import FormSubtitle from "../../../../../common/components/forms/form-subtitle";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import FieldCheckbox from "../../../../../common/components/fields/field-checkbox";
import {excludeFields, fieldsToHtml, fillFieldsFromData} from "../../../../../common/util/util-fields";
import ButtonIcon from "../../../../../common/components/button/button-icon";
import {checkFeatureFlag} from "../../../../../common/components/feature-flags";
import {LOAD_STATUS_IN_TRANSIT} from "../../../../../common/util/util-consts";
import LightBulbIcon from "@heroicons/react/24/outline/LightBulbIcon";

export default function LoadCompleteStopDialog({
                                                   show,
                                                   stops,
                                                   LoadID,
                                                   onClose,
                                                   onSubmit,
                                                   translate,
                                                   loadStatusID,
                                                   stopsCombined,
                                                   commodityData,
                                                   isLoadDropped,
                                                   canCompleteStop,
                                                   hidePlannedInfo,
                                                   submitButtonRef,
                                                   selectedLocation,
                                                   hideDialogFooter,
                                                   defaultWeightUnit,
                                                   commodityUnitType,
                                                   completeStopFormRef,
                                                   setLoadStepsHelperState,
                                                   isStopCompletedCheckboxRef,
                                                   suggestNextActionButtonRef,
                                                   selectedCombinedLocationIndex
                                               }) {
    if (!show) return null;

    const stopPalletData = getStopPalletData(selectedLocation, commodityData, defaultWeightUnit, stops);
    const [isStateDirty, setIsStateDirty] = useState(false);
    const isNextStopCompleted = !!stopsCombined?.[selectedCombinedLocationIndex + 1] && stopsCombined[selectedCombinedLocationIndex + 1].IsStopCompleted;

    function autoCompleteTimes() {
        let fieldsUpdate = cloneDeep(fields);
        fieldsUpdate.ActualArrivalDate.value = moment().format(DEFAULT_DATABASE_DATETIME_FORMAT);
        fieldsUpdate.ActualArrivalDateTime.value = getTimeFromServerDate(moment().format(DEFAULT_DATABASE_DATETIME_FORMAT).split(" ")[1]);

        fieldsUpdate.ActualDepartureDate.value = moment().add(1, "hour").format(DEFAULT_DATABASE_DATETIME_FORMAT);

        let departureTime = moment().add(1, "hour").format(DEFAULT_DATABASE_DATETIME_FORMAT);
        departureTime = getTimeFromServerDate(departureTime.split(" ")[1]);
        fieldsUpdate.ActualDepartureDateTime.value = departureTime;

        if (!fieldsUpdate.ActualDepartureDateTime.metadata) {
            fieldsUpdate.ActualDepartureDateTime.metadata = {}
        }

        fieldsUpdate.ActualDepartureDateTime.metadata = Object.assign(fieldsUpdate.ActualDepartureDateTime.metadata, {
            ActualArrivalDate: fieldsUpdate.ActualArrivalDate.value,
            ActualDepartureDate: fieldsUpdate.ActualDepartureDate.value
        })

        fieldsUpdate.IsStopCompleted.disabled = false;

        setFields(fieldsUpdate);
        setIsStateDirty(true);

        if (setLoadStepsHelperState) {
            setLoadStepsHelperState(fieldsUpdate);
        }
    }

    const [fields, setFields] = useState(getCompleteStopFields(selectedLocation, LoadID, stops, stopPalletData, defaultWeightUnit, commodityUnitType, autoCompleteTimes, translate, hidePlannedInfo))
    const [isTimeInAfterTimeOut, setIsTimeInAfterTimeOut] = useState(false);

    const handleInputChange = (name, value) => {
        let fieldsUpdate = fields;
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);

        if ("IsStopCompleted" === name) {
            fieldsUpdate = Object.values(fieldsUpdate).reduce((memo, it) => {
                if (
                    "totalCommodityPallets" !== it.name &&
                    !(selectedLocation.StopType.value === STOP_TYPE_DELIVERY && (it.name === "PalletsLoaded")) &&
                    !(selectedLocation.StopType.value === STOP_TYPE_PICKUP && (it.name === "PalletsUnLoaded"))
                ) {
                    it.disabled = value;
                }
                memo[it.name] = it;
                return memo
            }, {});
        }

        if ("ActualArrivalDate" === name || "ActualDepartureDate" === name) {
            fieldsUpdate.ActualDepartureDateTime.metadata = Object.assign(fieldsUpdate.ActualDepartureDateTime.metadata, {
                ActualArrivalDate: fieldsUpdate.ActualArrivalDate.value,
                ActualDepartureDate: fieldsUpdate.ActualDepartureDate.value
            });
        }

        let timeIn;
        if (!!fieldsUpdate.ActualArrivalDate.value && !!fieldsUpdate.ActualArrivalDateTime.value) {
            timeIn = fieldsUpdate.ActualArrivalDate.value.split(" ")[0] + " " + timePickerValueToServerTime(fieldsUpdate.ActualArrivalDateTime.value);
        }

        let timeOut;
        if (!!fieldsUpdate.ActualDepartureDate.value && !!fieldsUpdate.ActualDepartureDateTime.value) {
            timeOut = fieldsUpdate.ActualDepartureDate.value.split(" ")[0] + " " + timePickerValueToServerTime(fieldsUpdate.ActualDepartureDateTime.value);
        }

        if (!!timeIn && !!timeOut && moment(timeOut, DEFAULT_DATABASE_DATETIME_FORMAT).isBefore(moment(timeIn, DEFAULT_DATABASE_DATETIME_FORMAT))) {
            setIsTimeInAfterTimeOut(true)
        } else {
            setIsTimeInAfterTimeOut(false)
        }

        fieldsUpdate.IsStopCompleted.disabled =
            !fieldsUpdate.ActualArrivalDate.value || !fieldsUpdate.ActualArrivalDateTime.value ||
            !fieldsUpdate.ActualDepartureDate.value || !fieldsUpdate.ActualDepartureDate.value;

        setFields(fieldsUpdate);
        setIsStateDirty(true);

        if (setLoadStepsHelperState) {
            setLoadStepsHelperState(fieldsUpdate);
        }
    };

    const getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            setFields(validatedFields);

            scrollErrorIntoView(validatedFields);
        }
    }

    const fieldsHtml = fieldsToHtml(Object.values(excludeFields(Object.assign({}, fields), [getExcludedFields(selectedLocation?.StopType?.value)])), translate, handleInputChange);

    useEffect(() => {
        if (setLoadStepsHelperState) {
            setLoadStepsHelperState(fields);
        }
    }, [fields]);

    return (
        <ModalDefault
            show={show}
            addClass="max-h-[calc(100vh-15rem)] overflow-auto"
            modalClass="text-tm-gray-900 w-full inline-block align-bottom bg-popup rounded-card text-left shadow-xl transform transition-all sm:mt-20 sm:align-middle"
            hideDialogFooter={hideDialogFooter}
            title={translate('text.complete_stop')}
            widthClass="max-w-md"
            onClose={onClose}
            closeButtonLabel={translate('btn.close')}
            onButtonClick={() => onSubmit(getValidFields(), fields)}
            buttonLabel={translate("btn.save")}

            buttonDisabled={!isStateDirty || (!!selectedLocation?.IsStopCompleted?.value && !!fields?.IsStopCompleted?.value)}
            isLoading={false}
            submitButtonRef={submitButtonRef}
            options={(checkFeatureFlag('IsLoadHelperON') && loadStatusID === LOAD_STATUS_IN_TRANSIT && !stopsCombined?.[selectedCombinedLocationIndex]?.IsStopCompleted && (
                <ButtonIcon
                    onClick={() => {
                        if (suggestNextActionButtonRef.current) {
                            suggestNextActionButtonRef.current.click();
                        }
                    }}
                    tooltip={translate("btn.suggest_next_step")}>
                    <LightBulbIcon className="w-5 h-5 text-tm-gray-600"/>
                </ButtonIcon>
            ))
            }
        >
            <div className="grid gap-4 px-6 pb-6 grid-cols-2">
                {isTimeInAfterTimeOut && (
                    <div className="relative top-5 col-span-2 font-medium text-base">
                        <InfoParagraph type="danger" message={"Time In is after Time Out"}/>
                    </div>
                )}

                <div
                    ref={completeStopFormRef}
                    className="grid gap-4 grid-cols-2 col-span-2"
                >
                    {fieldsHtml}
                </div>

                <FormSubtitle
                    subtitle={translate('text.complete_stop')}
                />

                {canCompleteStop && (
                    <>
                        {!!isNextStopCompleted && (
                            <div className="col-span-full">
                                <InfoBar>
                                    {translate("text.stop_completion_note")}
                                </InfoBar>
                            </div>
                        )}

                        {!!isLoadDropped && (
                            <div className="col-span-full">
                                <InfoBar>
                                    {translate("text.complete_stop_dropped_note")}
                                </InfoBar>
                            </div>
                        )}

                        <div className="col-span-full">
                            <div className="h-10 flex items-center">
                                <label
                                    className={classNames(
                                        "mb-0 select-none p-2 flex items-center rounded-md w-full",
                                        !fields?.IsStopCompleted?.disabled ? "hover:bg-tm-gray-100 cursor-pointer" : "bg-tm-gray-200"
                                    )}

                                    htmlFor="isStopCompleted"
                                >
                                    <FieldCheckbox
                                        innerRef={isStopCompletedCheckboxRef}
                                        id="isStopCompleted"
                                        className={classNames("checkbox",
                                        )}
                                        onChange={handleInputChange}
                                        name="IsStopCompleted"
                                        value={fields?.IsStopCompleted?.value}
                                        disabled={fields?.IsStopCompleted?.disabled || isNextStopCompleted || isLoadDropped}
                                    />

                                    <span
                                        className="ml-2 flex flex-col text-sm font-semibold text-tm-gray-900"
                                    >
                                Is stop completed?
                                <p className="m-0 font-normal text-xs text-tm-gray-700 leading-none">
                                    {!fields?.IsStopCompleted?.disabled ? translate("text.stop_completed_note") : translate("text.stop_completed_disabled")}
                                </p>
                            </span>
                                </label>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </ModalDefault>
    );
}

function getCompleteStopFields(item, LoadID, stops, stopPalletData, defaultWeightUnit, commodityUnitTypeText, autoCompleteTimes, translate, hidePlannedInfo) {
    let isCompleted;
    const totalPalletsPlanned = stopPalletData.totalPalletsPlanned;
    const planedLoadedOnThisStop = stopPalletData.planedLoadedOnThisStop;
    const planedUnLoadedOnThisStop = stopPalletData.planedUnLoadedOnThisStop;

    const totalPalletsWeight = stopPalletData.totalPalletsWeight;

    const LoadedPalletsSoFar = stopPalletData.loadedSoFar;
    const unLoadedPalletsSoFar = stopPalletData.unLoadedSoFar;
    const stopWeightLoaded = stopPalletData.weightLoadedOnSelectedStop
    const stopWeightUnLoaded = stopPalletData.weightUnLoadedOnSelectedStop
    const hasBothLoadedAndUnloadedWeight = !!stopWeightLoaded && !!stopWeightUnLoaded;
    const hasLoadedOrUnloadedWeight = !!stopWeightLoaded || !!stopWeightUnLoaded;

    const stopsNumber = stops.length;

    let isTimeSaved = false;

    if (item) {
        item = FieldsManager.getFieldKeyValues(item);
        isTimeSaved = !!item.ActualArrivalDateTime;

        isCompleted = item.IsStopCompleted;

        item.PalletsLoaded = item?.PalletsLoaded ?? stopPalletData.planedLoadedOnThisStop;

        item.PalletsUnLoaded = item?.PalletsUnLoaded ?? stopPalletData.planedUnLoadedOnThisStop
    }

    // const selectedStop = stops.length && stops.find(it => it.LoadStopID.value === item.LoadStopID);
    // const hasAvailableFromDate = !!selectedStop.StopDate.value;

    const isStopCompletedDisabled = !item.ActualArrivalDateTime || !item.ActualDepartureDateTime;

    const fieldTemplates = {
        ActualArrivalDate: new Field('ActualArrivalDate', '', [''], isCompleted, 'datetime', {
            addContainerClass: 'col-start-1 w-[calc(100%+1rem+1px)]',
            label: "time_in",
            htmlBefore: () => <FormSubtitle subtitle={translate('text.time_in_out')}/>
        }, {isClearable: true, addClass: "rounded-r-none"}),
        ActualArrivalDateTime: new Field('ActualArrivalDateTime', '', [''], isCompleted, 'time-custom', {hideLabel: true}, {addClass: "rounded-l-none"}),
        ActualDepartureDate: new Field('ActualDepartureDate', '', [''], isCompleted, 'datetime', {
            addContainerClass: 'col-start-1 w-[calc(100%+1rem+1px)]',
            label: "time_out",
        }, {isClearable: true, addClass: "rounded-r-none"}),
        ActualDepartureDateTime: new Field('ActualDepartureDateTime', '', [''], isCompleted, 'time-custom', {
            hideLabel: true,
            htmlAfter: (it) => {
                return isTimeSaved || (!!it.metadata.ActualArrivalDate && !!it.metadata.ActualDepartureDate)
                    ? null
                    : (
                        <div className="relative col-span-full h-4">
                            <button
                                className="btn btn-text font-semibold absolute left-auto -top-2 right-0"
                                onClick={autoCompleteTimes}
                            >
                                <ClockIcon className="w-5 h-5 mr-1"/> Auto fill times
                            </button>
                        </div>
                    )
            }
        }, {addClass: "rounded-l-none"}),
        NotOnTime: new Field('NotOnTime', '', [''], isCompleted, hidePlannedInfo ? 'hidden': 'checkbox', {
            addContainerClass: 'col-span-2',
            note: translate('note.not_on_time'),
            labelType: "float",
            htmlAfter: () => (
                <div className="col-span-full">
                    <FormSubtitle subtitle={translate('text.Cargo')}/>

                    {!hidePlannedInfo && (
                        <React.Fragment>
                            <div className="space-y-4">
                                {(!!planedLoadedOnThisStop || !!planedUnLoadedOnThisStop || (stopsNumber > 2 && !!LoadedPalletsSoFar)) && (
                                    <InfoBar
                                        Icon={ViewColumnsIcon}
                                    >
                                        <div>
                                            {!!planedLoadedOnThisStop && (
                                                <div className="flex gap-x-1 flex-wrap">
                                                <span
                                                    className="font-bold">{stopPalletData.planedLoadedOnThisStop}</span>
                                                    <span className="font-bold">{commodityUnitTypeText}</span>
                                                    {"planed for pickup on this stop out of"}
                                                    <span className="font-bold">{totalPalletsPlanned}</span>
                                                    {"total."}
                                                </div>
                                            )}

                                            {!!planedUnLoadedOnThisStop && (
                                                <div className="flex gap-x-1 flex-wrap">
                                                <span
                                                    className="font-bold">{stopPalletData.planedUnLoadedOnThisStop}</span>
                                                    <span className="font-bold">{commodityUnitTypeText}</span>
                                                    {"planed for delivery on this stop out of"}
                                                    <span className="font-bold">{totalPalletsPlanned}</span>
                                                    {"total."}
                                                </div>
                                            )}

                                            {stopsNumber > 2 && (
                                                <div className="flex gap-x-1">
                                                    <span className="font-bold">{LoadedPalletsSoFar}</span>
                                                    <span className="font-bold">{commodityUnitTypeText}</span>

                                                    <span>
                                                loaded and
                                            </span>

                                                    <span className="font-bold">{unLoadedPalletsSoFar}</span>
                                                    <span className="font-bold">{commodityUnitTypeText}</span>

                                                    <span>
                                                unloaded so far.
                                            </span>
                                                </div>
                                            )}
                                        </div>
                                    </InfoBar>
                                )}

                                {
                                    !!item?.PalletsLoaded && item?.PalletsLoaded !== planedLoadedOnThisStop
                                    && (
                                        <InfoBar
                                            type={"warning"}
                                            Icon={ViewColumnsIcon}
                                        >

                                        </InfoBar>
                                    )
                                }

                                {
                                    !!item?.PalletsUnLoaded && item?.PalletsUnLoaded !== planedUnLoadedOnThisStop
                                    && (
                                        <InfoBar
                                            type={"warning"}
                                            Icon={ViewColumnsIcon}
                                        >
                                            {translate("text.planed_unit_type_do_not_match_loaded", [commodityUnitTypeText])}
                                        </InfoBar>
                                    )
                                }

                                {hasLoadedOrUnloadedWeight && (
                                    <InfoBar
                                        Icon={ScaleIcon}
                                    >
                                        <div className="space-y-1">
                                            <p>
                                                <React.Fragment>
                                                    {!!stopWeightLoaded && !!hasLoadedOrUnloadedWeight && (
                                                        <span className="font-bold">
                                                    {!!stopWeightLoaded && Number(stopWeightLoaded).toFixed(2)} {" "}
                                                            {defaultWeightUnit} {" "}
                                                            loaded
                                                            {" "}
                                                    </span>
                                                    )}

                                                    {hasBothLoadedAndUnloadedWeight && (
                                                        <span>
                                                       and
                                                    </span>
                                                    )}

                                                    {!!stopWeightUnLoaded && !!hasLoadedOrUnloadedWeight && (
                                                        <span className="font-bold">
                                                         {" "}
                                                            {!!stopWeightUnLoaded && Number(stopWeightUnLoaded).toFixed(2)} {" "}
                                                            {defaultWeightUnit} {" "}
                                                            unloaded
                                                            {" "}
                                                    </span>
                                                    )}

                                                    {!!hasLoadedOrUnloadedWeight && (
                                                        <span>
                                                         on this stop out of
                                                            {" "}
                                                    </span>
                                                    )}

                                                    <span className="font-bold">
                                                    {!!totalPalletsWeight && Number(totalPalletsWeight).toFixed(2)} {" "}
                                                        {defaultWeightUnit}
                                                        {" "}
                                                </span>

                                                    <span>
                                                    planned total.
                                                </span>
                                                </React.Fragment>
                                            </p>
                                        </div>
                                    </InfoBar>
                                )}
                            </div>

                        </React.Fragment>
                    )}
                </div>
            )
        }),
        LoadID: new Field('LoadID', LoadID, [''], isCompleted, 'hidden'),
        LoadStopID: new Field('LoadStopID', item?.LoadStopID?.value, [''], isCompleted, 'hidden'),
        IsStopCompleted: new Field('IsStopCompleted', "", [''], isStopCompletedDisabled, 'hidden'),

        PalletsLoaded: new Field('PalletsLoaded', "", ['float_or_empty'], isCompleted || item.StopType === STOP_TYPE_DELIVERY, 'float_or_empty', {
            addContainerClass: "col-start-1",
            labelRaw: translate("text.items_loaded", [commodityUnitTypeText])
        }),
        PalletsUnLoaded: new Field('PalletsUnLoaded', "", ['float_or_empty'], isCompleted || item.StopType === STOP_TYPE_PICKUP, 'float_or_empty', {labelRaw: translate("text.items_unloaded", [commodityUnitTypeText])}),
        SealNo: new Field('SealNo', '', [''], isCompleted, '', {addContainerClass: "col-start-1"}),
        BOLNumber: new Field('BOLNumber', '', [''], isCompleted, ''),

        SignedByName: new Field('SignedByName', '', [''], isCompleted, 'text', {
            htmlBefore: () => <FormSubtitle subtitle={translate('text.signed_by')}/>
        }),
        SignedByPhone: new Field('SignedByPhone', '', [''], isCompleted, 'text'),
    }

    let filledFields = fillFieldsFromData(fieldTemplates, item);

    // Override time zone adjustment
    filledFields.ActualArrivalDate.value = toFrontDate(item.ActualArrivalDateTime ? item.ActualArrivalDateTime : "");
    filledFields.ActualArrivalDateTime.value = getTimeFromServerDate(item.ActualArrivalDateTime ? item.ActualArrivalDateTime.split(" ")[1] : "");
    filledFields.ActualDepartureDate.value = toFrontDate(item.ActualDepartureDateTime ? item.ActualDepartureDateTime : "");
    filledFields.ActualDepartureDateTime.value = getTimeFromServerDate(item.ActualDepartureDateTime ? item.ActualDepartureDateTime.split(" ")[1] : "");

    return filledFields;
}

export function getStopPalletData(selectedLocation, commodityData, defaultWeightUnit, stops) {
    const stopsData = useMemo(() => {
        return Object.values(stops).map(it => FieldsManager.getFieldKeyValues(it));
    }, [stops]);

    let totalPalletsPlanned = 0;
    let loadedTotal = 0;
    let unLoadedTotal = stopsData.reduce((memo, it) => {
        loadedTotal += Number(it.PalletsLoaded)
        memo += Number(it.PalletsUnLoaded);
        return memo;
    }, 0);

    const loadedOnSelectedStop = selectedLocation?.PalletsLoaded?.value ? selectedLocation.PalletsLoaded.value : 0;
    const unLoadedOnSelectedStop = selectedLocation?.PalletsUnLoaded?.value ? selectedLocation.PalletsUnLoaded.value : 0;

    let planedLoadedOnThisStop = 0;
    let planedUnLoadedOnThisStop = 0;
    let loadedSoFar = 0;
    let unLoadedSoFar = 0;

    let totalPalletsWeight = 0;
    let palletWeightSoFar = 0;
    let totalStopPalletsWeight = 0;

    let weightLoadedOnSelectedStop = 0;
    let weightUnLoadedOnSelectedStop = 0;

    let stopsSoFar = [];

    stopsData.find(it => {
        stopsSoFar.push(it.LoadStopID);

        loadedSoFar += Number(it.PalletsLoaded ? it.PalletsLoaded : 0);
        unLoadedSoFar += Number(it.PalletsUnLoaded ? it.PalletsUnLoaded : 0);

        return it.LoadStopID === selectedLocation.LoadStopID.value
    })

    commodityData?.forEach(it => {
        totalPalletsPlanned += it.CountPallets;
        totalPalletsWeight += Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), defaultWeightUnit))
        let singlePalletWeight = totalPalletsWeight / totalPalletsPlanned;

        if (stopsSoFar.includes(it.LoadCommodityPickupID)) {
            palletWeightSoFar += it.Weight;
        }

        if (
            selectedLocation?.StopType?.value === STOP_TYPE_PICKUP &&
            it.LoadCommodityPickupID === selectedLocation.LoadStopID.value
        ) {
            planedLoadedOnThisStop += Number(it.CountPallets);
            totalStopPalletsWeight += Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), defaultWeightUnit));
            weightLoadedOnSelectedStop = selectedLocation.PalletsLoaded.value * singlePalletWeight;
        }

        if (
            selectedLocation?.StopType?.value === STOP_TYPE_DELIVERY &&
            it.LoadCommodityDestinationID === selectedLocation.LoadStopID.value
        ) {
            planedUnLoadedOnThisStop += Number(it.CountPallets);
            totalStopPalletsWeight += Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), defaultWeightUnit))
            weightUnLoadedOnSelectedStop = selectedLocation.PalletsUnLoaded.value * singlePalletWeight;
        }
    });

    return {
        totalPalletsPlanned: totalPalletsPlanned,
        unLoadedTotal: unLoadedTotal,
        loadedTotal: loadedTotal,
        loadedOnSelectedStop: loadedOnSelectedStop,
        unLoadedOnSelectedStop: unLoadedOnSelectedStop,

        planedLoadedOnThisStop: planedLoadedOnThisStop,
        planedUnLoadedOnThisStop: planedUnLoadedOnThisStop,

        loadedSoFar: loadedSoFar,
        unLoadedSoFar: unLoadedSoFar,
        totalPalletsWeight: totalPalletsWeight,
        palletWeightSoFar: palletWeightSoFar,
        totalStopPalletsWeight: totalStopPalletsWeight,
        weightLoadedOnSelectedStop: Number(weightLoadedOnSelectedStop.toFixed(2)),
        weightUnLoadedOnSelectedStop: Number(weightUnLoadedOnSelectedStop.toFixed(2)),
    };
}

const getExcludedFields = (stopType) => {
    switch (stopType) {
        case STOP_TYPE_DELIVERY:
            return 'PalletsLoaded'
        case STOP_TYPE_PICKUP:
            return 'PalletsUnLoaded'
        default:
            return "";
    }
}