import React, {useEffect, useRef, useState} from "react";
import NotificationsContainer from "./notifications-container";
import { useSelector } from "react-redux";
import {useHistory} from "react-router";

export default function Notifications({translate, dispatch, generateNotificationBaseOnTitle}) {
    let currentID = useRef(0);
    const history = useHistory();
    const ui = useSelector((state) => state.ui);
    const [notification, setNotification] = useState({});
    const [closeNotification, setCloseNotification] = useState({});
    const [clearNotifications, setClearNotifications] = useState(0);

    useEffect(() => {
        const newNotification = ui.notification;
        if (newNotification) {
            currentID.current = currentID.current + 1;

            setNotification(Object.assign(
                // Defaults parameters
                {
                    id: currentID.current,
                    isOpen: true,
                    isCurrent: true,
                    position: "bottom-left",
                    messageType: "success",
                    notificationType: "simple"
                },

                // Notification with parameters (overrides defaults)
                newNotification,

                //Handle defaults/exceptions
                !!generateNotificationBaseOnTitle && generateNotificationBaseOnTitle(ui.notification, dispatch, translate, history),
                !generateNotificationBaseOnTitle && {title: ui.notification.title}
            ))
        }
    }, [ui.notification])

    useEffect(() => {
        if (ui.closeNotification) {
            setCloseNotification(ui.closeNotification);
        }
    }, [ui.closeNotification]);

    useEffect(() => {
        if (!Object.keys(ui).length) {
            setClearNotifications(clearNotifications + 1);
        }
    }, [ui]);

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex px-4 py-6 sm:p-6 z-[100]"
        >
            <NotificationsContainer
                position="top"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="bottom-left"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="top-right"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="top-left"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="bottom"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="bottom-right"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />
        </div>
    )
}
