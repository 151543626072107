import React, {useEffect, useMemo, useState} from "react";
import CommandCenterTableStyles from "./command-center-table-styles";
import InnerTable from "./inner-table";
import {
    ArrowPathIcon,
    ArrowsPointingOutIcon,
    ArrowTopRightOnSquareIcon, ArrowTrendingUpIcon,
    BackwardIcon,
    BellAlertIcon,
    ForwardIcon,
    PaperClipIcon,
    PlayIcon
} from "@heroicons/react/24/outline";
import {
    ArrowsPointingInIcon,
    BarsArrowDownIcon,
    BarsArrowUpIcon,
    CheckCircleIcon as CheckCircleSolidIcon,
    InformationCircleIcon,
    PlayPauseIcon
} from "@heroicons/react/20/solid";
import DriverStatusBadge from "../badge/driver-status-badge";
import moment from "moment";
import NoRecords from "../no-records-found/no-records";
import Tooltip from "../tooltip";
import {useDispatch} from "react-redux";
import InnerGroupTable from "./inner-group-table";
import TableCellsIcon from "@heroicons/react/20/solid/TableCellsIcon";
import TableOptionsModal from "./table-options-modal";
import LocalStorage from "../../../common/util/localStorage";
import DisplayDataGrid from "../display-data/display-data-grid";
import StopUpdate from "./stop-update";
import PopOver from "../popover";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import FieldCheckbox from "../fields/field-checkbox";
import CalendarIcon from "@heroicons/react/20/solid/CalendarIcon";
import FleetShareTableMap from "./fleet-share-table/fleet-share-table-map";
import ModalDefault from "../modal/modal-default";
import {Loader} from "../loader";
import {checkPerm, classNames, getLookup, getProp, openInNewTab} from "../../util/util-helpers";
import {DEFAULT_DATABASE_DATE_FORMAT, DRIVER_STATUS_TYPES, READ_PERM} from "../../util/util-consts";
import {excludeFields, fillFieldsFromData, includeFields} from "../../util/util-fields";
import Fields from "../fields/fields-to-html";
import TableCard from "../resource-table/table-components/table-card";
import ActiveFilters from "../resource-table/table-components/active-filters";
import FieldsToHtml from "../../../common/components/fields/fields-to-html"
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import Badge from "../badge";
import {
    getUserDateTimeFormat,
    toFrontDate,
    toFrontDateTime,
    toFrontDateTimeFromUTC,
    toFrontTimeFromUTC
} from "../../util/util-dates";
import {genericMoneyFormatter} from "../../util/util-vanilla";
import CellButton from "../resource-table/table-components/cell-button";
import {showGlobalModal} from "../../../data/actions/ui";
import CommandCenterCommentModal from "./command-center-comment-modal";
import CopyToClipboardButton from "../buttons/copy-to-clipboard-button";
import {getInfoResource} from "../../../data/actions/infoResource";
import ArrowUpIcon from "@heroicons/react/20/solid/ArrowUpIcon";

export default function CommandCenterTable({
                                               list,
                                               comments,
                                               isLoading,
                                               isLoadingSilent,
                                               onFetchData,
                                               isAutoRefreshEnabled,
                                               setIsAutoRefreshEnabled,
                                               isClosestMatchListingActive,
                                               closesMatchData,
                                               driverMileageDiff,
                                               setIsClosestMatchDialogVisible,
                                               translate,
                                               info
                                           }) {


    const dispatch = useDispatch();
    const momentNow = moment();

    const [data, setData] = useState([]);
    const [tableStyles, setTableStyles] = useState({});
    const [rowHeights, setRowHeights] = useState({});


    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [isTableFullScreen, setIsTableFullScreen] = useState(false);


    const [queryFields, setQueryFields] = useState(() => getQueryFields(LocalStorage.get('command-center_state')?.queryFields ?? {}));

    const dates = useMemo(() => {
        let starDate = moment();
        let numberOfDays = 14;

        if (closesMatchData?.TargetDate) {
            starDate = moment(closesMatchData?.TargetDate, DEFAULT_DATABASE_DATE_FORMAT).subtract('3', 'day');
            numberOfDays = 7; // Three days before and three days after
        } else if (queryFields.StartDate.value) {
            starDate = moment(queryFields.StartDate.value, DEFAULT_DATABASE_DATE_FORMAT);
            numberOfDays = moment(queryFields.EndDate.value, DEFAULT_DATABASE_DATE_FORMAT).diff(starDate, 'days') + 1;
        }
        // if (queryFields.Dates.value === 'previous') {
        //     starDate = moment().subtract(1, 'week').startOf('isoWeek');
        //     numberOfDays = 7;
        // } else if (queryFields.Dates.value === 'PreviousTwo') {
        //     starDate = moment().subtract(2, 'week').startOf('isoWeek');
        //     numberOfDays = 14;
        // } else if (queryFields.Dates.value === 'next') {
        //     starDate = moment().add(1, 'week').startOf('isoWeek');
        //     numberOfDays = 7;
        // } else if (queryFields.Dates.value === 'current') {
        //     starDate = moment().startOf('isoWeek');
        //     numberOfDays = 7;
        // }

        return {
            list: new Array(numberOfDays).fill(undefined).map((date, i) => starDate.clone().add(i, "days").format(DEFAULT_DATABASE_DATE_FORMAT)),
            starDate: starDate
        };
    }, [queryFields, closesMatchData?.TargetDate])

    const [selectedItem, setSelectedItem] = useState({});
    const [selectedDriver, setSelectedDriver] = useState({});
    const [selectedComment, setSelectedComment] = useState({});
    const [isCellModalOpen, setIsCellModalOpen] = useState(false);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [isUpdateStopModalOpen, setIsUpdateStopModalOpen] = useState(false);
    const [isTableOptionsDialogOpen, setIsTableOptionsDialogOpen] = useState(false);
    const [isViewInfoModalOpen, setViewInfoModalOpen] = useState(false);

    const [tableOptions, setTableOptions] = useState(() => LocalStorage.get('command-center_state')?.tableOptions ?? getDefaultTableOptions());
    const fields = useMemo(() => getFields(), []);
    const [isFieldsDateDirty, setIsFieldsDateDirty] = useState(false);


    const [sort, setSort] = useState("ASC");
    const [sortBy, setSortBy] = useState("Driver");
    const [groupSort, setGroupSort] = useState("ASC");
    const [groupSortBy, setGroupSortBy] = useState("");
    const hasData = !!data?.[0]?.driver?.length && !isLoading;

    const selects = useMemo(
        () => {
            let dispatcherOptions = (list ?? []).reduce((memo, it) => {
                memo[it?.PrimaryDispatchID] = it?.PrimaryDispatch;
                return memo;
            }, {});

            return {
                DriverPositionType: getLookup("DriverPositionType"),
                DriverStatusID: DRIVER_STATUS_TYPES,
                GroupBy: {
                    "0": "None",
                    "1": "Contact group",
                    "2": "Dispatcher"
                },
                Dates: {
                    "PreviousTwo": "Previous two weeks",
                    "previous": "Previous week",
                    "current": "Current week",
                    "next": "Next week",
                    "upcoming": "Upcoming 2 weeks starting from today"
                },
                CDLInfo: {
                    PharmaApproved: "Ph",
                    HazmatApproved: "Haz",
                    HasTSAApproved: "TSA",
                    HasTWICCard: "TWIC"
                },
                Dispatchers: dispatcherOptions
            }
        }, [list])


    function handleQueryChange(name, value) {
        let queryFieldsUpdate = Object.assign({}, queryFields);
        if ("GroupBy" === name && "0" === value) {
            value = "";
        }

        if ("GroupBy" === name && "2" === value) {
            queryFieldsUpdate.Dispatchers.value = "";
        }

        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);
        setQueryFields(queryFieldsUpdate);
    }

    function updateStartEndDate(StartDate, EndDate) {
        let queryFieldsUpdate = Object.assign({}, queryFields);

        queryFieldsUpdate.StartDate.value = StartDate;
        queryFieldsUpdate.EndDate.value = EndDate;

        setQueryFields(queryFieldsUpdate);
    }

    function handleClearFiltersClick() {
        let queryFieldsUpdate = Object.assign({}, queryFields);
        queryFieldsUpdate = Object.values(queryFieldsUpdate).reduce((memo, it) => {
            if (it.name !== "StartDate" && it.name !== "EndDate") {
                it.value = "";
            }

            memo[it.name] = it;
            return memo;
        }, {});
        setQueryFields(queryFieldsUpdate);
    }

    function handleSort(columnName) {
        if (columnName === "PrimaryDispatch" || columnName === "ContactGroup") {
            if (groupSortBy === columnName) {
                setGroupSort(groupSort === "ASC" ? "DESC" : "ASC");
            } else {
                setGroupSort("DESC");
                setGroupSortBy(columnName);
            }
        } else {
            if (sortBy === columnName) {
                setSort(sort === "ASC" ? "DESC" : "ASC");
            } else {
                setSort("ASC");
                setSortBy(columnName);
            }
        }
    }

    function handleFullScreenToggle() {
        setIsTableFullScreen(!isTableFullScreen);
    }

    function getDataTotals(modifiedData) {
        return modifiedData.map(driver => {
            driver.Totals = Object.keys(driver?.Schedule).reduce((memo, key) => {
                const day = driver?.Schedule[key]; // Day should be renamed dayData and key to date
                if (!memo.PriceTotal) {
                    memo.PriceTotal = 0;
                }

                if (!memo.CalculationPriceTotal) {
                    memo.CalculationPriceTotal = 0;
                }

                if (!memo.TotalMilesTotal) {
                    memo.TotalMilesTotal = 0;
                }

                const dateCalcMethod = tableOptions.grossDateType === "pickup" ?
                    ['PICKED', 'PICKUP_PLANNED', 'PICKUP_IN_PROGRESS'] :
                    ['DELIVERED', 'DELIVERY_PLANNED', 'DELIVERY_IN_PROGRESS']

                if (dates.list.includes(key)) {
                    if (Array.isArray(day)) {
                        day.forEach((d) => {
                            if (dateCalcMethod.includes(d?.Type)) {
                                memo.PriceTotal = memo.PriceTotal + (d?.Load?.Price ?? 0);
                                memo.CalculationPriceTotal = memo.CalculationPriceTotal + (d?.Load?.CalculationPrice ?? 0);
                                memo.TotalMilesTotal = memo.TotalMilesTotal + (d?.Load?.TotalMiles ?? 0);
                            }
                        })
                    } else if (dateCalcMethod.includes(day?.Type)) { // Calculate only visible dates
                        memo.PriceTotal = memo.PriceTotal + (day?.Load?.Price ?? 0);
                        memo.CalculationPriceTotal = memo.CalculationPriceTotal + (day?.Load?.CalculationPrice ?? 0);
                        memo.TotalMilesTotal = memo.TotalMilesTotal + (day?.Load?.TotalMiles ?? 0);
                    }
                }

                return memo;
            }, {})
            return driver;
        });
    }

    function sortData(dataList = null, sort = 'ASC') {
        if (!dataList || !dataList?.[0]?.Schedule) {
            return [];
        }

        // if (isClosestMatchListingActive) {
        //     return [{
        //         Dispatcher: "",
        //         key: "0",
        //         ContactGroup: "",
        //         "driver": getDataTotals(dataList)
        //     }]
        // }

        let modifiedData = dataList;

        if (queryFields.query.value) {
            modifiedData = dataList.filter(it => {

                if (queryFields.GroupBy.value === "2") {
                    return it.Driver.toLowerCase().includes(queryFields.query.value.toLowerCase())
                        || (it?.Truck?.toLowerCase() ?? "").includes(queryFields.query.value.toLowerCase())
                        || (it?.DriverLoadID ?? "").toString().includes(queryFields.query.value)
                        || it?.PrimaryDispatch?.toLowerCase()?.includes(queryFields.query.value.toLowerCase());
                }

                return it.Driver.toLowerCase().includes(queryFields.query.value.toLowerCase())
                    || (it?.Truck?.toLowerCase() ?? "").includes(queryFields.query.value.toLowerCase())
                    || (it?.DriverLoadID ?? "").toString().includes(queryFields.query.value)
            });
        }

        let dispatcherOptions = Object.values(queryFields.Dispatchers.value ?? {})?.map(it => it.value);

        if (queryFields?.GroupBy?.value === "2" && dispatcherOptions.length) {

            modifiedData = modifiedData.filter(it => {
                return dispatcherOptions.includes(it.PrimaryDispatchID.toString());
            });
        }

        if (queryFields.DriverStatusID.value) {
            modifiedData = dataList.filter(it => queryFields.DriverStatusID.value.map(option => option.value).includes(it.DriverStatusID.toString()));

        }

        if (queryFields.DriverPositionType.value) {
            modifiedData = dataList.filter(it => queryFields.DriverPositionType.value.map(option => option.label).includes(it.DriverPositionType));
        }


        // if (queryFields.CDLInfo.value) { // OR case
        //     modifiedData = dataList.filter(it => !!queryFields.CDLInfo.value.find(option => !!it[option?.value]));
        // }

        if (queryFields.CDLInfo.value) { // AND case
            modifiedData = dataList.filter(it => !queryFields.CDLInfo.value.find(option => !it[option?.value]));
        }

        modifiedData = getDataTotals(modifiedData)

        if (!isClosestMatchListingActive) {
            modifiedData
                .sort((A, B) => {
                    let a = A[sortBy];
                    let b = B[sortBy];

                    if ("GrossMileage" === sortBy) {
                        a = A?.Totals?.PriceTotal ? A?.Totals?.PriceTotal : 0;
                        b = B?.Totals?.PriceTotal ? B?.Totals?.PriceTotal : 0;
                    }

                    if (a === null && b !== null) {
                        return sort === "ASC" ? 1 : -1;
                    } else if (a !== null && b === null) {
                        return sort === "ASC" ? -1 : 1;
                    } else {
                        return sort === "ASC" ? (a === b ? 0 : (a < b ? -1 : 1)) : (a === b ? 0 : (a > b ? -1 : 1));
                    }
                });
        }

        if (queryFields.GroupBy.value === "" || queryFields.GroupBy.value === "0") {
            return [{
                Dispatcher: "",
                key: "0",
                ContactGroup: "",
                "driver": modifiedData
            }]
        }


        if (queryFields.GroupBy.value === "1") {
            modifiedData = Object.values(modifiedData.reduce((memo, driver) => {

                if (!memo[driver.ContactGroup ?? ""]) {
                    memo[driver.ContactGroup ?? ""] = {
                        key: driver.ContactGroup ?? "",
                        ContactGroup: driver.ContactGroup ?? "",
                        "driver": []
                    }
                }

                memo[driver.ContactGroup ?? ""].driver.push(driver);

                return memo;
            }, {}));

            modifiedData.sort((a, b) => {
                const ContactGroupA = a.ContactGroup.toLowerCase();
                const ContactGroupB = b.ContactGroup.toLowerCase();
                if (ContactGroupA < ContactGroupB) return groupSort === "ASC" ? -1 : 1;
                if (ContactGroupA > ContactGroupB) return groupSort === "ASC" ? 1 : -1;
                return 0;
            });

            return modifiedData;
        }

        if (queryFields.GroupBy.value === "2") {
            modifiedData = Object.values(modifiedData.reduce((memo, driver) => {

                if (!memo[driver?.PrimaryDispatchID ?? 0]) {
                    memo[driver?.PrimaryDispatchID ?? 0] = {
                        Dispatcher: driver.PrimaryDispatch ?? "",
                        key: driver.PrimaryDispatchID ?? 0,
                        "driver": []
                    }
                }

                memo[driver?.PrimaryDispatchID ?? 0].driver.push(driver);

                return memo;
            }, {}));

            modifiedData.sort((a, b) => {
                const dispatcherA = a.Dispatcher.toLowerCase();
                const dispatcherB = b.Dispatcher.toLowerCase();
                if (dispatcherA < dispatcherB) return groupSort === "ASC" ? -1 : 1;
                if (dispatcherA > dispatcherB) return groupSort === "ASC" ? 1 : -1;
                return 0;
            });

            return modifiedData;
        }
    }


    function handleLocationClick(it) {
        setIsMapModalOpen(true);
        setSelectedLocation(it);
    }

    function getQueryFields(item = {}) {
        const fieldTemplates = {
            query: new Field('query', '', [''], false, 'search', {
                labelType: "float",
            }, {}),
            GroupBy: new Field('GroupBy', '', [''], false, 'select', {hideLabel: false, labelType: "float"}, {}),
            Dispatchers: new Field('Dispatchers', '', [''], false, 'select', {
                hideLabel: false, labelType: "float"
            }, {
                isMulti: true
            }),
            DriverStatusID: new Field('DriverStatusID', '', [''], false, 'multi-select', {
                hideLabel: false,
                labelType: "float"
            }, {
                isMulti: true,
                all: true
            }),
            DriverPositionType: new Field('DriverPositionType', '', [''], false, 'multi-select', {
                hideLabel: false,
                labelType: "float"
            }, {
                isMulti: true,
                all: true
            }),
            CDLInfo: new Field('CDLInfo', '', [''], false, 'multi-select', {
                hideLabel: false,
                labelType: "float"
            }, {
                isMulti: true,
                all: true
            }),
            StartDate: new Field('StartDate', moment().format(DEFAULT_DATABASE_DATE_FORMAT), [''], false, 'date', {
                addContainerClass: "col-span-6",
                hideLabel: false,
                labelType: "float"
            }, {
                minDate: momentNow.clone().startOf('isoWeek').subtract('4', 'week').format(DEFAULT_DATABASE_DATE_FORMAT),
                maxDate: momentNow.clone().startOf('isoWeek').add('2', 'week').format(DEFAULT_DATABASE_DATE_FORMAT)
            }),
            EndDate: new Field('EndDate', moment().add('2', 'week').format(DEFAULT_DATABASE_DATE_FORMAT), [''], false, 'date', {
                addContainerClass: "col-span-6",
                hideLabel: false,
                labelType: "float"
            }, {
                minDate: momentNow.clone().startOf('isoWeek').subtract('4', 'week').format(DEFAULT_DATABASE_DATE_FORMAT),
                maxDate: momentNow.clone().startOf('isoWeek').add('2', 'week').format(DEFAULT_DATABASE_DATE_FORMAT)
            }),
            // sort: new Field('sort', 'ASC', [''], false, 'text', {}, {}),
            // sortBy: new Field('sortBy', 'FirstName', [''], false, 'text', {}, {}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    const interpolateColor = (value) => {
        // Define the colors
        const green = [0, 128, 0]; // RGB for green
        const red = [255, 0, 0]; // RGB for red

        // Clamp value to the range of 0 to -10000
        const clampedValue = Math.max(-10000, Math.min(0, value));

        // Calculate interpolation factor (0 at 0, 1 at -10000)
        const factor = Math.abs(clampedValue) / 10000;

        // Interpolate between green and red
        const color = green.map((start, index) =>
            Math.round(start + factor * (red[index] - start))
        );

        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    };

    function getFields(compressedView = undefined) {
        if (compressedView !== false) {
            compressedView = tableOptions?.compressedView ?? 0
        }

        return {
            Driver: new Field('Driver', '', [''], false, "custom", {
                canSort: true,
                isSticky: true,
                width: 150,
                render: (it) => {
                    const value = (it.Escrows + it.UnpaidLoads) - it.Advances;
                    const color = value <= -10000 ? 'rgb(255, 0, 0)' : interpolateColor(value);

                    return (
                        <DriverTooltip
                        driver={it} translate={translate}>
                        <CellButton
                            className="leading-5 inline-flex py-0.5 -mx-2 px-2 rounded-btn text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary text-primary-shade font-btn hover:bg-primary-transparent focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                            onClick={() => {
                                dispatch(showGlobalModal('ViewDriverCard', it?.DriverID));
                            }}
                        >
                            {it.Driver}
                        </CellButton>
                        {checkPerm(Resources.CommandCenterNetValues, READ_PERM) && (
                            <div style={{ color: color }}>Net: {genericMoneyFormatter(value)}</div>
                        )}
                    </DriverTooltip>
                    )

                }
            }),
            DriverStatusID: new Field('DriverStatusID', '', [''], false, "custom", {
                canSort: true,
                isSticky: false,
                width: 135,
                render: (item) => <div className="flex flex-col">
                    <DriverStatusBadge
                        item={Object.assign(item, {StatusID: item.DriverStatusID})}
                        translate={translate}
                    />

                    <div className="flex flex-wrap mt-1 gap-0.5">
                        {!!item?.HasTSAApproved && (
                            <Badge type="custom" textClass="text-xs" paddingClass="px-0.5"
                                   addClass="bg-pink-600 text-white">TSA</Badge>
                        )}
                        {!!item?.HazmatApproved && (
                            <Badge type="custom" textClass="text-xs" paddingClass="px-0.5"
                                   addClass="bg-red-600 text-white">Haz</Badge>
                        )}
                        {!!item?.PharmaApproved && (
                            <Badge type="custom" textClass="text-xs" paddingClass="px-0.5"
                                   addClass="bg-gray-600 text-white">Ph</Badge>
                        )}
                        {!!item?.HasTWICCard && (
                            <Badge type="custom" textClass="text-xs" paddingClass="px-0.5"
                                   addClass="bg-violet-600 text-white">TWIC</Badge>
                        )}
                    </div>
                    {/*'TSA', 'Tank', 'Haz', 'Ph', 'TWIC'*/}
                </div>
            }),
            DriverPositionType: new Field('DriverPositionType', '', [''], false, "text", {
                canSort: true,
                isSticky: false,
                width: 170
            }),
            GrossMileage: new Field('GrossMileage', '', [''], false, "custom", {
                label: "Gross/Mileage",
                canSort: true,
                isSticky: false,
                width: 135,
                render: (it) => {
                    let value = it.Totals?.PriceTotal ? it.Totals?.PriceTotal : 0;

                    if (tableOptions.grossMileageType === 'dispatch') {
                        value = it.Totals?.CalculationPriceTotal ? it.Totals?.CalculationPriceTotal : 0;
                    }

                    const RPM = value / Math.round(it.Totals.TotalMilesTotal ?? 0);
                    // dispatch ili total price
                    return <Tooltip disabled={!value}
                                    content={
                                        <div>
                                            <p>RPM: {RPM?.toFixed(2)}</p>
                                            <div>
                                                Includes loads
                                                from &quot;{toFrontDate(queryFields.StartDate.value)}&quot; to &quot;{toFrontDate(queryFields.EndDate.value)}&quot; based
                                                on
                                                {tableOptions.grossDateType === "pickup" ? " pickup " : " delivery "} date.
                                                {tableOptions.grossMileageType === 'dispatch' ? " Amount is based on the dispatch pay. " : " Amount is based on the load price. "}
                                                Only loaded miles are used for calculation.
                                            </div>
                                            {/*tableOptions.grossMileageType === 'dispatch'*/}
                                            {/*tableOptions.grossMileageType === 'total'*/}
                                        </div>}
                    >
                        <div>
                            <div
                                className='flex gap-1'>{genericMoneyFormatter(value)} / {Math.round(it.Totals.TotalMilesTotal ?? 0)} mi
                            </div>

                            {!!RPM && !compressedView && (
                                <div>
                                    <span className="text-tm-gray-500 font-bold">RPM: {RPM?.toFixed(2)}</span>
                                </div>
                            )}
                        </div>
                    </Tooltip>
                }
            }),
            Truck: new Field('Truck', '', [''], false, "text", {
                canSort: true,
                isSticky: false,
                width: 110,
                label: "Truck",
                render: (it) =>
                    <div className={"flex flex-row"}>
                    <div className={"flex-1 content-center mr-1 hover:cursor-pointer"}>
                        <InformationCircleIcon
                            className="w-5 h-5 text-primary"
                            onClick={() => handleToggleViewModal(it)}
                        />
                    </div>
                    <div className={"grow"}>
                        <CellButton
                            className="block leading-5 py-0.5 -mx-2 px-2 rounded-btn text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary text-primary-shade font-btn hover:bg-primary-transparent focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                            onClick={() => {
                                dispatch(showGlobalModal('ViewTruckCard', it?.TruckID));
                            }}
                        >
                            <div>{it.Truck}</div>
                        </CellButton>
                        <CellButton
                            className="block leading-5 py-0.5 -mx-2 px-2 rounded-btn text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary text-primary-shade font-btn hover:bg-primary-transparent focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                            onClick={() => {
                                dispatch(showGlobalModal('ViewTrailerCard', it?.TrailerID));
                            }}
                        >
                            <div>{it.Trailer}</div>
                            {!compressedView && it.TrailerType ? (<div> ({it.TrailerType})</div>) : ''}
                        </CellButton>
                    </div>
                </div>
            }),
            LastLocation: new Field('LastLocation', '', [''], false, "custom", {
                label: "Last GPS/ELD location",
                canSort: false,
                isSticky: false,
                width: 170,
                render: (it) => {
                    const hasCoordinates = it.Latitude && it.Longitude;
                    return <button
                        disabled={!hasCoordinates}
                        onClick={() => (hasCoordinates ? handleLocationClick(it) : null)}
                        className={
                            classNames(
                                hasCoordinates
                                    ? "group h-full w-full py-0.5 px-2 rounded-sm text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary text-primary-shade font-btn hover:bg-primary-transparent focus-visible:bg-primary-transparent focus:outline-none focus:ring-0 leading-4"
                                    : undefined
                            )}
                    >
                        {it?.CityName || it?.State || it?.PostalCode ?
                            <div>{it?.CityName ? it.CityName + ", " : ""} {it?.State} {it?.PostalCode}</div> : ""}
                        {(it.LocationDate ? <div>({toFrontDateTimeFromUTC(it.LocationDate)})</div> : undefined)}
                    </button>
                }
            })

        }
    }

    function handleCommentClick(currentDay, selectedDriver = {}, selectedComment = {}) {
        setSelectedDriver(selectedDriver);
        setSelectedComment(selectedComment);
        setSelectedItem(currentDay);
        setIsCommentModalOpen(true);
    }

    function getSchedulerFields(dates) {
        return dates.list.reduce((memo, it, i) => {
            const currentDay = dates.starDate.clone().add(i, "days");
            const isCurrentDay = it === moment().format(DEFAULT_DATABASE_DATE_FORMAT);

            memo[it] = new Field(it, '', [''], false, "custom", {
                label: <div className="text-center py-1 leading-4 text-xs group relative">
                    <div
                        className={isCurrentDay ? "font-bold" : "font-light"}
                    >
                        {currentDay.format(getUserDateTimeFormat()?.split("/")?.slice(0, 2)?.join("/"))}
                    </div>
                </div>,
                width: "200",
                hasCommentAction: true,
                render: (data) => <SchedulerData date={it} data={data}/>
            })
            return memo;
        }, {});
    }

    function stripTimeFromDate(date) {
        if (!date) {
            return "";
        }

        date = toFrontDateTime(date).split(" ");
        date.splice(0, 1);

        return date;
    }

    function SchedulerData({date, data}) {
        const compressedView = tableOptions?.compressedView ?? 0
        const dateData = data?.["Schedule"]?.[date];

        return <>
            {driverMileageDiff[data["DriverID"]]?.DiffMileage && date === closesMatchData?.TargetDate && (
                <>
                    <div
                        className="absolute h-4 top-0 left-0 bg-tm-gray-800 font-bold text-inverse w-full flex items-center py-0.5 px-2 rounded-sm">
                        {Math.round(driverMileageDiff[data["DriverID"]]?.DiffMileage * 100) / 100} mi.
                    </div>

                    <div className="h-[1rem] flex-shrink-0"/>
                </>
            )}

            {Array.isArray(dateData) &&
                dateData.map((it, i) => processDateData(it, date, compressedView, i))
            }

            {!Array.isArray(dateData) && (
                processDateData(dateData, date)
            )}
        </>
    }

    function handleCellClick(dateData, date) {
        setIsFieldsDateDirty(false);
        const dateDataUpdate = Object.assign({}, dateData);
        if (dateDataUpdate?.Load) {
            dateDataUpdate.Load.Pickup = <div className="flex flex-col">
                <div>{dateData.Load.PickupCityName + ", " + dateData.Load.PickupState + " " + dateData.Load.PickupPostalCode}</div>
                <div className="font-normal">{toFrontDateTime(dateData.Load.PickupDate)}</div>
            </div>
            dateDataUpdate.Load.Destination = <div className="flex flex-col">
                <div>{dateData.Load.DestinationCityName + ", " + dateData.Load.DestinationState + " " + dateData.Load.DestinationPostalCode}</div>
                <div className="font-normal">{toFrontDateTime(dateData.Load.DestinationDate)}</div>
            </div>
        }
        setSelectedItem({dateData: dateDataUpdate, date});
        setIsCellModalOpen(true);
    }

    function toggleAutoRefresh() {
        setIsAutoRefreshEnabled(!isAutoRefreshEnabled);
    }

    function processDateData(dateData, date, compressedView = false, index) {
        switch (dateData?.Type) {
            case 'PARKED':
                return <div
                    key={index}
                    className="group flex text-left h-full w-full items-center justify-center rounded-sm bg-yellow-500/50 py-0.5 px-2 text-xs leading-4">
                    Parked
                    - {compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </div>
            case 'TIME_OFF':
                return <div
                    key={index}
                    className="group h-full w-full flex text-left items-center justify-start rounded-sm bg-yellow-500/50 py-0.5 px-2 text-xs leading-4">
                    Time off ({dateData?.Vacation})
                </div>
            case 'PICKED':
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group h-full w-full flex text-left flex-col justify-center rounded-sm bg-purple-500/20 hover:bg-purple-800/40 py-0.5 px-2 text-xs leading-4">
                    {!compressedView ? (<>Picked up
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            case 'IN_TRANSIT_COMPLETED':
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group flex text-left h-full w-full items-center justify-start rounded-sm bg-purple-500/20 hover:bg-purple-800/40 py-0.5 px-2 text-xs leading-4">
                    <span>In Transit #{dateData?.LoadNumber}</span>
                </button>
            case 'DELIVERED': {
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group h-full w-full flex text-left flex-col justify-center rounded-sm bg-purple-500/20 hover:bg-purple-800/40 py-0.5 px-2 text-xs leading-4">
                    {!compressedView ? (<>Delivered
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            }
            case 'PICKUP_IN_PROGRESS':
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group flex h-full w-full text-left items-center justify-center rounded-sm bg-green-500/20 hover:bg-blue-500/40 py-0.5 px-2 text-xs leading-4">
                    {!compressedView ? (<>Picking up
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            case 'IN_TRANSIT':
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group flex h-full w-full text-left items-center justify-start rounded-sm bg-green-500/20 hover:bg-green-500/40 py-0.5 px-2 text-xs leading-4">
                    <span>In Transit #{dateData?.LoadNumber}</span>
                </button>
            case 'DELIVERY_IN_PROGRESS': {
                const ETA = stripTimeFromDate(dateData?.ETATime);
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group h-full w-full flex text-left flex-col justify-center rounded-sm bg-green-500/20 hover:bg-green-500/40 py-0.5 px-2 text-xs leading-4">
                    {!compressedView ? (<>Delivering
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`} {ETA ? "(ETA " + stripTimeFromDate(dateData?.ETATime) + ")" : ""}
                </button>
            }
            case 'PICKUP_PLANNED':
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group h-full w-full flex text-left flex-col justify-center rounded-sm bg-blue-500/20 hover:bg-blue-500/40 py-0.5 px-2 text-xs leading-4">
                    {!compressedView ? (<>Pickup planned
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            case 'IN_TRANSIT_PLANNED':
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group flex h-full w-full text-left flex-col justify-center rounded-sm bg-blue-500/20 hover:bg-blue-500/40 py-0.5 px-2 text-xs leading-4">
                    In Transit #{dateData?.LoadNumber}
                </button>
            case 'DELIVERY_PLANNED':
                return <button
                    key={index}
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={(e) => {
                        if (e.button === 1) {
                            openInNewTab("/loads/info/" + dateData?.LoadID)
                        }
                    }}
                    className="group flex h-full w-full text-left flex-col justify-center rounded-sm bg-blue-500/20 hover:bg-blue-500/40 py-0.5 px-2 text-xs leading-4">
                    {!compressedView ? (<>Delivery planned
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            default:
                return null
        }
    }

    function getDefaultTableOptions() {
        return {
            style: {
                showVerticalLines: true,
                showHorizontalLines: true,
            },
            columns: Object.values(getFields(false)).reduce((memo, it) => {
                memo[it.name] = ({
                    name: it.name,
                    isSticky: !!it?.metadata?.isSticky,
                    hidden: ['DriverStatusID', 'DriverPositionType', 'TSA', 'Tank', 'Haz', 'Ph', 'TWIC'].includes(it.name),
                    defaultWidth: it.metadata.width,
                    width: ['TSA', 'Tank', 'Haz', 'Ph', 'TWIC'].includes(it.name) ? 0 : it.metadata.width
                })

                return memo;
            }, {}),
            grossMileageType: "dispatch",
            grossDateType: "pickup",
            compressedView: 0
        }
    }

    function handleToggleStickyCol(colName) {
        const tableOptionsUpdate = Object.assign({}, tableOptions);

        tableOptionsUpdate.columns[colName].isSticky = !tableOptionsUpdate.columns[colName].isSticky;

        setTableOptions(tableOptionsUpdate);
    }

    function handleToggleViewModal(selectedItem = null) {
        setSelectedItem(selectedItem)
        if (!isViewInfoModalOpen) {
            dispatch(getInfoResource({
                user: LocalStorage.get('user'),
                resource: Resources.DriverInfo,
                query: {id: selectedItem?.DriverID}
            }))
        }
        setViewInfoModalOpen(!isViewInfoModalOpen)
    }

    function getDateFilterLabel() {
        let defaultText = (!queryFields.StartDate.value || !queryFields.EndDate.value) && translate('text.select_date_range')
        let pickupOrDelivery = ''
        // if (queryFields.DateType.value !== '') {
        //     pickupOrDelivery = ' - ' + (queryFields.DateType.value == 1 ? 'Pickup' : 'Delivery')
        // }

        let from = '*'
        let to = '*'

        if (queryFields.StartDate.value) {
            from = toFrontDate(queryFields.StartDate.value);
        }

        if (queryFields.EndDate.value) {
            to = toFrontDate(queryFields.EndDate.value)
        }

        return defaultText ? defaultText : (from + ' - ' + to + pickupOrDelivery)
    }

    useEffect(() => {
        setData(sortData(Array.from(list), sort, dates));
        setRowHeights({});
    }, [list, sort, sortBy, groupSort, groupSortBy, queryFields, isLoading]);

    useEffect(() => {
        LocalStorage.set("command-center_state", {
            queryFields: Object.values(queryFields).reduce((memo, it) => {
                memo[it.name] = it.value;
                return memo;
            }, {}),
            tableOptions: tableOptions
        });
    }, [queryFields, tableOptions]);

    // useEffect(() => {
    //     const queryFieldsUpdate = Object.assign({}, queryFields);
    //     setQueryFields(Object.values(queryFieldsUpdate).reduce((memo, it) => {
    //         it.disabled = isClosestMatchListingActive;
    //         memo[it.name] = it;
    //         return memo;
    //     }, {}));
    // }, [isClosestMatchListingActive]);

    let groupByFields = {};

    if (queryFields.GroupBy.value) {
        groupByFields = queryFields.GroupBy.value === "1"
            ? {
                ContactGroup: new Field('ContactGroup', '', [''], false, "text", {
                    isSticky: true,
                    render: (it) => it.ContactGroup ? it.ContactGroup : "None",
                    canSort: true,
                })
            }
            : {
                PrimaryDispatch: new Field('PrimaryDispatch', '', [''], false, "text", {
                    render: (it) => <div className={"-rotate-45"}>{it.PrimaryDispatch ? it.PrimaryDispatch : "None"}</div>,
                    isSticky: true,
                    label: "Dispatcher",
                    canSort: true,
                })
            }
    }
    const hasGroups = queryFields.GroupBy.value !== "" && queryFields.GroupBy.value !== "0";
    const columns = Object.values(Object.assign({}, groupByFields, fields, getSchedulerFields(dates)));
    if (queryFields.EndDate.value) {
        queryFields.StartDate.props.maxDate = queryFields.EndDate.value;
    }

    if (queryFields.StartDate.value) {
        queryFields.EndDate.props.minDate = queryFields.StartDate.value;
    }

    const excludedFiltersLg = ['Dispatchers', 'DriverStatusID', 'DriverPositionType', 'CDLInfo', 'StartDate', 'EndDate'];
    const excludedFiltersSm = ['Dispatchers', 'StartDate', 'EndDate'];

    const infoData = getProp(info, "data", []);
    const infoLoading = getProp(info, "isLoading", []);

    const unitInfo =
        (selectedItem?.Driver ?
            ("Driver Information:"
            + "\n - Name: " + selectedItem?.Driver)
            + "\n - Phone: " + (infoData?.Phone ?? "/")
            : "")
        +
        (selectedItem?.Truck ?
            ("\nTruck Details:"
            + "\n - Truck Number: " + selectedItem?.Truck)
            + "\n - License Plate: " + (selectedItem?.TruckLicenceNumber ?? "/")
            : "")
        +
        (selectedItem?.Trailer ?
            ("\nTrailer Details:"
            + "\n - Trailer Number: " + selectedItem?.Trailer)
            + "\n - License Plate: " + (selectedItem?.TrailerLicenceNumber ?? "/")
            : "")
    
    return <>
        <CommandCenterTableStyles
            driverFields={fields}
            schedulerFields={getSchedulerFields(dates)}
            setTableStyles={setTableStyles}
            hasGroups={hasGroups}
            compressedView={tableOptions?.compressedView}
            queryFields={queryFields}
            tableOptions={tableOptions}
            selectedDate={closesMatchData?.TargetDate}
        />

        <div>
            <ActiveFilters
                excludedFields={['StartDate', 'EndDate']}
                filterFields={queryFields}
                onLabelClick={handleQueryChange}
                onClearFiltersClick={handleClearFiltersClick}
                translate={translate}
            />
        </div>

        <TableCard
            addClass={
                classNames(
                    isTableFullScreen
                        ? "fixed inset-1 z-50"
                        : undefined
                )}
        >
            <header
                className="md:px-4 py-4 md:ring-1 ring-black ring-opacity-5 ring-b-none rounded-t-card md:border-b border-tm-gray-300">
                <div className="flex justify-between">
                    <div className="flex gap-2 pt-2">
                        <div className="grid 3xl:hidden grid-cols-2 3xl:grid-cols-5 gap-2 flex-1 max-w-[100rem]">
                            <FieldsToHtml
                                fieldsState={excludeFields(queryFields, excludedFiltersLg)}
                                onInputChange={handleQueryChange}
                                selects={selects}
                                translate={translate}
                            />
                        </div>

                        <div className="hidden 3xl:grid grid-cols-3 3xl:grid-cols-5 gap-2 flex-1 max-w-[100rem]">
                            <FieldsToHtml
                                fieldsState={excludeFields(queryFields, excludedFiltersSm)}
                                onInputChange={handleQueryChange}
                                selects={selects}
                                translate={translate}
                            />
                        </div>

                        {isClosestMatchListingActive && (
                            <button
                                onClick={() => setIsClosestMatchDialogVisible(true)}
                                className="text-opacity-90 form-control p-0 w-auto text-tm-gray-700 flex items-center py-2 px-4">
                                <CalendarIcon className="w-4 h-4 -ml-1 mr-1"/>
                                {toFrontDate(closesMatchData?.TargetDate)}
                                <Tooltip content="Displaying three days before, and after the selected date">
                                    <InformationCircleIcon className="ml-1 w-4 h-4 text-primary"/>
                                </Tooltip>
                            </button>
                        )}

                        {!isClosestMatchListingActive && (
                            <PopOver
                                className="relative"
                                btnClass="text-opacity-90 form-control p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-3"
                                BtnIcon={CalendarIcon}
                                chevronIcon={true}
                                btnLabel={getDateFilterLabel()}
                                btnIconClass={classNames("h-4 w-4 mr-1", queryFields?.StartDate?.value && queryFields?.EndDate?.value ? "hidden 2xl:block" : undefined)}
                                chevronIconClass={
                                    classNames(
                                        "ml-2 h-4 w-4 text-tm-gray-700 group-hover:text-opacity-80 transition ease-in-out duration-150",
                                        queryFields?.StartDate?.value && queryFields?.EndDate?.value ? "hidden 2xl:block" : undefined
                                    )}
                                widthClass="max-w-lg"
                                positionClass="absolute translate-x-0 left-0"
                            >
                                <div
                                    className="bg-popup rounded-card border border-tm-gray-200 grid grid-cols-12 gap-4 p-6">


                                    <Fields
                                        fieldsState={includeFields(queryFields, ['StartDate', 'EndDate'])}
                                        onInputChange={handleQueryChange}
                                        translate={translate}
                                    />

                                    <button
                                        disabled={isClosestMatchListingActive}
                                        className="btn btn-outline-secondary col-span-6 justify-center"
                                        onClick={() => updateStartEndDate(
                                            momentNow.clone().format(DEFAULT_DATABASE_DATE_FORMAT),
                                            momentNow.clone().format(DEFAULT_DATABASE_DATE_FORMAT)
                                        )}
                                    >
                                        <ArrowUpIcon className="w-5 h-5 mr-1 -ml-1"/> Today
                                    </button>

                                    <button
                                        disabled={isClosestMatchListingActive}
                                        className="btn btn-outline-secondary col-span-6 justify-center"
                                        onClick={() => updateStartEndDate(
                                            momentNow.clone().format(DEFAULT_DATABASE_DATE_FORMAT),
                                            momentNow.clone().startOf('isoWeek').add('1', 'week').subtract('1', "day").format(DEFAULT_DATABASE_DATE_FORMAT)
                                        )}
                                    >
                                        <ArrowTrendingUpIcon className="w-5 h-5 mr-1 -ml-1"/> Remainder of the week
                                    </button>

                                    <button
                                        disabled={isClosestMatchListingActive}
                                        className="btn btn-outline-secondary col-span-6 justify-center"
                                        onClick={() => updateStartEndDate(
                                            momentNow.clone().startOf('isoWeek').subtract('1', 'week').format(DEFAULT_DATABASE_DATE_FORMAT),
                                            momentNow.clone().startOf('isoWeek').subtract('1', "day").format(DEFAULT_DATABASE_DATE_FORMAT)
                                        )}
                                    >
                                        <PlayIcon className="w-5 h-5 mr-1 -ml-1 rotate-180"/> Previous week
                                    </button>

                                    <button
                                        disabled={isClosestMatchListingActive}
                                        className="btn btn-outline-secondary col-span-6 justify-center"
                                        onClick={() => updateStartEndDate(
                                            momentNow.clone().startOf('isoWeek').format(DEFAULT_DATABASE_DATE_FORMAT),
                                            momentNow.clone().startOf('isoWeek').add('1', 'week').subtract('1', "day").format(DEFAULT_DATABASE_DATE_FORMAT)
                                        )}
                                    >
                                        <PlayIcon className="w-5 h-5 mr-1 -ml-1"/> Current week
                                    </button>

                                    <button
                                        disabled={isClosestMatchListingActive}
                                        className="btn btn-outline-secondary col-span-6 justify-center"
                                        onClick={() => updateStartEndDate(
                                            momentNow.clone().startOf('isoWeek').subtract('2', 'week').format(DEFAULT_DATABASE_DATE_FORMAT),
                                            momentNow.clone().startOf('isoWeek').subtract('1', 'day').format(DEFAULT_DATABASE_DATE_FORMAT)
                                        )}
                                    >
                                        <BackwardIcon className="w-5 h-5 mr-1 -ml-1"/> Previous two weeks
                                    </button>

                                    <button
                                        disabled={isClosestMatchListingActive}
                                        className="btn btn-outline-secondary col-span-6 justify-center"
                                        onClick={() => updateStartEndDate(
                                            momentNow.clone().startOf('isoWeek').add('1', 'week').format(DEFAULT_DATABASE_DATE_FORMAT),
                                            momentNow.clone().startOf('isoWeek').add('2', 'week').subtract('1', 'day').format(DEFAULT_DATABASE_DATE_FORMAT)
                                        )}
                                    >
                                        <ForwardIcon className="w-5 h-5 mr-1 -ml-1"/> Next week
                                    </button>
                                </div>
                            </PopOver>
                        )}
                    </div>

                    <div className="pt-2 flex items-center gap-2 shrink-0">
                        <button className={
                            classNames(
                                "btn btn-header",
                                isTableFullScreen ? "bg-primary text-primary-contrast hover:text-primary-contrast" : undefined
                            )
                        }
                                onClick={handleFullScreenToggle}
                        >
                            {!isTableFullScreen && (
                                <ArrowsPointingOutIcon className="w-5 h-5"/>
                            )}

                            {!!isTableFullScreen && (
                                <ArrowsPointingInIcon className="w-5 h-5"/>
                            )}

                        </button>

                        <div className="flex relative">
                            <button
                                className={
                                    classNames(
                                        "btn btn-header z-10 rounded-r-none relative -right-px"
                                    )
                                }
                                onClick={() => onFetchData(closesMatchData)}
                            >
                                <ArrowPathIcon className={
                                    classNames(
                                        "w-5 h-5",
                                        isLoading || isLoadingSilent ? "animate-spin" : undefined,
                                        isAutoRefreshEnabled ? "text-primary" : "text-tm-gray-700"
                                    )
                                }/>
                            </button>

                            <PopOver
                                btnClass="relative inline-flex items-center rounded-r-btn bg-inverse px-2 py-2 text-gray-400 ring-1 ring-inset ring-tm-gray-300 hover:bg-tm-gray-50 hover:z-10 hover:ring-tm-gray-600"
                                BtnIcon={ChevronDownIcon}
                                btnIconClass="w-5 h-5 text-tm-gray-400"
                                widthClass="max-w-xs"
                                positionClass="absolute translate-x-0 right-0"
                            >
                                <div className="bg-popup border border-tm-gray-300 rounded-card p-4">
                                    <label
                                        className="h-10 flex flex-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">
                                        <FieldCheckbox
                                            className="checkbox"
                                            onChange={toggleAutoRefresh}
                                            value={isAutoRefreshEnabled}
                                        />

                                        <span className="ml-2 text-sm select-none">
                                            {translate('field.isAutoRefreshEnabled')}
                                        </span>
                                    </label>
                                </div>
                            </PopOver>
                        </div>

                        <button
                            onClick={() => setIsTableOptionsDialogOpen(true)}
                            className="btn btn-header"
                        >
                            <TableCellsIcon className="w-5 h-5"/>
                        </button>
                    </div>
                </div>
            </header>

            {isLoading && (
                <div className="px-6 py-12 flex items-center justify-center">
                    <Loader disableContainer={true}/>
                </div>
            )}

            {!(isLoading || !hasData) && (
                <div className="w-full border-b border-tm-gray-200 max-h-full overflow-auto transform-gpu">
                    <div className="w-full border-x border-tm-gray-200" style={tableStyles}>
                        <div
                            className="flex relative bg-inverse rounded-tl-card rounded-tr">
                            <div
                                className="flex flex-col relative shrink-0 border-r-4 border-tm-gray-200 divide-y-4 divide-tm-gray-200">

                                <header className="sticky top-0 z-20">
                                    <div className="flex">
                                        {
                                            columns.filter(col => !tableOptions.columns?.[col.name]?.hidden).map(col => {
                                                    const canSort = !!col?.metadata?.canSort && !isClosestMatchListingActive;
                                                    const DynamicElement = canSort ? "button" : "div"

                                                    return <div
                                                        key={col.name}

                                                        className={classNames(
                                                            "group relative cc-header col-" + col.name
                                                        )}
                                                    >
                                                        <div className="flex flex-col w-full">
                                                            {["DriverStatusID", "DriverPositionType", "GrossMileage", "Truck", "LastLocation"].includes(col.name) && (
                                                                <div
                                                                    className="absolute inset-0 border border-transparent group-hover:border-primary rounded-t-btn"
                                                                >
                                                                    <Tooltip
                                                                        content={tableOptions?.columns?.[col?.name]?.isSticky ? "Unfreeze column" : "Freeze column"}>
                                                                        <button
                                                                            onClick={() => handleToggleStickyCol(col.name)}
                                                                            className="hidden group-hover:z-10 group-hover:block absolute bottom-1.5 left-0">
                                                                            <div

                                                                                className="btn-icon p-1 bg-primary text-primary-contrast">
                                                                                <PaperClipIcon className="w-5 h-5"/>
                                                                            </div>
                                                                        </button>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            <DynamicElement
                                                                className={canSort ? "-mx-0.5 px-0.5 py-1 mb-0.5 hover:bg-tm-gray-200 flex items-center relative z-1 rounded-btn leading-3" : undefined}
                                                                onClick={canSort ? (() => handleSort(col.name)) : undefined}>
                                                                {col?.metadata?.label ?? translate("field." + col.name)}

                                                                {canSort && (
                                                                    <div className={
                                                                        classNames(
                                                                            col.name === sortBy || col.name === groupSortBy ? "text-tm-gray-800" : "text-tm-gray-300",
                                                                            "inline-flex flex-shrink-0 ml-2 w-5 h-5"
                                                                        )
                                                                    }>
                                                                        {(col.name === "PrimaryDispatch" || col.name === "ContactGroup") && (
                                                                            <>
                                                                                {!(col.name === groupSortBy && groupSort === "DESC") && (
                                                                                    <BarsArrowUpIcon/>
                                                                                )}

                                                                                {col.name === groupSortBy && groupSort === "DESC" && (
                                                                                    <BarsArrowDownIcon/>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        {col.name !== "PrimaryDispatch" && col.name !== "ContactGroup" && (
                                                                            <>
                                                                                {!(col.name === sortBy && sort === "DESC") && (
                                                                                    <BarsArrowUpIcon/>
                                                                                )}

                                                                                {col.name === sortBy && sort === "DESC" && (
                                                                                    <BarsArrowDownIcon/>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </DynamicElement>
                                                        </div>
                                                    </div>
                                                }
                                            )
                                        }
                                    </div>
                                </header>

                                {data?.[0] && hasData && (
                                    data.map((it, i) => {
                                        return <div key={it.key} className="flex">
                                            {hasGroups && (
                                                <InnerGroupTable
                                                    hideFooter={true}
                                                    // onSort={() => setGroupSort(groupSort === "ASC" ? "DESC" : "ASC")}
                                                    setHeights={rowHeights}

                                                    tableIndex={i}
                                                    fields={groupByFields}
                                                    data={data}
                                                    sort={groupSort}
                                                    translate={translate}
                                                />
                                            )}

                                            <InnerTable
                                                hideHeader={i}
                                                prevRowCount={data?.[i - 1]}
                                                onSort={handleSort}
                                                tableIndex={i}
                                                tableOptions={tableOptions}
                                                fields={Object.assign({}, getFields(), getSchedulerFields(dates))}
                                                onDayCommentClick={handleCommentClick}
                                                closesMatchData={closesMatchData}
                                                comments={comments}
                                                data={data}
                                                sort={sort}
                                                sortBy={sortBy}
                                                translate={translate}
                                            />
                                        </div>
                                    })

                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <NoRecords
                show={(!list || list.length === 0) && !isLoading}
                addClass="p-10"
                title="No Data Available"
                text={queryFields.query.value ? "No results available for '" + queryFields.query.value + "'" : "We're sorry, but there is currently no data available for this page."}
            />
        </TableCard>

        <ModalDefault
            show={isTableOptionsDialogOpen}
            widthClass={"max-w-2xl"}
            title={translate("text.display_options")}
            limitHeight={true}
            closeButtonLabel={translate("btn.close")}
            onClose={() => {
                setIsTableOptionsDialogOpen(false);
            }}
        >
            <TableOptionsModal
                translate={translate}
                queryFields={excludeFields(queryFields, queryFields.GroupBy.value === "2" ? [] : ['Dispatchers'])}
                handleQueryChange={handleQueryChange}
                tableOptions={tableOptions}
                setTableOptions={setTableOptions}
                selects={selects}
            />
        </ModalDefault>

        <ModalDefault
            show={isCellModalOpen}
            widthClass={"max-w-3xl"}
            title={moment(selectedItem?.date)?.format('dddd') + ", " + toFrontDate(selectedItem?.date)}
            limitHeight={true}
            closeButtonLabel={isFieldsDateDirty ? translate("btn.cancel") : translate("btn.close")}
            onClose={() => {
                setIsCellModalOpen(false);
            }}
        >
            <div className="p-4">
                {selectedItem?.dateData?.Load && (
                    <div className="mb-8">
                        <DisplayDataGrid
                            addGridClass={"grid-cols-2 border-b border-tm-gray-300 p-5 text-tm-gray-700 font-bold"}
                            displayList={Object.values({
                                LoadNumber: new Field('LoadNumber', '', [''], false, "text",),
                                NumberOfStops: new Field('NumberOfStops', '', [''], false, "text"),
                                Pickup: new Field('Pickup', '', [''], false, "text", {
                                    clipboardText: selectedItem?.dateData?.Load?.PickupCityName + ", " + selectedItem?.dateData?.Load?.PickupState + " " + selectedItem?.dateData?.Load?.PickupPostalCode
                                }),
                                Destination: new Field('Destination', '', [''], false, "text", {
                                    clipboardText: selectedItem?.dateData?.Load?.DestinationCityName + ", " + selectedItem?.dateData?.Load?.DestinationState + " " + selectedItem?.dateData?.Load?.DestinationPostalCode,
                                }),

                                Price: new Field('Price', '', [''], false, "money"),
                                CalculationPrice: new Field('CalculationPrice', '', [''], false, "money"),
                                AccesorialAmount: new Field('AccesorialAmount', '', [''], false, "money"),
                                EmptyMiles: new Field('EmptyMiles', '', [''], false, 'float', {addContainerClass:"row-start-5"}),
                                TotalMiles: new Field('TotalMiles', '', [''], false, 'float',{addContainerClass:"row-start-5"}),
                                DriverPayAmount: new Field('DriverPayAmount', '', [''], false, "money",{addContainerClass:"row-start-6"}),
                                AccessorialDriverPayAmount: new Field('AccessorialDriverPayAmount', '', [''], false, "money",{addContainerClass:"row-start-6"})
                            })}
                            data={selectedItem?.dateData?.Load ?? {}}
                            translate={translate}
                        />
                    </div>
                )}

                {selectedItem?.dateData?.Load && (
                    <div className="flex gap-4 justify-center">
                        <div>
                            <button
                                className="btn btn-outline-secondary"
                                onClick={() => openInNewTab("/loads/info/" + selectedItem?.dateData?.LoadID)}>
                                <ArrowTopRightOnSquareIcon className="w-5 h-5 -ml-1 mr-1"/> Open load in another tab
                            </button>
                        </div>

                        <div>
                            <button
                                className="btn btn-outline-secondary"
                                onClick={() => setIsUpdateStopModalOpen(true)}>
                                <BellAlertIcon className="w-5 h-5 -ml-1 mr-1"/> Create a load update event
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {selectedItem?.dateData?.Load && (
                <StopUpdate
                    show={isUpdateStopModalOpen}
                    loadData={selectedItem?.dateData?.Load}
                    onClose={() => setIsUpdateStopModalOpen(false)}
                    piggyResource={Resources.UnitsSchedule}
                    getQuery={() => {
                    }}
                    stopUpdatePiggyResource={Resources.UnitsSchedule}
                    translate={translate}
                />
            )}
        </ModalDefault>

        <CommandCenterCommentModal
            isClosestMatchListingActive={isClosestMatchListingActive}
            closesMatchData={closesMatchData}
            selectedItem={selectedItem}
            selectedDriver={selectedDriver}
            isCommentModalOpen={isCommentModalOpen}
            setIsCommentModalOpen={setIsCommentModalOpen}
            setSelectedDriver={setSelectedDriver}
            selectedComment={selectedComment}
            translate={translate}
        />

        <ModalDefault
            show={isMapModalOpen}
            widthClass={"max-w-7xl"}
            title={translate("text.last_known_truck_location")}

            closeButtonLabel={translate("btn.close")}
            onClose={() => setIsMapModalOpen(false)}
        >
            <FleetShareTableMap
                selectedLocation={selectedLocation}
            />
        </ModalDefault>

        <ModalDefault
            show={isViewInfoModalOpen}
            limitHeight={true}
            title={
                <div className={"flex"}>
                    {translate("modal_heading.unitInfo")}

                    <CopyToClipboardButton
                        addClass={"bottom-1"}
                        clipboardText={unitInfo}
                        translate={translate}
                    />

                </div>
            }
            isLoading={infoLoading}
            widthClass={"max-w-screen"}
            translate={translate}
            onClose={() => handleToggleViewModal()}
            closeButtonLabel={translate('btn.close')}
        >
            <div className="p-5 bg-tm-gray-100">
                <div className="mb-8">
                    <div
                        className="text-base p-5 bg-inverse rounded-card whitespace-pre-line shadow"
                    >
                        {unitInfo}
                    </div>
                </div>
            </div>
        </ModalDefault>
    </>
}

function DriverTooltip({driver, translate, children}) {
    return <Tooltip content={<div className="flex flex-col gap-y-2">
        <div className="flex items-center">
            <div className="w-32">Status</div>
            <DriverStatusBadge
                item={Object.assign({}, driver, {StatusID: driver.DriverStatusID})}
                translate={translate}/>
        </div>

        {driver.DriverPositionType && (
            <div className="flex">
                <div className="w-32">Position type</div>
                {driver.DriverPositionType}
            </div>
        )}

        {driver.TSA && (
            <div className="flex">
                <div className="w-32">TSA</div>
                <CheckCircleSolidIcon className="w-5 h-5 text-green-600 inline"/>
            </div>
        )}

        {driver.Tank && (
            <div className="flex">
                <div className="w-32">Tank</div>
                <CheckCircleSolidIcon className="w-5 h-5 text-green-600 inline"/>
            </div>
        )}

        {driver.Haz && (
            <div className="flex">
                <div className="w-32">Haz</div>
                <CheckCircleSolidIcon className="w-5 h-5 text-green-600 inline"/>
            </div>
        )}

        {driver.Ph && (
            <div className="flex">
                <div className="w-32">Ph</div>
                <CheckCircleSolidIcon className="w-5 h-5 text-green-600 inline"/>
            </div>
        )}

        {driver.TWIC && (
            <div className="flex">
                <div className="w-32">TWIC</div>
                <CheckCircleSolidIcon className="w-5 h-5 text-green-600 inline"/>
            </div>
        )}
    </div>
    }>
        <div className="px-1 py-2">
            {children}
        </div>
    </Tooltip>
}