import React, {Component} from 'react';
import {connect} from "react-redux";
import {EyeIcon, LinkIcon, PencilIcon, UsersIcon} from "@heroicons/react/24/outline";
import LocalStorage from "../../util/localStorage";
import NoRecordsTable from "../no-records-found/no-records-table";
import ListBulletIcon from "@heroicons/react/24/outline/ListBulletIcon";
import TableCardFooter from "../resource-table/table-components/table-card-footer";
import StackedList from "../display-data/stacked-list";
import Pagination from "../resource-table/table-components/pagination";
import TableCard from "../resource-table/table-components/table-card";
import {Field} from "../../../data/services/fields";
import {checkPerm, getProp, openInNewTab} from "../../util/util-helpers";
import {READ_PERM, RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS, UPDATE_PERM} from "../../util/util-consts";
import ToolTipTableCell from "../resource-table/table-components/tooltip-table-cell";
import ResourceTable from "../../components/resource-table"
import Resources from "../../../data/services/resources";
import {showModal} from "../../../data/actions/ui";
import {includeFields} from "../../util/util-fields";

class UsedVsIdle extends Component {
    constructor(props) {
        super(props);
        this.pagePath = this.props.location.pathname.substring(1) + "-widget-drivers";


        this.state = {
            offset: 0,
            limit: this.props?.isCompact ? "5" : "10",
            sort: "ASC",
            sortBy: "Driver",
            paginationPage: 1,
            myDispatchGroup: 1,
        }
        // this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath));
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.isCompact !== this.props.isCompact || prevState.myDispatchGroup !== this.state.myDispatchGroup)) {
            this.props.onFetchData("Drivers", this.getQuery())
        }
    };

    fetchData = () => {
        this.props.onFetchData("Drivers", this.getQuery())
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()).map(it => it));
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            myDispatchGroup: this.state.myDispatchGroup ? 1 : 0,
            sortBy: this.state.sortBy
        }
    }

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        })
    };

    updateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, this.fetchData);
    }

    getFields = () => {
        return {
            Driver: new Field("Driver", '', [], false, 'custom', {
                render: (item) => <ToolTipTableCell
                    placement={"right"}
                    cellValue={item.Driver}
                    tooltips={[
                        {
                            id: checkPerm(Resources.DriverInfo, READ_PERM) ? item.ContactID : null,
                            content: this.props.translate("btn.quick_view"),
                            onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID})),
                            icon: EyeIcon
                        },
                        {
                            id: checkPerm(Resources.DriverInfo, UPDATE_PERM) ? item.ContactID : null,
                            content: this.props.translate("btn.edit"),
                            onClick: () => this.props.history.push(`/drivers/info/${item.DriverID}`),
                            icon: PencilIcon,
                        },
                    ]}
                />
            }),
            Email: new Field("Email", '', [], false, 'text'),
            Active: new Field("Active", '', [], false, 'text', {label: "DriverStatusID", omitSort: true}),
            OnLoad: new Field("OnLoad", '', [], false, 'checkbox', {omitSort: true}),
        }
    }

    render() {
        const {translate, drivers, isLoading, isCompact, dispatch} = this.props;
        const data = getProp(drivers, 'list', []);
        const count = getProp(drivers, 'count', [])

        return (
            <TableCard>
                <div className="px-5 py-3 flex justify-between">
                    <div
                        className="group flex items-center gap-2 relative cursor-pointer"
                    >
                        <div onClick={() => openInNewTab('/drivers')}>
                            <LinkIcon
                                className="hidden hover:cursor-pointer group-hover:block w-5 h-5 text-primary absolute -left-4"/>

                            <h5 className="text-lg pl-2 ">
                                {translate("text.drivers")}
                            </h5>
                        </div>
                    </div>

                    {!!this.state.myDispatchGroup && (
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.setState({myDispatchGroup: !this.state.myDispatchGroup})}
                        >
                            <ListBulletIcon className="w-5 h-5 mr-1 -lm-1 text-tm-gray-500"/>
                            {translate('text.show_all')}
                        </button>

                    )}

                    {!this.state.myDispatchGroup && (
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.setState({myDispatchGroup: !this.state.myDispatchGroup})}
                        >
                            <UsersIcon className="w-5 h-5 mr-1 -lm-1 text-tm-gray-500"/>
                            {translate('field.myDispatchGroup')}
                        </button>
                    )}
                </div>

                {!isCompact && (
                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        translate={this.props.translate}
                        options={RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS}
                        tableID="widget-drivers"
                        isLoading={isLoading}
                        limit={this.state.limit}
                        onSortChange={this.updateSort}
                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                    />
                )}

                {isCompact && (
                    <StackedList
                        data={data}
                        isLoading={isLoading}
                        listKey="LoadID"
                        onTapRow={(item) => dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                        fields={includeFields(this.getFields(), ['Driver', 'Email'])}
                    />
                )}

                <TableCardFooter
                    show={!isLoading && count > this.state?.limit}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        hideRowsPerPage={true}
                        handleQueryChange={
                            (name, value, currentPage) => this.updateOffset(value, currentPage)
                        }
                        pageOffset={this.state.offset}
                        pageLimit={this.state?.limit}
                        translate={translate}
                    />
                </TableCardFooter>

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters')}
                />

            </TableCard>
        )
    }
}

export default connect(state => state)(UsedVsIdle);
