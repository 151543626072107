import Tooltip from "../../../tooltip";
import {BellIcon as BellSolidIcon} from "@heroicons/react/20/solid";
import CellButton from "../cell-button";
import React from "react";
import {classNames} from "../../../../util/util-helpers";

export default function TableReminder({message, type,content, disabled, onClick, item}) {
    let className = 'mr-1 w-5 h-5 flex-shrink-0'

    console.log('type', type)

    switch (type) {
        case 'ok':
            className = classNames(className, "text-green-600");
            break;
        case 'warning':
            className = classNames(className, "animate-blink-soft text-yellow-600");
            break;
        case 'danger':
        default:
            className = classNames(className, "animate-blink-soft text-red-600")

    }

    return (
        <CellButton
            disabled={disabled}
            onClick={onClick}
        >
            {/*<Tooltip content={message}>*/}
                <BellSolidIcon className={className}/>
            {/*</Tooltip>*/}

            {content}
        </CellButton>
    )
}