import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {
    ArrowLeftIcon,
    ArrowTopRightOnSquareIcon,
    ArrowUturnLeftIcon,
    AtSymbolIcon,
    CheckCircleIcon,
    ChevronRightIcon,
    DocumentIcon,
    EnvelopeIcon,
    EyeIcon,
    FolderArrowDownIcon,
    MinusCircleIcon,
    PlusCircleIcon,
    PlusIcon,
    Squares2X2Icon,
    TrashIcon,
    ViewColumnsIcon,
} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getProp,
    openInNewTab,
    renderExpenseStatusBadge
} from '../../../../common/util/util-helpers'
import {cloneDeep, emailValid, genericMoneyFormatter, getRandomID} from '../../../../common/util/util-vanilla'
import {currentDate, toFrontDate, toFrontDateTimeFromUTC} from '../../../../common/util/util-dates'
import Env from '../../../../util/env'
import Resources from '../../../../data/services/resources'
import {createDialogResource, deleteDialogResource} from '../../../../data/actions/dialogResource'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import {
    ACCOUNT_TYPE_OTHER_CURRENT_ASSETS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    feeTypes,
    LINE_OF_BUSINESS_PRIMARY_CARRIER,
    LINE_OF_BUSINESS_PRIMARY_DRIVER,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    UPDATE_PERM
} from '../../../../util/util-constants'
import {numberWithCommas} from '../../../../util/util-formaters'
import ArrowPathIcon from '@heroicons/react/24/outline/ArrowPathIcon'
import {DocumentChartBarIcon} from '@heroicons/react/20/solid'
import ExpenseExpenseDetailsDialog from "../../dialogs/invoice-expense-details-dialog";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import ModalDefault from "../../../../common/components/modal/modal-default";
import InfoParagraph from "../../../../common/components/info-paragraph";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ResourceTable from "../../../../common/components/resource-table";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import StatusProgressTable from "../../../../common/components/resource-table/table-components/status-progress-table";
import {fieldsToHtml, includeFields} from "../../../../common/util/util-fields";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import LocalStorage from "../../../../util/localStorage";
import {getJWT, getUser} from "../../../../common/util/util-auth";
import FieldCellText from "../../../../common/components/fields/field-cell-text";
import FieldCheckbox from "../../../../common/components/fields/field-checkbox";
import FileViewer from "../../../../common/components/file-viewer/components";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import TableFooter from "../../../../common/components/resource-table/table/table-footer";
import FieldSelectSearch from "../../../../common/components/fields/field-select-search";
import FieldSearch from "../../../../common/components/fields/field-text/search";
import ModalHeader from "../../../../common/components/modal/modal-header";
import {LoaderLarge} from "../../../../common/components/loader";
import {HourglassIcon} from "../../../../data/themes/icons";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import DriverStatusBadge from "../../../../common/components/badge/driver-status-badge";
import EmployeeStatusBadge from "../../../../common/components/badge/employee-status-badge";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import Modal from "../../../../common/components/modal";
import {DEFAULT_QUERY_LIMIT} from '../../../../common/util/util-consts'
import FieldContainer from "../../../../common/components/fields/field-container";
import ExclamationCircleIcon from "@heroicons/react/20/solid/ExclamationCircleIcon";

const PAYROLL_BATCH_TYPES = [
    {label: 'Drivers', value: 'Drivers'},
    {label: 'Agents', value: 'Agents'},
    {label: 'Employees', value: 'Employees'}
]

export default class PayrollBatchProcessDialog extends Component {
    constructor(props) {
        super(props)

        this.tableOptions = {
            behaviour: {
                hasVirtualRows: true
            },
            style: {
                condensed: true,
                horizontalLines: true,
                verticalLines: true,
                headerHeightClass: 'h-8',
                floatingActions: false,
                frozenActionColumn: true
            },
        }

        this.state = {
            selectedItem: null,
            addedItems: [],
            fields: this.getFields(),
            emailFields: this.getEmailFields(),
            notesFields: this.getNoteFields(),
            batchItems: getProp(this.props, 'batchItems', {}),
            History: getProp(this.props, 'batchItems.History', []),
            CurrentItems: getProp(this.props, 'batchItems.Items', []),
            FuelPurchase: getProp(this.props, 'batchItems.PotentialItems.FuelPurchase', []),
            Accesorials: getProp(this.props, 'batchItems.PotentialItems.Accesorials', []),
            LineHaul: getProp(this.props, 'batchItems.PotentialItems.LineHaul', []),
            Reimbursement: getProp(this.props, 'batchItems.PotentialItems.Reimbursement', []),
            Recurring: getProp(this.props, 'batchItems.PotentialItems.Recurring', []),
            Other: getProp(this.props, 'batchItems.PotentialItems.Other', []),

            queryFields: this.getQueryFields(),
            addContactsModalQueryFields: this.getAddContactsModalQueryFields(),
            contactsModalOffset: 0,
            contactsModalSort: 'ASC',
            contactsModalSortBy: '',

            selectedView: 'info',
            isDirty: false,
            isShowingDeliveredOnly: false,
            isGridView: LocalStorage.has('PayrollBatchProcessDialogGrid') ? LocalStorage.get('PayrollBatchProcessDialogGrid') : true,

            closeDirtyBatchConfirmationModal: false,
            addAdvanceModal: false,
            addPayModal: false,
            useEscrowModal: false,

            isConfirmDeleteModalOpen: false,
            itemToDelete: null,

            isAddEntryModalOpen: false,
            isExpenseDetailsDialogVisible: false,
            selectedExpenseItem: null
        }

        this.pagePath = this.props.pagePath

        this.batchTableOptions = getDefaultTableOptions(this.getBatchFields(), {
            behaviour: {
                hasVirtualRows: true
            },
            style: {
                condensed: true,
                horizontalLines: true,
                verticalLines: true,
                headerHeightClass: 'h-8',
                floatingActions: true,
                frozenActionColumn: true,
            },
        }, this.pagePath + "_batch", this.props.translate);
        this.commissionTableOptions = getDefaultTableOptions(this.getCommissionLineHaulFields(), Object.assign({}, this.tableOptions, {
            columns: {
                LoadID: {
                    minWidth: 130
                },
                PickupDate: {
                    minWidth: 120
                },
                DestinationDate: {
                    minWidth: 120
                },
                Price: {
                    minWidth: 150
                },
                AccessorialAmount: {
                    minWidth: 150
                },
                CarrierAmount: {
                    minWidth: 150
                },
                Amount: {
                    minWidth: 150
                },
                FeeAmount: {
                    minWidth: 150
                },
            }
        }), this.pagePath + "_commission", this.props.translate);
        this.lineHaulTableOptions = getDefaultTableOptions(this.getLineHaulFields(), Object.assign({}, this.tableOptions, {
            columns: {
                LoadID: {
                    minWidth: 130
                },
                PickupDate: {
                    minWidth: 120
                },
                DestinationDate: {
                    minWidth: 120
                },
                EmptyMiles: {
                    minWidth: 120
                },
                TotalMiles: {
                    minWidth: 120
                },
                Price: {
                    minWidth: 150
                },
                AccessorialAmount: {
                    minWidth: 150
                },
                Amount: {
                    minWidth: 150
                },
            }
        }), this.pagePath + "_load_pay", this.props.translate);
        this.escrowTableOptions = getDefaultTableOptions(this.getStatementFields(), Object.assign({}, this.tableOptions, {
            columns: {
                Amount: {
                    minWidth: 150
                }
            }
        }), this.pagePath + "_escrow", this.props.translate);
        this.reimbursementTableOptions = getDefaultTableOptions(this.getReimbursementFields(), Object.assign({}, this.tableOptions, {
            columns: {
                LoadID: {
                    minWidth: 150
                },
                UsedAmount: {
                    minWidth: 150
                },
                Amount: {
                    minWidth: 150
                }
            }
        }), this.pagePath + "_reimbursement", this.props.translate);
        this.recurringTableOptions = getDefaultTableOptions(this.getRecurringFields(), Object.assign({}, this.tableOptions, {
            columns: {
                UsedAmount: {
                    minWidth: 180
                },
                AccountAmount: {
                    minWidth: 150
                },
                Amount: {
                    minWidth: 180
                }
            }
        }), this.pagePath + "_recurringTableOptions", this.props.translate);
        this.accessorialTableOptions = getDefaultTableOptions(this.getAccesorialFields(), Object.assign({}, this.tableOptions, {
            columns: {
                LoadID: {
                    minWidth: 150
                },
                Amount: {
                    minWidth: 150
                }
            }
        }), this.pagePath + "_accessorialTableOptions", this.props.translate);
    }

    componentDidMount() {
        this.props.fetchSingleBatch(this.getQuery())
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && !this.props.isLoading) {
            this.setState({
                fields: this.getFields(),
                notesFields: this.getNoteFields(),
                emailFields: this.getEmailFields(getProp(this.props, 'batchItems', {})),
                batchItems: getProp(this.props, 'batchItems', {}),
                History: getProp(this.props, 'batchItems.History', []),
                CurrentItems: getProp(this.props, 'batchItems.Items', []).map((it) => {
                    if (!it.ReferenceType) {
                        it.ReferenceType = 'tbl_PayrollBatchEntryItem';
                    }
                    if (!it.ReferenceID) {
                        it.ReferenceID = it.PayrollBatchEntryItemID;
                    }
                    return it;
                }),
                FuelPurchase: getProp(this.props, 'batchItems.PotentialItems.FuelPurchase', []),
                Accesorials: getProp(this.props, 'batchItems.PotentialItems.Accesorials', []),
                LineHaul: getProp(this.props, 'batchItems.PotentialItems.LineHaul', []),
                Reimbursement: getProp(this.props, 'batchItems.PotentialItems.Reimbursement', []),
                Recurring: getProp(this.props, 'batchItems.PotentialItems.Recurring', []),
                Other: getProp(this.props, 'batchItems.PotentialItems.Other', [])
            })
        }

        if (!prevState.isAddEntryModalOpen && this.state.isAddEntryModalOpen) {
            this.props.fetchContacts({query: this.getContactsQuery()})
        }

        if (prevState.isDirty != this.state.isDirty) {
            this.props.checkIsStateDirty(this.state.isDirty)
        }


    }

    /** Data Events
     ================================================================= */
    fetchExpenseDialogData = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            query: {
                ExpenseID: this.state.selectedExpenseItem?.ExpenseID
            },
            resource: Resources.ExpenseExpense
        }))
    }

    sendEmail = () => {
        this.setState({emailFields: FieldsManager.validateFields(this.state.emailFields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.emailFields)) {
                const Emails = getProp(this.state.emailFields, 'Emails.value', [])
                    .filter(it => emailValid(it.value))
                    .map(it => it.value) ?? []
                const params = Object.assign({},
                    FieldsManager.getFieldKeyValues(this.state.emailFields),
                    {
                        PayrollBatchEntryID: this.state.batchItems.PayrollBatchEntryID,
                        Emails: Emails
                    }
                )

                this.props.sendPayrollBatchEmail({params})
                this.setState({
                    selectedView: 'info',
                    emailFields: this.getEmailFields()
                })
            }
        })
    }

    downloadPDF = (item = null, ShowPerMile = 0) => {
        this.props.downloadPayrollBatchPDF({
            query: Object.assign({
                format: 'PDF',
                name: 'payroll_batch_item_' + currentDate() + '.pdf',
                PayrollBatchEntryID: this.state.batchItems.PayrollBatchEntryID ?? this.state.previewFile?.PayrollBatchEntryID ?? item.PayrollBatchEntryID,
                ShowPerMile: ShowPerMile
            })
        })
    }

    handleAddEntry = (item) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {
                PayrollBatchID: this.props.PayrollBatchID,
                ContactID: item.ContactID
            },
            query: this.getQuery(),
            resource: Resources.PayrollBatchesItem,
            piggyResource: Resources.PayrollBatch,
            errorMessage: true,
            successMessage: this.props.translate('message.entry_added'),
        }))
        this.handleToggleAddEntryModal()
    }

    /** UI Events
     ================================================================= */
    handleAddAllItemsToCurrentFromState = (state, stateName) => {
        state.filter(it => !this.isItemAddedAlready(it)).forEach(it => this.handleAddItemToContact(this.handleFormatPotentialItem(stateName, it), it.ReferenceID))
        this.setState({isDirty: true})
    }

    handleQueryInputChange = (name, value) => {
        const queryFieldsUpdate = FieldsManager.updateField(this.state.queryFields, name, value)

        queryFieldsUpdate.offset.value = 0

        if ('sortBy' === name) {
            queryFieldsUpdate.sort.value = queryFieldsUpdate.sortBy.value === value
                ? queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
                : queryFieldsUpdate.sort.value
        }

        this.setState({
            queryFields: queryFieldsUpdate,
        }, () => {
            this.props.fetchSingleBatch(this.getQuery())
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            addContactsModalQueryFields: FieldsManager.updateField(this.state.addContactsModalQueryFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.props.fetchContacts({query: this.getContactsQuery()})
        })
    }

    handleRecurringInputChange = (name, value, refID) => {
        let newRecurringState = cloneDeep(this.state.Recurring)
        const itemToChangeIndex = this.state.Recurring.findIndex(item => item.ReferenceID == refID)

        let currentItemsIDs = this.state.CurrentItems.map(it => it.ReferenceID)

        if (currentItemsIDs.includes(newRecurringState[itemToChangeIndex].ReferenceID)) {

        } else {
            newRecurringState[itemToChangeIndex].Amount = value
            this.setState({
                Recurring: newRecurringState
            })
        }
    }

    handleUpdateOffset = (offset) => {
        this.setState({
            queryFields: FieldsManager.updateField(this.state.queryFields, 'offset', offset),
        }, () => {
            this.props.fetchSingleBatch(this.getQuery())
        })
    }

    handleUpdateContactsModalOffset = (offset) => {
        this.setState({
            contactsModalOffset: offset
        }, () => {
            this.props.fetchContacts({query: this.getContactsQuery()})
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            contactsModalSortBy: sortBy,
            contactsModalSort: (this.state.contactsModalSortBy === sortBy) ? (this.state.contactsModalSort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.props.fetchContacts({query: this.getContactsQuery()})
        })
    }

    handleRemoveFromCurrentItems = (item) => {
        if (this.state.batchItems.IsPayed) {
            return true
        }

        let CurrentItems = this.state.CurrentItems.filter(it => (
            !(it.ReferenceID == item.ReferenceID
                &&
                it.ReferenceType == item.ReferenceType)
        ))
        this.setState({isDirty: true, CurrentItems: CurrentItems})
    }

    handleRevertChanges = () => {
        this.props.getBatchEntryItems(this.state.selectedItem)
        this.setState({
            isDirty: false
        })
    }

    handleEmailInputChange = (name, value) => {
        this.setState({emailFields: FieldsManager.updateField(this.state.emailFields, name, value), isDirty: true})
    }

    handleSaveItems = () => {
        this.props.saveEntryItems(
            this.state?.selectedItem,
            this.state.CurrentItems,
            this.state.notesFields
        )

        this.setState({
            isDirty: false,
        })
    }

    handleSelectContact = (it) => {
        this.setState({
            selectedItem: it,
            selectedView: 'info'
        }, () => {
            this.props.getBatchEntryItems(it)
        })
    }

    handleSendEmailDialog = (it) => {
        this.setState({
            selectedItem: it,
            selectedView: 'email'
        }, () => {
            this.props.getBatchEntryItems(it)
        })
    }

    handleAddItemToContact = (item) => {
        if (!item.PayrollBatchEntryItemID) {
            item.PayrollBatchEntryItemID = item.ReferenceID
        }
        if (!item.ReferenceType) {
            item.ReferenceType = 'tbl_PayrollBatchEntryItem';
        }
        if (!item.ReferenceID) {
            item.ReferenceID = getRandomID(10000);
        }

        let CurrentItems = this.state.CurrentItems
        CurrentItems.push(item)

        this.setState({
            CurrentItems: CurrentItems,
            fields: this.getFields()
        })
        this.setState({
            isDirty: true,
        })
    }

    handleFormatPotentialItem = (state, item) => {
        let itemTmp = {}
        switch (state) {
            case 'Commissions':
                itemTmp = {
                    Description: 'Load #' + item.LoadNumber,
                    Amount: item.Amount,
                    TotalMiles: item.TotalMiles,
                    ReferenceID: item.LoadCommissionID,
                    ReferenceType: 'tbl_LoadCommission',
                    AccountID: item?.AccountID,
                    Metadata: {
                        Price: item?.Price,
                        CalculationPrice: item?.CalculationPrice,
                        CarrierAmount: item?.CarrierAmount,
                        Customer: item?.Customer,
                        IsPODReviewed: item?.IsPODReviewed,
                        IsAccessorialHold: item?.IsAccessorialHold,
                        LoadID: item.LoadID,
                        LoadNumber: item.LoadNumber,
                        LoadStatusID: item.LoadStatusID,
                        Pickup: item.Pickup,
                        PickupDate: item.PickupDate,
                        Destination: item.Destination,
                        DestinationDate: item.DestinationDate,
                        EmptyMiles: item.EmptyMiles,
                        TotalMiles: item.TotalMiles,
                        FeeTypeID: item.FeeTypeID,
                        Amount: item.Amount
                    }
                }
                break
            case 'LineHaul':
                itemTmp = {
                    Description: 'Load #' + item.LoadNumber,
                    Amount: item.Amount,
                    TotalMiles: item.TotalMiles,
                    ReferenceID: item.LoadDriverPayID,
                    ReferenceType: 'tbl_LoadDriverPay',
                    AccountID: item?.AccountID,
                    Metadata: {
                        Price: item?.Price,
                        CalculationPrice: item?.CalculationPrice,
                        CarrierAmount: item?.CarrierAmount,
                        IsPODReviewed: item?.IsPODReviewed,
                        Customer: item?.Customer,
                        IsAccessorialHold: item?.IsAccessorialHold,
                        LoadID: item.LoadID,
                        ReferenceID: item.LoadDriverPayID,
                        LoadNumber: item.LoadNumber,
                        LoadStatusID: item.LoadStatusID,
                        Pickup: item.Pickup,
                        PickupCityName: item.PickupCityName,
                        PickupState: item.PickupState,
                        PickupPostalCode: item.PickupPostalCode,
                        PickupDate: item.PickupDate,
                        Destination: item.Destination,
                        DestinationDate: item.DestinationDate,
                        DestinationCityName: item.DestinationCityName,
                        DestinationState: item.DestinationState,
                        DestinationPostalCode: item.DestinationPostalCode,
                        EmptyMiles: item.EmptyMiles,
                        TotalMiles: item.TotalMiles,
                        FeeTypeID: item.FeeTypeID,
                        Amount: item.Amount
                    }
                }
                break
            case 'Accesorials':
                itemTmp = {
                    Description: 'Load #' + item.LoadNumber,
                    Amount: item.Amount,
                    ReferenceID: item.LoadAccesorialDriverPayID,
                    ReferenceType: 'tbl_LoadAccessorialDriverPay',
                    AccountID: item?.AccountID,
                    Metadata: {
                        Price: item?.Price,
                        CalculationPrice: item?.CalculationPrice,
                        CarrierAmount: item?.CarrierAmount,
                        IsPODReviewed: item?.IsPODReviewed,
                        Customer: item?.Customer,
                        IsAccessorialHold: item?.IsAccessorialHold,
                        LoadID: item.LoadID,
                        ReferenceID: item.LoadDriverPayID,
                        LoadNumber: item.LoadNumber,
                        LoadStatusID: item.LoadStatusID,
                        Pickup: item.Pickup,
                        PickupCityName: item.PickupCityName,
                        PickupState: item.PickupState,
                        PickupPostalCode: item.PickupPostalCode,
                        PickupDate: item.PickupDate,
                        Destination: item.Destination,
                        DestinationDate: item.DestinationDate,
                        DestinationCityName: item.DestinationCityName,
                        DestinationState: item.DestinationState,
                        DestinationPostalCode: item.DestinationPostalCode,
                        EmptyMiles: item.EmptyMiles,
                        TotalMiles: item.TotalMiles,
                        FeeTypeID: item.FeeTypeID,
                        Amount: item.Amount
                    }
                }
                break
            case 'FuelPurchase':
                itemTmp = {
                    Description: toFrontDateTimeFromUTC(item.Date),
                    Amount: item.Amount,
                    ReferenceID: item.FuelPurchaseID,
                    ReferenceType: 'tbl_FuelPurchase',
                    AccountID: item?.AccountID
                }
                break
            case 'Reimbursement':
                itemTmp = {
                    Description: item.Notes,
                    Amount: item.Amount,
                    ReferenceID: item.ReimbursementID,
                    ReferenceType: 'tbl_Reimbursement',
                    AccountID: item?.AccountID
                }
                break
            case 'Recurring': {
                const TotalAddedMiles = this.state.CurrentItems?.filter(it => {
                    return it.Description.startsWith('Load #')
                }).reduce((memo, it) => {
                    return memo + it.TotalMiles + it.EmptyMiles
                }, 0)

                itemTmp = {
                    Description: item.Notes,
                    Amount: !!item.AmountPer && item.AmountPer > 0 ? (-item.AmountPer * TotalAddedMiles) : item.Amount,
                    ReferenceID: item.DriverScheduledDeductionsID,
                    ReferenceType: 'tbl_DriverScheduledDeductions',
                    AccountID: item?.AccountID,
                    IsDriverEscrow: item?.IsDriverEscrow
                }
            }
                break
            case 'Other':
                itemTmp = {
                    Description: item.Description,
                    Amount: -item.TotalAmount,
                    ReferenceID: item.ExpenseLineID,
                    ReferenceType: 'tbl_ExpenseLine',
                    AccountID: item?.AccountID
                }
                break
            default:
                break
        }
        return Object.assign(
            item,
            itemTmp
        )
    }

    isItemAddedAlready = (item) => {
        return this.state.CurrentItems.some((it) => {
            return (it.ReferenceType === item.ReferenceType) && (it.ReferenceID === item.ReferenceID)
        })
    }

    handleToggleAddAdvance = () => {
        this.setState({
            addAdvanceModal: !this.state.addAdvanceModal
        })
    }

    handleToggleAddPay = () => {
        this.setState({
            addPayModal: !this.state.addPayModal
        })
    }

    handleToggleUseEscrow = () => {
        this.setState({
            useEscrowModal: !this.state.useEscrowModal
        })
    }

    handleToggleAddEntryModal = () => {
        this.setState({
            isAddEntryModalOpen: !this.state.isAddEntryModalOpen
        })
    }

    showPreviewModal = (item) => {
        this.setState({showPreviewModal: true, previewFile: item})
    }

    handleAssign = (item) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {
                PayrollBatchEntryID: item.PayrollBatchEntryID,
                ContactID: getUser('Contact.ContactID')
            },
            query: this.getQuery(),
            resource: Resources.PayrollBatchesAssign,
            piggyResource: Resources.PayrollBatch,
            errorMessage: true,
            successMessage: this.props.translate('text.assigned'),
        }))
    }

    handleUnAssign = (item) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {
                PayrollBatchEntryID: item.PayrollBatchEntryID,
                ContactID: null
            },
            query: this.getQuery(),
            resource: Resources.PayrollBatchesAssign,
            piggyResource: Resources.PayrollBatch,
            errorMessage: true,
            successMessage: this.props.translate('text.unassigned'),
        }))
    }

    handleToggleConfirmDeleteModal = (item = null) => {
        this.setState({
            itemToDelete: item,
            isConfirmDeleteModalOpen: !this.state.isConfirmDeleteModalOpen
        })
    }

    handleAssignView = (item) => {
        if (!!item.AssignedToContactID && item.AssignedToContactID !== getUser('Contact.ContactID')) {
            return false
        } else {
            return !(item.AssignedToContactID && item.AssignedToContactID === getUser('Contact.ContactID'))
        }
    }

    handleUnAssignView = (item) => {
        return !!item.AssignedToContactID && item.AssignedToContactID === getUser('Contact.ContactID')
    }

    handleAddNoteClick = (noteFieldName, type = "textarea") => {
        let fieldsUpdate = this.state.notesFields;

        fieldsUpdate[noteFieldName].type = type;

        this.setState({
            notesFields: fieldsUpdate
        });
    }

    clearNotesField = (fieldName) => {
        let fieldsUpdate = this.state.notesFields;

        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, fieldName, "");
        fieldsUpdate[fieldName].type = "hidden";

        this.setState({
            notesFields: fieldsUpdate,
            isDirty: true
        })
    }

    handleToggleExpenseDetailsDialog = (item = null) => {
        this.setState({
            isExpenseDetailsDialogVisible: !this.state.isExpenseDetailsDialogVisible,
            selectedExpenseItem: item
        })
    }
    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            Description: new Field('Description', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-1'
            }),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-1'
            }),
            Amount: new Field('Amount', '', ['empty'], false, 'float', {
                addContainerClass: 'col-span-1'
            }),
        }
    }

    getStatementFields = () => {
        return {
            PayrollBatchEntryItemID: new Field('PayrollBatchEntryItemID', '', [''], false, 'hidden', {hideTable: true}),
            Description: new Field('Description', '', [''], false, 'custom', {
                label: 'name',
                render: (item) => {
                    return (
                        <Tippy content={item.Description} placement={'top-start'}>
                            <div>
                                {item.Description}
                            </div>
                        </Tippy>
                    )
                }
            }),
            Type: new Field('Type', '', [''], false, 'custom', {
                render: (it) => {
                    if (it.IsGross) {
                        return 'Pay'
                    } else if (it.IsEscrowUsage) {
                        return it.Amount < 0 ? 'Escrow' : 'Escrow usage'
                    } else if (it.IsAdvance) {
                        return it.Amount > 0 ? 'Payroll advance' : 'Loan pay'
                    } else if (it.ReferenceType === 'tbl_ExpenseLine') {
                        return 'Expense'
                    } else if (
                        (it.ReferenceType === 'tbl_DriverScheduledDeductions')
                        &&
                        (it.IsDriverEscrow)
                    ) {
                        return 'Escrow'
                    } else {
                        return 'Adjustment'
                    }
                }
            }),
            Amount: new Field('Amount', '', [''], false, 'money', {addTableHeaderClass: 'text-right'}),
        }
    }

    getCommissionLineHaulFields = () => {
        let fields = {
            LoadID: new Field('LoadID', '', [''], false, 'custom', {
                render: (it) => <div
                    className="hover:bg-primary-transparent px-2 py-1 rounded-btn flex gap-x-1"
                    onClick={() => openInNewTab('/loads/info/' + it.LoadID)}
                >
                    {it.LoadNumber} <ArrowTopRightOnSquareIcon
                    className="w-5 h-5 text-tm-gray-400"/></div>
            }),
            PickupDate: new Field('PickupDate', '', [''], false, 'date'),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'date', {label: 'delivery_date'}),
            LoadStatusID: new Field('LoadStatusID', 0, [''], false, 'custom', {
                    render: (item) => {
                        return (
                            <StatusProgressTable
                                addClass=""
                                LoadStatusID={item.LoadStatusID}
                                translate={this.props.translate}
                            />
                        )
                    }
                }
            ),
            Customer: new Field('Customer', '', [], false, 'text'),
            Price: new Field('Price', '', [], false, 'money', {
                addTableHeaderClass: 'text-right',
                label: 'CustomerPrice'
            }),
            AccessorialAmount: new Field('AccessorialAmount', '', ['empty'], false, 'money', {
                addTableHeaderClass: 'text-right',
                label: 'CustomerAccPrice'
            }),
            CarrierAmount: new Field('CarrierAmount', '', ['empty'], false, 'money', {
                addTableHeaderClass: 'text-right',
                label: 'CarrierAmountPrice'
            }),
            FeeAmount: new Field('FeeAmount', '', ['empty'], false, 'custom', {
                addTableHeaderClass: 'text-right',
                label: 'FeeAmount',
                render: (item) => {
                    return <div className={'text-right'}>{
                        genericMoneyFormatter(item.Price - item.CalculationPrice)
                    }</div>
                }
            }),
            IsPODReviewed: new Field('IsPODReviewed', '', [], false, 'checkbox', {
                label: 'DocumentsReady'
            }),
            FeeTypeID: new Field('FeeTypeID', '', [''], false, 'text', {
                render: (item) => {
                    return (
                        <>{this.props.translate(feeTypes[item.FeeTypeID])}</>
                    )
                }
            }),
            Amount: new Field('Amount', 0, [''], false, 'money', {addTableHeaderClass: 'text-right'}),
        }

        if (!getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_DRIVER)) {
            delete fields['CarrierAmount']
        }
        if (!getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER)) {
            delete fields['FeeAmount']
        }
        return fields
    }

    getLineHaulFields = () => {
        return {
            LoadID: new Field('LoadID', '', [''], false, 'custom', {
                render: (it) => <div
                    className="hover:bg-primary-transparent px-2 py-1 rounded-btn flex gap-x-1"
                    onClick={() => openInNewTab('/loads/info/' + it.LoadID)}
                >
                    {it.LoadNumber} <ArrowTopRightOnSquareIcon
                    className="w-5 h-5 text-tm-gray-400"/></div>
            }),
            PickupDate: new Field('PickupDate', '', [''], false, 'date'),
            Pickup: new Field('Pickup', '', [''], false, 'custom', {
                render: (item) =>
                    <div>{!!item.PickupState ? item.PickupCityName + ', ' + item.PickupState + ' ' + item.PickupPostalCode : item.Pickup}</div>,
                label: 'pickup'
            }),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'date', {label: 'delivery_date'}),
            Destination: new Field('Destination', '', [''], false, 'custom', {
                render: (item) =>
                    <div>{!!item.DestinationState ? item.DestinationCityName + ', ' + item.DestinationState + ' ' + item.DestinationPostalCode : item.Destination}</div>,
                label: 'delivery'
            }),
            EmptyMiles: new Field('EmptyMiles', 0, [''], false, 'custom', {
                    render: (item) => <div
                        className="text-right">{item.EmptyMiles ? Math.floor(item.EmptyMiles) : '0'}</div>,
                    addTableHeaderClass: 'text-right'
                },
            ),
            TotalMiles: new Field('TotalMiles', 0, [''], false, 'custom', {
                render: (item) => <div
                    className="text-right">{item.TotalMiles ? Math.floor(item.TotalMiles) : '0'}</div>,
                addTableHeaderClass: 'text-right',
                label: 'LoadedMiles'
            }),
            LoadStatusID: new Field('LoadStatusID', 0, [''], false, 'custom', {
                    render: (item) => {
                        return (
                            <StatusProgressTable
                                addClass=""
                                LoadStatusID={item.LoadStatusID}
                                translate={this.props.translate}
                            />
                        )
                    }
                }
            ),
            Customer: new Field('Customer', '', [], false, 'text'),
            Price: new Field('Price', '', [], false, 'money', {addTableHeaderClass: 'text-right'}),
            AccessorialAmount: new Field('AccessorialAmount', '', ['empty'], false, 'money', {addTableHeaderClass: 'text-right'}),
            IsPODReviewed: new Field('IsPODReviewed', '', [], false, 'checkbox'),
            FeeTypeID: new Field('FeeTypeID', '', [''], false, 'text', {
                render: (item) => {
                    return (
                        <>{this.props.translate(feeTypes[item.FeeTypeID])}</>
                    )
                }
            }),
            Amount: new Field('Amount', 0, [''], false, 'money', {addTableHeaderClass: 'text-right'}),
        }
    }

    getAccesorialFields = () => {
        return {
            LoadID: new Field('LoadID', '', [''], false, 'custom', {
                render: (it) => <div
                    className="hover:bg-primary-transparent px-2 py-1 rounded-btn flex gap-x-1"
                    onClick={() => openInNewTab('/loads/info/' + it.LoadID)}
                >
                    {it.LoadNumber} <ArrowTopRightOnSquareIcon
                    className="w-5 h-5 text-tm-gray-400"/></div>
            }),
            Accessorial: new Field('Accessorial', '', [''], false, 'text', {label: 'name'}),
            Amount: new Field('Amount', '', [''], false, 'money', {addTableHeaderClass: 'text-right'})
        }
    }

    getFuelPurchaseFields = () => {
        return {
            Date: new Field('Date', '', [''], false, 'datetimezone'),
            Location: new Field('Location', '', [''], false, 'custom', {render: (it) => [it.AddressName, it.CityName, it.PostalCode].filter(it => !!it).join(', ')}),
            FuelCard: new Field('FuelCard', '', [''], false, 'custom', {render: (it) => ('****' + (it.FuelCard ? it.FuelCard?.slice(it.FuelCard?.length - 4) : ''))}),
            Fuel: new Field('Fuel', 0, [''], false, 'integer', {
                label: 'quantity',
                addTableHeaderClass: 'text-right'
            }),
            Amount: new Field('Amount', 0, [''], false, 'money', {addTableHeaderClass: 'text-right'}),
        }
    }

    getReimbursementFields = () => {
        return {
            Notes: new Field('Notes', '', [''], false, 'text', {label: 'name'}),
            UsedAmount: new Field('UsedAmount', '', [''], false, 'custom', {
                addTableHeaderClass: 'text-right',
                render: (it) => {
                    return (
                        <div className="flex flex-col text-right">
                            <div className="text-right">
                                {it.IsDelayedPayment ? genericMoneyFormatter(it.UsedAmount) : '/'}
                            </div>
                        </div>
                    )
                }
            }),
            Amount: new Field('Amount', '', [''], false, 'money', {addTableHeaderClass: 'text-right'}),
        }
    }

    getRecurringFields = () => {
        return {
            Notes: new Field('Notes', '', [''], false, 'text'),
            UsedAmount: new Field('UsedAmount', '', [''], false, 'custom', {
                addTableHeaderClass: 'text-right',
                label: "PayrollAmount",
                render: (it) => {
                    return <div className="text-right">
                        <div>
                            {genericMoneyFormatter(it.UsedAmount)}
                        </div>
                        {!!it?.MaxAmount && (<div> ( Until total reaches {it?.MaxAmount} )</div>)}
                        {!!it?.EndDate && (<div> ( {toFrontDate(it?.EndDate)} )</div>)}
                    </div>
                }
            }),
            Amount: new Field('Amount', '', [''], false, 'money', {
                addTableHeaderClass: 'text-right',
                render: (item) => {
                    return <div className="text-right">
                        {!!item?.Amount && !!item?.Amount > 0
                            ? <FieldCellText
                                align="right"
                                name="Amount"
                                focusedValue={item?.Amount}
                                unFocusedValue={genericMoneyFormatter(item?.Amount)}
                                onInputChange={(name, value) => {
                                    this.handleRecurringInputChange(name, value, item.ReferenceID)
                                }}
                            />
                            : (genericMoneyFormatter(item?.AmountPer) + ' per mile')}
                    </div>
                }
            }),
        }
    }

    getOtherFields = () => {
        return {
            Description: new Field('Description', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <Tippy content={item.Description} placement={'top-start'}>
                            <div>
                                {item.Description}
                            </div>
                        </Tippy>
                    )
                }
            }),
            Amount: new Field('Amount', '', [''], false, 'money', {addTableHeaderClass: 'text-right'})
        }
    }

    getNoteFields = () => {
        const InternalNotesValue = getProp(this.props.batchItems, 'InternalNotes', null)
        const ExternalNotesValue = getProp(this.props.batchItems, 'ExternalNotes', null)

        return {
            InternalNotes: new Field('InternalNotes', getProp(this.props.batchItems, 'InternalNotes', ''), [''], !this.props?.batchItems?.IsPayed ? false : true, (InternalNotesValue === null || InternalNotesValue === "") ? "hidden" : "textarea", {
                addContainerClass: "col-start-1 col-span-full",
                note: this.props.translate("text.internal_notes_message"),
                fieldOptions: () => (
                    <div className="space-x-1 h-5 relative bottom-1">
                        {!this.props?.batchItems?.IsPayed && (
                            <button
                                onClick={() => this.clearNotesField("InternalNotes")}
                                className="inline-block field-btn animate-button-fade-in"
                            >
                                <XMarkIcon className="w-5 h-5 text-primary"/>
                            </button>
                        )}
                    </div>
                )
            }),
            ExternalNotes: new Field('ExternalNotes', getProp(this.props.batchItems, 'ExternalNotes', ''), [''], !this.props?.batchItems?.IsPayed ? false : true, (ExternalNotesValue === null || ExternalNotesValue === "") ? "hidden" : "textarea", {
                addContainerClass: "col-start-1 col-span-full",
                note: this.props.translate("text.external_batch_notes"),
                fieldOptions: () => (
                    <div className="space-x-1 h-5 relative bottom-1">
                        {!this.props?.batchItems?.IsPayed && (
                            <button
                                onClick={() => this.clearNotesField("ExternalNotes")}
                                className="inline-block field-btn animate-button-fade-in"
                            >
                                <XMarkIcon className="w-5 h-5 text-primary"/>
                            </button>
                        )}
                    </div>
                )
            })
        }
    }

    getBatchFields = () => {
        return {
            Name: new Field('Name', '', ['']),
            LineHaul: new Field('LineHaul', '', [''], false, 'money'),
            Accesorials: new Field('Accesorials', '', [''], false, 'money'),
            Gross: new Field('Gross', '', [''], false, 'money', {
                render: (it) => <div
                    className="text-right font-bold">{genericMoneyFormatter(it.Gross, 0, '.', ',')}</div>
            }),
            Reimbursement: new Field('Reimbursement', '', [''], false, 'money', {label: 'ReimbursementsAdvances'}),
            Deduction: new Field('Deduction', '', [''], false, 'money', {label: 'PayAdjustments'}),
            Expenses: new Field('Expenses', '', [''], false, 'money'),
            TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {
                label: 'NetAmount',
                render: (it) => <div
                    className="text-right font-bold">{genericMoneyFormatter(it?.TotalAmount)}</div>
            }),
            IsDigitalSent: new Field('IsDigitalSent', '', [''], false, 'checkbox'),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {
                label: 'Posted'
            }),
            AssignedToContactID: new Field('AssignedToContactID', '', [''], false, 'text', {
                render: (it) => <div>
                    {it.AssignedToContact}
                </div>
            }),
            UpdatedByContact: new Field('UpdatedByContact', '', ['']),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'data', {
                label: 'LastUpdated',
                render: (it) => <div
                    className="text-right font-bold">{toFrontDateTimeFromUTC(it.CreateUpdateDate, 0, '.', ',')}</div>
            }),
        }
    }

    getEmailFields = (item = null) => {
        return {
            Subject: new Field('Subject', item ? 'Settlement Summary #' + item.AutoCounter + ' ' + toFrontDate(item.StartDate) + ' - ' + toFrontDate(item.EndDate) : '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Emails: new Field('Emails', item ? [{
                value: item.SendEmail,
                label: item.SendEmail
            }] : '', ['empty'], false, 'creatable-select-search', {
                addContainerClass: 'col-span-full',
                labelType: 'stack'
            }, {
                onCreateOption: (_, value) => {
                    this.handleEmailInputChange('Emails', [...this.state.emailFields.Emails.value, {
                        value: value,
                        label: value,
                        invalid: !emailValid(value),
                        manual: true
                    }])
                },
                formatCreateLabel: userInput => this.props.translate('btn.Create') + ' "' + userInput + '"',
                isMulti: true
            }),
            Content: new Field('Content', '', [], false, 'rich-text', {
                addContainerClass: 'col-span-full',
                labelClass: 'form-group has-float-label mb-1 font-weight-bold',
            }),
        }
    }

    getQuery = () => {
        let isPayedQuery = this.state.queryFields.isPaid.value ? ['IsPayed', '=', this.state.queryFields.isPaid.value] : 0

        return {
            ...FieldsManager.getFieldKeyValues(this.state.queryFields),
            searchFields: JSON.stringify({
                IsPayed: isPayedQuery
            })
        }
    }

    getContactsQuery = () => {
        return {
            offset: this.state.contactsModalOffset,
            sort: this.state.contactsModalSort,
            sortBy: this.state.contactsModalSortBy,
            ...FieldsManager.getFieldKeyValues(this.state.addContactsModalQueryFields),
            IncludeStatuses: 1,
            IsDriver: this.state.addContactsModalQueryFields?.PayrollBatchTypes?.value?.value === 'Drivers' ? 1 : 0,
            IsAgent: this.state.addContactsModalQueryFields?.PayrollBatchTypes?.value?.value === 'Agents' ? 1 : 0,
            IsEmployee: this.state.addContactsModalQueryFields?.PayrollBatchTypes?.value?.value === 'Employees' ? 1 : 0
        }
    }

    getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            isPaid: new Field('isPaid', '', [''], false, 'select', {labelType: "float"}, {isClearable: true}),
            sort: new Field('sort', 'ASC', [''], false, ''),
            sortBy: new Field('sortBy', 'Name', [''], false, ''),
            offset: new Field('offset', '0', [''], false, ''),
            limit: new Field('limit', 100, [''], false, 'select', {hideLabel: true, labelType: 'float'}),
            PayrollBatchID: new Field('PayrollBatchID', this.props.PayrollBatchID, [''], false, 'select', {hideLabel: true})
        }
    }

    getAddContactsModalQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, 'select', {}, {
                isClearable: true,
                options: PAYROLL_BATCH_TYPES
            }),
            HighImportance: new Field('HighImportance', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: 'top'}),
        }
    }

    handleShowDeliveredClick = () => {
        this.setState({
            isShowingDeliveredOnly: !this.state.isShowingDeliveredOnly
        })
    }

    handleNotesInputChange = (name, value) => {
        this.setState({notesFields: FieldsManager.updateField(this.state.notesFields, name, value), isDirty: true})
    }


    /** Render
     ================================================================= */
    renderBatchItem = (translate, netTotalAmount, batchItems) => {
        const isDriver = !!batchItems?.DriverSettlement
        const lineHaulFiltered = this.state.LineHaul.filter(load => this.state.isShowingDeliveredOnly ? load.LoadStatusID === 7 : true)

        const totalLoadedMileage = this.state.CurrentItems?.filter(it => it.Description.startsWith('Load #')).reduce((memo, it) =>
            parseFloat(it?.ReferenceType === 'tbl_LoadDriverPay' ? it?.Metadata?.TotalMiles : 0) + memo, 0.00)
        const totalEmptyMiles = this.state.CurrentItems?.filter(it => it.Description.startsWith('Load #')).reduce((memo, it) =>
            parseFloat(it?.ReferenceType === 'tbl_LoadDriverPay' ? it?.Metadata?.EmptyMiles : 0) + memo, 0.00)

        const notesFieldsInternal = fieldsToHtml(Object.values(includeFields(this.state.notesFields, "InternalNotes")), translate, this.handleNotesInputChange, this.selects);
        const notesFieldsExternal = fieldsToHtml(Object.values(includeFields(this.state.notesFields, "ExternalNotes")), translate, this.handleNotesInputChange, this.selects);

        const statementsView = (
            <div>
                {!!this.state.CurrentItems.length && (
                    <div className="space-y-4">
                        <div className={'font-bold text-2xl text-tm-gray-900'}>Statement</div>

                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <h3 className={'text-xl text-tm-gray-900'}>Load pay</h3>
                            </div>
                            <TableCard>
                                <ResourceTable
                                    addClass="rounded-card"
                                    data={this.state.CurrentItems.filter(it => it.Description.startsWith('Load #')).map(it => {
                                        if (it.Metadata) {
                                            it.Metadata.ArchivedDate = true
                                        }

                                        return it.Metadata ? Object.assign({}, it.Metadata, it) : {}
                                    })}
                                    fields={isDriver ? this.getLineHaulFields() : this.getCommissionLineHaulFields()}
                                    tableKey="PayrollBatchEntryItemID"
                                    translate={translate}
                                    actions={[
                                        {
                                            action: (item) => {
                                                this.handleRemoveFromCurrentItems(item)
                                            },
                                            customContent: () => <MinusCircleIcon
                                                className="w-5 h-5"/>,
                                            visible: () => !this.state.batchItems.IsPayed,
                                            title: translate('text.remove'),
                                        }
                                    ]}

                                    options={isDriver ? this.lineHaulTableOptions : this.commissionTableOptions}
                                />
                            </TableCard>

                            {this.state.CurrentItems?.filter(it => it.Description.startsWith('Load #')).length === 0 && (
                                <div>No items</div>)}
                        </div>

                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <h3 className={'text-xl text-tm-gray-900'}>Adjustments/Escrows</h3>
                            </div>


                            <TableCard>
                                <ResourceTable
                                    addClass="rounded-card"
                                    data={this.state.CurrentItems.filter(it => !it.Description.startsWith('Load #')).map(it => {
                                        it.ArchivedDate = true
                                        return it
                                    })}
                                    fields={this.getStatementFields()}
                                    tableKey="PayrollBatchEntryItemID"
                                    translate={translate}

                                    actions={[
                                        {
                                            action: (item) => {
                                                this.handleRemoveFromCurrentItems(item)
                                            },
                                            customContent: () => <MinusCircleIcon
                                                className="w-5 h-5"/>,
                                            visible: () => !this.state.batchItems.IsPayed,
                                            title: translate('text.remove'),
                                        }
                                    ]}

                                    options={this.escrowTableOptions}
                                />
                            </TableCard>

                            {this.state.CurrentItems?.filter(it => !it.Description.startsWith('Load #')).length === 0 && (
                                <div>No items</div>)}
                        </div>
                    </div>
                )}

                {!this.state.CurrentItems.length && (
                    <div className="text-center">
                        <p className="font-semibold text-lg text-tm-gray-700">No statement items</p>
                    </div>
                )}

                <div className={"space-y-4"}>
                    {!!this.state.batchItems.IsPayed && (this.state.notesFields.InternalNotes?.type === 'textarea' || this.state.notesFields.ExternalNotes?.type === 'textarea') && (
                        <div className={"m-2 font-bold"}>
                            NOTES
                        </div>
                    )}

                    {!this.state.batchItems.IsPayed && (
                        <div className={"m-2 font-bold"}>
                            NOTES
                        </div>
                    )}


                    <div>
                        {this.state.notesFields.InternalNotes?.type === 'hidden' && !this.state.batchItems.IsPayed && (
                            <button className={"flex m-3"}
                                    onClick={() => this.handleAddNoteClick("InternalNotes")}>
                                <PlusCircleIcon className="w-5 h-5"/>
                                <div className={"mt-0.5 ml-1"}>Internal notes</div>
                            </button>

                        )}

                        {notesFieldsInternal}
                    </div>

                    <div>
                        {this.state.notesFields.ExternalNotes?.type === 'hidden' && !this.state.batchItems.IsPayed && (
                            <button className={"flex m-3"}
                                    onClick={() => this.handleAddNoteClick("ExternalNotes")}>
                                <PlusCircleIcon className="w-5 h-5"/>
                                <div className={"mt-0.5 ml-1"}>External notes</div>
                            </button>
                        )}

                        {notesFieldsExternal}
                    </div>
                </div>

            </div>
        )

        const unprocessedItemsView = (
            <div>
                {!this.props.isLoading && !this.props?.batchItems?.IsPayed && (
                    <div className="space-y-4">
                        <div className={'font-bold text-2xl text-tm-gray-900'}>Unprocessed items
                        </div>
                        {/** Commissions **/}
                        {!isDriver && (
                            <div className="space-y-2">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <h3 className={'text-xl text-tm-gray-900'}>Commissions</h3>

                                        <label
                                            className="mb-0 select-none py-3 px-4 inline-flex items-center cursor-pointer hover:bg-tm-gray-100 rounded-lg ml-3"
                                        >
                                            <FieldCheckbox
                                                id="showDeliveredOnly"
                                                className={classNames('checkbox border-primary')}
                                                onChange={this.handleShowDeliveredClick}
                                                value={this.state.isShowingDeliveredOnly}
                                            />

                                            <span
                                                className="ml-2 flex flex-col text-sm font-semibold text-tm-gray-900"
                                            >
                                                                        {translate('text.show_only_delivered')}
                                                                    </span>
                                        </label>
                                    </div>

                                    {!this.state.batchItems.IsPayed && !!lineHaulFiltered.length && (
                                        <Tippy content={<span>Add all items from Line Haul</span>}>
                                            <button
                                                className="btn btn-outline-secondary py-1"
                                                onClick={() => this.state.LineHaul.length > 0 && this.handleAddAllItemsToCurrentFromState(this.state.LineHaul, 'Commissions')}
                                                disabled={this.state.LineHaul.every(it => !!this.isItemAddedAlready(this.handleFormatPotentialItem('Commissions', it)))}
                                            >
                                                Add all
                                            </button>
                                        </Tippy>
                                    )}
                                </div>

                                {!!lineHaulFiltered.length && (
                                    <TableCard>
                                        <ResourceTable
                                            addClass="rounded-card"
                                            data={lineHaulFiltered.map(it => {
                                                let formattedItem = this.handleFormatPotentialItem('Commissions', it)
                                                let currentItemsIDs = this.state.CurrentItems.map(it => it.ReferenceID)
                                                formattedItem.ArchivedDate = currentItemsIDs?.includes(formattedItem.ReferenceID)
                                                return formattedItem
                                            })}
                                            fields={this.getCommissionLineHaulFields()}
                                            tfoot={{
                                                LoadID: () => <div className="pl-6">Total</div>,
                                                TotalMiles: () => <div
                                                    className="text-right font-bold">
                                                    {numberWithCommas(parseFloat(this.state.LineHaul?.reduce((memo, it) => parseFloat(it?.TotalMiles ?? 0) + memo, 0)))}
                                                </div>,
                                                Amount: () => <div className="text-right font-bold">
                                                    {genericMoneyFormatter(parseFloat(this.state.LineHaul?.reduce((memo, it) => parseFloat(it?.Amount ?? 0) + memo, 0)))}
                                                </div>
                                            }}
                                            translate={translate}

                                            actions={[
                                                {
                                                    action: (it) => !this.state.batchItems.IsPayed && this.handleAddItemToContact(this.handleFormatPotentialItem('Commissions', it)),
                                                    customContent: () => <PlusCircleIcon
                                                        className="w-5 h-5"/>,
                                                    visible: (it) => !this.isItemAddedAlready(this.handleFormatPotentialItem('Commissions', it)),
                                                    title: translate('text.add'),
                                                }
                                            ]}

                                            options={this.commissionTableOptions}
                                        />
                                    </TableCard>
                                )}

                                {lineHaulFiltered?.length === 0 && (this.state.isShowingDeliveredOnly ?
                                    <div>No items with show delivered only filter active</div> :
                                    <div>No items</div>)}
                                {!!this.state.batchItems?.DriverSettlement?.PayOnlyWhenPODReady && (
                                    <span className={'mr-2'}>* Only line hauls that have POD ready status will be shown for this driver.</span>
                                )}
                            </div>
                        )}

                        {/** Line haul**/}
                        {isDriver && (
                            <div className="space-y-2">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <h3 className={'text-xl text-tm-gray-900'}>Line haul</h3>

                                        <label
                                            className="mb-0 select-none py-3 px-4 inline-flex items-center cursor-pointer hover:bg-tm-gray-100 rounded-lg ml-3"
                                        >
                                            <FieldCheckbox
                                                id="showDeliveredOnly"
                                                className={classNames('checkbox border-primary')}
                                                onChange={this.handleShowDeliveredClick}
                                                value={this.state.isShowingDeliveredOnly}
                                            />

                                            <span
                                                className="ml-2 flex flex-col text-sm font-semibold text-tm-gray-900"
                                            >
                                                                        {translate('text.show_only_delivered')}
                                                                    </span>
                                        </label>
                                    </div>

                                    {!this.state.batchItems.IsPayed && !!lineHaulFiltered.length && (
                                        <Tippy content={<span>Add all items from Line Haul</span>}>
                                            <button
                                                className="btn btn-outline-secondary py-1"
                                                onClick={() => this.state.LineHaul.length > 0 && this.handleAddAllItemsToCurrentFromState(this.state.LineHaul, 'LineHaul')}
                                                disabled={this.state.LineHaul.every(it => !!this.isItemAddedAlready(this.handleFormatPotentialItem('LineHaul', it)))}
                                            >
                                                Add all
                                            </button>
                                        </Tippy>
                                    )}
                                </div>


                                {!!lineHaulFiltered.length && (
                                    <TableCard>
                                        <ResourceTable
                                            addClass="rounded-card"
                                            data={lineHaulFiltered.map(it => {
                                                let formattedItem = this.handleFormatPotentialItem('LineHaul', it)
                                                let currentItemsIDs = this.state.CurrentItems.map(it => it.ReferenceID)
                                                formattedItem.ArchivedDate = currentItemsIDs?.includes(formattedItem.ReferenceID)
                                                return formattedItem
                                            })}
                                            fields={this.getLineHaulFields()}
                                            commonTable={true}
                                            tfoot={{
                                                LoadID: () => <div className="pl-6">Total</div>,
                                                TotalMiles: () => <div
                                                    className="text-right font-bold">
                                                    {numberWithCommas(parseFloat(this.state.LineHaul?.reduce((memo, it) => parseFloat(it?.TotalMiles ?? 0) + memo, 0)))}
                                                </div>,
                                                Amount: () => <div className="text-right font-bold">
                                                    {genericMoneyFormatter(parseFloat(this.state.LineHaul?.reduce((memo, it) => parseFloat(it?.Amount ?? 0) + memo, 0)))}
                                                </div>
                                            }}
                                            tableKey="LoadID"
                                            translate={translate}

                                            actions={[
                                                {
                                                    action: (it) => !this.state.batchItems.IsPayed && this.handleAddItemToContact(this.handleFormatPotentialItem('LineHaul', it)),
                                                    customContent: () => <PlusCircleIcon
                                                        className="w-5 h-5"/>,
                                                    visible: (it) => !this.isItemAddedAlready(this.handleFormatPotentialItem('LineHaul', it)),
                                                    title: translate('text.add'),
                                                }
                                            ]}

                                            options={this.lineHaulTableOptions}
                                        />
                                    </TableCard>
                                )}

                                {lineHaulFiltered?.length === 0 && (this.state.isShowingDeliveredOnly ?
                                    <div>No items with show delivered only filter active</div> :
                                    <div>No items</div>)}
                                {!!this.state.batchItems?.DriverSettlement?.PayOnlyWhenPODReady && (
                                    <span className={'mr-2'}>* Only line hauls that have POD ready status will be shown for this driver.</span>
                                )}
                            </div>
                        )}

                        {/** Accesorials **/}
                        {isDriver && (
                            <div className="space-y-2">
                                <div className="flex items-center justify-between">
                                    <h3 className={'text-xl text-tm-gray-900'}>Accesorials</h3>

                                    {!this.state.batchItems.IsPayed && this.state.Accesorials?.length > 0 && (
                                        <Tippy
                                            content={<span>Add all items from Accesorials</span>}>
                                            <button
                                                className="btn btn-outline-secondary py-1"
                                                onClick={() => this.state.Accesorials.length > 0 && this.handleAddAllItemsToCurrentFromState(this.state.Accesorials, 'Accesorials')}
                                                disabled={this.state.Accesorials.every(it => !!this.isItemAddedAlready(this.handleFormatPotentialItem('Accesorials', it)))}
                                            >
                                                Add all
                                            </button>
                                        </Tippy>
                                    )}
                                </div>

                                {!!this.state.Accesorials.length && (
                                    <TableCard>
                                        <ResourceTable
                                            addClass="rounded-card"
                                            data={this.state.Accesorials.map(it => {
                                                let formattedItem = this.handleFormatPotentialItem('Accesorials', it)
                                                let currentItemsIDs = this.state.CurrentItems.map(it => it.ReferenceID)
                                                formattedItem.ArchivedDate = currentItemsIDs?.includes(formattedItem.ReferenceID)
                                                return formattedItem
                                            })}
                                            fields={this.getAccesorialFields()}
                                            commonTable={true}
                                            tfoot={{
                                                LoadID: () => <div className="pl-6">Accessorials
                                                    Total</div>,
                                                Amount: () => <div className="text-right font-bold">
                                                    {genericMoneyFormatter(parseFloat(this.state.Accesorials?.reduce((memo, it) => parseFloat(it.Amount) + memo, 0)))}
                                                </div>
                                            }}
                                            tableKey="LoadID"
                                            translate={translate}

                                            actions={[
                                                {
                                                    action: (it) => !this.state.batchItems.IsPayed && this.handleAddItemToContact(this.handleFormatPotentialItem('Accesorials', it)),
                                                    customContent: () => <PlusCircleIcon
                                                        className="w-5 h-5"/>,
                                                    visible: (it) => !this.isItemAddedAlready(this.handleFormatPotentialItem('Accesorials', it)),
                                                    title: translate('text.add'),
                                                }
                                            ]}

                                            options={this.accessorialTableOptions}
                                        />
                                    </TableCard>
                                )}


                                {this.state.Accesorials?.length === 0 && (<div>No items</div>)}
                            </div>
                        )}

                        {/** Fuel card purchases **/}
                        {/*
                                            <div className="space-y-2">
                                                <div className="flex items-center justify-between">
                                                    <h3 className={'text-xl text-tm-gray-900'}>Fuel card purchases</h3>

                                                    {!this.state.batchItems.IsPayed && !!this.state.FuelPurchase.length && false && (
                                                        <Tippy content={<span>Add all items Fuel card purchases</span>}>
                                                            <button
                                                                className="btn btn-outline-secondary py-1"
                                                                onClick={() => this.state.FuelPurchase.length > 0 && this.handleAddAllItemsToCurrentFromState('FuelPurchase')}
                                                            >
                                                                Add all
                                                            </button>
                                                        </Tippy>
                                                    )}
                                                </div>

                                                {!!this.state.FuelPurchase.length && (
                                                    <TableCard>
                                                        <ResourceTable
                                                            addClass="rounded-card"
                                                            data={this.state.FuelPurchase}
                                                            fields={this.getFuelPurchaseFields()}
                                                            commonTable={true}
                                                            tfoot={{
                                                                Date: "Total",
                                                                Amount: <div className="text-right font-bold">
                                                                    {genericMoneyFormatter(parseFloat(this.state.FuelPurchase?.reduce((memo, it) => parseFloat(it.Amount) + memo, 0)))}
                                                                </div>
                                                            }}
                                                            tableKey="FuelPurchaseID"
                                                            translate={translate}

                                                            onRowClick={(it, key, index) => {
                                                                const item = this.handleFormatPotentialItem('FuelPurchase', it);
                                                                const alreadyAdded = this.isItemAddedAlready(item);
                                                                if (alreadyAdded) return;
                                                                return !this.state.batchItems.IsPayed && this.handleAddItemToContact(item, index)
                                                            }}

                                                            actions={[
                                                                {
                                                                    action: (it, key, index) => !this.state.batchItems.IsPayed && this.handleAddItemToContact(this.handleFormatPotentialItem('FuelPurchase', it), index),
                                                                    customContent: () => <PlusCircleIcon className="w-5 h-5"/>,
                                                                    visible: (it) => !this.isItemAddedAlready(this.handleFormatPotentialItem('FuelPurchase', it)),
                                                                    title: translate('text.add'),
                                                                }
                                                            ]}

                                                            options={this.tableOptions}
                                                        />
                                                    </TableCard>
                                                )}

                                                {this.state.FuelPurchase?.length === 0 && (<div>No items</div>)}
                                                {!this.state.batchItems?.DriverSettlement?.IsPaysFuel && (
                                                    <span className={"mr-2"}>* Fuel card purchase are handled by the company for this driver.</span>
                                                )}
                                            </div>
                                            */}

                        {/** Reimbursements **/}
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <h3 className={'text-xl text-tm-gray-900'}>{translate('text.reimbursements_advances')}</h3>

                                {!this.state.batchItems.IsPayed && this.state.Reimbursement?.length > 0 && (
                                    <Tippy content={<span>Add all items from Reimbursement</span>}>
                                        <button
                                            className="btn btn-outline-secondary py-1"
                                            onClick={() => this.state.Reimbursement.length > 0 && this.handleAddAllItemsToCurrentFromState(this.state.Reimbursement, 'Reimbursement')}
                                            disabled={this.state.Reimbursement.every(it => !!this.isItemAddedAlready(this.handleFormatPotentialItem('Reimbursement', it)))}
                                        >
                                            Add all
                                        </button>
                                    </Tippy>
                                )}
                            </div>

                            {!!this.state.Reimbursement.length && (
                                <TableCard>
                                    <ResourceTable
                                        addClass="rounded-card"
                                        data={this.state.Reimbursement.map(it => {
                                            let formattedItem = this.handleFormatPotentialItem('Reimbursement', it)
                                            let currentItemsIDs = this.state.CurrentItems.map(it => it.ReferenceID)
                                            formattedItem.ArchivedDate = currentItemsIDs?.includes(formattedItem.ReferenceID)
                                            return formattedItem
                                        })}
                                        fields={this.getReimbursementFields()}
                                        commonTable={true}
                                        tfoot={{
                                            Notes: () => <div className="pl-6">Reimbursements
                                                total</div>,
                                            Amount: () => <div className="text-right font-bold">
                                                {genericMoneyFormatter(parseFloat(this.state.Reimbursement?.reduce((memo, it) => parseFloat(it.Amount) + memo, 0)))}
                                            </div>
                                        }}
                                        tableKey="ReimbursementID"
                                        translate={translate}

                                        actions={[
                                            {
                                                action: (it) => !this.state.batchItems.IsPayed && this.handleAddItemToContact(this.handleFormatPotentialItem('Reimbursement', it)),
                                                customContent: () => <PlusCircleIcon
                                                    className="w-5 h-5"/>,
                                                visible: (it) => !this.isItemAddedAlready(this.handleFormatPotentialItem('Reimbursement', it)),
                                                title: translate('text.add'),
                                            }
                                        ]}

                                        options={this.reimbursementTableOptions}
                                    />
                                </TableCard>
                            )}

                            {this.state.Reimbursement?.length === 0 && (<div>No items</div>)}
                        </div>

                        {/** Pay adjustments< **/}
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <h3 className={'text-xl text-tm-gray-900'}>Pay adjustments</h3>

                                {!this.state.batchItems.IsPayed && this.state.Recurring?.length > 0 && (
                                    <Tippy content={
                                        <span>Add all items from Pay adjustments</span>}>
                                        <button
                                            className="btn btn-outline-secondary py-1"
                                            onClick={() => this.state.Recurring.length > 0 && this.handleAddAllItemsToCurrentFromState(this.state.Recurring, 'Recurring')}
                                            disabled={this.state.Recurring.every(it => !!this.isItemAddedAlready(this.handleFormatPotentialItem('Recurring', it)))}
                                        >
                                            Add all
                                        </button>
                                    </Tippy>
                                )}
                            </div>

                            {!!this.state.Recurring.length && (
                                <TableCard>
                                    <ResourceTable
                                        addClass="rounded-card"
                                        data={this.state.Recurring.map(it => {
                                            let formattedItem = this.handleFormatPotentialItem('Recurring', it)
                                            let currentItemsIDs = this.state.CurrentItems.map(it => it.ReferenceID)
                                            formattedItem.ArchivedDate = currentItemsIDs?.includes(formattedItem.ReferenceID)
                                            return formattedItem
                                        })}
                                        fields={this.getRecurringFields()}
                                        commonTable={true}
                                        tfoot={{
                                            Notes: () => <div className="pl-6">Total</div>,
                                            Amount: () => <div className="text-right font-bold">
                                                {genericMoneyFormatter(parseFloat(this.state.Recurring?.filter(it => !it.AmountPer).reduce((memo, it) => parseFloat(it.Amount) + memo, 0)))}
                                            </div>
                                        }}
                                        tableKey="DriverScheduledDeductionsID"
                                        translate={translate}

                                        actions={[
                                            {
                                                action: (it) => !this.state.batchItems.IsPayed && this.handleAddItemToContact(this.handleFormatPotentialItem('Recurring', it)),
                                                customContent: () => <PlusCircleIcon
                                                    className="w-5 h-5"/>,
                                                visible: (it) => !this.isItemAddedAlready(this.handleFormatPotentialItem('Recurring', it)),
                                                title: translate('text.add'),
                                            }
                                        ]}

                                        options={this.recurringTableOptions}
                                    />
                                </TableCard>
                            )}

                            {this.state.Recurring?.length === 0 && (<div>No items</div>)}
                        </div>

                        {/** Other< **/}
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <h3 className={'text-xl text-tm-gray-900'}>Expenses</h3>

                                {!this.state.batchItems.IsPayed && this.state.Other?.length > 0 && (
                                    <Tippy content={<span>Add all items from Other</span>}>
                                        <button
                                            className="btn btn-outline-secondary py-1"
                                            onClick={() => this.state.Other.length > 0 && this.handleAddAllItemsToCurrentFromState(this.state.Other, 'Other')}
                                            disabled={this.state.Other.every(it => !!this.isItemAddedAlready(this.handleFormatPotentialItem('Other', it)))}
                                        >
                                            Add all
                                        </button>
                                    </Tippy>
                                )}
                            </div>

                            {!!this.state.Other.length && (
                                <TableCard>
                                    <ResourceTable
                                        addClass="rounded-card"
                                        data={this.state.Other.map(it => {
                                            let formattedItem = this.handleFormatPotentialItem('Other', it)
                                            let currentItemsIDs = this.state.CurrentItems.map(it => it.ReferenceID)
                                            formattedItem.ArchivedDate = currentItemsIDs?.includes(formattedItem.ReferenceID)
                                            return formattedItem
                                        })}
                                        fields={this.getOtherFields()}
                                        commonTable={true}
                                        tfoot={{
                                            Notes: () => <div className="pl-6">Total</div>,
                                            Amount: () => <div className="text-right font-bold">
                                                {genericMoneyFormatter(parseFloat(this.state.Other?.reduce((memo, it) => parseFloat(it.Amount) + memo, 0)))}
                                            </div>
                                        }}
                                        tableKey="ExpenseLineID"
                                        translate={translate}

                                        actions={[
                                            {
                                                action: (it) => !this.state.batchItems.IsPayed && this.handleAddItemToContact(this.handleFormatPotentialItem('Other', it)),
                                                customContent: () => <PlusCircleIcon
                                                    className="w-5 h-5"/>,
                                                visible: (it) => !this.isItemAddedAlready(this.handleFormatPotentialItem('Other', it)),
                                                title: translate('text.add'),
                                                order: 20
                                            },
                                            {
                                                action: (it) => this.handleToggleExpenseDetailsDialog(it),
                                                customContent: () => <EyeIcon
                                                    className="w-5 h-5"/>,
                                                visible: (it) => !!it.ExpenseID,
                                                title: translate('btn.view'),
                                                order: 10
                                            }
                                        ]}

                                        options={getDefaultTableOptions(this.getOtherFields(), Object.assign({}, this.tableOptions, {
                                            columns: {
                                                Amount: {
                                                    minWidth: 150
                                                }
                                            }
                                        }), this.pagePath + "_accessorialTableOptions", this.props.translate)
                                        }
                                    />
                                </TableCard>
                            )}
                        </div>
                        {this.state.Other?.length === 0 && (<div>No items</div>)}

                        {/** Balances **/}
                        {this.renderBalances(batchItems)}
                    </div>
                )}
            </div>
        )

        return (
            <div
                className={
                    classNames(
                        'relative',
                        this.props.isLoading ? 'invisible' : undefined
                    )}
            >
                <div className={'border-t border-tm-gray-300 bg-tm-gray-100 p-5 h-dialog-body'}>
                    <div className={''}>
                        {netTotalAmount < 0 && (
                            <InfoBar addClass="mb-8">Cannot post negative net value</InfoBar>
                        )}

                        {!this.props.isLoading && this.props?.batchItems?.DriverSettlement?.InitialPayrollSetupCompleted === 0 && checkPerm(Resources.DriverSettlements, UPDATE_PERM) && (
                            <InfoBar addClass="mb-8 group" type="warning" Icon={ExclamationCircleIcon}
                                     onClick={() => openInNewTab(`drivers/info/${this.props?.batchItems?.DriverSettlement?.DriverID}?tab=drivers/settlements`)}>
                                <div className="flex items-center justify-between w-full">
                                    <span>{translate("text.complete_payroll_warning")}</span>
                                    <span
                                        className="font-bold text-primary px-4 ml-4 group-hover:underline">{translate("btn.open_payroll")}</span>
                                </div>
                            </InfoBar>
                        )}

                        <div>
                            <div className={'flex justify-between items-center mb-3'}>
                                {!!batchItems?.Expense && (
                                    <div className={'inline'}>
                                        Bill: {renderExpenseStatusBadge(batchItems?.Expense)}
                                    </div>
                                )}

                                <div className="grid grid-cols-4 border-bottom-1">
                                    <div className={'text-base bold text-center'}>
                                        Net amount: {genericMoneyFormatter(netTotalAmount)}
                                    </div>
                                    <div className={'text-base bold text-center'}>
                                        Gross: {genericMoneyFormatter(parseFloat(this.state.CurrentItems?.filter(it => {
                                        return it.Description.startsWith('Load #') ||
                                            (!!it.IsGross && it.Amount > 0)
                                    }).reduce((memo, it) => memo + parseFloat(it.Amount), 0)))}
                                    </div>
                                    {isDriver && (
                                        <div className={'text-base bold text-center'}>
                                            Loaded mileage: {numberWithCommas(totalLoadedMileage)}
                                        </div>
                                    )}
                                    {isDriver && (
                                        <div className={'text-base bold text-center'}>
                                            Empty mileage: {numberWithCommas(totalEmptyMiles)}
                                        </div>
                                    )}
                                </div>

                                <div className={'flex space-x-1.5'}>
                                    {!this.state.batchItems.IsPayed && isDriver && (
                                        <>
                                            <Tippy content={<span>{translate('text.UseEscrow')}</span>}>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={this.handleToggleUseEscrow}
                                                >
                                                    <PlusIcon className="h-4 w-4 text-white mr-1"/>
                                                    Use escrow
                                                </button>
                                            </Tippy>
                                        </>
                                    )}
                                    {!this.state.batchItems.IsPayed && (
                                        <>
                                            <Tippy content={<span>{translate('text.AddAdvance')}</span>}>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={this.handleToggleAddAdvance}
                                                >
                                                    <PlusIcon className="h-4 w-4 text-white mr-1"/>
                                                    Add/Pay advance
                                                </button>
                                            </Tippy>

                                            <Tippy content={<span>{translate('text.AddPay')}</span>}>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={this.handleToggleAddPay}
                                                >
                                                    <PlusIcon className="h-4 w-4 text-white mr-1"/>
                                                    Add pay
                                                </button>
                                            </Tippy>
                                        </>
                                    )}

                                    {!this.state.batchItems.IsPayed && (
                                        <Tippy content={<span>{translate('text.RevertChanges')}</span>}>
                                            <button
                                                type="button"
                                                onClick={this.handleRevertChanges}
                                                className={classNames('relative z-10 p-2 rounded-full text-tm-gray-900 bg-tm-gray-100 hover:text-tm-gray-900 hover:bg-tm-gray-100')}
                                            >
                                                <ArrowUturnLeftIcon className="h-4 w-4 text-tm-gray-700"/>
                                            </button>
                                        </Tippy>
                                    )}
                                </div>
                            </div>

                            {!this.props.isLoading
                                && this.props?.batchItems?.DriverSettlement?.InitialPayrollSetupCompleted === 0
                                && !checkPerm(Resources.DriverSettlements, UPDATE_PERM)
                                && (
                                    <InfoBar type="warning" Icon={ExclamationCircleIcon} addClass="mb-4">
                                        {translate("text.payroll_setup_not_completed")}
                                    </InfoBar>
                                )}

                            <div
                                className={
                                    classNames(
                                        'grid gap-4',
                                        !this.props?.batchItems?.IsPayed && this.state.isGridView ? 'grid-cols-2' : 'grid-cols-1'
                                    )
                                }
                            >
                                {this.state.isGridView ? (
                                    <>
                                        {statementsView}
                                        {unprocessedItemsView}
                                    </>
                                ) : (
                                    <>
                                        {unprocessedItemsView}
                                        {statementsView}
                                    </>
                                )}

                            </div>
                            {/*grid end*/}

                            {!!this.state.batchItems.IsPayed && (
                                <>
                                    {this.renderBalances(batchItems, true)}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBatchItemEmail = (translate) => {
        const metadata = {
            Emails: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    value: it.Email,
                    label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email
                })
            }
        }

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.emailFields)), translate, this.handleEmailInputChange, metadata)
        return (
            <div className={'p-5'}>
                <div className="flex justify-start items-center mb-5">
                    <Tippy content={<span>{translate('text.BackToBatch')}</span>}>
                        <button
                            className="mx-1 bg-tm-gray-100-light p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-100 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2  mr-3"
                            onClick={() => {
                                this.setState({selectedView: 'info'})
                            }}
                        >
                            <ArrowLeftIcon className="w-5 h-5"/>
                        </button>
                    </Tippy>

                    <h2 className={'font-bold text-lg'}>Send statement via email</h2>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    {fieldsHtml}
                </div>
            </div>
        )
    }

    renderBatchItemPDF = (translate, showPerMile) => {
        return (
            <div className="p-5 h-dialog-body">
                <div className="flex justify-start items-center mb-5">
                    <Tippy content={<span>{translate('text.BackToBatch')}</span>}>
                        <button
                            className="mx-1 bg-tm-gray-100-light p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-100 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2  mr-3"
                            onClick={() => {
                                this.setState({selectedView: 'info'})
                            }}
                        >
                            <ArrowLeftIcon className="w-5 h-5"/>
                        </button>
                    </Tippy>

                    <h2 className={'font-bold text-2xl'}>PDF preview {showPerMile ?
                        <span>(Blank version)</span> : null}</h2>
                </div>

                <FileViewer
                    fileType={'pdf'}
                    filePath={Env.getApiUrl('api/' + Resources.PayrollBatchesItemsDocument, Object.assign({}, {
                        PayrollBatchEntryID: this.state.batchItems.PayrollBatchEntryID,
                        token: getJWT().access_token,
                        ShowPerMile: showPerMile ? 1 : 0,
                        type: 1
                    }))}
                    onError={(e) => {
                        console.log(e)
                    }}/>
            </div>
        )
    }

    renderBatchItemHistory = (translate) => {
        const data = this.state.History.map((item, index) => {
            return (
                <li key={index}>
                    <div className="relative pb-8">
                        {index !== this.state.History.length - 1 ? (
                            <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-tm-gray-200"
                                  aria-hidden="true"/>
                        ) : null}

                        <div className="relative flex items-start space-x-3">
                            <>
                                <div className="relative">
                                    <img
                                        className="h-10 w-10 rounded-full bg-icon flex items-center justify-center ring-8 ring-white"
                                        src={Env.getApiUrl('api/' + Resources.ContactImage, {
                                            token: getJWT().access_token,
                                            ContactID: item.UpdatedByContactID
                                        })}
                                        onError={({currentTarget}) => {
                                            currentTarget.onerror = null // prevents looping
                                            currentTarget.src = '/images/avatar-light.png'
                                        }}
                                        alt=""/>
                                </div>
                                <div className="min-w-0 flex-1">
                                    <div>
                                        <div className="text-sm">
                                            <a href={'#'} className="font-medium text-tm-gray-900">
                                                {item.UpdatedByContact}
                                            </a>
                                        </div>
                                        <p
                                            className="mt-0.5 text-sm text-tm-gray-500">{toFrontDateTimeFromUTC(item.CreateUpdateDate)}</p>
                                    </div>
                                    <div className="mt-2 text-sm text-tm-gray-700">
                                        <p>{item.Name}</p>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </li>
            )
        })

        return (
            <div className="border-t border-tm-gray-300 bg-tm-gray-100 p-5">
                <div className="flex justify-start items-center mb-5">
                    <Tippy content={<span>{translate('text.BackToBatch')}</span>}>
                        <button
                            className="mx-1 bg-tm-gray-100-light p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-100 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2  mr-3"
                            onClick={() => {
                                this.setState({selectedView: 'info'})
                            }}
                        >
                            <ArrowLeftIcon className="w-5 h-5"/>
                        </button>
                    </Tippy>

                    <h2 className={'font-bold text-2xl'}>History</h2>
                </div>
                <ul role="list" className="-mb-8 list-none">
                    {data}
                </ul>
            </div>
        )
    }

    getPrimaryButtonFooterLabel = () => {
        switch (this.state.selectedView) {
            case 'email':
                return 'Send Email'
            case 'pdf':
                return 'Download PDF'
            case 'pdf_blank':
                return 'Download PDF'
            case 'info':
                if (!this.props.isLoading && !!this.state.selectedItem && !this.state.batchItems?.IsPayed) return 'Save'
                return null
            default:
                return null
        }
    }

    onPrimaryButtonClick = () => {
        if (this.state.selectedView === 'email') {
            this.sendEmail()
        } else if (this.state.selectedView === 'pdf') {
            this.downloadPDF()
        } else if (this.state.selectedView === 'pdf_blank') {
            this.downloadPDF(null, 1)
        } else if (this.state.selectedView === 'info') {
            this.handleSaveItems()
        }
    }

    closeBatchItem = () => {
        if (this.state.isDirty) {
            this.setState({
                closeDirtyBatchConfirmationModal: true
            })
        } else {
            this.setState({
                selectedItem: null,
                isDirty: false
            })
            this.props.fetchSingleBatch(this.getQuery())
        }
    }

    render() {
        const {
            translate,
            show,
            onClose,
            postEntryItems,
            unpostEntryItems,
            batchSingleCount,
            batchSingle,
            isLoading
        } = this.props

        const {batchItems} = this.state

        const netTotalAmount = this.state.CurrentItems?.reduce((memo, it) => parseFloat(it.Amount) + memo, 0.0).toFixed(2)

        const contactsData = getProp(this.props.contactResource, 'list', [])
        const contactsCount = getProp(this.props.contactResource, 'count', 0)

        const isDriver = !!batchItems?.DriverSettlement

        return (
            <Modal
                show={show}
                widthClass={''}
                onClose={() => {
                    if (this.state.selectedItem) {
                        this.closeBatchItem()
                    } else {
                        onClose()
                    }
                }}
            >
                <ModalHeader
                    title={
                        <div className={'flex items-center'}>
                            {!isLoading && <>
                                {this.state.selectedItem && (
                                    <Tippy content={<span>{translate('text.back_to_list')}</span>}>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.closeBatchItem()
                                            }}
                                            className={classNames('btn btn-icon relative z-10 p-2 rounded-full text-tm-gray-900 bg-tm-gray-100 hover:text-tm-gray-900 hover:bg-tm-gray-100 mr-3')}
                                        >
                                            <ArrowLeftIcon className="h-4 w-4"/>
                                        </button>
                                    </Tippy>
                                )}

                                {
                                    this.state.selectedItem ?
                                        <div
                                            className={'flex items-center'}>{(
                                            !!this.props?.batchItems?.Contact &&
                                            (typeof this.props?.batchItems?.Contact === 'string' || this.props?.batchItems?.Contact instanceof String)) ? this.props?.batchItems?.Contact : ''} {this.props?.batchItems?.IsPayed ?
                                            <div className="truncate max-w-full ml-5">
                                                <div
                                                    className="border bg-inverse w-full inline-block text-center px-3 py-0.5 rounded-md text-sm font-semibold border-green-600 text-green-600">Posted
                                                </div>
                                            </div>
                                            :
                                            <div className="truncate max-w-full ml-5">
                                                <div
                                                    className="border bg-inverse w-full inline-block text-center px-3 py-0.5 rounded-md text-sm font-semibold border-yellow-600 text-yellow-600">Preparing
                                                </div>
                                            </div>
                                        }</div>
                                        : translate('text.BatchProcess') + ' ' + this.props?.AutoCounter
                                }

                                {this.state.selectedItem && isDriver && (
                                    <div className={'ml-2'}>
                                        {!!batchItems.GrossSoFar && (
                                            <span
                                                className={'mr-2'}>Gross this year: {genericMoneyFormatter(batchItems.GrossSoFar)}</span>
                                        )}
                                        {!!batchItems.TotalAmountSoFar && (
                                            <span>Net paid this year: {genericMoneyFormatter(batchItems.TotalAmountSoFar)} </span>
                                        )}
                                    </div>
                                )}

                                {this.state.selectedItem && (
                                    <div className={'ml-2'}>
                                        {!!batchItems?.DriverSettlement?.Send1099 && (
                                            <span
                                                className={'mr-2 text-mute'}> (Should send 1099 for this driver.)</span>
                                        )}
                                        {!!batchItems?.DriverSettlement?.Organization && (
                                            <span
                                                className={'mr-2'}>(Pay to {batchItems?.DriverSettlement?.Organization})</span>
                                        )}
                                    </div>
                                )}
                            </>}
                        </div>
                    }
                    onClose={() => {
                        if (this.state.selectedItem) {
                            this.closeBatchItem()
                        } else {
                            onClose()
                        }
                    }}
                >

                    {!!this.props.isLoading && (
                        <LoaderLarge stripesBg/>
                    )}

                    <div className="flex flex-grow w-full justify-end px-4 items-center mr-2">
                        {this.state.selectedItem && (
                            <>
                                {!isLoading && !!this.state.selectedItem && !this.props?.batchItems?.IsPayed && (
                                    <Tippy
                                        content={this.state.isGridView ? translate('text.show_stacked') : translate('text.show_in_grid')}>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.setState({
                                                        isGridView: !this.state.isGridView
                                                    }, () => LocalStorage.set('PayrollBatchProcessDialogGrid', this.state.isGridView)
                                                )

                                            }}
                                            className={
                                                classNames(
                                                    'btn btn-icon relative z-10 p-2 rounded-full text-tm-gray-900 bg-tm-gray-100 hover:text-tm-gray-900 hover:bg-tm-gray-100 mr-3'
                                                )
                                            }
                                        >
                                            {this.state.isGridView && (
                                                <ViewColumnsIcon className="w-5 h-5 rotate-90"/>
                                            )}

                                            {!this.state.isGridView && (
                                                <Squares2X2Icon className="w-5 h-5"/>
                                            )}
                                        </button>
                                    </Tippy>
                                )}

                                {!isLoading && !!this.state.selectedItem && (
                                    <Tippy content={<span>{translate('text.History')}</span>}>
                                        <button
                                            type="button"
                                            onClick={() => this.setState({
                                                selectedView: 'history'
                                            })}
                                            className={classNames('btn btn-icon relative z-10 p-2 rounded-full text-tm-gray-900 bg-tm-gray-100 hover:text-tm-gray-900 hover:bg-tm-gray-100 mr-3')}
                                        >
                                            <HourglassIcon className="w-5 h-5"/>
                                        </button>
                                    </Tippy>
                                )}

                                {!isLoading && this.state.selectedView === 'info' && !!this.state.selectedItem && !!batchItems?.IsPayed && (
                                    <Tippy content={<span>{translate('text.SendViaEmail')}</span>}>
                                        <button
                                            type="button"
                                            onClick={() => this.setState({
                                                selectedView: 'email'
                                            })}
                                            className={classNames('btn btn-icon relative z-10 p-2 rounded-full text-tm-gray-900 bg-tm-gray-100 hover:text-tm-gray-900 hover:bg-tm-gray-100 mr-3')}
                                        >
                                            <AtSymbolIcon className={'w-5 h-5'}/>
                                        </button>
                                    </Tippy>
                                )}

                                {!isLoading && this.state.selectedView === 'info' && !!this.state.selectedItem && (
                                    <Tippy content={<span>{translate('text.PreviewPDF')}</span>}>
                                        <button
                                            type="button"
                                            onClick={() => this.setState({
                                                selectedView: 'pdf'
                                            })}
                                            className={classNames('btn btn-icon relative z-10 p-2 rounded-full text-tm-gray-900 bg-tm-gray-100 hover:text-tm-gray-900 hover:bg-tm-gray-100')}
                                        >
                                            <DocumentIcon className="w-5 h-5"/>
                                        </button>
                                    </Tippy>
                                )}

                                {!isLoading && this.state.selectedView === 'info' && !!this.state.selectedItem && (
                                    <Tippy content={<span>Preview blank PDF</span>}>
                                        <button
                                            type="button"
                                            onClick={() => this.setState({
                                                selectedView: 'pdf_blank'
                                            })}
                                            className={classNames('btn btn-icon relative z-10 p-2 rounded-full text-tm-gray-900 bg-tm-gray-100 hover:text-tm-gray-900 hover:bg-tm-gray-100')}
                                        >
                                            <DocumentChartBarIcon className="w-5 h-5"/>
                                        </button>
                                    </Tippy>
                                )}
                            </>
                        )}
                    </div>
                </ModalHeader>

                <div className="h-dialog-body">
                    {!this.state.selectedItem && (
                        <div>
                            <div className={'flex justify-between pt-4 pl-4 relative z-50'}>
                                <div className="grid grid-cols-12 gap-4 pb-4">
                                    <div className="col-span-6">
                                        <FieldSearch
                                            placeholder={translate('text.search')}
                                            addClass="form-control form-group"
                                            value={this.state.queryFields.query.value}
                                            onChange={this.handleQueryInputChange}
                                            {...this.state.queryFields.query}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <FieldContainer
                                            item={this.state.queryFields.isPaid}
                                            translate={this.props.translate}
                                        >
                                            <FieldSelectSearch
                                                className="form-control"
                                                values={{
                                                    0: 'Not posted',
                                                    1: 'Posted'
                                                }}
                                                onChange={this.handleQueryInputChange}
                                                {...this.state.queryFields.isPaid}
                                                placeholder={''}
                                                keySort={true}
                                                addClass={'form-control'}
                                                isClearable={true}
                                            />
                                        </FieldContainer>
                                    </div>
                                </div>

                                <div className={'flex'}>
                                    <button className={'btn btn-primary mr-4'}
                                            onClick={this.handleToggleAddEntryModal}>
                                        Add entry
                                    </button>
                                    <button className="btn btn-header mr-5"
                                            onClick={() => this.props.fetchSingleBatch(this.getQuery())}>
                                        <ArrowPathIcon className="w-5 h-5"/>
                                    </button>
                                </div>
                            </div>

                            <ResourceTable
                                maxHeightClass={'max-h-[calc(100vh-22rem)]'}
                                data={batchSingle}
                                fields={this.getBatchFields()}

                                translate={translate}
                                isLoading={isLoading}

                                options={this.batchTableOptions}

                                limit={this.state.queryFields.limit.value}
                                sort={this.state.queryFields.sort.value}
                                sortBy={this.state.queryFields.sortBy.value}
                                onSortChange={(value) => this.handleQueryInputChange('sortBy', value)}

                                onRowClick={(it) => this.handleSelectContact(it.PayrollBatchEntryID)}

                                actions={[
                                    {
                                        action: (item) => this.showPreviewModal(item),
                                        icon: DocumentIcon,
                                        visible: (item) => !!item.IsPayed,
                                        title: translate('text.preview')
                                    },
                                    {
                                        action: (it) => this.handleSendEmailDialog(it.PayrollBatchEntryID),
                                        icon: EnvelopeIcon,
                                        visible: (it) => !!it.IsPayed,
                                        label: false,
                                        title: translate('btn.send_document_via_email'),
                                        disabled: false,
                                        class: false,
                                        iconClass: false
                                    },
                                    {
                                        action: (item) => this.downloadPDF(item),
                                        icon: FolderArrowDownIcon,
                                        visible: (item) => !!item.IsPayed,
                                        title: translate('text.download_pdf')
                                    },
                                    {
                                        action: (it) => this.handleSelectContact(it.PayrollBatchEntryID),
                                        icon: ChevronRightIcon, // make this a function
                                        visible: () => true,
                                        label: false, // make this a function
                                        title: translate('text.process'),
                                        disabled: false,
                                        class: false,
                                        iconClass: false
                                    },
                                    {
                                        action: this.handleAssign,
                                        icon: CheckCircleIcon, // make this a function
                                        visible: (item) => this.handleAssignView(item),
                                        label: false, // make this a function
                                        title: translate('text.assign'),
                                        disabled: false,
                                        class: false,
                                        iconClass: false
                                    },
                                    {
                                        action: this.handleUnAssign,
                                        icon: XMarkIcon, // make this a function
                                        visible: (item) => this.handleUnAssignView(item),
                                        label: false, // make this a function
                                        title: translate('text.unassign'),
                                        disabled: false,
                                        class: false,
                                        iconClass: false
                                    },
                                    {
                                        action: this.handleToggleConfirmDeleteModal,
                                        icon: TrashIcon,
                                        visible: (item) => !item.IsPayed,
                                        title: translate('text.remove')
                                    }
                                ]}
                            />

                            <NoRecordsTable
                                show={(batchSingle.length === 0) && !isLoading}
                                canCreate={false}
                                title={translate('text.no_matching_records')}
                                btnLabel={translate('btn.create_contact')}
                                onClearFilterClick={() => this.setState({
                                    queryFields: this.getQueryFields()
                                }, () => this.props.fetchSingleBatch(this.getQuery()))}
                                clearFilterBtnLabel={translate('text.clear_filter')}
                                clearFilterText={translate('text.try_without_filters')}
                                filters={this.state.queryFields}
                            />

                            <TableCardFooter
                                show={!(!batchSingle.length && !isLoading)}
                            >
                                <Pagination
                                    count={batchSingleCount}
                                    isLoading={isLoading}
                                    handleQueryChange={
                                        (name, value) => name === 'offset'
                                            ? this.handleUpdateOffset(value)
                                            : this.handleQueryInputChange(name, value)
                                    }
                                    queryFields={this.state.queryFields}
                                    pageOffset={this.state.offset}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        </div>
                    )}

                    {!!this.state.selectedItem && this.state.selectedView === 'info' && this.renderBatchItem(translate, netTotalAmount, batchItems)}

                    {!isLoading && !!this.state.selectedItem && this.state.selectedView === 'history' && this.renderBatchItemHistory(translate)}

                    {!isLoading && !!this.state.selectedItem && this.state.selectedView === 'email' && this.renderBatchItemEmail(translate)}

                    {!isLoading && !!this.state.selectedItem && this.state.selectedView === 'pdf' && this.renderBatchItemPDF(translate)}

                    {!isLoading && !!this.state.selectedItem && this.state.selectedView === 'pdf_blank' && this.renderBatchItemPDF(translate, true)}
                </div>

                <ModalFooter
                    closeButtonLabel={translate('btn.close')}
                    onClose={onClose}
                    buttonDisabled={
                        ((this.state.selectedView === 'info') && !this.state.isDirty)
                    }
                    buttonLabel={this.getPrimaryButtonFooterLabel()}
                    onButtonClick={this.onPrimaryButtonClick}
                >
                    {!isLoading && this.state.selectedView === 'info' && !!this.state.selectedItem && !batchItems?.IsPayed && !this.state.isDirty && (
                        <button
                            className={'btn btn-outline ml-3'}
                            disabled={netTotalAmount < 0}
                            onClick={() => postEntryItems(batchItems?.PayrollBatchEntryID)}
                        >
                            {translate('btn.post')}
                        </button>
                    )}

                    {!isLoading && this.state.selectedView === 'info' && !!this.state.selectedItem && !!batchItems?.IsPayed && !this.state.isDirty && (
                        <button
                            className={'btn btn-outline ml-3'}
                            onClick={() => unpostEntryItems(batchItems?.PayrollBatchEntryID)}
                        >
                            Unpost
                        </button>
                    )}
                </ModalFooter>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.isConfirmDeleteModalOpen}
                    text={translate('text.confirm_delete')}
                    onClose={this.handleToggleConfirmDeleteModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={translate('btn.cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get('user'),
                            query: {
                                PayrollBatchEntryID: this.state.itemToDelete.PayrollBatchEntryID,
                            },
                            resource: Resources.PayrollBatchesItem,
                            piggyResource: Resources.PayrollBatch,
                            piggyQuery: this.getQuery(),
                            errorMessage: true,
                            successMessage: translate('text.entry_removed')
                        }))
                        this.handleToggleConfirmDeleteModal()
                    }
                    }
                />

                <ModalDefault
                    show={this.state.showPreviewModal}
                    widthClass={'max-w-7xl'}
                    limitHeight={true}
                    title={translate('modal_heading.Preview')}

                    close={() => this.setState({showPreviewModal: false, previewFile: null})}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => this.setState({showPreviewModal: false, previewFile: null})}
                    buttonLabel={translate('Download')}
                    onButtonClick={() => {
                        this.downloadPDF()
                    }}
                >
                    {!!this.state.previewFile && (
                        <FileViewer
                            filePath={Env.getApiUrl('api/' + Resources.PayrollBatchesItemsDocument, Object.assign({}, {
                                PayrollBatchEntryID: this.state.previewFile.PayrollBatchEntryID,
                                token: getJWT().access_token,
                                type: 1
                            }))}
                            fileType={'pdf'}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>

                {/* Contacts Modal */}
                <ModalDefault
                    show={this.state.isAddEntryModalOpen}
                    widthClass={'max-w-5xl'}
                    limitHeight={true}

                    title={translate('text.add_entry')}
                    onButtonClick={this.handleSave}

                    buttonLabel={translate('btn.save')}
                    closeButtonLabel={translate('btn.Cancel')}

                    onClose={this.handleToggleAddEntryModal}
                >
                    <div className={'pb-5 space-y-5'}>
                        <div className="">
                            <TableCard addClass={'rounded-none'}>
                                <div className="grid grid-cols-4 gap-4 px-6 pt-2 pb-4 border-b border-tm-gray-300">
                                    <FieldsToHtml
                                        fieldsState={this.state.addContactsModalQueryFields}
                                        excludeFields={['limit']}
                                        onInputChange={this.handleFilterInputChange}
                                        translate={translate}
                                        selects={{
                                            Groups: {
                                                api: 'api/' + Resources.ContactGroupsQuick,
                                                query: {},
                                                searchMap: (item) => ({
                                                    label: item.ContactGroupName,
                                                    value: item.ContactGroupID
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <ResourceTable
                                    commonTable={true}
                                    data={contactsData}
                                    count={contactsCount}

                                    fields={{
                                        FirstName: new Field('FirstName', '', ['empty']),
                                        LastName: new Field('LastName', '', ['empty']),
                                        Email: new Field('Email', '', ['empty', 'email']),
                                        Status: new Field('Status', '', [''], false, 'custom', {
                                            omitSort: true,
                                            render: (item) => {
                                                if (!item.DriverStatusID
                                                    && !item.EmployeeStatusID
                                                    && !item.AgentStatusID
                                                ) {
                                                    return null
                                                }

                                                item.StatusID = item.DriverID ?
                                                    item.DriverStatusID : (item.EmployeeID ? item.EmployeeStatusID : item.AgentStatusID)
                                                if (!!item.DriverID) {
                                                    return <DriverStatusBadge item={item}
                                                                              translate={this.props.translate}/>
                                                } else {
                                                    return <EmployeeStatusBadge item={item}
                                                                                translate={this.props.translate}/>
                                                }
                                            }
                                        }),
                                        DriverPositionTypeID: new Field('DriverPositionTypeID', '', [], false, 'select')
                                    }}

                                    translate={this.props.translate}
                                    isLoading={this.props.contactsIsLoading}

                                    limit={this.state.addContactsModalQueryFields.limit.value}
                                    offset={this.state.contactsModalOffset}
                                    page={this.state.contactsModalPaginationPage}
                                    paginationButtonLimit={5}
                                    onOffsetChange={this.handleUpdateContactsModalOffset}

                                    onRowClick={this.handleAddEntry}

                                    sort={this.state.contactsModalSort}
                                    sortBy={this.state.contatsModalSortBy}
                                    onSortChange={this.handleUpdateSort}

                                    options={{ // test
                                        style: {
                                            floatingActions: false,
                                            stripedRows: true,
                                            rowHoverClass: 'cursor-pointer group-hover:bg-primary-transparent'
                                        }
                                    }}

                                />

                                <NoRecords
                                    addClass={'mt-5'}
                                    show={(contactsData.length === 0) && !this.props.contactsIsLoading}
                                    title={translate('text.no_records')}
                                />

                                <TableFooter
                                    addClass=""
                                    show={!(!contactsData.length && !this.props.contactsIsLoading)}
                                >
                                    <Pagination
                                        count={contactsCount}
                                        isLoading={this.props.contactsIsLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === 'offset'
                                                ? this.handleUpdateContactsModalOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.contactsModalOffset}
                                        queryFields={this.state.addContactsModalQueryFields}
                                        translate={translate}
                                    />
                                </TableFooter>
                            </TableCard>
                        </div>
                    </div>
                </ModalDefault>

                <ModalConfirm
                    title={'Unsaved changes'}
                    show={this.state.closeDirtyBatchConfirmationModal}
                    text={translate('text.confirm_close_batch')}
                    onClose={() => this.setState({closeDirtyBatchConfirmationModal: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={translate('btn.cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.setState({
                            selectedItem: null,
                            isDirty: false,
                            closeDirtyBatchConfirmationModal: false,
                        })
                        this.props.fetchSingleBatch(this.getQuery())
                    }}
                />

                <ModalSaveResource
                    show={this.state.useEscrowModal}
                    title={this.props.translate('text.PayrollUseEscrow')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-1"
                    onClose={this.handleToggleUseEscrow}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.IsEscrowUsage = 1
                            this.handleAddItemToContact(params)
                            this.handleToggleUseEscrow()
                        }
                    }}
                    translate={translate}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: {
                                MainAccountTypeID: MAJOR_ACCOUNT_TYPE_LIABILITY
                            },
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName
                            })
                        }
                    }}

                    htmlAfter={(fields) => {
                        return (
                            <div className="p-3 md border-t border-tm-gray-300">
                                <div className="flex items-center">
                                    <div className="ml-1 w-0 flex-1">
                                        <InfoParagraph type="info" className={"p-0"}>
                                            {"Set positive value to use escrow, or negative to subtract from paycheck and add to escrow balance."}
                                        </InfoParagraph>
                                        <p className={"font-bold mt-1"}>Current escrow balances</p>
                                        <dl>
                                            {batchItems?.EscrowBalances?.map((it) => {
                                                const currentUsedFunds = this.state.CurrentItems.reduce((memo, ci) => {
                                                    if (it.AccountID === ci.AccountID) {
                                                        memo += parseFloat(ci.Amount ?? 0)
                                                    }
                                                    return memo
                                                }, 0)
                                                const newVal = fields.AccountID?.value?.value === it.AccountID ? (it.Amount - currentUsedFunds - (fields.Amount?.value ?? 0)) : null
                                                return (
                                                    <dd>
                                                        <div
                                                            className="text-base text-tm-gray-900 flex justify-between">
                                                            <div>
                                                                {it.Notes}
                                                            </div>
                                                            <div>
                                                                <span> {new Intl.NumberFormat(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(it.Amount - currentUsedFunds)}
                                                                    {newVal ? (
                                                                        <span
                                                                            className={newVal < 0 ? 'text-red-600' : 'font-medium'}>-&gt; {new Intl.NumberFormat(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(newVal)}</span>
                                                                    ) : null}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </dd>
                                                )
                                            })}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />

                <ModalSaveResource
                    show={this.state.addAdvanceModal}
                    title={this.props.translate('text.PayrollAdvance')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-1"
                    onClose={this.handleToggleAddAdvance}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.IsAdvance = 1
                            this.handleAddItemToContact(params)
                            this.handleToggleAddAdvance()
                        }
                    }}
                    translate={translate}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: {
                                AccountTypeID: ACCOUNT_TYPE_OTHER_CURRENT_ASSETS
                            },
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName
                            })
                        }
                    }}
                    htmlAfter={(fields) => {
                        return (
                            <div className="p-3 md border-t border-tm-gray-300">
                                <div className="flex items-center">
                                    <div className="ml-1 w-0 flex-1">
                                        <InfoParagraph type="info" className={"p-0"}>
                                            {"Set positive value to give advance, or negative value to record advance payment. Entry will not impact gross value."}
                                        </InfoParagraph>
                                        <p className={"font-bold mt-1"}>Current advances balances</p>
                                        <dl>
                                            {batchItems?.LoanBalances?.map((it) => {
                                                const currentUsedFunds = this.state.CurrentItems.reduce((memo, ci) => {
                                                    if (it.AccountID === ci.AccountID) {
                                                         memo += parseFloat(ci.Amount)
                                                    }
                                                    return memo
                                                }, 0)
                                                const newVal = fields.AccountID?.value?.value === it.AccountID ? (parseFloat(it.Amount) + parseFloat(currentUsedFunds) + parseFloat(fields.Amount?.value ?? 0)) : null
                                                return (
                                                    <dd>
                                                        <div
                                                            className="text-base text-tm-gray-900 flex justify-between">
                                                            <div>{it.Description}</div>
                                                            <div>
                                                                <span> {new Intl.NumberFormat(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(it.Amount + currentUsedFunds)}
                                                                    {newVal ? (
                                                                        <span
                                                                            className={newVal < 0 ? 'text-red-600' : 'font-medium'}>-&gt; {new Intl.NumberFormat(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(newVal)}</span>
                                                                    ) : null}
                                                            </span><
                                                            /div>
                                                        </div>
                                                    </dd>
                                                )
                                            })}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />

                <ModalSaveResource
                    show={this.state.addPayModal}
                    title={this.props.translate('text.PayrollPay')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-1"
                    onClose={this.handleToggleAddPay}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.IsGross = 1
                            this.handleAddItemToContact(params)
                            this.handleToggleAddPay()
                        }
                    }}
                    translate={translate}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: {
                                MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                            },
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName
                            })
                        }
                    }}

                    htmlAfter={() => {
                        return (
                            <div className="p-3 md border-t border-tm-gray-300">
                                <div className="flex items-center">
                                    <div className="ml-1 w-0 flex-1">
                                        <InfoParagraph type="info" className={"p-0"}>
                                            {"Add positive value to pay additional money, or negative value to subtract from paycheck. Entry WILL IMPACT gross value."}
                                        </InfoParagraph>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />

                <ModalDefault
                    show={this.state.isExpenseDetailsDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={translate('text.ExpenseID')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleExpenseDetailsDialog()}
                >
                    <ExpenseExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource?.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        type="expense"

                        showLoadReferenceLinks={true}
                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.fetchExpenseDialogData()
                            }
                        }}
                    />
                </ModalDefault>
            </Modal>
        )
    }

    renderBalances(batchItems, isPayed = false) {
        return (
            <>
                <div className="space-y-2">
                    {batchItems?.LoanBalances?.length > 0 && (
                        <div className="">
                            <h3 className={'text-xl text-tm-gray-900'}>Current advances
                                balances</h3>
                            <dl>
                                {batchItems?.LoanBalances?.map((it, i) => {
                                    const currentUsedFunds = this.state.CurrentItems.reduce((memo, ci) => {
                                        if (it.AccountID === ci.AccountID) {
                                             memo += parseFloat(ci.Amount)
                                        }
                                        return memo
                                    }, 0)
                                    return (
                                        <dd
                                            key={i}
                                        >
                                            <div
                                                className="text-base text-tm-gray-900 flex justify-between">
                                                <div>{it.Description}</div>
                                                <div>
                                                    {numberWithCommas(it.Amount + (!isPayed ? currentUsedFunds : 0))}
                                                </div>
                                            </div>
                                        </dd>
                                    )
                                })}
                                <dd className={"font-bold"}>
                                    <hr/>
                                    <div
                                        className="text-base text-tm-gray-900 flex justify-between">

                                        <div>Total</div>
                                        <div>
                                            {numberWithCommas(batchItems?.LoanBalances?.reduce((acc, it) => {
                                                const currentUsedFunds = this.state.CurrentItems.reduce((memo, ci) => {
                                                    if (it.AccountID === ci.AccountID) {
                                                        memo += parseFloat(ci.Amount)
                                                    }
                                                    return memo
                                                }, 0)
                                                return acc + it.Amount + (!isPayed ? currentUsedFunds : 0);
                                            }, 0))}
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    )}
                </div>

                <div className="space-y-2">
                    {batchItems?.EscrowBalances?.length > 0 && (
                        <div className="">
                            <h3 className={'text-xl text-tm-gray-900'}>Current escrow
                                balances</h3>
                            <dl>
                                {batchItems?.EscrowBalances?.map((it, i) => {
                                    const currentUsedFunds = this.state.CurrentItems.reduce((memo, ci) => {
                                        if (it.AccountID === ci.AccountID) {
                                            memo += parseFloat(ci.Amount)
                                        }
                                        return memo
                                    }, 0)

                                    console.log("it.Amount", it.Amount)
                                    return (
                                        <dd
                                            key={i}
                                        >
                                            <div
                                                className="text-base text-tm-gray-900 flex justify-between">
                                                <div>{it.Notes}</div>
                                                <div>
                                                    {numberWithCommas(it.Amount - ((!isPayed ? currentUsedFunds : 0)))}
                                                </div>
                                            </div>
                                        </dd>
                                    )
                                })}
                                <dd className={"font-bold"}>
                                    <hr/>
                                    <div
                                        className="text-base text-tm-gray-900 flex justify-between">
                                        <div>Total</div>
                                        <div>
                                            {numberWithCommas(batchItems?.EscrowBalances?.reduce((acc, it) => {
                                                const currentUsedFunds = this.state.CurrentItems.reduce((memo, ci) => {
                                                    if (it.AccountID === ci.AccountID) {
                                                         memo += parseFloat(ci.Amount)
                                                    }
                                                    return memo
                                                }, 0)
                                                return acc + it.Amount - ((!isPayed ? currentUsedFunds : 0));
                                            }, 0))}
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    )}
                </div>
            </>
        )
    }
}
