import React, {useEffect, useState} from 'react'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import {CREATE_PERM, DEFAULT_CRUD_STATE} from '../../../util/util-constants'
import {
    checkPerm,
    getProp,
    returnMileage,
    returnMileageText
} from '../../../common/util/util-helpers'
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import Button from "../../../common/components/button";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";

const MileageTab = (props) => {

    /** Constants
     ================================================================= */
    const {dispatch, translate, resource} = props

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)

    /** UI events
     ================================================================= */
    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        handleResetPagination()
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleArchiveResource = (item = null) => {
        setConfirmModal(!confirmModal)
        setSelectedItem(item)
    }

    const handleCreateEditResource = (item = null) => {
        setSelectedItem(item)
        setCreateUpdateModal(!createUpdateModal)
    }

    /** Fields/data definitions
     ================================================================= */
    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true})
        }
    }

    const getFields = (item = null) => {
        let fieldTemplates = {
            Mileage: new Field('Mileage', 0, ['float'], false, 'float', {
                label: returnMileageText(),
                addContainerClass: 'col-span-full'
            }),
            Date: new Field('Date', '', [], false, 'date', {addContainerClass: 'col-span-full'}),
            Notes: new Field('Notes', 0, [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item)

        if (item && item.Mileage) {
            fieldTemplates['Mileage'].value = returnMileage(item.Mileage)
        }

        return fieldTemplates
    }

    const tableFields = () => {
        return {
            Mileage: new Field('Mileage', '', ['empty'], false, 'custom', {
                render: (item) =>
                    <div>{returnMileage(item.Mileage)} {returnMileageText() ?? translate("text." + returnMileageText())}</div>
            }),
            Date: new Field('Date', '', ['empty'], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'datetimezone'),
            Source: new Field('Source', '', ['empty'], false, 'text'),
            UpdatedByContact: new Field('UpdatedByContact', '', ['empty'], false, 'custom', {
                render: (item) => <div>{item.Source === 'Manual' ? item.UpdatedByContact : 'System'}</div>
            }),
            Notes: new Field('Notes', '', ['empty'], false, 'text'),
        }
    }

    /** State
     ================================================================= */
    const [query, setQuery] = useState(Object.assign({}, DEFAULT_CRUD_STATE, {
        sort: 'DESC',
        sortBy: 'Date',
    }))
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFilterFields())
    const [confirmModal, setConfirmModal] = useState(false)
    const [createUpdateModal, setCreateUpdateModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    /** Helpers
     ================================================================= */
    const getQuery = () => {
        return {
            offset: query.offset,
            sort: query.sort,
            sortBy: query.sortBy,
            id: getID(),

            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    const getMileage = (Mileage) => {
        if ((+LocalStorage.get('user')?.Contact?.DistanceUnitID) === 1) {
            return Mileage
        } else if ((+LocalStorage.get('user')?.Contact?.DistanceUnitID) === 2) {
            return (Mileage * 0.621371192).toFixed(2)
        }
    }

    const getID = () => {
        return props.match.params.id
    }

    const getResourceName = () => {
        return Resources.TrucksMileage
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query, queryFilterFields])

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: getQuery()
        }))
    }

    const archiveResource = () => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: {
                TruckMileageID: selectedItem.TruckMileageID
            },
            piggyQuery: getQuery(),
            piggyResource: getResourceName(),
            errorMessage: true, successMessage: 'Mileage deleted.'
        }))
    }

    /** Render
     ================================================================= */
    return (
        <React.Fragment>
            <div className={"flex"}>
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    translate={translate}
                />

                <div className="flex ml-auto">
                    <Button
                        hasPerm={checkPerm(getResourceName(), CREATE_PERM)}
                        onClick={handleCreateEditResource}
                        appearance="primary"
                    >
                        {translate('btn.create_new')}
                    </Button>
                </div>
            </div>

            <TableCard>
                <TableFilters
                    hideLimit
                    filterFields={queryFilterFields}
                    onRefreshTable={fetchData}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                    isLoading={resource.isLoading}
                />

                <ResourceTable
                    data={data}
                    count={count}

                    fields={tableFields()}
                    translate={translate}
                    isLoading={resource.isLoading}

                    limit={query.limit}
                    offset={query.offset}
                    page={query.paginationPage}
                    paginationButtonLimit={5}
                    onOffsetChange={handleUpdateOffset}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    actions={[
                        {
                            tooltipText: () => translate('text.edit'),
                            visible: (item) => item.Source === 'Manual',
                            action: (item) => handleCreateEditResource(item),
                            icon: () => PencilIcon
                        },
                        {
                            tooltipText: () => translate('text.delete'),
                            visible: (item) => item.Source === 'Manual',
                            action: (item) => handleArchiveResource(item),
                            icon: () => TrashIcon
                        }
                    ]}

                />

                {!(!data.length && !resource.isLoading) && (
                    <div
                        className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? handleUpdateOffset(value, currentPage)
                                    : handleFilterInputChange(name, value)
                            }
                            pageOffset={query.offset}
                            queryFields={queryFilterFields}
                            translate={translate}
                        />
                    </div>
                )}

                <NoRecordsTable
                    show={(data.length === 0) && !resource.isLoading}
                    title={'No matching records found'}
                    className={"pb-12 pt-16 px-6"}
                />

                <ModalSaveResource
                    title={selectedItem ? 'Edit mileage' : 'Create new mileage'}
                    widthClass="max-w-xl"
                    gridColsClass="grid-cols-3"
                    show={!!createUpdateModal}
                    onClose={handleCreateEditResource}
                    fields={getFields(selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.Mileage = getMileage(params.Mileage)
                            params.TruckID = getID()
                            if (selectedItem) {
                                params.TruckMileageID = selectedItem.TruckMileageID
                                dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    query: getQuery(),
                                    params: params,
                                    resource: getResourceName(),
                                    piggyResource: getResourceName(),
                                    errorMessage: true, successMessage: 'Mileage updated.'
                                }))
                            } else {
                                dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: getQuery(),
                                    params: params,
                                    resource: getResourceName(),
                                    piggyResource: getResourceName(),
                                    errorMessage: true, successMessage: 'Mileage updated.'
                                }))
                            }
                            handleCreateEditResource()
                        }
                    }}
                    translate={translate}
                    closeButtonLabel={translate('btn.cancel')}
                />

                {confirmModal && (
                    <ModalConfirm
                        text={'Are you sure you want to delete this mileage?'}
                        buttonLabel={translate("btn.confirm")}
                        closeButtonLabel={translate('Cancel')}
                        onConfirm={() => {
                            archiveResource()
                            setConfirmModal(false)
                        }}
                        onClose={handleArchiveResource}
                        translate={translate}
                        show={confirmModal}
                    />
                )}
            </TableCard>
        </React.Fragment>
    )
}

export default MileageTab
