import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getStackedResource} from '../../data/actions/stackedResource'
import LocalStorage from '../../util/localStorage'
import Localstorage from '../../util/localStorage'
import Resources from '../../data/services/resources'
import {pushNotification, showModal} from '../../data/actions/ui'
import DashboardSettings from './dashboard-settings'
import {classNames, getProp} from '../../common/util/util-helpers'
import {
    ArrowTrendingUpIcon,
    ClipboardDocumentListIcon,
    ClockIcon,
    CloudIcon,
    IdentificationIcon,
    MapIcon,
    PencilSquareIcon
} from "@heroicons/react/24/outline";
import Routes from "../../common/components/widgets/widget-routes";
import Drivers from "../../common/components/widgets/widget-drivers";
import WidgetTasks from "../../common/components/widgets/widget-tasks";
import WeatherCompactWidget from "../../common/components/widgets/widget-weather-compact";
import WeatherWidget from "../../common/components/widgets/weather";
import NotesWidget from "../../common/components/widgets/widget-notes";
import WidgetWorldClocks from "../../common/components/widgets/widget-world-clocks";
import WidgetFuelPrice from "../../common/components/widgets/widget-fuel-price";

class DashboardView extends Component {
    constructor(props) {
        super(props)
        this.savedLimits = JSON.parse(localStorage.getItem('dashboard_table_limits'))

        this.tasksLimit = this.savedLimits && this.savedLimits.tasksLimit ? this.savedLimits.tasksLimit : 6

        this.state = {
            userMenu: null,
            widgets: this.restoreWidgetsFromStorage(),
            activeResource: '',
            tasksLimit: this.tasksLimit,
            dashboardItems: this.getDashboardItems()
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.ui.refresh && this.props.ui.refresh) {
            const settings = this.restoreWidgetsFromStorage()
            if (JSON.stringify(this.state.widgets) !== JSON.stringify(settings)) {
                this.setState({
                    widgets: settings
                })

                let notificationData = {
                    title: this.props.translate('text.dashboard_updated')
                }

                this.props.dispatch(pushNotification(notificationData))
            }
        }
    }

    fetchDataStack = (resource, query = {}) => {
        this.props.dispatch(getStackedResource({
            user: LocalStorage.get('user'),
            query: query,
            resource: Resources[resource]
        }))
    }

    setLimit = (widgetState, limit) => {
        this.setState({
            [widgetState]: limit
        }, () => {
            this.saveLimits()
        })
    }

    saveLimits = () => {
        Localstorage.set('dashboard_table_limits', {
            tasksLimit: this.state.tasksLimit,
        })
    }

    handleResetClick = () => {
        this.setState({
            dashboardItems: this.getDashboardItems(true)
        }, () => {
            this.storeDashboardSettings();
        })
    }

    storeDashboardSettings = () => {
        const dashboardItems = Object.assign({}, this.state.dashboardItems);
        Object.values(dashboardItems).reduce((memo, it) => {
            delete it.Icon;
            memo[it.name] = it;
            return memo;
        }, {});
        LocalStorage.set("dashboard_widgets_v2", dashboardItems);
    }

    restoreWidgetsFromStorage = () => {
        let savedSettings = localStorage.getItem('dashboard_widgets')

        if (!savedSettings) {
            savedSettings = [['tasks', 'notes'], ['weather'], []] // some default view
        } else {
            savedSettings = JSON.parse(savedSettings)
        }

        return savedSettings
    }

    handleSettingsButtonClick = () => {
        this.props.dispatch(showModal('dashboardSettings'))
    }

    pushHistory = (route) => {
        this.props.history.push(route)
    }

    setActiveResource = (resource) => {
        this.setState({
            activeResource: resource
        })
    }

    getDashboardItems = (forceDefault = false) => {
        const defaultDashboardItems = {
            "tasks": {
                order: 1,
                name: "tasks",
                position: "main",
                visible: true,
                Icon: ClipboardDocumentListIcon
            },
            "routes": {
                order: 2,
                name: "routes",
                position: "main",
                visible: true,
                Icon: MapIcon
            },
            "drivers": {
                order: 3,
                name: "drivers",
                position: "main",
                visible: false,
                Icon: IdentificationIcon
            },
            "WorldClock": {
                order: 4,
                name: "WorldClock",
                position: "sidebar",
                visible: true,
                Icon: ClockIcon
            },
            "weather": {
                order: 5,
                name: "weather",
                position: "sidebar",
                visible: true,
                Icon: CloudIcon
            },
            "notes": {
                order: 6,
                name: "notes",
                position: "sidebar",
                visible: true,
                Icon: PencilSquareIcon
            },
            "FuelPrice": {
                order: 7,
                name: "FuelPrice",
                position: "sidebar",
                visible: true,
                Icon: ArrowTrendingUpIcon
            }
        }

        let localStorageDashboardItems = {}

        if (!forceDefault) {
            localStorageDashboardItems = Localstorage.get('dashboard_widgets_v2', {});
        }

        return Object.assign(defaultDashboardItems, localStorageDashboardItems);
    }

    getWidgets = (area = 'main', hasMainAreaWidgets = true) => {
        const {resource, stackedResource, isMobileView, translate} = this.props;

        const routes = getProp(stackedResource, 'data.' + Resources.Routes + '.list', [])
        const routesAreLoading = getProp(stackedResource, 'isLoading.' + Resources.Routes, false)

        const tasks = getProp(stackedResource, 'data.tasks/dashboard', [])
        const tasksAreLoading = getProp(stackedResource, 'isLoading.tasks/dashboard', false)

        const notes = getProp(stackedResource, 'data.' + Resources.NotesPersonal + '.list', [])
        const notesCount = getProp(stackedResource, 'data.' + Resources.NotesPersonal + '.count', 0)
        const notesAreLoading = getProp(stackedResource, 'isLoading.' + Resources.NotesPersonal, false)

        const drivers = getProp(stackedResource, 'data.' + Resources.Drivers, []);
        const driversAreLoading = getProp(stackedResource, 'isLoading.' + Resources.Drivers, false);

        return Object.values(this.state.dashboardItems)
            .filter(item => item.visible && item.position === area)
            .sort((a, b) => a.order - b.order)
            .map((widget) => {
                let widgetComponent

                switch (widget.name) {
                    case 'routes':
                        widgetComponent = (
                            <Routes
                                data={resource.data}
                                isCompact={(area === 'sidebar' || isMobileView) && hasMainAreaWidgets}
                                translate={translate}
                                onSetLimit={this.setLimit}
                                onPushHistory={this.pushHistory}
                                routes={routes}
                                history={this.props.history}
                                isLoading={routesAreLoading}
                                onFetchData={this.fetchDataStack}
                            />
                        )
                        break
                    case 'drivers':
                        widgetComponent = (
                            <Drivers
                                drivers={drivers}
                                translate={translate}
                                isCompact={(area === 'sidebar' || isMobileView) && hasMainAreaWidgets}
                                location={this.props.location}
                                history={this.props.history}
                                onPushHistory={this.pushHistory}
                                // onSetLimit={this.setLimit}
                                onFetchData={this.fetchDataStack}
                                isLoading={driversAreLoading}
                            />
                        )
                        break
                    case 'tasks':
                        widgetComponent = (
                            <WidgetTasks
                                tasks={tasks}
                                resource={'DashboardTasks'}
                                isCompact={(area === 'sidebar' || isMobileView) && hasMainAreaWidgets}
                                limit={this.state.tasksLimit}
                                onSetLimit={this.setLimit}
                                location={this.props.location}
                                onFetchData={this.fetchDataStack}
                                isLoading={tasksAreLoading}
                                translate={translate}
                                onPushHistory={this.pushHistory}
                                taskResource={this.props.taskResource}
                            />
                        )
                        break
                    case 'weather':
                        widgetComponent = (area === 'sidebar' || isMobileView) && hasMainAreaWidgets ?
                            <WeatherCompactWidget translate={translate}/> : <WeatherWidget translate={translate}/>
                        break
                    case 'notes':
                        widgetComponent = (
                            <NotesWidget
                                count={notesCount}
                                notes={notes}
                                isCompact={(area === 'sidebar' || isMobileView) && hasMainAreaWidgets}
                                onSetActiveResource={this.setActiveResource}
                                isLoading={notesAreLoading || resource?.isLoading}
                                translate={translate}
                                dispatch={this.props.dispatch}
                                onFetchData={this.fetchDataStack}
                                ui={this.props.ui}
                            />
                        )
                        break
                    case 'WorldClock':
                        widgetComponent = <WidgetWorldClocks translate={translate}
                                                             isCompact={(area === 'sidebar' || isMobileView) && hasMainAreaWidgets}/>
                        break
                    case 'FuelPrice':
                        widgetComponent = <WidgetFuelPrice translate={translate}/>
                }
                return (
                    <div
                        key={widget.name}
                    >
                        {widgetComponent}
                    </div>
                )
            })

    }


    render() {
        const sidebarItemsCount = Object.values(this.state.dashboardItems).filter(item => item.position === 'sidebar' && item.visible).length;
        const mainItemsCount = Object.values(this.state.dashboardItems).filter(item => item.position === 'main' && item.visible).length;

        const {
            translate,
            onSettingsToggleClick,
            isDashboardSettingsModalVisible
        } = this.props

        return (
            <div className="pb-12 -mx-4 sm:mx-0">
                <div className="flex gap-4 flex-col 2xl:flex-row w-full 2xl:max-w-full">
                    {!!mainItemsCount && (
                        <div className={
                            classNames(
                                sidebarItemsCount ? "2xl:max-w-[calc(100%-25rem)]" : undefined,
                                "w-full gap-4 flex flex-col xl:grow-0"
                            )}
                        >
                            {this.getWidgets('main')}
                        </div>
                    )}

                    {!!sidebarItemsCount && (
                        <div
                            className={
                                classNames(
                                    mainItemsCount ? "2xl:w-96" : undefined,
                                    "flex flex-col sm:grid sm:grid-cols-2 2xl:flex 2xl:flex-col flex-shrink-0 gap-4 pb-12 max-w-full"
                                )
                            }
                        >
                            {this.getWidgets('sidebar', !!mainItemsCount)}
                        </div>
                    )}
                </div>

                <DashboardSettings
                    show={isDashboardSettingsModalVisible}
                    dashboardItems={this.state.dashboardItems}
                    setDashboardItems={(dashboardItems) => this.setState({
                        dashboardItems: dashboardItems
                    }, () => {
                        this.storeDashboardSettings();
                    })}
                    restoreWidgetsFromStorage={this.restoreWidgetsFromStorage}
                    onClose={onSettingsToggleClick}
                    translate={translate}
                    onReset={this.handleResetClick}
                    updateDashboardTab={this.props.updateDashboardTab}
                />
            </div>
        )
    }
}

export default connect(state => state)(DashboardView)
