import React, {Component} from 'react'
import {connect} from 'react-redux'
import InfoTab from './infoTab'
import Resources from '../../../data/services/resources'
import {showGlobalModal} from '../../../data/actions/ui'
import LocalStorage from '../../../util/localStorage'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import Tippy from '@tippyjs/react'
import {Field} from '../../../data/services/fields'
import {getInfoResource} from '../../../data/actions/infoResource'
import MileageTab from './mileage-tab'
import TruckTypeTab from './truck-type-tab'
import {CREATE_PERM, DELETE_PERM, READ_PERM, REFERENCE_TYPE_TRUCK, UPDATE_PERM} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDocumentTypesFor,
    getProp,
    getTabSettings,
    resourceIsUpdated
} from '../../../common/util/util-helpers'
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import ResourceListTab from "../../../common/components/tabs/resource-list-tab";
import ExpiryItemsTab from "../../../common/components/tabs/expiry-items-tab";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import LoadsTab from "../../../common/components/modal/load-tab";
import LocationsTab from "../../../common/components/modal/locations-tab";
import ModalDefault from "../../../common/components/modal/modal-default";
import ServicesTab from "./services-tab";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";

class TruckView extends Component {

    constructor(props) {
        super(props)

        let tabsSettings = getTabSettings('Fleet', 'Trucks');

        const tabs = [
            {
                name: 'TrucksInfo',
                resource: Resources.TrucksInfo,
                current: true,
                visible: checkPerm(Resources.TrucksInfo, READ_PERM)
            },
            {
                name: 'TrucksPermits',
                resource: Resources.TrucksPermits,
                current: false,
                visible: checkPerm(Resources.TrucksPermits, READ_PERM)
            },
            {
                name: 'TrucksMileage',
                resource: Resources.TrucksMileage,
                current: false,
                visible: checkPerm(Resources.TrucksMileage, READ_PERM)
            },
            {
                name: 'TrucksType',
                resource: Resources.TrucksType,
                current: false,
                visible: checkPerm(Resources.TrucksType, READ_PERM)
            },
            {
                name: 'RestrictedArea',
                resource: Resources.TrucksCanGo,
                current: false,
                visible: checkPerm(Resources.TrucksCanGo, READ_PERM)
            },
            {
                name: 'TrucksLocations',
                resource: Resources.TrucksLocations,
                current: false,
                visible: checkPerm(Resources.TrucksLocations, READ_PERM)
            },
            {
                name: 'TrucksLoads',
                resource: Resources.TrucksLoads,
                current: false,
                visible: checkPerm(Resources.TrucksLoads, READ_PERM)
            },
            {
                name: 'TruckServices',
                resource: Resources.TruckServices,
                current: false,
                visible: checkPerm(Resources.TruckServices, READ_PERM)
            },
            {
                name: 'ExpiryItems',
                resource: Resources.TrucksExpiry,
                current: false,
                visible: checkPerm(Resources.TrucksExpiry, READ_PERM)
            },
            {
                name: 'TruckDocuments',
                resource: Resources.TruckDocuments,
                current: false,
                visible: checkPerm(Resources.TruckDocuments, READ_PERM)
            }
        ].map(it => {
            if (!!tabsSettings && it.visible !== false) {
                it.visible = tabsSettings?.[it.name]?.isVisible !== false;
            }

            return it;
        })

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.TrucksInfo,

            // Modals
            resourceTaskModalOpen: false,

            canSwapTabs: true,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.handleCheckIfRedirected()
            this.fetchTaskResourceCount()
            this.fetchData()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (resourceIsUpdated(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === Resources.TrucksColor) {
            this.fetchData();
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.TrucksInfo,
            query: {
                id: this.getID()
            }
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    /** UI events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (!this.state.canSwapTabs) {
            this.setState({
                confirmModalOpen: true,
                reserveTab: resource
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModalOpen: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleCreateNewResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasks = () => {
        this.setState({
            resourceTaskModalOpen: !this.state.resourceTaskModalOpen
        })
    }

    handleCheckIfRedirected = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab')
        if (tab) {
            this.handleTabChange(tab)
        }
    }

    /** Helpers
     ================================================================= */
    canSwapTabsHandler = (canSwapTabs = false) => {
        this.setState({canSwapTabs: canSwapTabs})
    }

    getMinDate = (fields) => {
        return fields.reduce((memo, item) => {
            if (item.name === 'StartDate') {
                memo = item.value
                return memo
            }

            return memo
        }, '');
    }

    getMaxDate = (fields) => {
        return fields.reduce((memo, item) => {
            if (item.name === 'EndDate') {
                memo = item.value
                return memo
            }

            return memo
        }, '');
    }

    getID = () => {
        return this.props.match.params.id
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, download} = this.props
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const openTaskCountTwoDigits = TaskCount > 9;
        const infoData = getProp(this.props.info, 'data', {})
        const ColorTags = getProp(this.props.info, 'data.ColorTag', "")
        const truckNumber = getProp(infoData, 'TruckNumber', 'Truck')

        return (
            <Layout {...this.props}
                    hasFooter={this.state.selectedTab === Resources.TrucksInfo}
                    isDirty={!this.state.canSwapTabs}
                    customPageTitle={truckNumber || 'Truck'}>
                <Page>
                    <div className="mb-2 flex justify-between items-center">
                        <div className="flex items-center justify-center">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/trucks'}
                            />
                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('text.truck')}
                                <span className="text-tm-gray-500"> - {infoData.TruckNumber}
                                    {infoData.ArchivedDate && (
                                        <Tippy content={<span>Archived</span>}>
                                            <i className="iconsminds-delete-file"/>
                                        </Tippy>
                                    )}
                                            </span>
                            </h1>

                            <div>
                                <ResourceTableTags data={ColorTags} addClass="py-1 mx-2"/>
                            </div>
                        </div>

                        <div className="gap-2 flex flex-wrap items-center justify-end">
                            {checkPerm(Resources.Tasks, CREATE_PERM) && (
                                <div className="btn btn-outline mr-3"
                                     onClick={() => this.handleCreateNewResourceTask()}>{translate('btn.new_truck_task')}
                                </div>
                            )}

                            {checkPerm(Resources.Tasks, READ_PERM) && (
                                <div className="relative">
                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasks()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -right-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="block sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.TrucksInfo && (
                        <InfoTab
                            canSwapTabsHandler={this.canSwapTabsHandler}
                            setActiveTab={this.handleTabChange}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksMileage && (
                        <MileageTab
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.TruckDocuments && (
                        <DocumentsUpdateTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}
                            thirdResource={this.props.thirdResource}

                            dialogTitle={'TrucksDocuments'}

                            sortBy={'Description'}
                            primaryKey={'TruckDocumentID'}

                            download={download}
                            resourceName={Resources.TruckDocuments}

                            documentUpdateFields={checkPerm(Resources.TruckDocuments, UPDATE_PERM) && {
                                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                    values: getDocumentTypesFor("IsFleetDocument")
                                }),
                                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}

                            documentTypes={getDocumentTypesFor("IsFleetDocument")}
                            searchFields={{
                                query: new Field('query', '', [], false, 'search', {
                                    containerClass: 'col-md-4'
                                }),
                                DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true,
                                    values: getDocumentTypesFor("IsFleetDocument")
                                }),
                                DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true
                                }),
                            }}
                            readOnly={!checkPerm(Resources.TruckDocuments, CREATE_PERM)}
                            disableDelete={!checkPerm(Resources.TruckDocuments, DELETE_PERM)}
                        />
                    )}
                    {this.state.selectedTab === Resources.TrucksPermits && (
                        <ResourceListTab
                            id={this.getID()}
                            tabKey={'TrucksPermits'}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}
                            disableEdit={!checkPerm(Resources.TrucksPermits, UPDATE_PERM)}
                            disableDelete={!checkPerm(Resources.TrucksPermits, DELETE_PERM)}

                            dialogTitle={translate('modal_heading.TrucksPermits')}

                            sortBy={'TruckPermitID'}
                            primaryKey={'TruckPermitItemID'}
                            resourceName={Resources.TrucksPermits}
                            fields={{
                                TruckPermitID: new Field('TruckPermitID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                PermitNumber: new Field('PermitNumber', '', [], false, 'text', {
                                    addContainerClass: 'col-span-full'
                                }),
                                StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                                    maxDate: this.getMaxDate,
                                    addContainerClass: 'col-span-full'
                                }),
                                EndDate: new Field('EndDate', '', [], false, 'date', {
                                    minDate: this.getMinDate,
                                    addContainerClass: 'col-span-full'
                                }),
                                Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            searchFields={{
                                query: new Field('query', '', [''], false, 'search', {
                                    containerClass: 'col-md-4'
                                }),
                                limit: new Field('limit', 10, [''], false, 'select', {
                                    containerClass: 'col-md-4',
                                    hideLabel: true
                                })
                            }}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksType && (
                        <TruckTypeTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}

                            dialogTitle={translate('modal_heading.TrucksType')}

                            sortBy={'TruckTypeID'}
                            primaryKey={'TruckTypeItemID'}
                            resourceName={Resources.TrucksType}

                            fields={{
                                TruckTypeID: new Field('TruckTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            searchFields={{
                                query: new Field('query', '', [''], false, 'search', {
                                    containerClass: 'col-md-4'
                                })
                            }}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksCanGo && (
                        <ResourceListTab
                            tabKey={'TrucksCanGo'}
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}

                            dialogTitle={translate('modal_heading.TrucksCanGo')}

                            sortBy={'TruckCanGoID'}
                            primaryKey={'TruckCanGoItemID'}
                            resourceName={Resources.TrucksCanGo}
                            fields={{
                                TruckCanGoID: new Field('TruckCanGoID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            searchFields={{
                                query: new Field('query', '', [''], false, 'search', {}),
                                limit: new Field('limit', 10, [''], false, 'select', {})
                            }}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksLocations && (
                        <LocationsTab
                            {...this.props}
                            resourcePath={Resources.TrucksLocations}
                            primaryKey={'TruckLocationID'}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksLoads && (
                        <LoadsTab
                            tabResource={Resources.TrucksLoads}
                            handleTabChange={this.handleTabChange}
                            id={this.getID()}
                            tabKey={'id'}
                            resource={this.props.resource}
                            dispatch={this.props.dispatch}
                            history={this.props.history}
                            translate={this.props.translate}
                        />
                    )}

                    {this.state.selectedTab === Resources.TruckServices && (
                        <ServicesTab
                            {...this.props}
                            tabKey={'TruckServices'}
                            disableEdit={!checkPerm(Resources.TruckServices, UPDATE_PERM)}
                            disableDelete={!checkPerm(Resources.TruckServices, DELETE_PERM)}

                            dialogTitle={translate('modal_heading.TruckServices')}

                            sortBy={'TruckServiceID'}
                            primaryKey={'TruckServiceID'}
                            resourceName={Resources.TruckServices}
                            TruckID={this.getID()}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksExpiry && (
                        <ExpiryItemsTab
                            translate={translate}
                            resourceName={Resources.TrucksExpiry}
                            Reference={getProp(infoData, 'TruckNumber', '')}
                            ReferenceID={this.getID()}
                            ReferenceType={REFERENCE_TYPE_TRUCK}
                        />
                    )}

                    <ModalConfirm
                        type="default"
                        title={translate("dialog_heading.unsaved_changes_on_tab")}
                        show={!!this.state.confirmModalOpen && this.state.selectedTab === Resources.TrucksInfo}
                        onClose={() => this.setState({confirmModalOpen: false})}
                        buttonLabel={translate("btn.proceed")}
                        closeButtonLabel={translate('btn.stay_on_current_tab')}
                        translate={translate}
                        onConfirm={() => this.setState({
                            selectedTab: this.state.reserveTab,
                            confirmModalOpen: false,
                            canSwapTabs: true,
                            tabs: this.state.tabs.map((it) => {
                                it.current = it.resource === this.state.reserveTab
                                return it
                            })
                        })}
                    >
                        <p>{translate("text.unsaved_changes_on_tab")}</p>
                        <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                    </ModalConfirm>

                    <ModalDefault
                        show={!!this.state.resourceTaskModalOpen}
                        widthClass={'max-w-3xl'}
                        title={translate('text.truck_tasks')}

                        closeButtonLabel={translate('btn.close')}
                        onClose={this.handleToggleResourceTasks}

                        translate={translate}
                    >
                        <ResourceTasksDialog
                            close={this.hideConfirmCheckLoadTasksHandler}
                            {...this.props}
                            large={true}
                        />
                    </ModalDefault>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TruckView)
