import React from 'react'
import { Field } from '../../../../../data/services/fields'
import ArrowPathIcon from '@heroicons/react/20/solid/ArrowPathIcon'
import Tippy from '@tippyjs/react'
import {classNames, fieldsToInfoHtml} from '../../../../../common/util/util-helpers'
import { toFrontDateTime } from "../../../../../common/util/util-dates";
import {fillFieldsFromData} from "../../../../../common/util/util-fields";

const SwitchDispatchInfo = (props) => {
    const {data, translate} = props

    const goToDriverPage = (id) => {
        const win = window.open('/drivers/info/' + id, '_blank')
        win.focus()
    }

    const goToTruckPage = (id) => {
        const win = window.open('/trucks/info/' + id, '_blank')
        win.focus()
    }

    const goToTrailerPage = (id) => {
        const win = window.open('/trailers/info/' + id, '_blank')
        win.focus()
    }

    const getFields = (item, previousItem) => {
        const AreaCode = item.AreaCode ? `(${item.AreaCode}) ` : ''
        const PhoneExtension = item.PhoneExtension ? ` [${item.PhoneExtension}] ` : ''

        const isDriverDiff = false //previousItem?.Driver && previousItem?.Driver !== item?.Driver
        const isTruckDiff = false; //previousItem?.Truck && previousItem?.Truck !== item?.Truck
        const isTrailerDiff = false //previousItem?.Trailer && previousItem?.Trailer !== item?.Trailer

        item.CreateUpdateDate = toFrontDateTime(item.CreateUpdateDate);

        const fieldTemplates = {
            ActualDepartureDateTime: new Field('ActualDepartureDateTime', "", [''], false, 'render', {
                addContainerClass: 'relative col-span-2 text-base',
                render: (it) => {
                    return "Swap happened at stop " + item.StopOrder + " at "  + toFrontDateTime(it?.value) + "";
                }
            }),
            Driver: new Field('Driver', '', ['empty_select_search'], false, 'text', {
                // afterValueHtml: item.Active !== 'Active' ? (
                //     <span className="ml-2 text-red-600">( {item.Active} )</span>) : '',
                beforeLabelHtml: isDriverDiff ?
                    <Tippy content={translate('text.driver_changed', [previousItem.Driver, item.Driver])}>
                        <div><ArrowPathIcon className="absolute -left-5 text-green-600 w-5 h-5"/></div>
                    </Tippy> : ''
            }, {onClick: () => goToDriverPage(item.DriverID)}),
             // FullPhone: new Field('FullPhone', AreaCode + item.PhoneNumber + PhoneExtension, [''], false, 'text'),
            Truck: new Field('Truck', '', [''], false, 'text', {
                // afterValueHtml: !item.OutOfServiceID ? (
                //     <span className="ml-2 text-red-600">( {item.OutOfService} )</span>) : '',
                beforeLabelHtml: isTruckDiff ?
                    <Tippy content={translate('text.truck_changed', [previousItem.Truck, item.Truck])}>
                        <div><ArrowPathIcon className="absolute -left-5 text-green-600 w-5 h-5"/></div>
                    </Tippy> : ''
            }, {onClick: () => goToTruckPage(item.TruckID)}),
            Trailer: new Field('Trailer', '', ['text'], false, 'text', {
                beforeLabelHtml: isTrailerDiff ?
                    <Tippy content={translate('text.trailer_changed', [previousItem.Trailer, item.Trailer])}>
                        <div><ArrowPathIcon className="absolute -left-5 text-green-600 w-5 h-5"/></div>
                    </Tippy> : ''
            }, {onClick: item.TrailerID && (() => goToTrailerPage(item.TrailerID))}),
            Notes: new Field('Notes', '', [''], false, !!item.Notes ? 'textarea' : 'hidden', {addContainerClass: 'relative col-span-2'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    return (
        <div>

            {data.map((it, i) => {
                let prevIt = null
                if (data[i - 1]) {
                    prevIt = data[i - 1]
                }

                return (
                    <div
                        className={
                            classNames(
                                'px-10 py-6 grid gap-4 grid-cols-2',
                                i % 2 ? 'bg-tm-gray-50' : ''
                            )
                        }
                    >
                        {fieldsToInfoHtml((Object.values(Object.assign({}, getFields(it, prevIt)))), translate)}
                    </div>
                )
            })}
        </div>
    )
}

export default SwitchDispatchInfo


