import React, {useEffect, useState} from 'react';

export default function ChatbotBox({content, streamContent, fakeStreamSpeed = 10, onStream}) {
    const [textStream, setTextStream] = useState("");

    useEffect(() => {
        if (textStream.length <= content.length && streamContent) {
            setTimeout(() => {
                setTextStream(content.slice(0, textStream.length + 1));
                if (onStream) {
                    onStream()
                }
            }, fakeStreamSpeed);
        }

        if (!!content && !streamContent) {
            setTextStream(content);
        }
    }, [textStream]);

    return <div className='items-center'>

        {textStream.split("\n").map((it, i) => {
            return (
                <div key={i}>{it}<br/></div>
            )
        })}
    </div>
}
