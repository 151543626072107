import {classNames} from "../../common/util/util-helpers";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {Switch} from "@headlessui/react";
import React from "react";

export default function DraggableWidgetListItem({
                                                    item,
                                                    area,
                                                    selectedItem,
                                                    draggedItem,
                                                    canDrag,
                                                    setCanDrag,
                                                    toggleVisible,
                                                    handleDragStart,
                                                    handleDragEnd,
                                                    handleDragEnter,
                                                    setSelectedItem,
                                                    isLast,
                                                    isDragOverEmptyArea,

                                                    translate
                                                }) {
    const lastItem = isLast ? Object.assign({}, item, {isLast: true}) : undefined;
    return <div
        className="p-1 -mx-1 relative">

        {!!draggedItem && draggedItem.name !== item.name && !selectedItem?.isLast && !isDragOverEmptyArea && (
            <>
                <div onDragEnter={() => handleDragEnter(item, area)}
                     onDragLeave={() => setSelectedItem(undefined)}
                     onDragOver={e => e.preventDefault()}
                     className={
                         classNames(
                             "border-inverse absolute -inset-x-1.5 -top-1.5 bottom-0 z-20 -translate-y-1/2 flex items-center"
                         )}
                />

                {selectedItem && selectedItem?.name === item.name && (
                    <div className='w-full h-1 bg-primary absolute z-0 -top-2 left-0 right-0 rounded-card'></div>
                )}
            </>
        )}

        <div
            key={item.name}
            draggable={canDrag}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={() => {
                handleDragStart(item);
            }}
            onDragEnd={() => {
                handleDragEnd()
            }}

            className={
                classNames(
                    "py-3 px-4 col-span-full flex items-center bg-inverse justify-between rounded-xl shadow border border-tm-gray-300",
                    canDrag  ? "hover:bg-sky-600/10" : undefined
                )
            }
        >

            <div
                className="flex items-center gap-6 text-base select-none"
            >
                <div
                    className={classNames(
                        "flex items-center text-tm-gray-500 hover:cursor-grab relative"
                    )}
                >
                    <div
                        onMouseEnter={() => setCanDrag(true)}
                        onMouseLeave={() => {
                            setCanDrag(false);
                        }}
                        className="absolute -inset-1 z-10"
                    />

                    <div className="w-6 h-5 relative">
                        <EllipsisVerticalIcon
                            className="w-5 h-5 flex-shrink-0 absolute -left-1"/>
                        <EllipsisVerticalIcon
                            className="w-5 h-5 flex-shrink-0 absolute right-0.5"/>
                    </div>
                </div>

                <div className="flex items-center">
                    {!!item.Icon && (
                        <item.Icon className="w-6 h-6 mr-4 -ml-1 text-tm-gray-600"/>
                    )}

                    {translate("text." + item.name)}
                </div>
            </div>

            <Switch
                checked={item.visible}
                onChange={() => toggleVisible(item)}
                className={classNames(
                    item.visible ? 'bg-primary' : 'bg-tm-gray-200',
                    'relative inline-flex items-center h-6 rounded-full w-11 transition-colors'
                )}
            >
                <span className="sr-only"> World Clocks</span>

                <span
                    className={`${
                        item.visible ? 'translate-x-6' : 'translate-x-1'
                    } inline-block w-5 h-5 transform bg-white rounded-full transition-transform`}
                />
            </Switch>

            {isLast && !!draggedItem && draggedItem.name !== item.name && (
                <>
                    <div onDragEnter={() => handleDragEnter(lastItem, area)}
                         onDragLeave={() => setSelectedItem(undefined)}
                         onDragOver={e => e.preventDefault()}
                         className={
                             classNames(
                                 "border-inverse absolute -inset-x-1.5 -top-1.5 bottom-0 z-20 translate-y-1/2 flex items-center"
                             )}
                    />

                    {selectedItem && selectedItem.isLast && selectedItem.position === area && (
                        <div className='w-full h-1 bg-primary absolute z-0 -bottom-2 left-0 right-0 rounded-card'></div>
                    )}
                </>
            )}
        </div>
    </div>
}