import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    classNames,
    getDefaultTableOptionsJSON,
    getLookupWithFilter,
    getProp,
    renderExpenseStatusBadge,
    renderInvoiceStatusBadge
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import {createResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {useHistory} from 'react-router-dom'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {getSecondResource} from '../../../data/actions/secondResource'
import PencilIcon from '@heroicons/react/24/outline/PencilIcon'
import {
    ACCOUNT_TYPE_CASH,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    PAYMENT_METHOD_CHECK,
    PAYMENT_METHOD_FACTOR
} from '../../../util/util-constants'
import {numberWithCommasToBack} from '../../../util/util-formaters'
import {ChevronRightIcon, ExclamationCircleIcon, EyeIcon} from '@heroicons/react/20/solid'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import moment from 'moment'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {getThirdResource} from '../../../data/actions/thirdResource'
import Tippy from '@tippyjs/react'
import {toFrontDate} from '../../../common/util/util-dates'
import {getDialogResource} from '../../../data/actions/dialogResource'
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import NoRecords from "../../../common/components/no-records-found/no-records";
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import PopoverDatePicker from "../../../common/components/popover/popover-datepicker";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import {LoaderLarge} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Page from "../../../common/components/layout/layout-components/page";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {formatMoney} from "../../../common/util/util-formaters";
import Layout from "../../../common/components/layout";
import DisplayDataGrid from "../../../common/components/display-data/display-data-grid";

export default function Reconciliation(props) {
    const pagePath = props.location.pathname.substring(1)

    const ulrQuery = new URLSearchParams(props.location.search)
    const ccID = ulrQuery.get('cc_statement')
    const ccBackPath = ccID ? '/accounting/credit-cards/' + ccID : undefined

    const dispatch = useDispatch()
    const history = useHistory()

    const ui = useSelector((state) => state.ui)
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)

    const secondResource = useSelector((state) => state.secondResource)
    const thirdResource = useSelector((state) => state.thirdResource)
    const dialogResource = useSelector((state) => state.dialogResource)

    const data = getProp(resource, 'data.list', [])
    const isLoading = getProp(resource, 'isLoading', false)

    const transactionsData = getProp(dialogResource, 'data.list', [])
    const transactionsDataIsLoading = getProp(dialogResource, 'isLoading', false)

    const ReconciliationBatchID = props.match.params.id

    const secondData = getProp(secondResource, 'data.list', [])
    const secondCount = getProp(secondResource, 'data.count', 0)
    const secondIsLoading = getProp(secondResource, 'isLoading', false)
    const [dataView, setDataView] = useState('table')
    const [dataViewType, setDataViewType] = useState('payment')
    const [showUnreconciled, setShowUnReconciled] = useState(false)

    /** Helpers
     ===================================================== */
    const getFields = (item = null) => {
        const fieldTemplates = {
            AccountID: new Field('AccountID', '', ['empty'], true, 'select-search', {addContainerClass: 'col-span-4'}),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-4'}),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-4'}),
            IsCompleted: new Field('IsCompleted', '', [], false, 'checkbox', {
                hideDialog: true,
                addContainerClass: 'col-span-4'
            }),
            StartingBalance: new Field('StartingBalance', '', ['empty'], false, 'money', {addContainerClass: 'col-span-4'}, {
                isNegativeAllowed: true
            }),
            EndingBalance: new Field('EndingBalance', '', ['empty'], false, 'money', {addContainerClass: 'col-span-4'}, {
                isNegativeAllowed: true
            }),
            Notes: new Field('Notes', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full'
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getTableFields = () => {
        let paymentMethods = getLookupWithFilter('PaymentType', 'PaymentTypeID', 'PaymentType', (it) => {
            return it.PaymentTypeID !== PAYMENT_METHOD_FACTOR
        })
        let fields = {
            Date: new Field('Date', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div className="flex gap-x-1 px-2">
                            {(!item.ReconciliationDate && (!!item.OtherReconciliationDate)) && (
                                <Tippy
                                    content={'This is an internal transaction, other part of the transaction is already reconciled.'}>
                                    <ExclamationCircleIcon
                                        className="w-5 h-5 text-yellow-600"
                                    />
                                </Tippy>
                            )}
                            {toFrontDate(item.Date)}
                        </div>
                    )
                }
            }),
            JournalEntryType: new Field('JournalEntryType', '', [''], false, 'text', {hideTable: ccID}),
            PaymentTypeID: new Field('PaymentTypeID', '', ['empty'], false, 'custom', {
                hideTable: ccID,
                render: (it) => {
                    return <span>{paymentMethods[it.PaymentTypeID]} {(it.PaymentTypeID === PAYMENT_METHOD_CHECK) ? '(' + it.CheckNumber + ')' : ''}</span>
                },
                omitSort: true
            }),
            Items: new Field('Items', '', [''], false, 'custom', {
                hideTable: ccID,
                render: (item) => {
                    return (
                        <Tippy content={item.Items}>
                            <span>{item.Items}</span>
                        </Tippy>
                    )
                },
                label: 'Refs'
            }),
            Payee: new Field('Payee', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <Tippy content={item.Payee}>
                            <span>{item.Payee}</span>
                        </Tippy>
                    )
                }
            }),
            Description: new Field('Description', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <Tippy content={item.Description}>
                            <span>{item.Description}</span>
                        </Tippy>
                    )
                }
            }),
        }

        if (dataView === 'table') {
            fields.Payment = new Field('Payment', '', [''], false, 'money', {
                render: (item) => {
                    return (
                        <div className="text-right">
                            {item.Payment ? genericMoneyFormatter(item.Payment) : ''}
                        </div>
                    )
                }
            })
            fields.Deposit = new Field('Deposit', '', [''], false, 'money', {
                render: (item) => {
                    return (
                        <div className="text-right">
                            {item.Deposit ? genericMoneyFormatter(item.Deposit) : ''}
                        </div>
                    )
                }
            })
        } else {
            if (dataViewType === 'payment') {
                fields.Payment = new Field('Payment', '', [''], false, 'money', {
                    render: (item) => {
                        return (
                            <div className="text-right">
                                {item.Payment ? genericMoneyFormatter(item.Payment) : ''}
                            </div>
                        )
                    }
                })
                fields.Deposit = new Field('Deposit', '', [''], false, 'hidden', {hideTable: true})
            } else {
                fields.Deposit = new Field('Deposit', '', [''], false, 'money', {
                    render: (item) => {
                        return (
                            <div className="text-right">
                                {item.Deposit ? genericMoneyFormatter(item.Deposit) : ''}
                            </div>
                        )
                    }
                })
                fields.Payment = new Field('Payment', '', [''], false, 'hidden', {hideTable: true})
            }
        }
        return fields
    }

    const getDetailsFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                omitSort: true
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: 'PayTo',
                render: (it) => it.Organization ?? it.Contact
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                omitSort: true,
                label: 'PaymentAmount',
            }),
            ExpenseAmount: new Field('ExpenseAmount', '', [], false, 'money', {
                hideTable: !getProp(dialogResource, 'data.list', [])[0]?.ExpenseAmount,
                omitSort: true
            }),
            ExpenseStatus: new Field('ExpenseStatus', '', [''], false, 'custom', {
                hideTable: !getProp(dialogResource, 'data.list', [])[0]?.ExpenseStatus,
                omitSort: true,
                render: (it) => renderExpenseStatusBadge(it)
            }),
            InvoiceAmount: new Field('InvoiceAmount', '', [], false, 'money', {
                hideTable: !getProp(dialogResource, 'data.list', [])[0]?.InvoiceAmount,
                omitSort: true
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                hideTable: !getProp(dialogResource, 'data.list', [])[0]?.InvoiceStatus,
                omitSort: true,
                render: (it) => renderInvoiceStatusBadge(it)
            })
        }
    }

    const getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'text', {}, {}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: 'float'}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: 'float'}),
            limit: new Field('limit', 1000, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: 'top'}),
        }
    }

    const getFormQuery = () => {
        return Object.assign(FieldsManager.getFieldKeyValues(fields), {
            searchFields: JSON.stringify({ReconciliationBatchID: ReconciliationBatchID})
        })
    }

    const getTableListQuery = () => {
        let fieldsData = getFields(getReconciliationData())
        delete fieldsData['StartDate']
        delete fieldsData['EndDate']
        return Object.assign({},
            FieldsManager.getFieldKeyValues(fieldsData),
            query, {
                ReconciliationBatchID: ReconciliationBatchID
            },
            ccID ? {PaymentTypeID: PAYMENT_METHOD_CHECK} : {}
        )
    }

    const getReconciliationData = () => {
        return getProp(resource, 'data.list', [{}])[0]
    }

    const IsCompleted = getReconciliationData()?.IsCompleted

    const getTableOptions = (pagePath, translate) => {
        return getDefaultTableOptionsJSON(getTableFields(), {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasVirtualRows: true
            },
            style: {
                stripedRows: true,
                horizontalLines: true,
                verticalLines: false,
                condensed: true,
                floatingActions: true,
                frozenActionColumn: false,
            },
        }, translate)
    }

    /** State
     ===================================================== */
    const [fields, setFields] = useState(getFields())
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFields())
    const [query, setQuery] = useState({})
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState({})
    const [unSelectedRows, setUnSelectedRows] = useState({})
    const [collapsed, setCollapsed] = useState(true)
    const [tableOptions, setTableOptions] = useState(getTableOptions(pagePath, props.translate))
    const [isTransactionsDialogVisible, setIsTransactionsDialogVisible] = useState(false)
    const [selectedTransactionsItem, setSelectedTransactionsItem] = useState(null)

    const [sort, setSort] = useState('DESC')
    const [sortBy, setSortBy] = useState('Date')

    const [invoiceExpenseDialogFetchKey, setInvoiceExpenseDialogFetchKey] = useState(null)
    const [invoiceExpenseDialogResource, setInvoiceExpenseDialogResource] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)

    const tableFields = getTableFields()

    /** UI events
     ===================================================== */

    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    const handleUpdateQueryFields = (fields) => {
        setQueryFilterFields(fields)
        this.fetchData()
    }

    const handleSelectRow = (item) => {
        if (IsCompleted) {
            return
        }
        let selectedRowsClone = cloneDeep(selectedRows)

        if (selectedRowsClone[item.keyID]) {
            let unSelectedRowsClone = cloneDeep(unSelectedRows)
            unSelectedRowsClone[item.keyID] = item
            setUnSelectedRows(unSelectedRowsClone)
            delete selectedRowsClone[item.keyID]
        } else {
            selectedRowsClone[item.keyID] = item
        }

        setSelectedRows(selectedRowsClone)
    }

    const handleSelectAllRows = () => {
        if (IsCompleted) {
            return
        }
        let selectedRowsClone = cloneDeep(selectedRows)
        if (Object.keys(selectedRowsClone).length === secondData.length) {
            setUnSelectedRows(selectedRowsClone)
            setSelectedRows({})
        } else {
            secondData.forEach(it => {
                if (!selectedRowsClone[it.keyID]) {
                    selectedRowsClone[it.keyID] = it
                }
            })
            setSelectedRows(selectedRowsClone)
        }
    }

    const handleSelectRows = (items) => {
        let selectedRowsData = items.reduce((memo, item) => {
            if (!!item.ReconciliationDate) {
                memo[item.keyID] = item
            }
            return memo
        }, {})
        setSelectedRows(selectedRowsData)
    }

    const handleToggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const handleFormCancel = () => {
        setSelectedRows({})
    }

    const handleSortChange = (sortBy) => {
        let newSort = (sortBy === sortBy) ? (sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        setSortBy(sortBy)
        setSort(newSort)
    }

    /** Data events
     ===================================================== */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingReconciliationBatch,
            query: getFormQuery()
        }))
    }

    const fetchListData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingReconciliation,
            query: getTableListQuery()
        }))
    }

    const fetchExpenseDetailsData = (item) => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                SendBatchPaymentID: item?.SendBatchPaymentID ?? null,
                SendPaymentID: item?.SendPaymentID ?? null
            },
            resource: Resources.ExpenseTransactionsDetails
        }))
    }

    const fetchIncomeDetailsData = (item) => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                RecvBatchPaymentID: item?.RecvBatchPaymentID ?? null,
                RecvPaymentID: item?.RecvPaymentID ?? null
            },
            resource: Resources.IncomeTransactionsDetails
        }))
    }

    const submitData = () => {
        dispatch(createResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingReconciliationComplete,
            piggyResource: Resources.AccountingReconciliationBatch,
            query: getFormQuery(),
            params: {
                ReconciliationBatchID: props.match.params.id,
                Items: Object.values(unSelectedRows).map((item) => {
                    return {
                        RecvPaymentID: item.RecvPaymentID,
                        SendPaymentID: item.SendPaymentID,
                        SendBatchPaymentID: item.SendBatchPaymentID,
                        RecvBatchPaymentID: item.RecvBatchPaymentID,
                        AccountTransferID: item.AccountTransferID,
                        RecvPaymentDepositID: item.RecvPaymentDepositID,
                        TransactionID: item.TransactionID,
                        Clean: 0
                    }
                })
                    .concat(Object.values(selectedRows).map((item) => {
                        return {
                            RecvPaymentID: item.RecvPaymentID,
                            SendPaymentID: item.SendPaymentID,
                            SendBatchPaymentID: item.SendBatchPaymentID,
                            RecvBatchPaymentID: item.RecvBatchPaymentID,
                            AccountTransferID: item.AccountTransferID,
                            RecvPaymentDepositID: item.RecvPaymentDepositID,
                            TransactionID: item.TransactionID,
                            Clean: 1
                        }
                    }))
            }
        }))
    }

    const handleSaveAndClose = () => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingReconciliation,
            piggyResource: Resources.AccountingReconciliationBatch,
            query: getFormQuery(),
            params: {
                ReconciliationBatchID: props.match.params.id,
                Items: Object.values(unSelectedRows).map((item) => {
                    return {
                        RecvPaymentID: item.RecvPaymentID,
                        SendPaymentID: item.SendPaymentID,
                        SendBatchPaymentID: item.SendBatchPaymentID,
                        RecvBatchPaymentID: item.RecvBatchPaymentID,
                        AccountTransferID: item.AccountTransferID,
                        RecvPaymentDepositID: item.RecvPaymentDepositID,
                        TransactionID: item.TransactionID,
                        Clean: 0
                    }
                })
                    .concat(Object.values(selectedRows).map((item) => {
                        return {
                            RecvPaymentID: item.RecvPaymentID,
                            SendPaymentID: item.SendPaymentID,
                            SendBatchPaymentID: item.SendBatchPaymentID,
                            RecvBatchPaymentID: item.RecvBatchPaymentID,
                            AccountTransferID: item.AccountTransferID,
                            RecvPaymentDepositID: item.RecvPaymentDepositID,
                            TransactionID: item.TransactionID,
                            Clean: 1
                        }
                    }))
            }
        }))
    }

    const handleToggleShowTransactions = (item = null) => {
        setSelectedItem(item)
        setSelectedTransactionsItem(item)
        if ((item?.SendBatchPaymentID || item?.SendPaymentID)) {
            fetchExpenseDetailsData(item)
        } else {
            fetchIncomeDetailsData(item)
        }
        setIsTransactionsDialogVisible(!isTransactionsDialogVisible)
    }

    const handleToggleInvoiceExpenseDialog = (key = null, resource = null) => {
        setInvoiceExpenseDialogFetchKey(null)
        setInvoiceExpenseDialogResource(resource)
        setSelectedItem(null)
    }

    const handleSetDataView = (view) => {
        setDataView(view)
    }

    const handleSetDataViewType = (view) => {
        setDataViewType(view)
    }
    /** Lifecycle
     ===================================================== */
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (!isLoading && Object.keys(data)) {
            const fields = getFields(getReconciliationData())
            setFields(fields)
            fetchListData()
        }
    }, [isLoading])

    useEffect(() => {
        if (!secondIsLoading && secondData.length > 0) {
            handleSelectRows(secondData)
        }
    }, [secondIsLoading])

    useEffect(() => {
        setTableOptions(getTableOptions(pagePath, props.translate));
    }, [dataView]);

    /** Render
     ===================================================== */
    const paymentTotal = (Object.values(selectedRows).reduce((memo, it) => memo + parseFloat(it.Payment ? it.Payment : 0), 0))
    const depositTotal = (Object.values(selectedRows).reduce((memo, it) => memo + parseFloat(it.Deposit ? it.Deposit : 0), 0))

    const currentBalance = parseFloat(depositTotal - paymentTotal).toFixed(2)

    let startingBalance = (getReconciliationData()?.StartingBalance ?? 0)
    let endingBalance = (getReconciliationData()?.EndingBalance ?? 0)

    const filteredData = secondData.filter(it => {
        let canPass = true
        if (!!showUnreconciled && !!selectedRows[it.keyID]) {
            canPass = false
        }
        if (queryFilterFields.StartDate?.value) {
            canPass &= moment(it.Date, DEFAULT_DATABASE_DATETIME_FORMAT).isAfter(moment(queryFilterFields.StartDate?.value))
        }
        if (queryFilterFields.EndDate?.value) {
            canPass &= moment(it.Date).isBefore(moment(queryFilterFields.EndDate?.value))
        }
        const checkNumber = it?.CheckNumber + ''
        if (queryFilterFields.query?.value && !isNaN(queryFilterFields.query?.value)) {
            canPass &=
                (parseFloat(it.Payment) == parseFloat(queryFilterFields.query?.value))
                ||
                (parseFloat(it.Deposit) == parseFloat(queryFilterFields.query?.value))
                ||
                it.Items?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase())
                ||
                it.Payee?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase())
                ||
                it.Description?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase())
                ||
                (checkNumber?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase()))
        } else if (queryFilterFields.query?.value) {
            canPass &= it.Items?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase())
                ||
                it.Payee?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase())
                ||
                it.Description?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase())
                ||
                (checkNumber?.toLowerCase().includes(queryFilterFields.query?.value?.toLowerCase()))
        }

        return canPass
    }).sort((a, b) => {
        const sortOrder = (sort === 'DESC' ? -1 : 1)
        if (sortBy === 'Date') {
            return (moment(a[sortBy], DEFAULT_DATABASE_DATETIME_FORMAT).isBefore(moment(b[sortBy], DEFAULT_DATABASE_DATETIME_FORMAT)) ? 1 : -1) * sortOrder
        }
        if (sortBy === 'Payment' || sortBy === 'Deposit') {
            return (a[sortBy] > b[sortBy] ? 1 : -1) * sortOrder
        }
        return (a[sortBy] ?? '').localeCompare(b[sortBy]) * sortOrder
    })

    const canFinishReconcile = ((startingBalance) + parseFloat(currentBalance)).toFixed(2) == (endingBalance).toFixed(2)

    return (
        <Layout
            {...props}
            ui={ui}
            user={user}
            history={history}
            dispatch={dispatch}
            hasFooter
        >
            <Page>
                {!isLoading && (
                    <div className={classNames('mt-6 grid gap-4 grid-row-6')}>
                        <Card addClass="row-span-4">
                            <div className={'flex justify-between items-center'}>
                                <div className={'flex gap-x-4'}>

                                    <GoBackButton
                                        translate={props.translate}
                                        history={history}
                                        path={ccBackPath ? ccBackPath : '/accounting/reconciliations'}
                                    />

                                    {!!collapsed && (
                                        <button onClick={() => {
                                            setCollapsed(false)
                                        }}>
                                            <ChevronRightIcon className={'h-4 w-4'}/>
                                        </button>
                                    )}
                                    {!collapsed && (
                                        <button onClick={() => {
                                            setCollapsed(true)
                                        }}>
                                            <ChevronDownIcon className={'h-4 w-4'}/>
                                        </button>
                                    )}
                                </div>
                                <div className={'flex mr-auto'}>
                                    <h5 className="text-tm-gray-700 text-base"
                                        onClick={() => setCollapsed(!collapsed)}>{props.translate('text.details')}</h5>
                                    {!!collapsed && (<div className={'text-base ml-2'}>({data[0]?.Account})</div>)}
                                </div>
                                {!IsCompleted && (
                                    <button
                                        onClick={handleToggleEditModal}
                                        className="btn btn-outline">
                                        <PencilIcon className="h-4 w-4 mr-2"/>
                                        Edit
                                    </button>
                                )}
                            </div>
                            {!collapsed && (
                                <DisplayDataGrid
                                    addGridClass={'md:grid-cols-12 mb-5 border border-tm-gray-300 rounded-lg p-5 text-tm-gray-700 font-bold'}
                                    displayList={Object.values(getFields(getReconciliationData()))}
                                    data={getReconciliationData()}
                                    translate={props.translate}
                                />
                            )}
                        </Card>
                        {!!data.length && (
                            <Card addClass="row-span-4">
                                <div className={'text-lg flex justify-center'}>
                                    <div className={'mx-4 flex flex-col items-center'}>
                                        {formatMoney(startingBalance)}
                                        <h1 className={'text-sm text-tm-gray-600'}>Starting balance</h1>
                                    </div>
                                    {'->'}
                                    <div className={'mx-4 flex flex-col items-center'}>
                                        {formatMoney(currentBalance)}
                                        <h1 className={'text-sm text-tm-gray-600'}>Cleared balance</h1>
                                    </div>
                                    =
                                    <div
                                        className={(canFinishReconcile ? 'text-green-500' : ' text-yellow-500') + ' mx-4 flex flex-col items-center'}>

                                        {formatMoney(endingBalance)} ({
                                        'Difference: ' + formatMoney(((numberWithCommasToBack(startingBalance)) + parseFloat(currentBalance) - (numberWithCommasToBack(endingBalance))).toFixed(2))
                                    })
                                        <h1 className={'text-sm text-tm-gray-600'}>Ending balance</h1>
                                    </div>
                                </div>
                            </Card>
                        )}
                    </div>
                )}

                {isLoading && (
                    <LoaderLarge/>
                )}

                <TableCard>
                    <TableFilters
                        filterFields={queryFilterFields}
                        handleInputChange={handleFilterInputChange}
                        translate={props.translate}
                        hideLimit
                        customHeaderHtml={
                            <div className="flex col-span-2">
                                <Tippy content={'Show all'}>
                                    <button
                                        onClick={() => {
                                            handleSetDataView('table')
                                        }}
                                        className={classNames(dataView === 'table' ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', 'h-full relative inline-flex rounded-l-input items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1')}
                                    >
                                        All
                                    </button>
                                </Tippy>
                                <Tippy content={'Show payments'}>
                                    <button
                                        onClick={() => {
                                            handleSetDataView('split')
                                            handleSetDataViewType('payment')
                                        }}
                                        className={classNames((dataView === 'split' && dataViewType === 'payment') ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', 'h-full input  relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1')}
                                    >
                                        Payments
                                    </button>
                                </Tippy>
                                <Tippy content={'Show deposits'}>
                                    <button
                                        onClick={() => {
                                            handleSetDataView('split')
                                            handleSetDataViewType('deposit')
                                        }}
                                        className={classNames((dataView === 'split' && dataViewType === 'deposit') ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', 'h-full rounded-r-input  relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 mr-4')}
                                    >
                                        Deposits
                                    </button>
                                </Tippy>

                                <PopoverDatePicker
                                    queryFields={queryFilterFields}
                                    onQueryInputChange={handleFilterInputChange}
                                    updateFields={handleUpdateQueryFields}
                                    translate={props.translate}
                                />

                                <label
                                    className="h-10 flex flex-center justify-start px-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer ml-4">
                                    <FieldCheckbox
                                        className="checkbox"
                                        onChange={(name, val) => {
                                            setShowUnReconciled(val)
                                        }}
                                        value={showUnreconciled}
                                    />

                                    <span className="ml-2 text-sm select-none">
                                            Show not reconciled
                                        </span>
                                </label>
                            </div>
                        }
                    >
                        <button
                            className="btn btn-outline"
                            onClick={() => {
                                setQueryFilterFields(getQueryFields())
                                setShowUnReconciled(false)
                                setDataView('table')
                            }}
                        >
                            Clear filter(s)
                        </button>
                    </TableFilters>

                    {console.log("tableFields", tableFields)}

                    {dataView === 'table' && (
                        <>
                            <ResourceTable
                                fields={tableFields}
                                data={filteredData.map((it) => {
                                    if (it.Deposit === 0) {
                                        it.Deposit = ''
                                    }
                                    if (it.Payment === 0) {
                                        it.Payment = ''
                                    }
                                    return it
                                })}
                                count={secondCount}

                                sort={sort}
                                sortBy={sortBy}
                                onSortChange={handleSortChange}
                                options={tableOptions}
                                hideSelectAll={true}

                                tableKey={'keyID'}
                                selectedRows={selectedRows}

                                onSelectRow={handleSelectRow}
                                onSelectAllClick={handleSelectAllRows}
                                translate={props.translate}
                                isLoading={secondIsLoading || isLoading}

                                limit={queryFilterFields.limit.value}
                                offset={query.offset}

                                actions={[
                                    {
                                        tooltipText: () => props.translate('text.click_to_view_details'),
                                        visible: (item) => (!!item.FinalExpenseID || !!item.FinalInvoiceID),
                                        action: (it) => {
                                            if (it.FinalExpenseID) {
                                                setInvoiceExpenseDialogResource(Resources.ExpenseExpense)
                                                setInvoiceExpenseDialogFetchKey('ExpenseID')
                                            } else if (it.FinalInvoiceID) {
                                                setInvoiceExpenseDialogResource(Resources.InvoicesInvoice)
                                                setInvoiceExpenseDialogFetchKey('InvoiceID')
                                            }
                                            setSelectedItem(it)
                                        },
                                        icon: () => EyeIcon
                                    },
                                    {
                                        title: () => props.translate('text.click_to_view_details'),
                                        visible: (item) => ((!item.FinalExpenseID && !item.FinalInvoiceID) && (item.SendBatchPaymentID || item.SendPaymentID || item.RecvPaymentID || item.RecvBatchPaymentID)),
                                        action: (it) => {
                                            handleToggleShowTransactions(it)
                                        },
                                        icon: () => EyeIcon
                                    }
                                ]}
                            />
                            <NoRecords
                                addClass={'mt-5'}
                                show={(secondData.length === 0) && !secondIsLoading}
                                title={props.translate('text.no_records')}
                            />

                        </>)}

                    {dataView === 'split' && (
                        <>
                            {dataViewType === 'payment' && (
                                <>
                                    <ResourceTable
                                        data={filteredData.filter((it) => it.Payment)}
                                        count={secondCount}
                                        sort={sort}
                                        sortBy={sortBy}
                                        onSortChange={handleSortChange}
                                        options={tableOptions}
                                        hideSelectAll={true}
                                        tableKey={'keyID'}
                                        selectedRows={selectedRows}

                                        isSelectRowDisabled={() => {
                                            return IsCompleted
                                        }}
                                        onSelectRow={handleSelectRow}
                                        onSelectAllClick={handleSelectAllRows}

                                        fields={tableFields}
                                        translate={props.translate}
                                        isLoading={secondIsLoading || isLoading}

                                        limit={queryFilterFields.limit.value}
                                        offset={query.offset}
                                        actions={[
                                            {
                                                tooltipText: () => props.translate('text.click_to_view_details'),
                                                visible: (item) => (!!item.FinalExpenseID || !!item.FinalInvoiceID),
                                                action: (it) => {
                                                    if (it.FinalExpenseID) {
                                                        setInvoiceExpenseDialogResource(Resources.ExpenseExpense)
                                                        setInvoiceExpenseDialogFetchKey('ExpenseID')
                                                    } else if (it.FinalInvoiceID) {
                                                        setInvoiceExpenseDialogResource(Resources.InvoicesInvoice)
                                                        setInvoiceExpenseDialogFetchKey('InvoiceID')
                                                    }
                                                    setSelectedItem(it)
                                                },
                                                icon: () => EyeIcon
                                            }
                                        ]}
                                    />
                                    <NoRecords
                                        addClass={'mt-5'}
                                        show={(secondData.length === 0) && !secondIsLoading}
                                        title={props.translate('text.no_records')}
                                    />
                                </>
                            )}


                            {dataViewType === 'deposit' && (
                                <>
                                    <ResourceTable
                                        data={filteredData.filter((it) => it.Deposit)}
                                        count={secondCount}
                                        sort={sort}
                                        sortBy={sortBy}
                                        onSortChange={handleSortChange}
                                        options={tableOptions}
                                        hideSelectAll={true}
                                        tableKey={'keyID'}
                                        selectedRows={selectedRows}

                                        isSelectRowDisabled={() => {
                                            return IsCompleted
                                        }}
                                        onSelectRow={handleSelectRow}
                                        onSelectAllClick={handleSelectAllRows}

                                        fields={tableFields}
                                        translate={props.translate}
                                        isLoading={secondIsLoading || isLoading}

                                        limit={queryFilterFields.limit.value}
                                        offset={query.offset}

                                        actions={[
                                            {
                                                tooltipText: () => props.translate('text.click_to_view_details'),
                                                visible: (item) => (!!item.FinalExpenseID || !!item.FinalInvoiceID),
                                                action: (it) => {
                                                    if (it.FinalExpenseID) {
                                                        setInvoiceExpenseDialogResource(Resources.ExpenseExpense)
                                                        setInvoiceExpenseDialogFetchKey('ExpenseID')
                                                    } else if (it.FinalInvoiceID) {
                                                        setInvoiceExpenseDialogResource(Resources.InvoicesInvoice)
                                                        setInvoiceExpenseDialogFetchKey('InvoiceID')
                                                    }
                                                    setSelectedItem(it)
                                                },
                                                icon: () => EyeIcon
                                            }
                                        ]}
                                    />
                                    <NoRecords
                                        addClass={'mt-5'}
                                        show={(secondData.length === 0) && !secondIsLoading}
                                        title={props.translate('text.no_records')}
                                    />
                                </>
                            )}
                        </>
                    )}


                    <div className="px-0 py-1 border-y border-tm-gray-200  flex items-center">
                        <div className="mr-4 flex space-x-4 h-9">
                        </div>
                        <div className="text-sm flex justify-between w-full">
                            <div>
                                        <span
                                            className="font-bold text-tm-gray-700">{Object.keys(selectedRows).length}</span> entries
                                selected
                            </div>

                            <div className="mr-4">
                                Cleared balance:
                                <span className="ml-2 font-bold text-tm-gray-700">
                                            {genericMoneyFormatter(currentBalance)}
                                        </span>
                            </div>
                        </div>
                    </div>
                </TableCard>

                <ModalSaveResource
                    title={'Edit Reconciliation'}
                    widthClass="max-w-xl"
                    gridColsClass="grid-cols-12"
                    show={editModalOpen}
                    onClose={handleToggleEditModal}
                    fields={getFields(getReconciliationData())}
                    onSubmit={(params) => {
                        if (params) {
                            params.ReconciliationBatchID = ReconciliationBatchID
                            dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.AccountingReconciliationBatch,
                                // Form piggy
                                query: getFormQuery(),
                                piggyResource: Resources.AccountingReconciliationBatch,
                                // Table List piggy
                                piggySecondResource: Resources.AccountingReconciliation,
                                secondPiggyQuery: getTableListQuery(),
                                errorMessage: true,
                                successMessage: 'Reconciliation batch updated successfully!'
                            }))
                            handleToggleEditModal()
                        }
                    }}
                    translate={props.translate}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: {
                                AccountTypeID: ACCOUNT_TYPE_CASH,
                            },
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName
                            })
                        }
                    }}
                />

                <ModalDefault
                    show={!!(!!selectedItem && !!invoiceExpenseDialogFetchKey && !!invoiceExpenseDialogResource)}
                    widthClass={'max-w-5xl w-screen'}
                    title={invoiceExpenseDialogFetchKey ? props.translate('text.' + invoiceExpenseDialogFetchKey) : ''}

                    closeButtonLabel={props.translate('btn.Close')}
                    onClose={() => handleToggleInvoiceExpenseDialog()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={thirdResource}
                        isLoading={thirdResource.isLoading}
                        translate={props.translate}
                        dispatch={dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}
                        expenseID={selectedItem?.FinalExpenseID}

                        type={invoiceExpenseDialogFetchKey === 'ExpenseID' ? 'expense' : 'invoice'}

                        // Data events
                        onFetchData={() => {
                            if (selectedItem && invoiceExpenseDialogFetchKey && invoiceExpenseDialogResource) {
                                dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [invoiceExpenseDialogFetchKey]:
                                        selectedItem.FinalExpenseID ?? selectedItem.FinalInvoiceID
                                        ??
                                        selectedItem.ExpenseID ?? selectedItem.InvoiceID
                                    },
                                    resource: invoiceExpenseDialogResource
                                }))
                            }
                        }}
                    />
                </ModalDefault>

                <ModalDefault
                    show={isTransactionsDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={props.translate('text.Payments')}

                    closeButtonLabel={props.translate('btn.Close')}
                    onClose={() => handleToggleShowTransactions()}
                >
                    <div>
                        <ResourceTable
                            data={transactionsData}
                            commonTable={true}
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            fields={getDetailsFields()}

                            translate={props.translate}
                            isLoading={transactionsDataIsLoading}

                            limit={(transactionsData?.length ?? 10)}

                            actions={[
                                {
                                    action: (it) => {
                                        if (!!it.SendPaymentID || !!it.SendBatchPaymentID) {
                                            setInvoiceExpenseDialogResource(Resources.ExpenseExpense)
                                            setInvoiceExpenseDialogFetchKey('ExpenseID')
                                        } else if (!!it.RecvPaymentID || !!it.RecvBatchPaymentID) {
                                            setInvoiceExpenseDialogResource(Resources.InvoicesInvoice)
                                            setInvoiceExpenseDialogFetchKey('InvoiceID')
                                        }
                                        setSelectedItem(it)
                                    },
                                    icon: EyeIcon,
                                    visible: () => true,// TODO Check perm
                                    title: props.translate('text.show_details'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(transactionsData.length === 0) && !transactionsDataIsLoading}
                            canCreate={false}
                            title={props.translate('text.no_matching_records')}
                        />
                    </div>
                </ModalDefault>

                <PageFooter
                    translate={props.translate}
                    buttonLabel={IsCompleted ? 'Undo' : 'Finish reconciling'}
                    canSubmit={
                        canFinishReconcile
                    }
                    actionSubmit={submitData}
                    actionCancel={handleFormCancel}
                    htmlBefore={(
                        <>
                            {!IsCompleted && (
                                <button
                                    data-name="submit-button-shortcut"
                                    className="btn btn-primary"
                                    disabled={false}
                                    onClick={handleSaveAndClose}>
                                    {'Save and close'}
                                </button>
                            )}
                        </>
                    )}
                />
            </Page>
        </Layout>
    )
}



