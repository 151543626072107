import FieldContainer from "../field-container";
import FieldText from "../field-text";
import React from "react";
import {classNames, getLookup, getProp} from "../../../util/util-helpers";
import FieldSelectSearch from "../field-select-search";
import FieldDate from "../field-date";
import FieldDropdownSelect from "../field-dropdown-select";
import {getJWT} from "../../../util/util-auth";
import axios from "axios";
import Env from "../../../../util/env";
import {processResponse} from "../../../../data/services/api-util";
import Tippy from "@tippyjs/react";
import InfoTooltipNew from "../../info-tooltip-new";
import {getFieldContainerClass, getFieldLabelClass} from "../../../util/util-fields";
import FieldTextarea from "../field-textarea";
import FieldSwitch from "../field-switch";

export default function FieldsForm({
                                       fieldsState,
                                       onChange,
                                       includeFields,
                                       excludeFields,
                                       translate,
                                       selects = {},
                                       isLoading
                                   }) {
    return !!fieldsState && Object.values(Object.assign({}, fieldsState)).reduce((memo, field) => {
        if (includeFields?.length && !includeFields.includes(field.name)) {
            return memo;
        }

        if (excludeFields?.length && !!excludeFields.includes(field.name)) {
            return memo;
        }

        const req = (field.validate.includes("empty") || field.validate.includes("required"));

        let itemLabelText;

        if (field?.metadata?.labelRaw) {
            itemLabelText = field.metadata.labelRaw + (req ? " *" : "");
        } else {
            itemLabelText = (field?.metadata?.label) ? (translate("field." + field?.metadata?.label) + " " + (req ? "*" : "")) : (translate("field." + field.name) + " " + (req ? "*" : ""))
        }

        let item;
        switch (field.type) {
            case "button-group": {
                const buttonData = selects[field.name] ?? field?.metadata?.data;
                const buttonKeys = Object.keys(buttonData);
                const addClass = item?.metadata?.addClass;
                const addButtonClass = item?.metadata?.addButtonClass;
                const hasTooltip = !!item?.metadata?.tooltip;

                const buttonsGroup = (
                    <div
                        //className="flex items-center"
                        key={field.name}
                    >
                        <div
                            className={
                                classNames(
                                    addClass,
                                    !field.disabled ? "bg-tm-gray-100 hover:bg-tm-gray-200" : "bg-tm-gray-300",
                                    "group p-0.5 flex h-9 rounded-lg border border-tm-gray-200",
                                    field?.metadata?.addClass
                                )
                            }
                        >
                            {
                                buttonKeys.map((buttonValue, i) => {
                                    const isActive = field.value === buttonValue;

                                    return (
                                        <button
                                            type="button"
                                            key={buttonData[buttonValue] + "-" + i}
                                            disabled={field.disabled || isLoading}
                                            className={classNames(
                                                addButtonClass,
                                                isActive
                                                    ? "shadow-sm ring-1 ring-black ring-opacity-5 focus-visible:ring-2 focus-visible:ring-primary focus:outline-none "
                                                    : "text-tm-gray-700 focus-visible:ring-2 focus-visible:ring-primary focus:outline-none focus-visible:z-50",
                                                isActive && !field.disabled ? "bg-inverse text-primary" : "",
                                                isActive && field.disabled ? "bg-tm-gray-100 text-tm-gray-700" : "",
                                                "text-sm font-medium",
                                                field.disabled ? "cursor-default" : "",
                                                "rounded-input p-1.5 lg:px-3 flex justify-center items-center grow"
                                            )}
                                            onClick={() => field.disabled ? null : onChange(field.name, buttonValue)}
                                        >
                                            {buttonData[buttonValue]}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                )

                item = (
                    <React.Fragment key={field.name}>
                        {field.metadata?.labelType !== "left" && (
                            <div
                                className={getFieldContainerClass(field?.metadata?.addContainerClass)}
                            >
                                <span className={getFieldLabelClass(field.metadata)}>
                                    {!item?.metadata?.hideLabel && (
                                        itemLabelText
                                    )}

                                    {item?.metadata?.info && (
                                        <Tippy content={item?.metadata?.info}>
                                            <i className="simple-icon-info ml-2 align-top"/>
                                        </Tippy>
                                    )}
                                </span>

                                {buttonsGroup}
                            </div>
                        )}

                        {field.metadata?.labelType === "left" && (
                            <div
                                className="relative flex items-center py-4 col-span-full"
                            >
                                <div className="min-w-0 flex-1 text-sm">
                                    <span className={
                                        classNames(
                                            hasTooltip ? "flex gap-1" : "",
                                            "font-medium text-tm-gray-900 select-none"
                                        )}
                                    >
                                        {translate("field." + field.name)}

                                        {hasTooltip && (
                                            <InfoTooltipNew>
                                                {field.metadata.tooltip}
                                            </InfoTooltipNew>
                                        )}

                                        {field?.metadata?.afterLabelHtml}
                                    </span>

                                    {!!item?.metadata?.note && (
                                        <p className="text-tm-gray-700 pr-4">{field?.metadata?.note}</p>
                                    )}
                                </div>

                                {buttonsGroup}
                            </div>
                        )}
                    </React.Fragment>
                );

                break;
            }
            case "date": {
                const minDate = !!field?.metadata?.minDate && field?.metadata?.minDate(fieldsState);
                const maxDate = !!field?.metadata?.maxDate && field?.metadata?.maxDate(fieldsState);

                item = (
                    <FieldContainer
                        key={field.name}
                        item={field}
                        translate={translate}
                    >
                        <FieldDate
                            {...field.metadata}
                            {...field}
                            addClass={"form-control"}
                            onChange={onChange}
                            showTimeSelect={false}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={isLoading || field.disabled}
                        />
                    </FieldContainer>
                );
                break;
            }
            case "select": {
                let values;
                if ((typeof selects[field.name] === "function")) {
                    values = selects[field.name](field, fieldsState, onChange);
                } else if (selects[field.name]) {
                    values = selects[field.name];
                } else {
                    values = getLookup(field.name.replace("ID", ""));
                }

                item = (
                    <FieldContainer
                        item={field}
                        translate={translate}
                        key={field.name}
                    >
                        <FieldSelectSearch
                            {...field.metadata}
                            {...field}
                            addClass="form-control"
                            values={values}
                            onChange={onChange}
                            translate={translate}
                            disabled={isLoading || field.disabled}
                        />
                    </FieldContainer>
                );
            }
                break;
            case 'select-search': {
                const api = selects?.[field.name]?.api ?? getProp(field, "props.api", "");
                const query = selects?.[field.name]?.query
                    ? selects?.[field.name]?.query
                    : getProp(field, "props.query", {});
                item = (
                    <FieldContainer
                        item={field}
                        translate={translate}
                        key={field?.metadata?.key ?? field.name}
                    >
                        <FieldDropdownSelect
                            {...field}
                            onChange={onChange}
                            disabled={isLoading || field.disabled}
                            addClass="form-control"
                            defaultOptions={true}
                            translate={translate}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl(
                                            api,
                                            Object.assign(query, {query: inputValue})),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + getJWT().access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);

                                            if (result && result.status === 0) {
                                                let list;
                                                if (selects?.[field.name]?.customizeList) {
                                                    list = selects[field.name].customizeList(result.data.list);
                                                } else {
                                                    list = result.data.list.map((it) => {
                                                        if (typeof field?.metadata?.searchMap === "function") {
                                                            return field.metadata.searchMap(it);
                                                        }

                                                        return selects[field.name].searchMap(it);
                                                    });
                                                }


                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log("Error", error)
                                        });
                                }
                            }
                            {...field.metadata}
                        />
                    </FieldContainer>
                )
                break;
            }
            case 'switch':
                item = (
                    <div
                        key={field.name}
                        className={field?.metadata?.addContainerClass}
                    >
                        {!!field?.metadata?.htmlBefore && field.metadata.htmlBefore(field)}

                        <FieldSwitch
                            name={field.name}
                            value={field.value}
                            disabled={field.disabled}
                            onChange={onChange}
                            label={field?.metadata?.label}
                            addClass={field?.metadata?.addClass}
                        />

                        {!!field?.metadata?.htmlAfter && field.metadata.htmlAfter(field)}
                    </div>
                )

                break;
            case 'textarea': {
                let heightClass = field.metadata?.heightClass ?? "min-h-[6rem]";
                item = (
                    <FieldContainer
                        key={field.name}
                        item={field}
                        translate={translate}
                    >
                        <FieldTextarea
                            {...field.props}
                            {...field}
                            addClass={classNames(heightClass, "form-control whitespace-pre-wrap")}
                            onChange={onChange}
                            disabled={isLoading || field.disabled}
                        />
                    </FieldContainer>
                );
                break;
            }
            case 'hidden':
                break;
            case 'text':
            default:
                item = (
                    <FieldContainer
                        key={field.name}
                        item={field}
                        label={field?.metadata?.label}
                        translate={translate}
                    >
                        <FieldText
                            {...field.metadata}
                            {...field}
                            id={field.name}
                            addClass={"form-control peer"}
                            onChange={onChange}
                            translate={translate}
                            placeholder={field?.metadata?.placeholder}
                            disabled={isLoading || field.disabled}
                            autoFocus={field?.metadata?.autoFocus}
                        />
                    </FieldContainer>
                )
        }

        memo.push(item);
        return memo;
    }, [])
}