import React, {useEffect, useState} from "react";
import EllipsisVerticalIcon from "@heroicons/react/20/solid/EllipsisVerticalIcon";
import {
    ArrowDownIcon,
    ArrowUturnLeftIcon,
    CheckIcon,
    ChevronDoubleDownIcon,
    EyeIcon,
    EyeSlashIcon,
    PlusSmallIcon
} from "@heroicons/react/20/solid";
import {
    ArrowPathIcon,
    ComputerDesktopIcon,
    DevicePhoneMobileIcon,
    HandRaisedIcon,
    PencilIcon,
    PlusIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import HandRaisedIconSolid from "@heroicons/react/20/solid/HandRaisedIcon"
import Tippy from "@tippyjs/react";
import ModalDefault from "../../modal/modal-default";
import {classNames, getProp} from "../../../util/util-helpers";
import FieldText from "../../fields/field-text";
import FieldCheckbox from "../../fields/field-checkbox";
import {cloneDeep} from "../../../util/util-vanilla";

export default function TableOptionsDialog({
                                               translate,
                                               pagePath,
                                               show,
                                               columns,
                                               onClose,
                                               getDefaultTableColumnOptions,
                                               setTableColumnOptions,
                                               enableMultiColumnsStack
                                           }) {

    const IconButtonClass = "p-2 rounded-full text-tm-gray-400 hover:bg-tm-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-link";

    const dirty = true;

    const close = () => {
        onClose();
    }

    const [tableColumns, setTableColumns] = useState(cloneDeep(Object.values(columns)))

    useEffect(() => {
        setTableColumns(cloneDeep(Object.values(columns)));
    }, [columns, show]);

    const [itemDraggedIndex, setitemDraggedIndex] = useState(undefined)

    const handleSaveClick = () => {
        setTableColumnOptions(tableColumns)
    }

    const handleDragStart = (i) => {
        setitemDraggedIndex(i)
    }

    const handleDragEnd = () => {
        setitemDraggedIndex(undefined);

        handleShow(0);
    }

    const handleEnterColumn = (index, force) => {
        if (index === expandedColumn && !force) return null;

        let tableColumnsUpdate = cloneDeep(tableColumns);

        let draggedColumn = tableColumnsUpdate.splice(itemDraggedIndex, 1);
        tableColumnsUpdate.splice(index, 0, draggedColumn[0]);
        tableColumnsUpdate.map((it, i) => {
            it.order = i
            return it;
        })
        setTableColumns(tableColumnsUpdate);
        setitemDraggedIndex(index);
    }

    const [expandedColumn, setExpandedColumn] = useState(undefined);

    const handleExpandColumns = (index) => {
        if (index === expandedColumn) {
            setExpandedColumn(undefined);
        } else {
            setExpandedColumn(index);

        }
    }

    const handleReleaseSubColumn = (parentColumnIndex) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);
        let subColumns = tableColumnsUpdate[parentColumnIndex].subColumns;

        subColumns.forEach((it) => {
            let item = Object.keys(it)[0];
            let itemIndex = tableColumnsUpdate.findIndex(col => {

                return col.name === item
            });
            tableColumnsUpdate[itemIndex].inSubColumn = false;
            tableColumnsUpdate[itemIndex].show = true;
        });

        tableColumnsUpdate[parentColumnIndex].subColumns = [];

        setTableColumns(tableColumnsUpdate);
    }

    const handleAddSubColumn = (addedItemIndex) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        tableColumnsUpdate[addedItemIndex].inSubColumn = true;
        tableColumnsUpdate[addedItemIndex].show = false;

        if (!tableColumnsUpdate[expandedColumn].subColumns) {
            tableColumnsUpdate[expandedColumn].subColumns = [];
        }

        tableColumnsUpdate[expandedColumn].subColumns.push({[tableColumnsUpdate[addedItemIndex].name]: tableColumnsUpdate[addedItemIndex]});
        setTableColumns(tableColumnsUpdate);
        setExpandedColumn(undefined);
    }

    const mergeColumns = (firstColumnIndex, secondColumnIndex) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        tableColumnsUpdate[firstColumnIndex].merged.push(tableColumnsUpdate[secondColumnIndex]);
        tableColumnsUpdate[firstColumnIndex].label = translate("field.FullName");
        tableColumnsUpdate[firstColumnIndex].type = "full-name";
        tableColumnsUpdate[secondColumnIndex].show = false;
        tableColumnsUpdate[secondColumnIndex].isMerged = true;

        setTableColumns(tableColumnsUpdate);
    }

    const splitMergedColumns = (firstColumnIndex, secondColumnIndex) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        tableColumnsUpdate[firstColumnIndex].merged = [];
        tableColumnsUpdate[firstColumnIndex].label = translate("field.FirstName");
        tableColumnsUpdate[firstColumnIndex].type = "text";
        tableColumnsUpdate[secondColumnIndex].show = true;
        tableColumnsUpdate[secondColumnIndex].isMerged = false;

        setTableColumns(tableColumnsUpdate);
    }

    const restoreDefaultName = (index) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        const name = translate("field." + tableColumnsUpdate[index].name);

        tableColumnsUpdate[index].label = name;

        setLabel(name);
        setTableColumns(tableColumnsUpdate);
    }

    const handleColumnVisibilityClick = (index) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        if (!tableColumnsUpdate[index].showMobile) {
            tableColumnsUpdate[index].showMobile = true;
        } else {
            tableColumnsUpdate[index].showMobile = false;
        }

        setTableColumns(tableColumnsUpdate);
    }

    const toggleFreezeColumn = (index) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        tableColumnsUpdate[index].frozen = !tableColumnsUpdate[index].frozen;

        setTableColumns(tableColumnsUpdate);
    }

    const toggleShow = (index) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        tableColumnsUpdate[index].show = !tableColumnsUpdate[index].show;

        setTableColumns(tableColumnsUpdate);
    }

    const handleShow = (index) => {
        let tableColumnsUpdate = cloneDeep(tableColumns);

        tableColumnsUpdate[index].show = true;

        setTableColumns(tableColumnsUpdate);
    }

    const [editColumn, setEditColumn] = useState(undefined);
    const [label, setLabel] = useState(undefined);

    const toggleEditColumnName = (index) => {
        if (editColumn !== index) {
            setEditColumn(index);
            setLabel(tableColumns[index].label);
        } else {
            setEditColumn(undefined);
            setLabel(undefined);
        }
    }

    const updateColumnName = () => {
        let tableColumnsUpdate = cloneDeep(tableColumns);
        tableColumnsUpdate[editColumn].label = label;
        setTableColumns(tableColumnsUpdate);
        toggleEditColumnName(editColumn);
    }

    const restoreDefaultOptions = () => {
        let columns = getDefaultTableColumnOptions()?.columns;

        if (!columns) return;

        columns = Object.values(columns);

        setTableColumns(columns);
    }

    const handleMergeNameChange = () => {
        let nameIndex = 0;
        const FirstName = tableColumns.find((it, i) => {
            nameIndex = i;
            return it.name === "FirstName"
        });

        let lastNameIndex = 0;
        tableColumns.find((it, i) => {
            lastNameIndex = i;
            return it.name === "LastName"
        });

        if (FirstName.merged.length) {
            splitMergedColumns(nameIndex, lastNameIndex)
        } else {
            mergeColumns(nameIndex, lastNameIndex)
        }
    }

    const [defaultTableColumns, setDefaultTableColumns] = useState(Object.assign({}, Object.values(columns)))

    const undoLastChanges = () => {
        setTableColumns(defaultTableColumns);
    }

    useEffect(() => {
        setDefaultTableColumns(tableColumns);
    }, [show]);

    const options = () => {
        return (
            <div className="flex items-center space-x-4">
                <Tippy
                    content={translate("btn.undo")}
                    delay={[400, 0]}
                    trigger={"mouseenter"}
                >
                    <button
                        onClick={undoLastChanges}
                        className="icon-btn"
                    >
                        <ArrowUturnLeftIcon className="w-5 h-5"/>
                    </button>
                </Tippy>


                <Tippy
                    content={translate("btn.restore_to_defaults")}
                    delay={[400, 0]}
                    trigger={"mouseenter"}
                >
                    <button
                        onClick={restoreDefaultOptions}
                        className="icon-btn"
                    >
                        <ArrowPathIcon className="w-5 h-5"/>
                    </button>
                </Tippy>
            </div>
        )
    }

    const settingsList = tableColumns
        .sort((a, b) => a.order - b.order)
        .map((item, index) => {
            const isFirst = !index;
            const itemName = item.name;
            const itemLabel = item.label;
            const isColumnExpanded = index === expandedColumn;
            const isVisible = !!item.show;
            const subColumns = getProp(item, "subColumns", []);
            const isEdit = editColumn === index;
            const isEditMode = editColumn !== undefined;
            const isDraggable = !expandedColumn && editColumn === undefined;

            return (
                <div
                    key={item.name}
                    className={
                        classNames(
                            isVisible ? "" : "bg-tm-gray-100",
                            item.inSubColumn ? "hidden" : "",
                            itemDraggedIndex === index ? "opacity-25" : "",
                            "relative col-span-3"
                        )
                    }
                >
                    {itemDraggedIndex !== undefined && !isColumnExpanded && (
                        <div
                            onDragEnter={() => handleEnterColumn(index)}
                            onDrop={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                return false;
                            }}
                            onDragOver={e => e.preventDefault()}
                            className="absolute left-0 inset-0 z-10"
                        />
                    )}

                    <div
                        draggable={isDraggable}
                        onDragStart={() => handleDragStart(index)}
                        onDragEnd={() => handleDragEnd()}
                        onDragOver={e => e.preventDefault()}
                        className={
                            classNames(
                                isColumnExpanded ? "" : "rounded-b-md",
                                itemDraggedIndex === index ? "z-20" : undefined,
                                "relative border border-tm-gray-300 px-4 min-h-[3rem] flex items-center rounded-t-md"
                            )
                        }
                    >
                        <div className="flex items-center text-sm w-full">
                            {expandedColumn === undefined && !isEdit && (
                                <div
                                    className={
                                        classNames(
                                            !isDraggable ? "opacity-25" : "cursor-move",
                                            "flex items-center justify-center h-8 w-8 rounded-full"
                                        )}
                                >
                                    <EllipsisVerticalIcon className="w-5 h-5 text-tm-gray-700 -mr-3"/>
                                    <EllipsisVerticalIcon className="w-5 h-5 text-tm-gray-700"/>
                                </div>
                            )}

                            {!!isEdit && (
                                <div className="w-8">
                                    <button
                                        onClick={() => restoreDefaultName(index)}
                                        className={isColumnExpanded || !!subColumns.length ? "hidden" : "btn-table-action"}
                                    >
                                        <ArrowUturnLeftIcon className="w-5 h-5"/>
                                    </button>
                                </div>
                            )}

                            {expandedColumn !== undefined && (
                                <div className="w-8">
                                    <button
                                        onClick={() => handleAddSubColumn(index)}
                                        className={isColumnExpanded || !!subColumns.length ? "hidden" : "btn-table-action"}
                                    >
                                        <PlusSmallIcon className="w-5 h-5"/>
                                    </button>
                                </div>
                            )}

                            <span
                                className="ml-3 font-bold text-tm-gray-900 flex items-center w-full"
                            >
                            {!isEdit && (
                                itemLabel
                            )}

                                {isEdit && (
                                    <FieldText
                                        autoFocus={true}
                                        addClass="form-control"
                                        onChange={(name, value) => setLabel(value)}
                                        value={label ?? translate("field." + itemName)}
                                        name={itemName}
                                        placeholder={itemName}
                                    />
                                )}

                    </span>

                            <div className="flex ml-auto gap-x-3">
                                {!isEditMode && !expandedColumn && (
                                    <Tippy delay="1000" content={
                                        <div>
                                            <p className="font-bold">{translate("text.rename_column")}</p>
                                            <p>{translate("text.rename_column_desc")}</p>
                                        </div>}
                                    >
                                        <button
                                            onClick={() => toggleEditColumnName(index)}
                                            className={classNames(IconButtonClass, "ml-1")}
                                        >
                                            <PencilIcon className="w-5 h-5 text-tm-gray-400"/>
                                        </button>
                                    </Tippy>
                                )}

                                {isEdit && !expandedColumn && (
                                    <React.Fragment>
                                        <button
                                            onClick={updateColumnName}
                                            disabled={!label}
                                            className={classNames(IconButtonClass, "ml-1")}
                                        >
                                            <CheckIcon
                                                className={classNames(!label ? "text-tm-gray-500" : "text-green-600", "w-5 h-5")}/>
                                        </button>

                                        <div className="w-8">
                                            <button
                                                onClick={() => toggleEditColumnName(index)}
                                                className={IconButtonClass}
                                            >
                                                <XMarkIcon className="w-5 h-5 text-red-600"/>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )}

                                {!isEditMode && !(expandedColumn && !isColumnExpanded) && (
                                    <Tippy delay="1000" content={
                                        <div>
                                            <p className="font-bold">{translate("text.group_columns")}</p>
                                            <p>{translate("text.group_columns_desc")}</p>
                                        </div>}
                                    >
                                        <button
                                            onClick={() => !subColumns.length || enableMultiColumnsStack ? handleExpandColumns(index) : null}
                                            className={classNames(IconButtonClass, isColumnExpanded ? "bg-tm-gray-300" : "")}
                                        >
                                            {(!subColumns.length || !enableMultiColumnsStack) && (
                                                <ChevronDoubleDownIcon className={
                                                    classNames(
                                                        index === expandedColumn ? "rotate-180" : "",
                                                        subColumns.length ? "opacity-25" : "",
                                                        "w-5 h-5"
                                                    )
                                                }
                                                />
                                            )}

                                            {(!!subColumns.length && !!enableMultiColumnsStack) && (
                                                <PlusIcon className={
                                                    classNames(
                                                        index === expandedColumn ? "rotate-45" : "",
                                                        "w-5 h-5"
                                                    )
                                                }
                                                />
                                            )}
                                        </button>
                                    </Tippy>
                                )}

                                {!isEditMode && (
                                    <Tippy delay="1000" content={
                                        <div>
                                            <p className="font-bold">{translate("text.freeze_column")}</p>
                                            <p>{translate("text.freeze_column_desc")}</p>
                                        </div>}
                                    >
                                        <button
                                            onClick={() => toggleFreezeColumn(index)}
                                            className={classNames(IconButtonClass, item.frozen ? "bg-tm-gray-300" : "")}
                                        >
                                            {!item.frozen && (
                                                <HandRaisedIcon className={classNames("w-5 h-5")}/>
                                            )}

                                            {item.frozen && (
                                                <HandRaisedIconSolid className={classNames("w-5 h-5 text-primary")}/>
                                            )}
                                        </button>
                                    </Tippy>
                                )}

                                {!isEdit && (
                                    <button
                                        className={
                                            classNames(
                                                IconButtonClass,
                                                isFirst ? "invisible" : ""
                                            )
                                        }
                                        onClick={() => {
                                            !isFirst && handleColumnVisibilityClick(index)
                                        }
                                        }
                                    >
                                        {!!item.showMobile && (
                                            <DevicePhoneMobileIcon className="text-primary w-5 h-5"/>
                                        )}

                                        {!item.showMobile && (
                                            <ComputerDesktopIcon className="w-5 h-5"/>
                                        )}
                                    </button>
                                )}

                                {!isEditMode && (
                                    <div className={classNames(isFirst ? "invisible" : "", "flex items-center")}>
                                        <Tippy delay="1000" content={
                                            <div>
                                                <p className="font-bold">{translate("text.hide_column")}</p>
                                                <p>{translate("text.hide_column_desc")}</p>
                                            </div>}
                                        >
                                            <button
                                                onClick={() => isFirst ? null : toggleShow(index)}
                                                type="button"
                                                className={classNames(
                                                    "bg-tm-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-link"
                                                )}
                                                role="switch"
                                                aria-checked="false"
                                            >
                                                <span className="sr-only">Use setting</span>

                                                <span
                                                    className={classNames(
                                                        isVisible ? "translate-x-5" : "translate-x-0",
                                                        "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-tm-gray-50 shadow transform ring-0 transition ease-in-out duration-200"
                                                    )}>
                                                <span
                                                    className={classNames(
                                                        isVisible ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
                                                        " absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <EyeSlashIcon className="h-3 w-3 text-tm-gray-400"/>
                                                </span>

                                                <span
                                                    className={
                                                        classNames(
                                                            isVisible ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100",
                                                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                        )}
                                                    aria-hidden="true"
                                                >
                                                    <EyeIcon className="h-3 w-3 text-primary"/>
                                                </span>
                                            </span>
                                            </button>
                                        </Tippy>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {(isColumnExpanded || !!subColumns.length) && (
                        <div
                            className="relative py-2 z-20 min-h-[3rem] font-bold text-tm-gray-700 shadow-inner bg-tm-gray-100 w-full grid gap-2 grid-cols-6 border-x border-b border-tm-gray-300 overflow-hidden rounded-b-md"
                        >
                            {subColumns.map((it, SubColindex) => {
                                let currentSubColumn = Object.values(it)[0];
                                return (
                                    <div
                                        key={SubColindex}
                                        className="py-2 px-4 relative h-30 flex items-center gap-3 col-start-2 col-end-7 rounded-md bg-inverse border border-tm-gray-300">
                                        <div
                                            className="flex items-center justify-center h-8 w-8 rounded-full cursor-move"
                                        >
                                            <button
                                                onClick={() => handleReleaseSubColumn(index)}
                                                className="btn-table-action"
                                            >
                                                <ArrowDownIcon className="w-5 h-5 text-tm-gray-700"/>
                                            </button>
                                        </div>

                                        {currentSubColumn.label ?? translate("field." + currentSubColumn.name)}
                                    </div>
                                )
                            })}

                            {!subColumns.length && (
                                <React.Fragment>
                                    <p className="text-tm-gray-700 col-span-6 flex items-center justify-center">Select
                                        a column to merge with {translate("field." + item.name)}</p>
                                </React.Fragment>
                            )}
                        </div>
                    )}

                    {tableColumns[index].name === expandedColumn && (
                        <div
                            onDragEnter={() => handleEnterColumn(index, true)}
                            onDrop={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                return false;
                            }}
                            onDragOver={e => e.preventDefault()}
                            className={classNames(
                                itemDraggedIndex < index ? "-bottom-5" : undefined,
                                itemDraggedIndex > index ? "-top-5" : undefined,
                                "absolute w-full h-5"
                            )}
                        />
                    )}
                </div>
            )
        });

    const FirstName = tableColumns.find((it) => it.name === "FirstName");
    const mergeNameValue = !!FirstName?.merged?.length;

    return (
        <ModalDefault
            show={show}
            widthClass={"max-w-xl"}
            limitHeight={true}
            title={translate('page.title.' + pagePath) + " " + translate("modal_heading.table_column_settings")}

            onButtonClick={handleSaveClick}
            buttonLabel={translate("btn.save")}
            buttonDisabled={!dirty}

            closeButtonLabel={translate("btn.cancel")}
            onClose={close}

            options={options()}
        >
            <div className="p-8">
                <div className="relative bg-popover rounded-md -space-y-px grid grid-cols-3 gap-6">
                    {!!columns['FirstName'] && columns['LastName'] && (
                        <div className="col-span-full">
                            <label
                                className="mb-0 select-none py-3 px-4 inline-flex items-center cursor-pointer hover:bg-tm-gray-100 rounded-lg"
                                htmlFor="mergeName"
                            >
                                <FieldCheckbox
                                    id="mergeName"
                                    className={classNames("checkbox border-primary")}
                                    onChange={() => handleMergeNameChange(mergeNameValue, mergeNameValue)}
                                    value={mergeNameValue}
                                />


                                <span
                                    className="ml-2 flex flex-col text-sm font-semibold text-tm-gray-900"
                                >
                                        {translate("text.join_first_last_name")}
                                    </span>
                            </label>
                        </div>
                    )}

                    {settingsList}
                </div>
            </div>
        </ModalDefault>
    )
}
