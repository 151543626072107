import React, {useEffect, useState} from 'react'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {getResource, updateResource} from '../../../data/actions/resource'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, keyboardShortcuts, UPDATE_PERM} from '../../../common/util/util-consts'
import {checkPerm} from "../../../common/util/util-helpers";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {LoaderSmall} from "../../../common/components/loader";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Card from "../../../common/components/card";
import FieldText from "../../../common/components/fields/field-text";
import FieldSelectSearch from "../../../common/components/fields/field-select-search";
import {READ_PERM} from "../../../common/util/util-consts";

const CommandsTab = (props) => {
    const {translate, resource, dispatch} = props
    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.UserCommands
    }

    const modifiers = {
        Shift: 'Shift',
        Alt: 'Alt',
        Ctrl: 'Ctrl',
    }
    /** State
     ================================================================= */
    const [fields, setFields] = useState({})
    const [canSubmit, setCanSubmit] = useState(false)

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        handleSetFields()
    }, [props])

    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }

    const submit = () => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: FieldsManager.getFieldKeyValues(fields),
            resource: Resources.UserCommands,
            piggyResource: Resources.UserCommands,
            onPiggyCallback: handleEnterNewDataInLookup,
            errorMessage: true, successMessage: 'Commands updated successfully!'
        }))
    }

    const handleRestoreResource = () => {
        let commandValues = Object.keys(keyboardShortcuts).reduce((memo, it) => {
            memo[it] = keyboardShortcuts[it].shortcut
            return memo
        }, {})

        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, commandValues),
            resource: Resources.UserCommands,
            piggyResource: Resources.UserCommands,
            onPiggyCallback: handleEnterNewDataInLookup,
            errorMessage: true, successMessage: 'Commands restored successfully!',
        }))
        setCanSubmit(false)
    }

    /** UI Events
     ================================================================= */
    const handleEnterNewDataInLookup = (result) => {
        if (result && result.status === 0) {
            LocalStorage.set('quick_commands', result.data.list.CommandMap)
        }
    }

    const handleSetFields = () => {
        let data = LocalStorage.get('quick_commands')

        let fieldsTmp = Object.keys(data)
            .filter(it => {
                if (keyboardShortcuts[it]?.perm) {
                    console.log("CHEKCPERM", checkPerm(Resources.Load, CREATE_PERM))
                    console.log("TEST", keyboardShortcuts[it].perm, keyboardShortcuts[it].permType, getPermType(keyboardShortcuts[it].permType), checkPerm(Resources[keyboardShortcuts[it].perm], getPermType(keyboardShortcuts[it].permType)))
                    return checkPerm(Resources[keyboardShortcuts[it].perm], getPermType(keyboardShortcuts[it].permType))
                }

                return true;
            })
            .reduce((memo, it) => {
                memo[it] = new Field(it, data[it], ['empty'])
                return memo
            }, {})

        console.log("fieldsTmp", fieldsTmp)

        setFields(fieldsTmp)
    }

    const cancel = () => {
        handleSetFields()
        setCanSubmit(false)
    }

    const handleInputChange = (name, value, key) => {
        let hasNoErrors = true;
        let fieldsTmp = fields
        let fieldValue = fieldsTmp[name].value.split('+')

        if (key === 'modifier') fieldValue[0] = value
        if (key === 'key') fieldValue[1] = value.slice(-1).toUpperCase()

        fieldValue = fieldValue.join('+')

        fieldsTmp[name].errorMessage = "";

        if (
            fieldValue === "Ctrl+S" ||
            fieldValue === "Ctrl+C"
        ) {
            fieldsTmp[name].errorMessage = ['error.CommandIsReserved'];
        }

        if (fieldsTmp[name].value !== fieldValue) {
            if (!handleCheckForSameValue(fieldValue)) {
                fieldsTmp[name].errorMessage = ['error.CommandIsAlreadyTaken']
            }
        }

        fieldsTmp = FieldsManager.updateField(fieldsTmp, name, fieldValue)

        Object.values(fieldsTmp).forEach(it => {
            if (it.errorMessage) {
                hasNoErrors = false;
            }
        })

        setCanSubmit(hasNoErrors);
        setFields(fieldsTmp)
    }

    const handleCheckForSameValue = (value) => {
        let fieldsTmp = fields
        return !Object.keys(fieldsTmp).some(it => fieldsTmp[it].value === value)
    }

    const fieldsToHtml = Object.keys(fields).map(it => {
        let value = fields[it].value
        const modifier = value.split('+')[0]
        const key = value.split('+')[1]

        return (
            <>
                <div className="col-span-8">
                    <p className="leading-5">{translate(`text.${it}`)}</p>

                    <label className="form-group has-float-label mb-4">
                        <FieldSelectSearch
                            className="form-control"
                            values={modifiers}
                            onChange={(name, value) => handleInputChange(it, value, 'modifier')}
                            value={modifier}
                            placeholder={''} addClass={'form-control'}/>
                        <span>{translate('field.Modifier')} *</span>
                    </label>
                </div>

                <div className="col-span-4 pt-5">
                    <label className="form-group has-float-label mb-4">
                        <FieldText
                            autoFocus
                            addClass="form-control"
                            onChange={(name, value) => handleInputChange(it, value, 'key')}
                            translate={translate}
                            value={key}
                            errorMessage={fields[it].errorMessage}
                            placeholder={''}/>
                        <span>{translate('field.Key')}</span>
                    </label>
                </div>
            </>
        )
    })

    function getPermType(type) {
        switch (type) {
            case 'create':
                return CREATE_PERM;
            default:
                return READ_PERM;
        }
    }

    return (
        <React.Fragment>
            <Card addClass="max-w-sm mx-auto" bodyClass="pb-5 pt-6 px-6">
                <PageHeader
                    title={translate('text.Commands')}
                    buttonLabel={translate('btn.RestoreCommands')}
                    onButtonClick={() => handleRestoreResource()}
                    buttonHidden={!checkPerm(getResourceName(), CREATE_PERM)}
                />

                <div className="mt-6">
                    {resource.isLoading && (
                        <div className="m-5 text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!resource.isLoading && (
                        <div className="grid grid-cols-12 gap-4">
                            {fieldsToHtml}
                        </div>
                    )}
                </div>
            </Card>

            <PageFooter
                translate={translate}
                canSubmit={canSubmit}
                actionCancel={cancel}
                actionSubmit={checkPerm(Resources.UserCommands, UPDATE_PERM) && submit}
            />
        </React.Fragment>
    )
}

export default CommandsTab
