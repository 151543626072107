import React, {Component} from "react";
import {FieldsManager} from "../../../../data/services/fields";
import {classNames, getProp} from "../../../util/util-helpers";
import {fieldsToHtml} from "../../../util/util-fields";
import InfoBar from "../../info-paragraph/info-bar";
import ModalDefault from "../modal-default";
import ResourceTable from "../../resource-table";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";
import Pagination from "../../resource-table/table-components/pagination";
import NoRecords from "../../no-records-found/no-records";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import {ArrowPathIcon} from "@heroicons/react/24/outline";

export default class ResourceTableDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: this.props.offset ?? 0,
            limit: this.props.limit ?? 10,
            sort: this.props.sort ?? "ASC",
            sortBy: this.props.sortBy ?? "",
            page: this.props.page ?? 1,
            paginationButtonLimit: this.props.paginationButtonLimit ?? 5,
            fieldsFilter: Object.assign({}, this.props.fieldsFilter ?? {}, this.props.searchFields ?? {})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.show && prevProps.show !== this.props.show) {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        const requestData = {
            user: LocalStorage.get("user"),
            resource: this.props.resource,
            query: this.getQuery()
        }

        if (!this.props.alternateDialogResourceCall) {
            this.props.dispatch(getDialogResource(requestData))
        } else {
            this.props.dispatch(this.props.alternateDialogResourceCall(requestData))
        }
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => {
            this.fetchData();
        })
    };

    handleInputChange = (name, value) => {
        this.setState({
            fieldsFilter: FieldsManager.updateField(this.state.fieldsFilter, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        });
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        let query = {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit,
            page: this.state.page,
            ...FieldsManager.getFieldKeyValues(this.state.fieldsFilter),
            ...this.props.defaultQuery,
        }

        if (this.props.searchFields) {
            let searchFields = {};
            query = Object.keys(query).reduce((memo, key) => {
                if (!this.props.searchFields[key]) {
                    memo[key] = query[key];
                } else {
                    if (query[key]) {
                        searchFields[key] = query[key];
                    }
                }

                return memo;
            }, {});

            query.searchFields = JSON.stringify(searchFields)
        }

        if (this.props.id) {
            query.id = this.props.id;
        }

        if (this.props.storeKey) {
            LocalStorage.set(this.props.storeKey, query);
        }

        return query;
    }

    getTableData = () => {
        let data
        let count
        let isLoading = false

        if (this.props.dialogData) {
            data = this.props.dialogData
            count = this.props.dialogCount
        } else if (this.props.alternateDialogResource) {
            data = getProp(this.props, "alternateDialogResource.data.list", [])
            count = getProp(this.props, "alternateDialogResource.data.count", [])
            isLoading = getProp(this.props, "alternateDialogResource.isLoading", false);
        } else {
            data = getProp(this.props, "dialogResource.data.list", [])
            count = getProp(this.props, "dialogResource.data.count", 0)
            isLoading = getProp(this.props, "dialogResource.isLoading", false);
        }

        return {
            data,
            count,
            isLoading
        }
    }

    render() {
        const {
            hasViewPerm = true,
            hasEditPerm = true,
            hasDeletePerm = true,
            hasRestorePerm = true,
            translate,
            initialFocusRef,
            show,
            title,
            widthClass,
            fields,
            fieldsFilter,
            selects,
            defaultAction,
            onSelectRow,
            onSelectAllClick,
            selectedRows,
            tableKey,
            onView,
            onCreate,
            onDelete,
            onCustomActions,
            messages = [],
            onClose,
            closeButtonLabel,
            options,
            htmlBefore,
            htmlAfter,
            buttonLabel,
            buttonDisabled,
            onButtonClick,
            limitHeight,
            tableHeaderRight,
            hideDialogFooter,
            noRecordsText,
            noRecordsBtnLabel,
            noRecordsBtnClick,
            isFooterVisible = true,
            dataFilter,
            hasRefreshButton = false
        } = this.props;


        const {data, count, isLoading} = this.getTableData()
        const filters = fieldsToHtml(Object.values(Object.assign({}, this.state.fieldsFilter)), translate, this.handleInputChange, selects);

        const areFiltersActive = !!Object.values(this.state.fieldsFilter).find(it => it.name !== "query" && !!it.value);
        const currentQuery = this.state.fieldsFilter?.query?.value;
        const hasQuery = !!currentQuery;

        const messagesRender = messages.map((message, i) => {
            return (
                <div key={i} className="px-6 pt-3">
                    <InfoBar
                        type={message.messageType}
                        onClick={() => !!message.handler && message.handler(message)}
                        Icon={message.icon}
                    >
                        {message.text}

                        {message.content}
                    </InfoBar>
                </div>
            )
        })

        return (
            <React.Fragment>
                <ModalDefault
                    show={show}
                    widthClass={widthClass ?? "max-w-5xl"}
                    title={title}
                    initialFocusRef={initialFocusRef}
                    closeButtonLabel={closeButtonLabel ?? translate("btn.cancel")}
                    onClose={onClose}
                    buttonLabel={buttonLabel}
                    buttonDisabled={buttonDisabled}
                    onButtonClick={onButtonClick}
                    limitHeight={limitHeight}
                    hideDialogFooter={hideDialogFooter}
                >

                    {!!messages.length && messagesRender}

                    {htmlBefore}

                    {!!fieldsFilter && (
                        <div className="flex">
                            <div className="p-5 grid gap-4 grid-cols-6 md:grid-cols-12 z-30 relative grow">
                                {filters}

                                {tableHeaderRight}

                                {hasRefreshButton && (
                                    <div className={"p-5 col-start-6 md:col-start-12"}>
                                        <button
                                            className={
                                                classNames(
                                                    "btn btn-header z-10",
                                                )
                                            }
                                            onClick={this.fetchData}
                                        >
                                            <ArrowPathIcon className={
                                                classNames(
                                                    "w-5 h-5",
                                                    isLoading ? "animate-spin" : undefined,
                                                )
                                            }/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <ResourceTable
                        data={dataFilter
                            ? dataFilter(data)
                            : data
                        }
                        maxHeightClass={"max-h-[calc(100vh-26rem)]"}
                        fields={fields}
                        translate={translate}
                        isLoading={isLoading}

                        hasViewPerm={hasViewPerm}
                        hasEditPerm={hasEditPerm}
                        hasDeletePerm={hasDeletePerm}
                        hasRestorePerm={hasRestorePerm}

                        limit={this.state.limit}

                        options={options}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={defaultAction}

                        onSelectRow={onSelectRow}
                        onSelectAllClick={onSelectAllClick}
                        onSelectAllRows={onSelectAllClick} // for common table, different prop name
                        selectedRows={selectedRows}
                        tableKey={tableKey}

                        onView={onView}
                        onCreate={onCreate}
                        onDelete={onDelete}
                        actions={onCustomActions}


                    />

                    <TableCardFooter
                        show={!(!data.length && !isLoading) && isFooterVisible}
                    >
                        <Pagination
                            count={count}
                            hideRowsPerPage={true}
                            pageLimit={this.state.limit}
                            pageOffset={this.state.offset}
                            paginationPage={this.state.page}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        addClass="py-12"
                        show={data.length === 0 && !isLoading}
                        title={'No matching records found'}
                        text={!!noRecordsText && noRecordsText(currentQuery)}
                        btnLabel={!!noRecordsBtnLabel && noRecordsBtnLabel(currentQuery)}
                        canCreate={hasQuery && !areFiltersActive}
                        onBtnClick={() => noRecordsBtnClick(currentQuery)}
                    />

                    {(typeof htmlAfter === 'function') ? htmlAfter(this.state.fields, this.getQuery()) : htmlAfter}
                </ModalDefault>
            </React.Fragment>
        );
    }
}
