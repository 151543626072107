import React, { Component } from 'react'
import LocalStorage from '../../../util/localStorage'
import {
    BANK_ACCOUNT_TYPES,
    INDIVIDUAL_SOLE_PROPRIETOR,
    UPDATE_PERM
} from '../../../../util/util-constants'
import {checkPerm, getLookup, getProp} from '../../../util/util-helpers'
import Card from '../../../components/card'
import LoaderLarge from "../../loader/loader-large";
import Resources from "../../../../data/services/resources";
import { emailValid } from "../../../util/util-vanilla";
import InfoParagraph from "../../info-paragraph";
import PageHeader from "../../layout/layout-components/page/page-header";
import FieldSwitchLabel from "../../fields/field-switch/field-switch-label";
import {Field, FieldsManager} from "../../../../data/services/fields";
import PageFooter from "../../layout/layout-components/page/page-footer";
import {updateResource, getResource} from "../../../../data/actions/resource";
import {fieldsToHtml, fillFieldsFromData} from "../../../util/util-fields";
import CopyToClipboardButton from "../../buttons/copy-to-clipboard-button";

export default class CompaniesPaymentTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            paymentFields: this.getPaymentFields(),
            generalFields: this.getGeneralFields(),
            billingFields: this.getBillingFields(),
            canSubmit: false,
        }

        this.selects = {
            PreferredBillingMethodID: getLookup('PreferredBillingMethod'),
            BankAccountTypeID: BANK_ACCOUNT_TYPES,
            NetTermTypeID: getLookup('NetTermType'),
            PaymentNetTermTypeID: getLookup('NetTermType'),
            InvoiceSendEmails: {
                api: 'api/' + Resources.ContactsQuick,
                query: {},
                searchMap: (it) => ({
                    label: `${it.Email}`,
                    value: it.ContactID,
                })
            },
            FactoringOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: {offset: 0, limit: 50},
                searchMap: (item) => {
                    return ({
                        value: item.OrganizationID,
                        label: item.LegalName + " City: " + item.CityName + " State: " + getLookup('State')[item.StateID] + " Zip: " + item.PostalCode,
                        metadata: item
                    })
                }
            }
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.resource?.isLoading && (!this.props?.resource?.isLoading)) {
            this.setState({
                paymentFields: this.getPaymentFields(),
                generalFields: this.getGeneralFields(),
                billingFields: this.getBillingFields(),
                canSubmit: false,
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: {
                id: this.getID()
            },
        }))
    }

    onSubmit = () => {
        const paymentFields = FieldsManager.validateFields(this.state.paymentFields);
        const generalFields = FieldsManager.validateFields(this.state.generalFields);
        const billingFields = FieldsManager.validateFields(this.state.billingFields);

        if (
            FieldsManager.checkFieldsForErrors(paymentFields)
            && FieldsManager.checkFieldsForErrors(generalFields)
            && FieldsManager.checkFieldsForErrors(billingFields)
        ) {
            this.setState({canSubmit: false}, () => {
                this.props.canSwapTabsHandler(false);
                let InvoiceSendEmails = getProp(billingFields, "InvoiceSendEmails.value", []).map(it => it.label).filter(it => emailValid(it))
                let paymentFieldsList = FieldsManager.getFieldKeyValues(this.state.paymentFields);
                delete paymentFieldsList.AddressName
                delete paymentFieldsList.CityName
                delete paymentFieldsList.State
                delete paymentFieldsList.PostalCode
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, paymentFieldsList,
                        FieldsManager.getFieldKeyValues(this.state.generalFields),
                        FieldsManager.getFieldKeyValues(this.state.billingFields), {
                            id: this.getID(),
                            OrganizationPaymentID: getProp(this.props.resource, 'data.OrganizationPaymentID', ''),
                            InvoiceSendEmails: InvoiceSendEmails
                        }),
                    query: {
                        id: this.getID(),
                    },
                    errorMessage: true, successMessage: `Payment info updated`,
                    resource: this.getResource(),
                    piggyResource: this.getResource(),
                }))
            })
        } else {
            this.setState({
                paymentFields,
                generalFields,
                billingFields,
            })
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let vals = FieldsManager.updateField(this.state.paymentFields, name, value);

        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        if (name === "IsFactoringActive") {
            vals.FactoringOrganizationID.disabled = !value;
            vals.FactoringOrganizationID.validate = value ? ['empty'] : [''];
            if (!value) {
                vals.FactoringOrganizationID.value = null;
                vals.AddressName.value = null;
                vals.AddressName.type = "hidden";
            }

        }
        if (name === "FactoringOrganizationID") {
            vals.AddressName.value = value?.metadata?.AddressName;
            vals.CityName.value = value?.metadata?.CityName;
            vals.State.value = value?.metadata?.State;
            vals.PostalCode.value = value?.metadata?.PostalCode;
        }

        this.setState({
            canSubmit: true,
            paymentFields: vals
        })
    }

    handleGeneralInputChange = (name, value) => {
        let generalFields = this.state.generalFields;
        FieldsManager.updateField(generalFields, name, value)
        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        if (name === 'OrganizationTypeID') {
            const isProprietorship = Number(value) === INDIVIDUAL_SOLE_PROPRIETOR;
            generalFields.Send1099.value =  isProprietorship ? true : !!generalFields.Send1099.value;
            generalFields.Send1099.disabled = isProprietorship;
        }

        this.setState({
            canSubmit: true,
            generalFields: generalFields
        })
    }

    handleBillingInputChange = (name, value) => {
        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        this.setState({
            canSubmit: true,
            billingFields: FieldsManager.updateField(this.state.billingFields, name, value)
        })
    }

    handleFormCancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                paymentFields: this.getPaymentFields(),
                generalFields: this.getGeneralFields()
            }, () => this.props.canSwapTabsHandler(false))
        }
    }

    /** Fields/Data definitions
     ================================================================= */
    getPaymentFields = () => {
        let item = getProp(this.props, 'resource.data', {})

        item.AddressName = getProp(this.props, 'resource.data.FactoringOrganizationData.AddressName', null);
        item.CityName = getProp(this.props, 'resource.data.FactoringOrganizationData.CityName', null);
        item.State = getProp(this.props, 'resource.data.FactoringOrganizationData.State', null);
        item.PostalCode = getProp(this.props, 'resource.data.FactoringOrganizationData.PostalCode', null);

        let fieldTemplates = {
            AccountPayName: new Field("AccountPayName", '', [], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            BankPayName: new Field("BankPayName", '', [], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            RoutingPayNumber: new Field("RoutingPayNumber", '', [], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            AccountPayNumber: new Field("AccountPayNumber", '', [], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            BankAccountTypeID: new Field("BankAccountTypeID", '', [], false, 'select', {
                addContainerClass: 'col-span-full'
            }, {isClearable: true}),
            PaymentNetTermTypeID: new Field('PaymentNetTermTypeID', '', [''], false, 'select', {addContainerClass: 'col-span-full'}, {isClearable: true}),
            IsFactoringActive: new Field("IsFactoringActive", '', [], false, 'checkbox', {
                addContainerClass: 'col-span-full'
            }),
            FactoringOrganizationID: new Field("FactoringOrganizationID", '', item.IsFactoringActive ? ['empty'] : [''], !item.IsFactoringActive, 'select-search', {
                addContainerClass: 'col-span-full relative'
            }, {
                menuPlacement: 'top'
            }),
            AddressName: new Field("AddressName", '', [], true, 'hidden', {
                addContainerClass: 'col-span-full'
            }),
            CityName: new Field("CityName", '', [], true, 'hidden', {
                addContainerClass: 'col-span-full'
            }),
            State: new Field("State", getLookup('State')[item.StateID], [], true, 'hidden', {
                addContainerClass: 'col-span-full'
            }),
            PostalCode: new Field("PostalCode", getLookup('State')[item.StateID], [], true, 'hidden', {
                addContainerClass: 'col-span-full'
            })

        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getGeneralFields = () => {
        let item = getProp(this.props, 'resource.data', {})
        const isProprietorship = Number(item.OrganizationTypeID) === INDIVIDUAL_SOLE_PROPRIETOR;

        let fieldTemplates = {
            OrganizationTypeID: new Field("OrganizationTypeID", '', [], false, 'select', {
                addContainerClass: 'col-span-full'
            }),
            Send1099: new Field("Send1099", 0, [], isProprietorship, 'switch', {
                addContainerClass: "-mx-4 pr-4 col-span-full flex items-center hover:bg-sky-600/10 rounded-xl",
                htmlBefore: (it) =>
                    <FieldSwitchLabel
                        onClick={() => this.handleGeneralInputChange("Send1099", !it.value)}
                        label={this.props.translate("field.Send1099")}
                    />
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getBillingFields = () => {
        let info = getProp(this.props.resource, 'data', {})

        let emails = getProp(this.props.resource, 'data.InvoiceSendEmails', [])

        let fieldTemplates = {
            InvoiceSendEmails: new Field('InvoiceSendEmails', [], [''], false, 'creatable-select-search', {
                label: 'email_invoices',
                labelType: 'stack',
                addContainerClass: 'col-span-full',
                htmlAfterField: (it) => <div className="h-5 relative top-2 flex">
                    <CopyToClipboardButton
                        clipboardText={(it.value ? it.value : []).reduce((memo, it) => {
                            memo.push(it.label);
                            return memo;
                        }, []).join(", ")}
                        addClass="relative -top-2"
                        translate={this.props.translate}
                    />
                </div>
            }, {
                onCreateOption: (_, value) => this.handleCreateEmail(value),
                isMulti: true,
                formatCreateLabel: userInput => this.props.translate('text.add_email') + ' "' + userInput + '"'
            }),
            NetTermTypeID: new Field('NetTermTypeID', getProp(info, 'NetTermTypeID', ''), [''], false, 'select', {addContainerClass: "col-span-6"}),
            PreferredBillingMethodID: new Field("PreferredBillingMethodID", '', [], false, 'select', {
                addContainerClass: "col-span-6"
            }),
            PreferredBillingNotes: new Field("PreferredBillingNotes", '', [], false, 'textarea', {
                addContainerClass: 'col-span-full'
            }),
            IsReceivableFactoringActive: new Field("IsReceivableFactoringActive", '', [], false, 'checkbox', {
                addContainerClass: 'col-span-full'
            })
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, info);

        fieldTemplates.InvoiceSendEmails.value = emails.filter(it => !!it).reduce((memo, it) => {
            memo.push({label: it, value: it});
            return memo;
        }, []) ?? [];

        return fieldTemplates;
    }

    handleCreateEmail = (value) => {
        let billingFields = this.state.billingFields
        if (billingFields.InvoiceSendEmails.value == null) {
            billingFields.InvoiceSendEmails.value = []
        }
        this.handleBillingInputChange('InvoiceSendEmails', [...billingFields.InvoiceSendEmails.value, {
            value: value,
            label: value,
            invalid: !emailValid(value),
            manual: true
        }])
    }

    getResource = () => {
        return this.props.resourceName ?? "";
    }

    getID = () => {
        return this.props.OrganizationID ?? "";
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;
        const isLoading = getProp(resource, 'isLoading', false);

        const fieldsHtml = fieldsToHtml((Object.values(this.state.paymentFields)), translate, this.handleInputChange, this.selects);
        const generalFieldsHtml = fieldsToHtml((Object.values(this.state.generalFields)), translate, this.handleGeneralInputChange, this.selects);
        const billingFieldsHtml = fieldsToHtml((Object.values(this.state.billingFields)), translate, this.handleBillingInputChange, this.selects);

        return (
            <div className="max-w-xl flex flex-col 2xl:max-w-[1680px] mx-auto 2xl:grid gap-5 grid-cols-12">
                <Card addClass="relative col-span-3" addBodyClass="min-h-[10rem]">
                    <PageHeader
                        title={translate('page.heading.Payment')}
                        titleClass="text-2xl"
                    />

                    <div className="grid grid-cols-3 gap-5">
                        {fieldsHtml}

                        {!!this.state.paymentFields.AddressName.value && (
                            <div className={"col-span-full"}>
                                <InfoParagraph>
                                    <span>
                                        {this.state.paymentFields.AddressName.value}, {this.state.paymentFields.CityName.value}, {this.state.paymentFields.State.value} {this.state.paymentFields.PostalCode.value}
                                    </span>
                                </InfoParagraph>
                            </div>
                        )}

                    </div>

                    {isLoading && (
                        <LoaderLarge stripesBg/>
                    )}
                </Card>

                <Card addClass="relative col-span-6" bodyClass="pt-3 pb-8 px-6 space-y-4 min-h-[10rem]">
                    <PageHeader
                        title={translate('page.heading.Receivable')}
                        titleClass="text-2xl"
                    />

                    <div className="grid grid-cols-12 gap-5">
                        {billingFieldsHtml}
                    </div>

                    {isLoading && (
                        <LoaderLarge stripesBg/>
                    )}

                </Card>

                <Card addClass="relative col-span-3" bodyClass="pt-3 pb-8 px-6 space-y-4 min-h-[10rem]">
                    <PageHeader
                        title={translate('page.heading.companies.general')}
                        titleClass="text-2xl"
                    />

                    <div className="grid grid-cols-3 gap-5">
                        {generalFieldsHtml}
                    </div>

                    {isLoading && (
                        <LoaderLarge stripesBg/>
                    )}
                </Card>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.canSubmit}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(this.getResource(), UPDATE_PERM) && this.onSubmit}
                />
            </div>
        )
    }
}
