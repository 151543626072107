import PopOver from "../popover";
import React, {useState} from "react";
import {BellSlashIcon} from "@heroicons/react/24/outline";
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import {Field, FieldsManager} from "../../../data/services/fields";
import {createSecondResource} from "../../../data/actions/secondResource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../../data/services/resources";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../util/util-consts";
import {getUserTimeFormat} from "../../util/util-dates";
import ModalSaveResource from "../modal/modal-save-resource";
import LoadsViewStopUpdate from "../../../views/dispatch/loads-view/loads-view-stop-update";
import EventsTab from "../../../views/dispatch/load-view/load-sidebar/events-tab";
import FieldsToHtml from "../../../common/components/fields/fields-to-html"
import {fillFieldsFromData} from "../../util/util-fields";
import {getLookup} from "../../util/util-helpers";

export default function StopUpdate({show, loadData, onClose, stopUpdatePiggyResource, getQuery, piggyResource, translate}) {
    const dispatch = useDispatch();

    /** State
     ================================================================= */
    const [snoozeFields, setSnoozeFields] = useState(() => getSnoozeFields());
    const [isPingUpdateModalOpen, setIsPingUpdateModalOpen] = useState(false);
    const [postUpdatePingLocation, setPostUpdatePingLocation] = useState({});

    /** UI Events
     ================================================================= */
    function handleStopUpdateFieldsChange (fields, name, value) {
        let fieldsClone = Object.assign({}, fields);
        fieldsClone = FieldsManager.updateField(fieldsClone, name, value);

        fieldsClone.StopUpdateReminderInternal.validate = fieldsClone.IsStopUpdateReminderOn.value ? ['empty'] : [''];
        fieldsClone.StopUpdateReminderInternal.errors = "";

        return fieldsClone;
    }

    function handleSnoozeInputChange(name, value) {
        setSnoozeFields(FieldsManager.updateField(Object.assign({}, snoozeFields), name, value));
    }

    function handlePostponeClick (e) {
        e.preventDefault();

        if (snoozeFields.Snooze.value < 1 || snoozeFields.Snooze.value > 120) {
            let snoozeFieldsClone = Object.assign({}, snoozeFields);

            snoozeFieldsClone.Snooze.errorMessage = [translate("text.snooze_error")];
            setSnoozeFields(snoozeFieldsClone)
        } else {
            dispatch(createSecondResource({
                user: LocalStorage.get('user'),
                params: {
                    Snooze: snoozeFields.Snooze.value,
                    LoadID: loadData.LoadID
                },
                resource: Resources.LoadStopUpdate,
                query: getQuery(),
                piggyResource: stopUpdatePiggyResource,
                successMessage: translate("text.loads_stop_snooze_updated", [loadData.LoadID, snoozeFields.Snooze.value]),
                errorMessage: true
            }))

            onClose();
        }
    }

    /** Helpers
     ================================================================= */
    function getSnoozeFields () {
        return {
            Snooze: new Field('Snooze', "", ['empty'], false, 'number', {}, {autoFocus: true})
        }
    }


    function getStopUpdateFields (item = {}) {
        const fieldTemplates = {
            LoadID: new Field('LoadID', item, [''], false, 'hidden'),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'datetime'),
            DateTime: new Field('DateTime', moment().format(getUserTimeFormat()), ['empty'], false, 'time-custom',),
            LoadStopStatusID: new Field('LoadStopStatusID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            IsCustomerVisible: new Field('IsCustomerVisible', "0", [''], false, 'checkbox',
                {
                    addContainerClass: 'col-span-full',
                    labelType: "float",
                    note: translate("field.visible_on_customer_shared_load")
                }
            ),
            Notes: new Field('Notes', '', [''], false, 'textarea',
                {
                    addContainerClass: 'col-span-full',
                    htmlAfter: () => {
                        return <div className="col-span-full text-lg mt-4 leading-6 font-medium text-tm-gray-900">
                            Stop update reminder
                        </div>
                    }
                }
            ),
            LoadStopID: new Field('LoadStopID', "0", [''], false, 'hidden', 'hidden'),
            IsStopUpdateReminderOn: new Field('IsStopUpdateReminderOn', item?.IsStopUpdateReminderOn ? "1" : "0", [''], false, 'switch', {
                addContainerClass: "px-4 col-span-2 flex items-center hover:cursor-default rounded-xl",
                htmlAfter: () =>
                    <div
                        className="py-3 px-4 min-w-0 flex-1 text-sm text-left">
                        <label htmlFor="person-3"
                               className="font-medium text-tm-gray-900 select-none">
                            {translate("field.IsStopUpdateReminderOn")}
                        </label>

                        <p className="text-tm-gray-700">{translate("text.StopUpdateReminderInfo")}</p>
                    </div>
            }),
            StopUpdateReminderInternal: new Field(
                'StopUpdateReminderInternal',
                item?.StopUpdateReminderInternal,
                [item?.IsStopUpdateReminderOn ? 'empty' : ''],
                false,
                'integer',
                {label: "set_next_reminderin"}
            ),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    /** Render
     ================================================================= */
    return (
        <ModalSaveResource
            title={translate('text.post_stop_update')}
            widthClass="max-w-5xl"
            show={show}
            limitHeight={false}
            // customButtonsHTML={
            //     <button
            //         className="btn btn-outline"
            //         onClick={() => {
            //             if (this.state.postUpdatePingLocation.address) {
            //                 this.setState({
            //                     postUpdatePingLocation: {}
            //                 })
            //             } else {
            //                 this.handlePingUpdateClick();
            //             }
            //         }}
            //     >
            //         <MapPinIcon className="w-5 h-5 -ml-1 mr-1"/>
            //
            //         {this.state.postUpdatePingLocation.address ? "Remove ping update" : "Add ping update"}
            //     </button>
            // }

            gridColsClass="grid-cols-3"
            fields={getStopUpdateFields(loadData)}
            handleInputChange={handleStopUpdateFieldsChange}
            onClose={onClose}
            options={
                <PopOver
                    btnClass="btn btn-icon"
                    btnCustomHtml={
                        <React.Fragment>
                            <BellSlashIcon className='w-6 h-6 mr-1'/>
                            Postpone
                        </React.Fragment>
                    }
                    widthClass="max-w-sm"
                    positionClass="absolute translate-x-0 right-0"
                >
                    <div className="popover-card px-6 py-4">
                        <p className='text-base mb-4'>{translate('text.post_stop_update_snooze')}</p>
                        <form className="space-y-4" onSubmit={handlePostponeClick}>
                            <FieldsToHtml
                                fieldsState={snoozeFields}
                                onInputChange={handleSnoozeInputChange}
                                translate={translate}

                            />

                            <div className='flex justify-end'>
                                <button className='btn btn-primary'>
                                    {translate("btn.postpone")}
                                </button>
                            </div>
                        </form>
                    </div>
                </PopOver>
            }
            onSubmit={(params) => {
                if (params) {
                    delete params.DateTime

                    dispatch(createSecondResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: Resources.LoadStopUpdate,
                        piggyResource: piggyResource,
                        query: getQuery(),
                        successMessage: translate("text.loads_stop_updated", [loadData.LoadID]),
                        errorMessage: true
                    }))

                    onClose();
                }
            }}
            translate={translate}
            metadata={{
                LoadStopStatusID: getLookup('LoadStopStatus', 'LoadStopStatusID', 'LoadStopStatus')
            }}
            htmlAfter={
                <LoadsViewStopUpdate
                    selectedItem={loadData}
                    isPingUpdateModalOpen={isPingUpdateModalOpen}
                    setIsPingUpdateModalOpen={(bool) =>
                        setIsPingUpdateModalOpen(bool)
                    }
                    postUpdatePingLocation={postUpdatePingLocation}
                    setPostUpdatePingLocation={(postUpdatePingLocation) => {
                        setPostUpdatePingLocation(postUpdatePingLocation)
                    }}
                    translate={translate}
                />
            }
            htmlRight={
                <div className="w-96 border-l border-tm-gray-300 h-[calc(100vh-12rem)] overflow-auto">
                    {loadData.LoadID && (
                        <EventsTab
                            loadID={loadData.LoadID}
                            disableCreateEvent={true}
                            translate={translate}
                        />
                    )}
                </div>
            }
        />
    )
}