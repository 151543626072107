import React from "react";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import {classNames} from "../../util/util-helpers";
import PencilSquareIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import QuestionMarkCircleIcon from "@heroicons/react/24/outline/QuestionMarkCircleIcon";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export default function InfoParagraph({type, message, children, onClick, className, style}) {

    const getTypeClasses = () => {
        switch (type) {
            case "success":
                return "bg-green-600/10"
            case "danger":
                return "bg-red-600/10"
            case "warning":
                return "bg-yellow-600/10"
            case "note":
                return "bg-gray-600/10"
            default:
                return "bg-sky-600/10"
        }
    }

    const getTypeIconClasses = () => {
        switch (type) {
            case "success":
                return "bg-green-600 text-white"
            case "danger":
                return "bg-red-600 text-white"
            case "warning":
                return "bg-yellow-600 text-white"
            case "note":
                return "bg-gray-600 text-white"
            default:
                return "bg-sky-700 text-white"
        }
    }

    const getTypeIcon = () => {
        switch (type) {
            case "success":
                return <CheckCircleIcon className="h-6 w-6"/>
            case "danger":
                return <ExclamationTriangleIcon className="h-6 w-6"/>
            case "warning":
                return <InformationCircleIcon className="h-6 w-6"/>
            case "note":
                return <PencilSquareIcon className="h-6 w-6"/>
            case "help":
                return <QuestionMarkCircleIcon className="h-6 w-6"/>
            default:
                return <InformationCircleIcon className="h-6 w-6"/>
        }
    }

    return (
        <div
            onClick={() => onClick ? onClick() : null}
            className={"flex grow" + (onClick ? " cursor-pointer" : "")}
            style={style}
        >
            <div className={classNames(
                "text-tm-gray-900 flex items-center flex-grow rounded-input overflow-hidden",
                className ? className : undefined,
                getTypeClasses()
            )}
            >
                <div
                    className={classNames("px-2 py-1 min-h-[2.25rem] h-full flex items-center justify-center", getTypeIconClasses())}>
                    {getTypeIcon()}
                </div>

                <div className="pl-4 pr-6 py-2 text-current w-full">
                    {message}

                    {children}
                </div>
            </div>
        </div>
    )
}
