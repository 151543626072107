import React, { useEffect, useMemo, useRef, useState } from "react";
import NavResponsiveMenu from "./nav-responsive-menu";
import {classNames} from "../../util/util-helpers";
import { cloneDeep } from "../../util/util-vanilla";
import {LockClosedIcon} from "@heroicons/react/20/solid";

const NavResponsive = (props) => {
    const {tabs, onTabChange, translate, disabled} = props;
    const currentElementIndexRef = useRef(0);
    let allTabs = useMemo(() => cloneDeep(tabs)
        .filter(tab => tab.visible !== false)
        .map((tab, index) => {
            if (tab.current) {
                currentElementIndexRef.current = index;
            }
            return tab;
        }), [tabs]);

    const navRef = useRef(null);
    const navItemsRef = useRef({});

    const [lastTabVisible, setLastTabVisible] = useState(undefined);

    const [tabList, setTabList] = useState(allTabs);

    useEffect(() => {
        const navItems = allTabs.map((it, i) => {
            it.index = i;
            it.width = navItemsRef.current[i].offsetWidth;
            it.left = navItemsRef.current[i].offsetLeft;
            return it;
        });

        const navWidthObserver = new ResizeObserver(() => {
            window.requestAnimationFrame(() => {
                if (navRef.current) {
                    const navBarWidth = navRef?.current?.offsetWidth;
                    let lastNavItemIndex = navItems.find((it) => {
                        return (it.left + it.width) > navBarWidth
                    });

                    if (!lastNavItemIndex) {
                        lastNavItemIndex = allTabs.length;
                    } else {
                        lastNavItemIndex = lastNavItemIndex.index - 1;
                    }

                    if (currentElementIndexRef.current > lastNavItemIndex) {
                        lastNavItemIndex = navItems.find((it) => {
                            return (it.left + it.width) > (navBarWidth - navItems[currentElementIndexRef.current].width)
                        }).index - 1;
                    }

                    setLastTabVisible(lastNavItemIndex);
                    setTabList(navItems);
                }
            });
        });

        navWidthObserver.observe(navRef.current);
    }, [tabs, navItemsRef.current])

    return (
        <div className={classNames(props.addClass, "relative pr-10 border-b border-tm-gray-300 z-20")}>
            <div className="overflow-hidden">
                <nav
                    ref={navRef}
                    className="-mb-px flex"
                    aria-label="Tabs"
                >
                    {allTabs.map((tab, i) => {
                        return (
                            <button
                                ref={ref => navItemsRef.current[i] = ref}
                                key={tab.name}
                                onClick={() => onTabChange(tab.resource, tab)}
                                disabled={!!tab?.disabled || tab.hasPerm === false || disabled}
                                className={classNames(
                                    !!lastTabVisible && i > lastTabVisible ? "invisible" : undefined,
                                    tab.current
                                        ? 'border-primary text-primary'
                                        : 'border-transparent text-tm-gray-700 hover:text-tm-gray-900',
                                    'flex whitespace-nowrap py-2 px-4 border-b-2 font-semibold text-xs uppercase focus-visible:bg-tm-gray-200 rounded-tr-lg rounded-tl-lg disabled:text-tm-gray-400'
                                )}
                            >
                                {tab.labelRaw ?? translate("tab." + tab.name)}
                                {tab.count ? (
                                    <span
                                        className={classNames(
                                            tab.current ? 'bg-primary-100 text-primary-600' : 'bg-tm-gray-100 text-secondary-900',
                                            'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                  {tab.count}
                                </span>
                                ) : null}

                                {tab.hasPerm === false && (
                                    <LockClosedIcon className="w-4 h-4 ml-1 -mr-1 text-red-500 opacity-50" />
                                )}
                            </button>
                        )
                    })}

                    {!!navRef.current && !!lastTabVisible && currentElementIndexRef.current > lastTabVisible && (
                        <button
                            className="absolute whitespace-nowrap py-2 px-4 border-b-2 font-semibold text-xs uppercase focus-visible:bg-tm-gray-200 rounded-tr-lg rounded-tl-lg border-primary text-primary"
                            style={{left: ((tabList?.[lastTabVisible]?.left + tabList?.[lastTabVisible]?.width) ?? 0) + "px"}}
                        >
                            {translate("tab." + tabList[currentElementIndexRef.current].name)}
                        </button>
                    )}
                </nav>
            </div>

            {lastTabVisible <  allTabs.length && (
                <NavResponsiveMenu
                    allTabs={cloneDeep(allTabs)}
                    lastVisibleTab={lastTabVisible}
                    onTabChange={onTabChange}
                    translate={translate}
                />
            )}
        </div>
    )
}

export default NavResponsive;