import React from 'react';
import { classNames } from '../../../../common/util/util-helpers';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";

export default function LoadInfoStopUpdateReminder({innerRef, isSectionExpanded, toggleExpandSection, onInputChange, fields, translate}) {
    return (
        <div ref={innerRef} className={classNames(
            "grid grid-cols-12 px-6 border-t border-tm-gray-300 py-3"
        )}>
            <div className="col-span-full py-3 pr-5">
                <div className="flex items-center">
                    <button
                        className="btn btn-icon w-8 h-8 -ml-2 md mr-2"
                        onClick={() => toggleExpandSection("LoadInfoReminder")}
                    >
                        <ChevronRightIcon
                            className={
                                classNames(
                                    "w-5 h-5",
                                    isSectionExpanded ? "rotate-90" : undefined
                                )
                            }
                        />
                    </button>

                    <Subtitle
                        subtitle={translate("text.LoadInfoStopUpdateReminder")}
                    />

                </div>
            </div>

            {isSectionExpanded && (
                <React.Fragment>
                    <div className="col-span-5 py-3 pr-5">
                        <FieldsToHtml
                            fieldsState={fields}
                            includeFields={['IsStopUpdateReminderOn']}
                            onInputChange={onInputChange}
                            translate={translate}
                        />
                    </div>

                    <div className="pl-5 col-span-7 mt-3 space-y-2">
                        {!!fields.IsStopUpdateReminderOn.value && (
                            <div className="grid grid-cols-12 gap-4">
                                <FieldsToHtml
                                    fieldsState={fields}
                                    includeFields={['StopUpdateReminderInternal']}
                                    onInputChange={onInputChange}
                                    translate={translate}
                                />
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}
