import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    READ_PERM,
} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultTableOptions,
    getProp,
    mergeDeep,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import {cloneDeep} from "../../../common/util/util-vanilla";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import moment from "moment-timezone";
import {formatMoney} from "../../../common/util/util-formaters";
import ModalDefault from "../../../common/components/modal/modal-default";
import InvoiceExpenseDetailsDialog from "../dialogs/invoice-expense-details-dialog";
import {getThirdResource} from "../../../data/actions/thirdResource";

export default class InventoryReport extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: true
            },
            columns: {}
        }

        this.pagePath = "inventory";

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? '',

            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            selectedItem: null,
            createModalOpen: false,
            confirmModalOpen: false
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }


    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_inventory_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleToggleReportModalOpen = (selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            reportModalOpen: !this.state.reportModalOpen
        })
    }

    handleToggleViewInvoiceExpense(item = null) {
        this.setState({
            selectedInvoiceExpenseItem: item
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleInputChange = (name, value) => {
        let fields = this.state.id
        fields = FieldsManager.updateField(fields, name, value)

        this.setState({
            id: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.ProductServiceReport
    }

    getQuery = () => {
        return {
            group: 1,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getFields = () => {
        return {
            ProductService: new Field('ProductService', '', [''], false, 'text', {
                omitSort: true
            }),
            SKU: new Field('SKU', '', [''], false, 'text', {
                omitSort: true
            }),
            Qty: new Field('Qty', '', [''], false, 'text', {
                omitSort: true
            }),
            TotalTotalAmount: new Field('TotalTotalAmount', '', [''], false, 'money', {
                omitSort: true
            }),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: 'top'})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)
        const isLoading = getProp(this.props, 'resource.isLoading', false)

        return (
            <React.Fragment>
                <div className={'min-h-[2.25rem] flex flex-wrap max-w-full items-center'}>
                    <ActiveFilters
                        addClass={'flex-wrap'}
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleFilterClear}
                        translate={translate}
                    />

                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        forceAll={true}
                        largeGrid
                        forceDialog
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                                gridCols={"grid-cols-1"}
                            />
                        }
                        onRefreshTable={this.fetchData}
                    />

                    <ResourceTable
                        maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                        data={data}
                        commonTable={true}
                        fields={this.getFields()}
                        onView={(item) => {
                            this.handleToggleReportModalOpen(item)
                        }}

                        limit={50}
                        translate={translate}
                        isLoading={isLoading}

                        options={this.state.tableOptions}
                    />

                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />


                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <ResourceTableDialog
                    widthClass={'max-w-6xl'}
                    show={this.state.reportModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.ProductServiceReport}
                    title={'Inventory report'}
                    dispatch={this.props.dispatch}
                    isFooterVisible={true}
                    onView={(item) => {
                        this.handleToggleViewInvoiceExpense(item)
                    }}
                    hasViewPerm={checkPerm(Resources.ExpenseExpense, READ_PERM) && checkPerm(Resources.InvoicesInvoice, READ_PERM)}
                    defaultAction={(item) => {
                        if (item.Type === 1 && !checkPerm(Resources.ExpenseExpense, READ_PERM)) {
                            return false;
                        }

                        if (item.Type !== 1 && !checkPerm(Resources.InvoicesInvoice, READ_PERM)) {
                            return false;
                        }
                        this.handleToggleViewInvoiceExpense(item)
                    }}
                    limit={50}
                    fields={{
                        Date: new Field('Date', '', [], false, 'date', {
                            omitSort: true
                        }),
                        Description: new Field('Description', '', [''], false, 'textarea', {omitSort: true}),
                        Type: new Field('Type', '', [''], false, 'custom', {
                            render: (it) => {
                                return it.Type === 1 ? 'Bought' : 'Sold'
                            },
                            omitSort: true
                        }),
                        FromTo: new Field('FromTo', '', [''], false, 'custom', {
                            render: (it) => it.FromTo
                        }),
                        Amount: new Field('Amount', '', [''], false, 'money', {omitSort: true}),
                        Qty: new Field('Qty', '', [''], false, 'text', {omitSort: true}),
                        TaxID: new Field('TaxID', '', [''], false, 'select-search', {omitSort: true}),
                        TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {omitSort: true})
                    }}

                    fieldsFilter={{
                        StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                        EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                        OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4'}, {
                            isClearable: true
                        }),
                    }}

                    htmlBefore={(
                        <div className={"p-2 text-lg"}>
                            Current profit for the filtered records: {formatMoney(this.props.dialogResource?.data?.TotalTotalAmount ?? 0)}
                        </div>
                    )}

                    tableKey="Name"
                    defaultQuery={{id: this.state?.selectedItem?.ProductServiceID ?? null}}
                    onClose={this.handleToggleReportModalOpen}
                    translate={translate}
                    selects={{
                        OrganizationID: {
                            api: 'api/' + Resources.OrganizationsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.OrganizationID,
                                label: item.LegalName,
                                Organization: item
                            })
                        }
                    }}
                    htmlAfter={() => {

                    }}
                />

                <ModalDefault
                    show={!!this.state.selectedInvoiceExpenseItem}
                    widthClass={'max-w-5xl w-screen'}
                    title={"Details"}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleViewInvoiceExpense()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource?.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedInvoiceExpenseItem) {
                                this.props.dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [this.state.selectedInvoiceExpenseItem.Type === 1 ? "ExpenseID" : "InvoiceID"]: this.state.selectedInvoiceExpenseItem.ID
                                    },
                                    resource: this.state.selectedInvoiceExpenseItem.Type === 1 ? Resources.ExpenseExpense : Resources.InvoicesInvoice
                                }))
                            }
                        }}
                    />
                </ModalDefault>
            </React.Fragment>
        )
    }
}
