import React, {Component} from 'react'
import LocalStorage from '../../../util/localStorage'
import FileViewer from '../../../components/file-viewer/components/index'
import NoRecordsTable from '../../../components/no-records-found/no-records-table'
import Tippy from '@tippyjs/react'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import {
    ChatBubbleOvalLeftEllipsisIcon,
    CheckCircleIcon,
    CurrencyDollarIcon,
    DocumentTextIcon,
    ArrowDownTrayIcon,
    ArrowTopRightOnSquareIcon,
    InformationCircleIcon,
    LinkIcon,
    EnvelopeIcon
} from '@heroicons/react/24/outline'
import ResourceTable from '../../../components/resource-table'
import DataCard from '../../../components/display-data/data-card'
import {
    BookmarkIcon,
    BookOpenIcon,
    CalendarIcon,
    BanknotesIcon,
    InformationCircleIcon as InformationCircleSolidIcon,
    BuildingOfficeIcon,
    UserGroupIcon
} from '@heroicons/react/20/solid'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon'
import InfoParagraph from '../../../components/info-paragraph'
import {classNames, getProp, openInNewTab} from "../../../util/util-helpers";
import {getJWT} from "../../../util/util-auth";
import Resources from "../../../../data/services/resources";
import {isTodayDate, toFrontDateTime} from "../../../util/util-dates";
import {Field, FieldsManager} from "../../../../data/services/fields";
import CellButton from "../../resource-table/table-components/cell-button";
import {LoaderSmall} from "../../loader";
import TableCard from "../../resource-table/table-components/table-card";
import PageHeader from "../../layout/layout-components/page/page-header";
import ModalDefault from "../modal-default";
import ModalConfirm from "../modal-confirm";
import OrganizationInfoDialog from "../modal-global/organization-info-dialog";
import ModalSaveResource from "../modal-save-resource";
import ContactInfoDialog from "../../modal/contact-info-dialog"
import {createSecondResource, deleteSecondResource} from "../../../../data/actions/secondResource";
import Env from "../../../../util/env";
import {cloneDeep, genericMoneyFormatter} from "../../../util/util-vanilla";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../util/util-constants";
import ResourceTableDialogWithFilters from "../resource-table-dialog-with-filters";
import {createDialogResource} from "../../../../data/actions/dialogResource";
import CommentForm from '../../forms/CommentForm'
import {excludeFields, fieldsToHtml, includeFields} from "../../../util/util-fields";
import Subtitle from "../../layout/layout-components/page/subtitle";

export default class InvoiceExpenseDetailsDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmDelete: -1,

            //MODAL
            previewDocumentModal: false,
            confirmModalOpen: false,
            emailModalOpen: false,
            isIncome: getProp(this.props.resource, 'data', {}).InvoiceID,
            isExpense: getProp(this.props.resource, 'data', {}).ExpenseID,

            tabs: [
                {
                    name: 'INFO',
                    icon: InformationCircleIcon,
                    visible: true
                },
                {
                    name: 'PAYMENTS',
                    icon: CurrencyDollarIcon,
                    visible: true
                },
                {
                    name: 'REFERENCE_LINKS',
                    icon: LinkIcon,
                    visible: this.props.showLoadReferenceLinks
                },
                {
                    name: 'COMMENTS',
                    icon: ChatBubbleOvalLeftEllipsisIcon,
                    visible: !this.props.disableComments
                },
                {
                    name: 'ATTACHED_DOCUMENT',
                    icon: DocumentTextIcon,
                    visible: false
                },
            ],
            selectedTab: 'INFO',
            addReferenceDialogOpen: false,
            referenceDialogFields: this.getReferenceDialogFields(),
            confirmDialog: false,
            confirmModal: null,
            isImportTransactionsModalOpen: false,

            importAdditionalFields: this.getImportAdditionalFields(),
            selectedRows: {},
            selectedOrganizationContact: {},
            isOrganizationInfoDialogOpen: false,
            isContactInfoDialogOpen: false
        }
    }

    /** Lifecycles
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resource.data !== this.props.resource.data) {
            const item = getProp(this.props.resource, 'data', {})
            let tabs = this.state.tabs
            if (item.AttachPath) {
                tabs = tabs.map(it => {
                    if (it.name === 'ATTACHED_DOCUMENT') {
                        it.visible = true
                    }
                    return it
                })
            }
            this.setState({tabs})
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.onFetchData()
    }

    handleImportData = () => {
        let validatedFields = FieldsManager.validateFields(this.state.importAdditionalFields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            this.props.dispatch(createSecondResource({
                user: LocalStorage.get('user'),
                resource: Resources.ExpenseImport,
                params: {
                    ExpenseID: this.props.expenseID,
                    Items: Object.keys(this.state.selectedRows),
                    ...FieldsManager.getFieldKeyValues(this.state.importAdditionalFields)
                },
                piggyResource: Resources.ExpenseExpense,
                query: {
                    ExpenseID: this.props.expenseID
                },
                errorMessage: true, successMessage: 'Transaction imported'
            }))
            this.handleToggleImportTransactionsModal()
        } else {
            this.setState({importAdditionalFields: validatedFields});
        }
    }

    downloadDocument = () => {
        this.props.onDocumentDownload(getProp(this.props.resource, 'data', {}))
    }

    handleAddCommentClick = (comment) => {
        this.props.onCommentCreate(comment)
    }

    handleConfirmDeleteCommentClick = (id) => {
        this.setState({
            confirmDelete: -1
        }, () => {
            this.props.onCommentDelete(id)
        })
    }

    handleDeleteCommentClick = (index) => {
        this.setState({
            confirmDelete: index
        })
    }

    cancelConfirmDelete = () => {
        this.setState({
            confirmDelete: -1
        })
    }

    handleToggleConfirmDialog = (item) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleEmailModal = () => {
        this.setState({
            emailModalOpen: !this.state.emailModalOpen
        })
    }

    handleToggleConfirmReferenceDialog = (item) => {
        this.setState({
            modalConfirm: item?.ExpenseID ? () => {
                    return [this.props.dispatch(deleteSecondResource({
                        user: LocalStorage.get('user'),
                        resource: Resources.ExpenseReference,
                        query: {
                            ExpenseReferenceID: item?.ExpenseReferenceID
                        },
                        secondPiggyQuery: {ExpenseID: getProp(this.props.resource, 'data', {}).ExpenseID},
                        secondPiggyResource: Resources.ExpenseExpense,
                        errorMessage: true,
                        successMessage: this.props.translate('Reference deleted')
                    })), this.setState({confirmDialog: false})]
                }
                : () => {
                    return [this.props.dispatch(deleteSecondResource({
                        user: LocalStorage.get('user'),
                        resource: Resources.IncomeReference,
                        query: {
                            InvoiceReferenceID: item?.InvoiceReferenceID
                        },
                        secondPiggyQuery: {InvoiceID: getProp(this.props.resource, 'data', {}).InvoiceID},
                        secondPiggyResource: Resources.InvoicesInvoice,
                        errorMessage: true,
                        successMessage: this.props.translate('Reference deleted')
                    })), this.setState({confirmDialog: false})]
                },
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleToggleAddReferenceDialog = () => {
        this.setState({
            addReferenceDialogOpen: !this.state.addReferenceDialogOpen,
            referenceDialogFields: this.getReferenceDialogFields()
        })
    }

    handleTabChange = (selectedTab) => {
        this.setState({selectedTab: selectedTab})
    }

    addReference = (item) => {
        if (item.ExpenseID) {
            this.setState({referenceDialogFields: FieldsManager.validateFields(this.state.referenceDialogFields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.referenceDialogFields)) {
                    this.props.dispatch(createSecondResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.referenceDialogFields), {
                            Reference: this.state.referenceDialogFields.ReferenceID.value.label,
                            ExpenseID: item.ExpenseID
                        })
                        ,
                        query: {ExpenseID: getProp(this.props.resource, 'data', {}).ExpenseID},
                        resource: Resources.ExpenseReference,
                        piggyResource: Resources.ExpenseExpense,
                        errorMessage: true, successMessage: this.props.translate('Reference Added'),
                    }))
                    this.handleToggleAddReferenceDialog()
                }
            })
        }

        if (item.InvoiceID) {
            this.setState({referenceDialogFields: FieldsManager.validateFields(this.state.referenceDialogFields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.referenceDialogFields)) {
                    this.props.dispatch(createSecondResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.referenceDialogFields), {
                            Reference: this.state.referenceDialogFields.ReferenceID.value.label,
                            InvoiceID: item.InvoiceID
                        })
                        ,
                        query: {InvoiceID: getProp(this.props.resource, 'data', {}).InvoiceID},
                        resource: Resources.IncomeReference,
                        piggyResource: Resources.InvoicesInvoice,
                        errorMessage: true, successMessage: this.props.translate('Reference Added'),
                    }))
                    this.handleToggleAddReferenceDialog()
                }
            })
        }
    }

    handleToggleImportTransactionsModal = () => {
        this.setState({
            selectedRows: {},
            isImportTransactionsModalOpen: !this.state.isImportTransactionsModalOpen
        })
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleToggleContactInfoDialog = (item = {}) => {
        this.setState({
            selectedContact: item,
            isContactInfoDialogOpen: true
        })
    }

    /** UI Events
     ================================================================= */
    getCommentsHtml = (comments) => {
        return comments.map((comment, i) => {
            const isMe = comment.UpdatedByContactID == LocalStorage.get('user')?.Contact?.ContactID

            return (
                <div key={i} className="block hover:bg-tm-gray-50">
                    <div className="p-4">
                        <div className="flex justify-between items-start">
                            <div className="flex items-start">
                                <img className="h-12 w-12 rounded-full mr-5"
                                     src={comment.ImagePath ? Env.getApiUrl('api/' + Resources.ContactImage, {
                                         token: getJWT().access_token,
                                         ContactID: comment.UpdatedByContactID
                                     }) : '/images/avatar.png'} alt={comment.ImagePath}/>
                                <div>
                                    <p
                                        className="text-sm font-medium text-indigo-600 truncate">{comment.FirstName} {comment.LastName}</p>
                                    <p
                                        className=" flex items-center text-sm text-tm-gray-500">{isTodayDate(comment.CreateUpdateDate, this.props.translate)}</p>
                                </div>
                            </div>

                            <div>
                                {!!isMe && (
                                    <React.Fragment>
                                        {this.state.confirmDelete !== i && (
                                            <Tippy
                                                content={this.props.translate('btn.delete')}
                                            >
                                                <button
                                                    className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                                    onClick={() => {
                                                        this.handleDeleteCommentClick(i)
                                                    }}
                                                >
                                                    <TrashIcon className="h-4 w-4"/>
                                                </button>
                                            </Tippy>
                                        )}

                                        {this.state.confirmDelete === i && (
                                            <span className="py-1 px-2">
                                                {this.props.translate('text.are_you_sure')}
                                                <button type="button"
                                                        onClick={() => this.handleConfirmDeleteCommentClick(comment.InvoiceCommentID || comment.ExpenseCommentID)}
                                                        className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary">
                                                    {this.props.translate('btn.yes')}
                                                </button>
                                                <button type="button"
                                                        onClick={() => this.cancelConfirmDelete()}
                                                        className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-tm-gray-300 shadow-sm text-xs font-medium rounded text-tm-gray-700 bg-white hover:bg-tm-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-offset-inverse">
                                                    {this.props.translate('btn.cancel')}
                                                </button>
                                            </span>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>

                        <div className={'pl-12 pr-4 ml-5'}>
                            {comment.InvoiceCommentNote || comment.ExpenseCommentNote}
                        </div>
                    </div>
                </div>
            )
        })
    }

    getImportAdditionalFields = () => {
        return {
            Date: new Field('Date', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            AdjustIncome: new Field('AdjustIncome', 0, [''], false, 'money', {addContainerClass: 'col-span-6'})
        }
    }

    getReadonlyFields = (item = {}) => {
        return {
            Office: new Field('Office', '', [], false, 'text', {icon: () => BuildingOfficeIcon}),
            ContactGroup: new Field('ContactGroup', '', [], false, 'text', {icon: () => UserGroupIcon}),
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {icon: () => BookOpenIcon}),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {icon: () => BookmarkIcon}),
            ContactName: new Field('ContactName', '', [], false, 'text', {
                icon: () => UserGroupIcon,
                addContainerClass: 'col-span-2'
            }),
            Organization: new Field('Organization', '', [], false, 'text', {
                icon: () => UserGroupIcon,
                addContainerClass: 'col-span-2',
                fieldOptions: () => {
                    return (
                        <div>
                            <Tippy content={<span>Visit Organization Page</span>}>
                                <button
                                    className="btn btn-icon w-8 h-8 p-1 text-tm-gray-600 hover:text-tm-gray-900"
                                    onClick={() => {
                                        if (item?.Customer) {
                                            window.open(`/customers/info/${item.OrganizationID}/${item.Customer.CustomerID}`, '_blank')
                                        } else if (item?.Carrier) {
                                            window.open(`/carriers/info/${item.OrganizationID}/${item.Carrier.CarrierID}`, '_blank')
                                        } else if (item?.Vendor) {
                                            window.open(`/vendors/info/${item.OrganizationID}/${item.Vendor.VendorID}`, '_blank')
                                        }
                                    }}
                                >
                                    <ArrowTopRightOnSquareIcon
                                        className={'w-5 h-5'}/>
                                </button>

                            </Tippy>
                        </div>)
                }
            }),
            Amount: new Field('Amount', '', [], false, 'money', {icon: () => BanknotesIcon}),
            InvoiceStatus: new Field('InvoiceStatus', '', [], false, 'text', {icon: () => InformationCircleSolidIcon}),
            ExpenseStatus: new Field('ExpenseStatus', '', [], false, 'text', {icon: () => InformationCircleSolidIcon}),
            Date: new Field('Date', '', [], false, 'date', {icon: () => CalendarIcon}),
            // BookDate: new Field('BookDate', '', [], false, 'date', {icon: () => CalendarIcon}),
            DueDate: new Field('DueDate', '', [], false, 'date', {icon: () => CalendarIcon}),
            IsFactoringActive: new Field('IsFactoringActive', '', [], false, 'custom', {
                labelRaw: "",
                render: (item) => {

                    return (<div className={"inline"}>

                        {item.IsFactoringActive ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/> :
                            <XCircleIcon className="w-5 h-5 text-red-600 inline"/>}

                        {!!this.props.onFactoringToggle && (
                            <button
                                className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                onClick={() => {
                                    this.props.onFactoringToggle(getProp(this.props.resource, 'data', {}))
                                }}
                            >
                                {getProp(this.props.resource, 'data', {}).IsFactoringActive ? "Remove factoring" : "Mark for factoring"}
                            </button>
                        )}
                    </div>)
                },
                icon: () => BanknotesIcon
            }),
        }
    }

    getPaymentFields = () => {
        return {
            Date: new Field('Date', '', [], false, 'date'),
            Amount: new Field('Amount', '', [], false, 'money', {
                render: (item) => {
                    if (item.ReconciliationDate) {
                        return (
                            <Tippy content={"Reconciled: " + toFrontDateTime(item.ReconciliationDate)}>
                                <div className={"text-right"}><CheckCircleIcon
                                    className="w-5 h-5 text-green-600"/> {genericMoneyFormatter(item.Amount)}</div>
                            </Tippy>
                        )
                    }
                    return <div className={"text-right"}>{genericMoneyFormatter(item.Amount)}</div>
                }
            }),
            Account: new Field('Account', '', [], false, 'text'),
            PaymentType: new Field('PaymentType', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <span>
                            {item.PaymentType} {item.CheckNumber ? "(" + item.CheckNumber + ")" : ""}
                        </span>
                    )
                }
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search'),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
    }

    getAdjustmentsFields = () => {
        return {
            Date: new Field('Date', '', [], false, 'date'),
            Amount: new Field('Amount', '', [], false, 'money'),
            Account: new Field('Account', '', [], false, 'text'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search'),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
    }


    getEmailFields = () => {
        return {
            emails: new Field("emails", '', ['empty'], false, 'creatable', {addContainerClass: "col-span-full"}, {isMulti: true}),
            subject: new Field("subject", '', ['empty'], false, 'text', {addContainerClass: "col-span-full"}),
            emailMessage: new Field("emailMessage", '', [], false, 'rich-text', {addContainerClass: "col-span-full"})
        }
    }
    getLoadReferenceLinksFields = () => {
        return {
            ReferenceID: new Field('ReferenceID', '', [], false, 'custom', {
                render: (item) => {
                    return this.getReferenceCell(item)
                }
            }),
        }
    }

    getReferenceDialogFields = () => {
        return {
            ReferenceType: new Field('ReferenceType', '', ['empty'], false, 'select', {addContainerClass: 'col-span-6'}, {valueSort: true}),
            ReferenceID: new Field('ReferenceID', '', ['empty'], false, 'hidden', {addContainerClass: 'col-span-6'}),
        }
    }

    getLinesFields = () => {
        return {
            Description: new Field('Description', '', [], false, 'text'),
            Account: new Field('Account', '', [], false, 'text'),


            ChargeTo: new Field('ChargeTo', '', [], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => item.ChargeToOrganizationID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
                        <div>
                            {item.ChargeToContactID ? item.ChargeToContact : item.ChargeToOrganization}
                            {item?.IsPayed ?
                                <Tippy
                                    content={'Item is processed'}
                                >
                                    <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                                </Tippy> : null}
                        </div>
                    </CellButton>,
                label: this.props.type === 'expense' ? 'ChargeTo' : 'PayTo'
            }),


            Amount: new Field('Amount', '', [], false, 'custom', {
                render: (item) => <div className="text-right">{genericMoneyFormatter(item.Amount)}</div>
            }),
            Qty: new Field('Qty', '', [], false, 'integer'),
            Tax: new Field('Tax', '', [], false, 'custom', {
                render: (item) => (
                    <div>{item.Tax ?? '/'}</div>
                )
            }),
            TotalAmount: new Field('TotalAmount', '', [], false, 'float', {
                render: (item) => (
                    <div className="text-right">{genericMoneyFormatter(item.TotalAmount)}</div>
                )
            }),
        }
    }

    getReferenceLinks = (item) => {
        switch (item.ReferenceType) {
            case 'tbl_Load':
                openInNewTab(`/loads/info/${item.ReferenceID}`)
                break
            case 'tbl_Truck':
                openInNewTab(`/trucks/info/${item.ReferenceID}`)
                break
            case 'tbl_Trailer':
                openInNewTab(`/trailers/info/${item.ReferenceID}`)
                break
            case 'tbl_Driver':
                openInNewTab(`/drivers/info/${item.ReferenceID}`)
                break
            case 'tbl_Agent':
                openInNewTab(`/agents/info/${item.ReferenceID}`)
                break
            case 'tbl_Employee':
                openInNewTab(`/employees/info/${item.ReferenceID}`)
                break
            case 'tbl_FixedAsset':
                openInNewTab(`/accounting-assets/${item.ReferenceID}`)
                break
            case 'tbl_MileageTaxEntry':
                openInNewTab(`/fuel-report`)
                break
            case 'tbl_PurchaseOrder':
                openInNewTab(`/purchase-order/${item.ReferenceID}`)
                break
            case 'tbl_FuelPurchaseImportPreview':
                openInNewTab(`/fuel-purchase`)
                break
            case 'tbl_PayrollBatch':
                openInNewTab(`/driver-payroll`)
                break
            default:
        }
    }

    getReferenceCell = (reference) => {
        if (reference.ReferenceType === 'tbl_CarrierPay') {
            return <span>{this.props.translate('text.CarrierPay')}</span>
        }

        if (reference.ReferenceType === 'tbl_Load') {
            return <span>{this.props.translate('text.Load')}: {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_Truck') {
            return <span>{this.props.translate('text.Truck')}: {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_Trailer') {
            return <span>{this.props.translate('text.Trailer')}: {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_Driver') {
            return <span>{this.props.translate('text.Driver')}: {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_FixedAsset') {
            return <span>{this.props.translate('text.fixed_asset')}: {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_MileageTaxEntry') {
            return <span>{this.props.translate('text.fuel_report')} : {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_FuelPurchaseImportPreview') {
            return <span>{this.props.translate('text.fuel_import')} : {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_FuelPurchaseImportPreview') {
            return <span>{this.props.translate('text.fuel_import')} : {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_PurchaseOrder') {
            return <span>{this.props.translate('text.purchase_order')} : {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_Employee') {
            return <span>{this.props.translate('text.employee')} : {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_Agent') {
            return <span>{this.props.translate('text.agent')} : {reference.Reference}</span>
        }

        if (reference.ReferenceType === 'tbl_PayrollBatch') {
            return <span>{this.props.translate('text.payroll_batch')} : {reference.Reference}</span>
        }
    }

    handleLoadReferenceViewClick = (item) => {
        if (this.props.onLoadReferenceView) {
            this.props.onLoadReferenceView(item)
        } else {
            this.getReferenceLinks(item)
        }
    }

    handleInputChange = (name, value) => {
        let fields = this.state.referenceDialogFields
        if (name === 'ReferenceType') {
            fields.ReferenceID.type = value ? 'select-search' : 'hidden'
        }
        this.setState({referenceDialogFields: FieldsManager.updateField(fields, name, value)})
    }

    handleImportFieldsInputChange = (name, value) => {
        let fields = this.state.importAdditionalFields
        this.setState({importAdditionalFields: FieldsManager.updateField(fields, name, value)})
    }

    getReferenceID = () => {
        switch (this.state.referenceDialogFields.ReferenceType.value) {
            case 'tbl_Load':
                return {
                    api: 'api/' + Resources.LoadsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        label: item.LoadID,
                        value: item.LoadID
                    })
                }
            case 'tbl_Truck':
                return {
                    api: 'api/' + Resources.TrucksQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.TruckID,
                        label: item.Truck
                    })
                }
            case 'tbl_Trailer':
                return {
                    api: 'api/' + Resources.TrailersQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.TrailerID,
                        label: item.Trailer
                    })
                }
            case 'tbl_Driver':
                return {
                    api: 'api/' + Resources.DriversQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.DriverID,
                        label: item.FirstName + ' ' + item.LastName
                    })
                }
            case 'tbl_Employee':
                return {
                    api: 'api/' + Resources.EmployeesQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.EmployeeID,
                        label: item.FirstName + ' ' + item.LastName
                    })
                }
            case 'tbl_Agent':
                return {
                    api: 'api/' + Resources.AgentsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.AgentID,
                        label: item.FirstName + ' ' + item.LastName
                    })
                }
            default:
                return null
        }
    }

    handleSelectRowClick = (item) => {
        const itemID = item.MoneyCodeID
        let selectedRows = cloneDeep(this.state.selectedRows)

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedRows})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.dialogResource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.MoneyCodeID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.MoneyCodeID.toString())) {
                    delete selectedRows[it.MoneyCodeID]
                }
            })
        }

        this.setState({selectedRows})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, isLoading, canDeletePayment, type} = this.props

        const item = getProp(resource, 'data', {})
        const Lines = getProp(resource, 'data.Lines', [])
        const Payments = getProp(resource, 'data.Payments', []).filter(it => !!it.PaymentTypeID)
        const Adjustments = getProp(resource, 'data.Payments', []).filter(it => !it.PaymentTypeID)
        const Comments = getProp(resource, 'data.Comments', [])
        const LoadReferenceLinks = getProp(resource, 'data.References', [])

        const totalAmount = Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0)

        const additionalFieldsHtml = <div className={'grid grid-cols-12 gap-4 mx-4 my-4'}>
            {fieldsToHtml(Object.values(Object.assign({}, this.state.importAdditionalFields)), this.props.translate, this.handleImportFieldsInputChange)}
        </div>

        const selects = {
            ReferenceType: {
                tbl_Load: 'Load',
                tbl_Truck: 'Truck',
                tbl_Trailer: 'Trailer',
                tbl_Driver: 'Driver',
                tbl_Employee: 'Employee',
                tbl_Agent: 'Agent',
            },
            ReferenceID: this.getReferenceID()
        }
        const readonlyInfoFields = Object.values(includeFields(this.getReadonlyFields(item),
            type === 'expense' ? ['Office', 'ContactGroup', 'AutoCounter', 'RefNumber', 'Organization', 'ContactName', 'Amount', 'ExpenseStatus'] : ['Office', 'ContactGroup', 'AutoCounter', 'RefNumber', 'Organization', 'ContactName', 'Amount', 'InvoiceStatus']
        )).filter(it => item[it.name] !== undefined && it.type !== 'hidden')
        const readonlyDateFields = Object.values(includeFields(this.getReadonlyFields(item), ['Date', 'BookDate', 'DueDate'])).filter(it => item[it.name] !== undefined && it.type !== 'hidden')

        const referenceDialogFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.referenceDialogFields)), translate, (fields, name, value) => {
            this.handleInputChange(fields, name, value)
        }, selects)

        const readonlyFactoringFields = Object.values(includeFields(this.getReadonlyFields(item), ['IsFactoringActive'])).filter(it => item[it.name] !== undefined && it.type !== 'hidden')

        const tableOptions = {
            style: {
                condensed: true,
                horizontalLines: true,
                verticalLines: true,
            },
            classes: {
                headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                primaryTextClass: ''
            }
        }

        const tabs = this.state.tabs
            .filter((element) => element.visible)
            .map((element, index) => {
                return (
                    <li key={index} className="">
                        <button
                            className={classNames(
                                this.state.selectedTab === element.name ? 'bg-tm-gray-100' : 'hover:bg-tm-gray-50',
                                'group w-full flex pl-5 py-2 pr-4 text-sm font-medium rounded-r-md'
                            )}
                            onClick={() => this.handleTabChange(element.name)}
                        >
                            <element.icon
                                className={
                                    classNames(
                                        (this.state.selectedTab === element.name ? 'text-tm-gray-900' : 'text-tm-gray-700'),
                                        'flex-shrink-0 h-6 w-6 opacity-70'
                                    )
                                }
                            />

                            <span
                                className={classNames(this.state.selectedTab === element.name ? 'text-tm-gray-900' : 'text-tm-gray-700', 'ml-4 text-left pt-0.5')}>
                            {translate('tab.' + element.name)}
                                {element.name === 'COMMENTS' && (
                                    <span
                                        className="ml-3 bg-inverse inline-flex items-center px-2.5 py-0 rounded-md text-xs border uppercase font-normal bg-green-100 text-green-800">
                                    {Comments.length}
                                </span>
                                )}
                        </span>
                        </button>
                    </li>
                )
            })

        return (
            <div className={''}>
                <div className="grid grid-cols-5">
                    <div className="pr-4 py-5 col-span-1">
                        <ul>
                            {tabs}
                        </ul>
                    </div>
                    <div
                        className="border-l border-tm-gray-300 px-4 py-5 col-span-4 max-h-[calc(100vh-20rem)] overflow-y-scroll text-tm-gray-900">
                        {isLoading && (
                            <div className="p-5 text-center">
                                <LoaderSmall/>
                            </div>
                        )}
                        {this.state.selectedTab === 'INFO' && !isLoading && (
                            <div>
                                {(this.props.type === "expense") && !this.props.disableTransactionImport && (
                                    <div className="float-right">
                                        <Tippy content={translate('btn.import')}>
                                            <button
                                                className="btn-icon"
                                                onClick={this.handleToggleImportTransactionsModal}
                                            >
                                                <ArrowDownTrayIcon className="w-5 h-5"/>
                                            </button>
                                        </Tippy>
                                    </div>
                                )}
                                <div className="max-w-lg mx-auto">
                                    <p className="font-bold leading-5 mb-2">Info</p>
                                    <div
                                        className="grid grid-cols-2 bg-inverse rounded-xl border-tm-gray-300 border shadow-card mb-6">
                                        {readonlyInfoFields.map((field, i) => {
                                            return (
                                                <DataCard
                                                    key={field.name}
                                                    displayField={field}
                                                    fieldsData={item}
                                                    className={classNames('px-3 py-1.5 flex items-center px-6', i < readonlyInfoFields.length - (i % 2 ? 1 : 2) ? 'border-b border-tm-gray-300' : undefined)}
                                                    translate={translate}
                                                />
                                            )
                                        })}
                                    </div>

                                    <p className="font-bold leading-5 mb-2">Dates</p>
                                    <div
                                        className="grid grid-cols-2 bg-inverse rounded-xl border-tm-gray-300 border shadow-card mb-6">
                                        {readonlyDateFields.map((field, i) => {
                                            return (
                                                <DataCard
                                                    key={field.name}
                                                    displayField={field}
                                                    fieldsData={item}
                                                    className={classNames('px-3 py-1.5 flex items-center px-6', i < readonlyDateFields.length - (i % 2 ? 1 : 2) ? 'border-b border-tm-gray-300' : undefined)}
                                                    translate={translate}
                                                />
                                            )
                                        })}
                                    </div>

                                    {(this.props.type === "invoice") && (
                                        <>
                                            <p className="font-bold leading-5 mb-2">Factoring</p>
                                            <div
                                                className="grid grid-cols-2 bg-inverse rounded-xl border-tm-gray-300 border shadow-card mb-6">
                                                {readonlyFactoringFields.map((field, i) => {
                                                    return (
                                                        <DataCard
                                                            key={field.name}
                                                            displayField={field}
                                                            fieldsData={item}
                                                            className={classNames('px-3 py-1.5 flex items-center px-6', i < readonlyFactoringFields.length - (i % 2 ? 1 : 2) ? 'border-b border-tm-gray-300' : undefined)}
                                                            translate={translate}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </>)}


                                </div>

                                <Subtitle
                                    className="mb-4"
                                    subtitle={translate('text.items')}
                                />

                                <TableCard>
                                    <ResourceTable
                                        data={Lines}
                                        commonTable={true}
                                        fields={excludeFields(this.getLinesFields(), type === 'expense' ? [] : [])}
                                        translate={translate}
                                        isLoading={isLoading}
                                        options={tableOptions}
                                    />

                                    <NoRecordsTable
                                        show={(Lines.length === 0) && !isLoading}
                                        canCreate={false}
                                        title={translate('text.no_matching_records')}
                                    />
                                </TableCard>
                            </div>
                        )}

                        <div className={!isLoading && this.state.selectedTab === 'PAYMENTS' ? '' : 'hidden'}>
                            <Subtitle
                                className="mb-4"
                                subtitle={translate('text.payments')}
                            />

                            {((Payments.length > 0) && !isLoading && !!item.IsDelayedPayment) && (
                                <div className={"py-3"}>
                                    <InfoParagraph>
                                        {translate("text.delayed_payment_delete")}
                                    </InfoParagraph>
                                </div>
                            )}

                            <TableCard>
                                <ResourceTable
                                    data={Payments}
                                    fields={this.getPaymentFields()}
                                    translate={translate}
                                    isLoading={isLoading}

                                    actions={[
                                        {
                                            action: canDeletePayment ? this.handleToggleConfirmDialog : null,
                                            icon: TrashIcon, // make this a function
                                            visible: (it) => {
                                                if (!!item.IsDelayedPayment && (it.SendPaymentID != Payments[Payments.length - 1].SendPaymentID)) {
                                                    return null
                                                }
                                                return !!canDeletePayment
                                                    && !it.ReconciliationDate
                                                    && !it.SendBatchPaymentID
                                                    && !it.RecvBatchPaymentID
                                            },
                                            label: false, // make this a function
                                            title: translate('btn.delete'),
                                            disabled: false,
                                            class: false,
                                            iconClass: false
                                        }
                                    ]}
                                    options={tableOptions}
                                />

                                <NoRecordsTable
                                    show={(Payments.length === 0) && !isLoading}
                                    canCreate={false}
                                    title={translate('text.no_matching_records')}
                                />
                            </TableCard>

                            {Adjustments.length > 0 && (
                                <>
                                    <Subtitle
                                        className="mb-4 mt-4"
                                        subtitle={translate('text.adjustments')}
                                    />

                                    <TableCard>
                                        <ResourceTable
                                            data={Adjustments}
                                            fields={this.getAdjustmentsFields()}
                                            translate={translate}
                                            isLoading={isLoading}

                                            onDelete={canDeletePayment ? this.handleToggleConfirmDialog : null}
                                            options={tableOptions}
                                        />

                                    </TableCard>
                                </>
                            )}
                        </div>

                        <div className={!isLoading && this.state.selectedTab === 'REFERENCE_LINKS' ? '' : 'hidden'}>
                            <PageHeader
                                titleClass={'text-medium mb-4'}
                                title={translate('text.ref_links')}
                                buttonLabel={translate('btn.create_new')}
                                onButtonClick={() => this.handleToggleAddReferenceDialog()}
                                buttonHidden={this.props.disableAddReference}
                            />

                            <TableCard addClass="rouded-card overflow-hidden">
                                <ResourceTable
                                    data={LoadReferenceLinks}
                                    fields={this.getLoadReferenceLinksFields()}
                                    translate={translate}
                                    isLoading={isLoading}
                                    onRowClick={this.handleLoadReferenceViewClick}
                                    onView={this.handleLoadReferenceViewClick}
                                    options={tableOptions}

                                    actions={[
                                        {
                                            action: this.handleToggleConfirmReferenceDialog,
                                            icon: TrashIcon,
                                            visible: (item) => (item.ReferenceType !== 'tbl_Load') && (item.ReferenceType !== 'tbl_CarrierPay'),
                                            title: translate('btn.delete'),
                                        }
                                    ]
                                    }
                                />

                                <NoRecordsTable
                                    show={(LoadReferenceLinks.length === 0) && !isLoading}
                                    canCreate={false}
                                    title={translate('text.no_matching_records')}
                                />
                            </TableCard>

                            <ModalDefault
                                show={!!this.state.addReferenceDialogOpen}
                                title={translate('preview')}
                                widthClass={'max-w-xl'}
                                translate={translate}
                                onClose={() => this.handleToggleAddReferenceDialog()}
                                closeButtonLabel={translate('btn.cancel')}
                                buttonLabel={translate('btn.save')}
                                onButtonClick={() => {
                                    this.addReference(item)
                                }}
                            >
                                <div className={'grid gap-4 grid-cols-6 m-5'}
                                     key={this.state.referenceDialogFields.ReferenceType.value}>
                                    {referenceDialogFieldsHtml}
                                </div>
                            </ModalDefault>

                            <ModalConfirm
                                title={'Confirm'}
                                show={!!this.state.confirmDialog}
                                text={translate('text.ConfirmDelete')}
                                onClose={() => this.handleToggleConfirmReferenceDialog()}
                                buttonLabel={translate('btn.confirm')}
                                closeButtonLabel={'Cancel'}
                                translate={translate}
                                onConfirm={this.state.modalConfirm}
                            />
                        </div>

                        <ResourceTableDialogWithFilters
                            show={this.state.isImportTransactionsModalOpen}

                            dialogResource={this.props.dialogResource}
                            resource={Resources.MoneyCodes}
                            title={translate("text.import_money_code_transactions")}
                            dispatch={this.props.dispatch}

                            options={{
                                behaviour: {
                                    rowSelect: true,
                                },
                            }}

                            selectedRows={this.state.selectedRows}
                            onSelectRow={this.handleSelectRowClick}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableKey={'MoneyCodeID'}

                            onQueryTransform={(params) => {
                                if (!params.offset) {
                                    params.offset = 0;
                                }
                                return {
                                    ...params,
                                    NotPaid: 1,
                                    searchFields: JSON.stringify({
                                        ...(!!params?.StartDate && {StartDate: ['Date', '>=', params?.StartDate]}),
                                        ...(!!params?.EndDate && {EndDate: ['Date', '<=', params?.EndDate]}),
                                        ContactID: params?.IssuedToID,
                                        LoadID: params?.LoadID,
                                        MoneyCodeTypeID: params?.MoneyCodeTypeID
                                    })
                                }
                            }}
                            fields={{
                                Code: new Field('Code', '', [], false, 'text'),
                                ContactID: new Field('ContactID', '', [], false, 'select-search'),
                                Date: new Field('Date', '', [], false, 'datetimezone'),
                                Amount: new Field('Amount', '', [], false, 'money'),
                                Description: new Field('Description', '', [], false, 'textarea', {label: 'notes'}),
                                MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', [], false, 'select-search'),
                                LoadID: new Field('LoadID', '', [], false, 'select-search'),
                                /*IsPayed: new Field('IsPayed', '', [], false, 'checkbox', { label: 'Processed' }),*/
                                IsVoided: new Field('IsVoided', '', [], false, 'checkbox'),
                                IntegrationType: new Field('IntegrationType', '', [], false, 'text')
                            }}

                            fieldsFilter={{
                                query: new Field('query', '', [''], false, 'search', {
                                    addContainerClass: "col-span-2",
                                    hideLabel: true,
                                    labelType: "float",
                                    autofocus: true,
                                }),
                                StartDate: new Field('StartDate', '', [''], false, 'date', {}, {
                                    isClearable: true
                                }),
                                EndDate: new Field('EndDate', '', [''], false, 'date', {}, {
                                    isClearable: true
                                }),
                                IssuedToID: new Field('IssuedToID', '', [''], false, 'select-search', {}, {
                                    isClearable: true
                                }),
                                LoadID: new Field('LoadID', '', [''], false, 'select-search', {}, {
                                    isClearable: true
                                }),
                                MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', [''], false, 'select-search', {}, {
                                    isClearable: true,
                                }),
                                offset: new Field('offset', 0, [''], false, 'hidden'),
                                limit: new Field("limit", 10, [''], false, 'select', {
                                    hideLabel: true,
                                    labelType: "float"
                                }),
                                sort: new Field('sort', "ASC", [''], false, 'hidden'),
                                sortBy: new Field('sortBy', "", [''], false, 'hidden'),
                            }}

                            selects={{
                                IssuedToID: {
                                    api: 'api/' + Resources.ContactsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        value: item.ContactID,
                                        label: item.FirstName + ' ' + item.LastName
                                    })
                                },
                                MoneyCodeTypeID: {
                                    api: 'api/' + Resources.MoneyCodeType,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        value: item.MoneyCodeTypeID,
                                        label: item.Reason
                                    })
                                },
                                LoadID: {
                                    api: 'api/' + Resources.LoadsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (it) => ({
                                        label: it.LoadNumber,
                                        value: it.LoadID
                                    })
                                }
                            }}

                            onClose={() => this.setState({
                                isImportTransactionsModalOpen: false
                            })}

                            htmlBefore={additionalFieldsHtml}

                            htmlAfter={Object.keys(this.state.selectedRows).length ? (
                                <div className="px-0 py-1 border-y border-tm-gray-200  flex items-center">
                                    <div className="mr-4 flex space-x-4 h-9">
                                    </div>
                                    <div className="text-sm flex justify-between w-full">
                                        <div>
                                        <span
                                            className="font-bold text-tm-gray-700">{Object.keys(this.state.selectedRows).length}</span> entries
                                            selected
                                        </div>

                                        <div className="mr-4">
                                            Total amount for selected entries:
                                            <span className="ml-2 font-bold text-tm-gray-700">
                                                {genericMoneyFormatter(totalAmount)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            hasDialogFooter={true}
                            footerButtonLabel={translate('btn.import')}
                            onFooterButtonClick={this.handleImportData}
                            footerButtonDisabled={!Object.keys(this.state.selectedRows).length}
                            footerCloseButtonLabel={'Close'}
                            onFooterCloseButtonClick={this.handleToggleImportTransactionsModal}

                            translate={translate}
                        />

                        <div className={!isLoading && this.state.selectedTab === 'COMMENTS' ? '' : 'hidden'}>
                            <div className={"flex justify-between"}>
                                <Subtitle
                                    className="mb-4"
                                    subtitle={translate('text.comments')}
                                />
                                <button
                                    className={"mb-4"}
                                    onClick={() => item.OrganizationID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
                                    <InformationCircleIcon className={"w-5 h-5 text-blue-500"}/>
                                </button>
                            </div>

                            {(Comments.length === 0) && (
                                <div
                                    className={'mb-3 mt-1 text-tm-gray-700'}>{this.props.translate('text.no_comments')}</div>)}

                            {(Comments.length > 0) && this.getCommentsHtml(Comments)}

                            <CommentForm
                                style={{width: '100%'}}
                                maxLength={8000}
                                rows={3}
                                required
                                id={(item.InvoiceID || item.ExpenseID)}
                                fieldName={this.props.translate('field.Comment') + ' *'}
                                translate={this.props.translate}
                                onAddComment={(_, value) => this.handleAddCommentClick(value)}
                            />
                        </div>

                        {this.state.selectedTab === 'ATTACHED_DOCUMENT' && !isLoading && (
                            <div>
                                <Subtitle
                                    className="mb-4 flex justify-between"
                                    subtitle={translate('text.documents')}
                                >
                                    {this.props.onDocumentDownload && (
                                        <Tippy content={translate('text.download_document')}>
                                            <button
                                                className="btn-icon ml-auto"
                                                onClick={this.downloadDocument}
                                            >
                                                <DocumentArrowDownIcon className="w-5 h-5"/>
                                            </button>
                                        </Tippy>
                                    )}

                                    {this.props.type === 'expense' && (
                                        <Tippy content={translate('text.send_document_via_email')}>
                                            <button
                                                className="btn-icon justify-items-end"
                                                onClick={this.handleToggleEmailModal}
                                            >
                                                <EnvelopeIcon className="w-5 h-5"/>
                                            </button>
                                        </Tippy>
                                    )}
                                </Subtitle>

                                <FileViewer
                                    fileType={item.AttachPath.split('.').pop()}
                                    filePath={
                                        Env.getApiUrl('api/' + (item.InvoiceID ? Resources.InvoicesDocuments : Resources.ExpenseDocuments), Object.assign({}, {
                                            id: (item.InvoiceID || item.ExpenseID),
                                            token: getJWT().access_token,
                                        }))}
                                    onError={(e) => {
                                        console.log(e)
                                    }}/>
                            </div>
                        )}
                    </div>
                </div>

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.ChargeToOrganizationID ?? this.state.selectedOrganization?.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContact?.ChargeToContactID ?? this.state.selectedContact?.ContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

                <ModalConfirm
                    title={translate('text.Confirm')}
                    show={!!this.state.confirmModalOpen}
                    text={translate('message.confirm_delete_payment')}
                    onClose={() => this.setState({confirmModalOpen: false, selectedItem: null})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={translate('btn.Cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.props.onDeletePayment(this.state.selectedItem)
                        this.setState({confirmModalOpen: false, selectedItem: null})
                    }}
                />

                <ModalSaveResource
                    title={'Send document via email'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.emailModalOpen}
                    buttonLabel={"Send"}
                    errorMessage={this.state.errorMessage}
                    onClose={this.handleToggleEmailModal}
                    fields={this.getEmailFields()}
                    isLoading={getProp(this.props.dialogResource, 'isLoading', false)}

                    onSubmit={(params) => {
                        if (params) {
                            let emails = params.emails.filter(it => !it.invalid).reduce((memo, it, i) => {
                                memo += it.value + ",";
                                return memo
                            }, "");

                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign(params, {
                                    ExpenseID: item.ExpenseID,
                                    emails: emails.substring(0, emails.length - 1)
                                }),
                                resource: Resources.ExpenseDocumentSend,
                                errorMessage: true,
                                successMessage: `Attachment sent to ${emails.substring(0, emails.length - 1)}!`
                            }))
                            this.handleToggleEmailModal();
                        }
                    }}
                    translate={this.props.translate}
                />
            </div>
        )
    }
}
