import React, {Component} from 'react'
import {connect} from 'react-redux'
import InfoTab from './infoTab'
import ReviewsTab from './../customer/reviewsTab'
import Resources from '../../../data/services/resources'
import {showGlobalModal} from '../../../data/actions/ui'
import LocalStorage from '../../../util/localStorage'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Tippy from '@tippyjs/react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getInfoResource} from '../../../data/actions/infoResource'
import Page from "../../../common/components/layout/layout-components/page";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import {
    CREATE_PERM,
    DELETE_PERM,
    FIELD_MASK_PHONE,
    READ_PERM,
    REFERENCE_TYPE_CARRIER,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, classNames, getProp} from '../../../common/util/util-helpers'
import {getDocumentTypesFor, resourceIsLoaded} from '../../../common/util/util-helpers'
import CertificationsTab from "./carrierCertsTab";
import SummaryTab from "./summaryTab";
import {deleteDialogResource} from "../../../data/actions/dialogResource";
import DocumentMagnifyingGlassIcon from "@heroicons/react/20/solid/DocumentMagnifyingGlassIcon";
import CarrierReportDialog from "../carriers-view/carriers-import-dialog/carrier-report-dialog";
import {getIntegrationResource} from "../../../data/actions/integrationResource";
import Layout from "../../../common/components/layout";
import CompaniesPaymentTab from "../../../common/components/tabs/companies-payment-tab";
import ContactsTab from "../../../common/components/tabs/contacts-tab";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import NavResponsive from "../../../common/components/nav-responsive";
import PreferencesUpdateTab from "../../../common/components/tabs/preferences-tab/update-tab";
import ExpiryItemsTab from "../../../common/components/tabs/expiry-items-tab";
import ModalDefault from "../../../common/components/modal/modal-default";
import {LoaderLarge, LoaderSmall} from "../../../common/components/loader";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import ResourceListTab from "../../../common/components/tabs/resource-list-tab";
import {HandRaisedIcon} from "@heroicons/react/24/outline";

class CarrierView extends Component {

    constructor(props) {
        super(props)

        const tabs = [// index.js => info, taskResource
            {
                name: 'CarrierInfo', // resource, historyResource
                resource: Resources.Carrier,
                current: false,
                visible: checkPerm(Resources.Carrier, READ_PERM)
            },
            {
                name: 'Summary',
                resource: Resources.Loads,
                current: false,
                visible: checkPerm(Resources.Loads, READ_PERM)
            },
            {
                name: 'CarrierPayment',
                resource: Resources.CarrierPayment,
                current: false,
                visible: checkPerm(Resources.CarrierPayment, READ_PERM)
            },
            {
                name: 'CarrierContacts',
                resource: Resources.CarrierContacts,
                current: false,
                visible: checkPerm(Resources.CarrierContacts, READ_PERM)
            },
            {
                name: 'CarrierReviews', // resource
                resource: Resources.CarrierReviews,
                current: false,
                visible: checkPerm(Resources.CarrierReviews, READ_PERM)
            },

            {
                name: 'CarrierPreferences', // resource
                resource: Resources.CarrierPreferences,
                current: false,
                visible: checkPerm(Resources.CarrierPreferences, READ_PERM)
            },
            {
                name: 'CarrierCertifications', // resource
                resource: Resources.CarrierCertifications,
                current: false,
                visible: checkPerm(Resources.CarrierCertifications, READ_PERM)
            },
            {
                name: 'CarrierDrivers', // resource
                resource: Resources.CarrierDrivers,
                current: false,
                visible: checkPerm(Resources.CarrierDrivers, READ_PERM)
            },
            {
                name: 'ExpiryItems',
                resource: Resources.CarrierExpiry,
                current: false,
                visible: checkPerm(Resources.CarrierExpiry, READ_PERM)
            },
            {
                name: 'CarrierDocuments', // resource
                resource: Resources.CarrierDocuments,
                current: false,
                visible: checkPerm(Resources.CarrierDocuments, READ_PERM)
            },
        ]
        tabs[0].current = true

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.Carrier,
            isDirty: false,

            // Modals
            isMyCarrierIntegrationDialogOpen: false,
            resourceTaskModalOpen: false
        }

        this.USDOTNumber = "";
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.selectTab()
            this.fetchData()
            this.fetchTaskResourceCount()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsLoaded(this.props.resource, prevProps.resource)) {
            if (this.props.resource.data.USDOTNumber) {
                this.USDOTNumber = this.props.resource.data.USDOTNumber
            }
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.Carrier,
            query: {
                id: this.getID()
            }
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    fetchIntegrationData = () => {
        this.props.dispatch(getIntegrationResource({
            user: LocalStorage.get('user'),
            resource: Resources.MyCarrierIntegration,
            query: {id: this.getID()}
        }));
    }

    handleSendInvite = () => {
        alert("carruer/index.js - handleSendInvite function")
    }

    /** UI Events
     ================================================================= */
    handleCreateNewTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasks = () => {
        this.setState({
            resourceTaskModalOpen: !this.state.resourceTaskModalOpen
        })
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.state.isDirty) {
            this.setState({
                confirmModal: true,
                reserveTab: resource
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleViewCarrierReportClick = () => {
        this.setState({
            isMyCarrierIntegrationDialogOpen: true
        }, () => {
            this.fetchIntegrationData()
        })
    }

    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.match.params.id
    }

    selectTab = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab')
        if (tab) this.handleTabChange(tab)
    }

    canSwapTabsHandler = (isDirty = true) => {
        this.setState({isDirty: isDirty})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const reportData = getProp(this.props, 'integrationResource.data', {})
        const hasAgreement = (getProp(reportData, "Message", '')) !== "Carrier Agreement does not exist."

        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const infoData = getProp(this.props.info, 'data', {})
        const dataIntegrations = getProp(this.props.info, 'data.integrations', {})
        const hasIntegrations = dataIntegrations
            && Object.keys(dataIntegrations).some(it => !!dataIntegrations[it].hasIntegration);
        const carrierName = getProp(infoData, 'LegalName', 'Carrier')

        const openTaskCountTwoDigits = TaskCount > 9;

        return (
            <Layout
                {...this.props}
                hasFooter={
                    this.state.selectedTab === Resources.Carrier ||
                    this.state.selectedTab === Resources.CarrierPayment
                }
                canSubmit={this.state.isDirty}
                customPageTitle={carrierName}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="flex justify-start items-center">
                            <GoBackButton
                                translate={this.props.translate}
                                canSubmit={this.state.isDirty}
                                history={this.props.history}
                                path={'/carriers'}
                            />

                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('text.Carrier')}
                                <span className="text-tm-gray-500"> - {infoData.LegalName}
                                    {infoData.ArchivedDate && (
                                        <Tippy content={<span>Archived</span>}>
                                            <i className="iconsminds-delete-file"/>
                                        </Tippy>
                                    )}
                                </span>
                            </h1>
                        </div>

                        <div className="gap-2 flex flex-wrap items-center justify-end">
                            {hasIntegrations && (
                                <button
                                    onClick={this.handleViewCarrierReportClick}
                                    className="btn btn-outline"
                                >
                                    <DocumentMagnifyingGlassIcon className="w-5 h-5 mr-1 opacity-75"/>
                                    View carrier report
                                </button>
                            )}

                            {checkPerm(Resources.Tasks, CREATE_PERM) && (
                                <button className="btn btn-outline"
                                        onClick={() => this.handleCreateNewTask()}>{translate('btn.new_carrier_task')}
                                </button>
                            )}

                            {checkPerm(Resources.Tasks, READ_PERM) && (
                                <div className="relative">
                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasks()}>

                                    {TaskCount > 0 && (
                                        <span
                                            className={
                                                classNames(
                                                    "absolute z-10 flex -top-2.5 -right-2",
                                                    openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                )
                                            }
                                        >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                    )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                </button>
                            </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div>
                        {this.state.selectedTab === Resources.CarrierPreferences && (
                            <PreferencesUpdateTab
                                id={this.getID()}
                                primaryKey={'PreferenceTypeItemID'}
                                resourceName={Resources.CarrierPreferences}
                                dialogTitle={'Preference'}
                                searchFields={{
                                    query: new Field('query', '', [''], false, 'search', {}, {}),
                                    limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}),
                                }}
                                {...this.props}
                            />
                        )}
                        {this.state.selectedTab === Resources.CarrierCertifications && (
                            <CertificationsTab
                                id={this.getID()}
                                resourceName={Resources.CarrierCertifications}
                                {...this.props}
                            />
                        )}

                        {this.state.selectedTab === Resources.Carrier && (
                            <InfoTab
                                canSwapTabsHandler={this.canSwapTabsHandler}
                                handleTabChange={this.handleTabChange}
                                {...this.props}/>
                        )}
                        {this.state.selectedTab === Resources.Loads && (
                            <SummaryTab
                                id={this.getID()}
                                organizationID={this.props.match.params.OrganizationID}
                                {...this.props}
                            />
                        )}
                        {this.state.selectedTab === Resources.CarrierReviews && (
                            <ReviewsTab {...this.props} id={this.getID()}
                                        resourceName={Resources.CarrierReviews}/>
                        )}

                        {this.state.selectedTab === Resources.CarrierDocuments && (
                            <DocumentsUpdateTab
                                id={this.props.match.params.OrganizationID}
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.resource}
                                thirdResource={this.props.thirdResource}

                                dialogTitle={'Carrier Documents'}

                                sortBy={'Description'}
                                primaryKey={'OrganizationDocumentID'}

                                download={this.props.download}
                                resourceName={Resources.CarrierDocuments}

                                documentUpdateFields={checkPerm(Resources.CarrierDocuments, UPDATE_PERM) && {
                                    DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                        values: getDocumentTypesFor("IsCompanyDocument")
                                    }),
                                    ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                    Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                }}

                                documentTypes={getDocumentTypesFor("IsCompanyDocument")}
                                searchFields={{
                                    query: new Field('query', '', [], false, 'search', {
                                        containerClass: 'col-md-4'
                                    }),
                                    DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                        containerClass: 'col-md-4'
                                    }, {
                                        all: true,
                                        values: getDocumentTypesFor("IsCompanyDocument")
                                    }),
                                    DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                        containerClass: 'col-md-4'
                                    }, {
                                        all: true
                                    }),
                                }}
                                readOnly={!checkPerm(Resources.CarrierDocuments, CREATE_PERM)}
                                disableDelete={!checkPerm(Resources.CarrierDocuments, DELETE_PERM)}
                            />
                        )}
                        {this.state.selectedTab === Resources.CarrierContacts && (
                            <div>
                                <ContactsTab
                                    {...this.props}
                                    id={this.getID()}
                                    resourceName={Resources.CarrierContacts}
                                    resourceImagePath={Resources.ContactImage}
                                    organizationID={this.props.match.params.OrganizationID}
                                    primaryKey={'ContactInOrganizationID'}
                                    disableMemberOfOrganization
                                    hideSystemUser={true}
                                    queryFilterFields={{
                                        DepartmentID: new Field('DepartmentID', '', [], false, 'select', {addContainerClass: "col-span-1"}, {isClearable: true}),
                                        limit: new Field('limit', 10, [''], false, 'select', {
                                            hideLabel: true,
                                            labelType: "float"
                                        }, {menuPlacement: "top"})
                                    }}
                                    fields={{
                                        FirstName: new Field('FirstName', '', []),
                                        LastName: new Field('LastName', '', []),
                                        Email: new Field('Email', '', []),
                                        Phone: new Field('Phone', '', []),
                                        Department: new Field('Department', '', []),
                                        Notes: new Field('Notes', '', []),
                                    }}
                                    onDelete={(item, query) => {
                                        this.props.dispatch(deleteDialogResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                ContactInOrganizationID: item.ContactInOrganizationID,
                                                ...query
                                            },
                                            piggyQuery: {
                                                ContactInOrganizationID: item.ContactInOrganizationID,
                                                ...query
                                            },
                                            errorMessage: true,
                                            successMessage: translate(`text.contact_deleted_from_${Resources.CarrierContacts}`, [`${item.FirstName} ${item.LastName}`]),
                                            resource: Resources.CarrierContacts,
                                            piggyResource: Resources.CarrierContacts
                                        }))
                                    }}
                                />
                            </div>
                        )}
                        {this.state.selectedTab === Resources.CarrierPayment && (
                            <CompaniesPaymentTab
                                resourceName={Resources.CarrierPayment}
                                OrganizationID={this.getID()}
                                canSwapTabsHandler={this.canSwapTabsHandler}
                                {...this.props}
                            />
                        )}
                        {this.state.selectedTab === Resources.CarrierDrivers && (
                            <ResourceListTab
                                id={this.getID()}
                                tabKey={'CarrierDrivers'}
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.resource}

                                dialogTitle={translate('modal_heading.CarrierDrivers')}

                                sortBy={'CarrierDriverID'}
                                primaryKey={'CarrierDriverID'}
                                resourceName={Resources.CarrierDrivers}
                                fields={{
                                    FirstName: new Field('FirstName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3'}),
                                    LastName: new Field('LastName', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),

                                    PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {
                                        addContainerClass: "col-span-3"
                                    }, {
                                        showMask: true,
                                        mask: FIELD_MASK_PHONE
                                    }),

                                    Notes: new Field('Notes', '', [''], false, 'textarea', {
                                        limitWidth: true,
                                        addContainerClass: 'col-span-3'
                                    }),
                                    IsBlacklisted: new Field('IsBlacklisted', '', [''], false, 'checkbox', {addContainerClass: 'col-span-2'}),
                                    Preferred: new Field('Preferred', '', [''], false, 'checkbox', {addContainerClass: 'col-span-1'}),
                                }}
                                searchFields={{
                                    query: new Field('query', '', [''], false, 'search', {}),
                                    limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true})
                                }}
                                handleInputChange={(fields, name, value) => {
                                    if (name === 'Preferred' && !!value) fields.IsBlacklisted.value = 0
                                    if (name === 'IsBlacklisted' && !!value) fields.Preferred.value = 0
                                    return FieldsManager.updateField(fields, name, value)
                                }}
                                addClassTableCard={"mt-2"}
                            />
                        )}

                        {this.state.selectedTab === Resources.CarrierExpiry && (
                            <ExpiryItemsTab
                                translate={translate}
                                resourceName={Resources.CarrierExpiry}
                                Reference={infoData.LegalName ?? ''}
                                ReferenceID={this.getID()}
                                ReferenceType={REFERENCE_TYPE_CARRIER}
                            />
                        )}
                    </div>


                </Page>

                <ModalConfirm
                    type="default"
                    title={translate("dialog_heading.unsaved_changes_on_tab")}
                    show={!!this.state.confirmModal && (this.state.selectedTab === Resources.Carrier || this.state.selectedTab === Resources.CarrierPayment)}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        isDirty: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>

                <ModalDefault
                    show={this.state.isMyCarrierIntegrationDialogOpen}
                    widthClass={'max-w-7xl'}
                    title={translate("text.carrier_report")}

                    onClose={() => this.setState({isMyCarrierIntegrationDialogOpen: false})}
                    closeButtonLabel={translate('Close')}
                    onButtonClick={() => null}
                    limitHeight={true}
                    hideDialogFooter={true}
                >
                    {!getProp(this.props, 'integrationResource.isLoading', false) && (
                        <CarrierReportDialog
                            data={reportData}
                            integrationResource={this.props.integrationResource}
                            hasAgreement={hasAgreement}
                            dataSource={Resources.MyCarrierIntegration}
                            translate={translate}
                            onSendInvite={this.handleSendInvite}
                            onClose={() => this.setState({isMyCarrierIntegrationDialogOpen: false})}
                        />
                    )}

                    {!!getProp(this.props, 'integrationResource.isLoading', false) && (
                        <div className="h-44">
                            <LoaderLarge/>
                        </div>
                    )}
                </ModalDefault>

                <ModalDefault
                    show={!!this.state.resourceTaskModalOpen}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.carrier_tasks')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.handleToggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(CarrierView)
