import React, {useEffect, useState} from 'react';
import {numberWithCommasToBack} from "../../../../../util/util-formaters";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import FieldOptions from "../../../../../common/components/fields/field-options";
import ModalDefault from "../../../../../common/components/modal/modal-default";
import FieldsForm from "../../../../../common/components/fields/fields-form";
import {LoaderLarge} from "../../../../../common/components/loader";
import Button from "../../../../../common/components/button";
import RecurringIncomeItemTable from "../recurring-income-item-table";
import moment from "moment";
import {
    CREATE_PERM,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY, MAJOR_ACCOUNT_TYPE_EXPENSE,
    RECURRING_TYPE, RECURRING_TYPE_UNSCHEDULED
} from "../../../../../common/util/util-consts";
import Resources from "../../../../../data/services/resources";
import {checkPerm, getLookup} from "../../../../../common/util/util-helpers";
import {numberWithCommas} from "../../../../../common/util/util-formaters";
import {fillFieldsFromData} from "../../../../../common/util/util-fields";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import {showModal} from "../../../../../data/actions/ui";
import {useDispatch} from "react-redux";

const DAILY = "1";
const WEEKLY = "2";
const MONTHLY = "3";

const today = moment().format(DEFAULT_DATABASE_DATETIME_FORMAT);

export default function RecurringIncomeModal({
                                                 show,
                                                 onClose,
                                                 isLoading,
                                                 selectedItem,
                                                 itemList,
                                                 fetchOnMount,
                                                 onSubmitEdit,
                                                 onSubmitCreate,
                                                 isCreatingFromTemplate,
                                                 translate,
                                                 getNextJobDayFromCron
                                             }) {
    const dispatch = useDispatch();
    const isEditMode = !!selectedItem;

    const [fields, setFields] = useState(null);
    const [items, setItems] = useState(itemList)

    let totalAmount = (items ?? []).reduce((memo, it) => {
        memo = memo + numberWithCommasToBack(it.TotalAmount.value)
        return memo
    }, 0);

    function handleSubmitButtonClick() {
        const validatedFields = FieldsManager.validateFields(fields);
        let validatedItems = [...items];
        let itemsAreValid = true;

        if (items?.length) {
            validatedItems = validatedItems.map(item => {
                const validatedItem = FieldsManager.validateFields(item);
                if (!FieldsManager.checkFieldsForErrors(validatedItem)) {
                    itemsAreValid = false;
                }

                return validatedItem;
            })
        }

        if (FieldsManager.checkFieldsForErrors(validatedFields) && itemsAreValid) {
            const params = FieldsManager.getFieldKeyValues(fields);

            params.Cron = generateCronString(params.Cron, params.OnDay, params.OnDate, params.EveryXMonths);
            params.Items = (items ?? []).map(it => {
                return FieldsManager.getFieldKeyValues(it);
            });
            params.Amount = totalAmount;

            if (isEditMode) {
                params.RecurringInvoiceID = selectedItem.RecurringInvoiceID

                onSubmitEdit(params);
            } else {
                onSubmitCreate(params);
            }
        } else {
            setFields(validatedFields);
            setItems(validatedItems);
        }
    }

    function handleInputChange(name, value) {
        let fieldsUpdate = Object.assign({}, fields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);

        if ("ChargeTo" === name) {
            fieldsUpdate.ContactID.type = value === "1" ? "hidden" : "select-search";
            fieldsUpdate.ContactID.validate = value === "1" ? [''] : ['required'];
            fieldsUpdate.ContactID.value = '';
            fieldsUpdate.OrganizationID.type = value !== "1" ? "hidden" : "select-search"
            fieldsUpdate.OrganizationID.validate = value !== "1" ? [''] : ['required'];
            fieldsUpdate.OrganizationID.value = '';
        }

        if ("Cron" === name) {
            fieldsUpdate.EveryXMonths.type = value === "3" ? "text" : "hidden";
            fieldsUpdate.OnDay.type = value === "2" ? "select" : "hidden";
            fieldsUpdate.OnDate.type = value === "3" ? "select" : "hidden";
            fieldsUpdate.StartDate.metadata.addContainerClass = value === "3" ? "col-span-3" : "col-span-2";
            fieldsUpdate.EndDate.metadata.addContainerClass = value === "3" ? "col-span-3" : "col-span-2";
        }

        fieldsUpdate[name].errorMessage = '';

        setFields(fieldsUpdate);
    }

    function cronStringToIntervalValue(cron) {
        if (!cron) {
            return undefined;
        }

        const parts = cron.split(" ");

        if (parts.length !== 5) {
            return undefined;
        } else if (parts[2] !== "*") {
            return MONTHLY;
        } else if (parts[4] !== "*") {
            return WEEKLY;
        } else {
            return DAILY
        }
    }

    function cronToStringToDay(cron) {
        if (!cron) {
            return undefined;
        }

        const parts = cron.split(" ");

        if (parts.length !== 5) {
            return undefined;
        } else if (parts[4] !== "*") {
            if (parts[4].length === 3) {
                return parts[4];
            }
        }

        return undefined;
    }

    function cronToStringToDate(cron, interval) {
        if (!cron || Number(interval) !== 3) {
            return undefined;
        }

        const parts = cron.split(" ");

        if (parts.length !== 5) {
            return undefined;
        } else if (parts[2] !== "*") {
            return parts[2];
        }

        return undefined;
    }

    function cronToStringToMonths(cron) {
        if (!cron) {
            return undefined;
        }

        const parts = cron.split(" ");

        if (parts.length !== 5) {
            return undefined;
        } else if (parts[3] !== "*") {
            return parts[3].split("/")[1];
        }

        return undefined;
    }

    function generateCronString(interval, day, date, everyXMonths) {
        switch (interval) {
            case DAILY:
                return "0 0 * * *";
            case WEEKLY: {
                return `0 0 * * ${day}`;
            }
            case MONTHLY: {
                return `0 0 ${date} */${everyXMonths} *`;
            }
        }
    }

    function handleAddInlineClick() {
        const itemsUpdate = [...items];
        itemsUpdate.push(getIncomeItemField())
        setItems(itemsUpdate);
    }

    function getFields(item = {}) {
        const startDate = item.LastCreatedDate ? item.LastCreatedDate : item.StartDate
        let nextDate = moment().format(DEFAULT_DATABASE_DATETIME_FORMAT);
        if (item.RecurringType !== RECURRING_TYPE_UNSCHEDULED && startDate && item.Cron) {
            nextDate = getNextJobDayFromCron(item.Cron, startDate);
        }
        const dueDate = moment(nextDate).add(30, 'days').format(DEFAULT_DATABASE_DATETIME_FORMAT);

        let Cron = cronStringToIntervalValue(item.Cron)
        let OnDay = cronToStringToDay(item.Cron);
        let OnDate = cronToStringToDate(item.Cron, Cron);
        let EveryXMonths = cronToStringToMonths(item.Cron);
        const hasOrganisationSelected = !!item?.OrganizationID || (!item?.OrganizationID && !item?.ContactID);

        let fieldTemplates = {
            "TemplateName": new Field('TemplateName', '', ['required'], false, 'text', {
                addContainerClass: "col-span-8",
                autoFocus: true
            }),
            "RecurringType": new Field('RecurringType', '1', ['required'], false, 'select', {addContainerClass: "col-span-4"}),

            "ChargeTo": new Field('ChargeTo', hasOrganisationSelected ? '1' : '0', [''], false, 'button-group', {
                addContainerClass: 'col-start-1 col-span-4',
            }),
            "ContactID": new Field('ContactID', "", !hasOrganisationSelected ? ['required'] : [], false, !hasOrganisationSelected ? 'select-search' : 'hidden', {addContainerClass: "col-span-8"}),
            "OrganizationID": new Field('OrganizationID', "", hasOrganisationSelected ? ['required'] : [], false, hasOrganisationSelected ? 'select-search' : 'hidden', {addContainerClass: "col-span-8"}),

            // Create from template fields
            Date: new Field('Date', startDate, ['required'], false, 'date', {addContainerClass: "col-start-1 col-span-4"}),
            BookDate: new Field('BookDate', startDate, ['required'], false, 'date', {addContainerClass: "col-span-4"}),
            DueDate: new Field('DueDate', dueDate, ['required'], false, 'date', {addContainerClass: "col-span-4"}),
            // Create from template fields end

            // Template fields
            "Cron": new Field('Cron', '1', ['required'], false, 'select', {
                label: "interval",
                addContainerClass: "col-span-3",
                omitSort: true
            }),
            "EveryXMonths": new Field('EveryXMonths', '1', ['min:1', 'max:12'], false, Cron === "3" ? 'text' : "hidden", {
                hideLabel: true,
                addContainerClass: "col-span-2",
                errorNoWrap: true,
                htmlBefore: () => <div className="pt-5 leading-9 text-right text-lg">every</div>,
                htmlAfter: () => <div className="pt-5 leading-9 text-left text-lg">month(s)</div>
            }),
            "OnDay": new Field('OnDay', 'mon', ['required'], false, Cron === "2" ? 'select' : 'hidden', {
                hideLabel: true,
                addContainerClass: "col-span-4",
                htmlBefore: () => <div className="pt-5 leading-9 text-right text-lg">on</div>
            }),
            "OnDate": new Field('OnDate', '1', ['required'], false, Cron === "3" ? 'select' : 'hidden', {
                omitSort: true,
                hideLabel: true,
                addContainerClass: "col-span-4",
                htmlBefore: () => <div className="pt-5 leading-9 text-right text-lg">on</div>
            }),
            // Template fields end

            "StartDate": new Field('StartDate', today, ['required'], false, 'date', {addContainerClass: Cron === "3" ? "col-span-3" : "col-span-2"}),
            "EndDate": new Field('EndDate', today, [''], false, 'date', {isClearable: true, addContainerClass: Cron === "3" ? "col-span-3" : "col-span-2"}),
            "RefNumber": new Field('RefNumber', "", [], false, 'text', {addContainerClass: "col-start-1 col-span-4"}),

            "OfficeID": new Field('OfficeID', "", ['required'], false, 'select-search', {addContainerClass: "col-start-1 col-span-6"}),
            "ContactGroupID": new Field('ContactGroupID', "", ['required'], false, 'select-search', {addContainerClass: "col-span-6", label: 'ContactDepartments'}),

            "InternalNotes": new Field('InternalNotes', "", [''], false, 'textarea', {heightClass: "!h-[7rem] mb-4"}),
            // "ExternalNotes": new Field('ExternalNotes', "", [''], false, 'textarea', {heightClass: "!h-[7rem]"}),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item);

        if (isCreatingFromTemplate) {
            delete fieldTemplates.TemplateName;
            delete fieldTemplates.RecurringType;
            delete fieldTemplates.Cron;
            delete fieldTemplates.EveryXMonths;
            delete fieldTemplates.OnDate;
            delete fieldTemplates.OnDay;
            delete fieldTemplates.StartDate;
            delete fieldTemplates.EndDate;
        } else {
            delete fieldTemplates.Date;
            delete fieldTemplates.BookDate;
            delete fieldTemplates.DueDate;
            fieldTemplates.Cron.value = Cron ?? '1';
            fieldTemplates.OnDay.value = OnDay ?? 'mon';
            fieldTemplates.OnDate.value = OnDate ?? '1';
            fieldTemplates.EveryXMonths.value = EveryXMonths ?? '1';
        }

        return fieldTemplates;
    }

    function getIncomeItemField(item = {}) {
        const Advanced = item.Advanced;
        const ChargeTo = item.ChargeToOrganizationID ? 2 : 1

        const fieldTemplates = {
            ItemNumber: new Field('ItemNumber', '', [''], false, 'hidden', {
                minWidth: "75",
                render: (it) => <div className="text-right">{it.index + 1}</div>
            }),
            ProductServiceID: new Field('ProductServiceID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full',
                label: 'choose_from_product_template',
                hideTable: true
            }, {isClearable: true}),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                minWidth: "168",
                addContainerClass: 'col-span-full'
            }),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                minWidth: "300",
                addContainerClass: 'col-span-full'
            }, {}),
            PrepaidExpense: new Field('PrepaidExpense', '', [], false, !item ? 'checkbox' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),
            PrepaidExpenseAccountID: new Field('PrepaidExpenseAccountID', '', [''], false, item.PrepaidExpenseAccountID ? 'select-search' : 'hidden', {
                hideTable: true,
                isClearable: true,
                addContainerClass: 'col-span-full'
            }, {
                query: {
                    AccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                }
            }),
            PrepaidExpenseCount: new Field('PrepaidExpenseCount', 1, ['empty'], false, item.PrepaidExpenseAccountID ? 'integer' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }, {min: 1}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                minWidth: "150",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {
                isNegativeAllowed: true
            }),
            Qty: new Field('Qty', 1, ['empty'], false, 'integer', {
                minWidth: "100",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {min: 1}),
            // IsPayed: new Field('IsPayed', item.IsPayed ?? 0, [''], false, 'hidden', {hideTable: true}),
            TaxID: new Field('TaxID', '', [''], false, 'select-search', {
                minWidth: "300",
                addContainerClass: 'col-span-full', addTableHeaderClass: 'mx-auto'
            }, {isClearable: true}),
            TotalAmount: new Field('TotalAmount', 0, [], true, 'money', {addContainerClass: 'col-span-full'}),

            Advanced: new Field('Advanced', '', [], false, 'switch', {
                addContainerClass: 'py-3 px-2 col-span-full flex items-center border border-tm-gray-200 rounded-card',
                addTableHeaderClass: 'text-center',
                label: "ChargeTo",
                htmlBefore: () => <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="person-3"
                           className="font-medium text-tm-gray-900 select-none">
                        {translate('field.Advanced')}
                    </label>

                    <p className="text-tm-gray-700">Show additional charge options</p>
                </div>
            }),
            ChargeTo: new Field('ChargeTo', ChargeTo, [''], false, item.Advanced ? 'button-group' : 'hidden', {
                data: {
                    1: translate('field.ChargeToContactID'),
                    2: translate('field.ChargeToOrganizationID')
                },
                hideTable: true,
                hideLabel: true,
                addContainerClass: 'col-span-full'
            }),
            ChargeToContactID: new Field('ChargeToContactID', '', [], false, Advanced && (item.ChargeToContactID || !item.ChargeToOrganizationID && ChargeTo === 1) || item.ChargeToContactID ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => dispatch(showModal('ViewContactCard', {ContactID: it?.value?.Contact?.ContactID})),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),
            ChargeToOrganizationID: new Field('ChargeToOrganizationID', '', [], false, Advanced && item.ChargeToOrganizationID || item.ChargeToOrganizationID ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleOrganizationQuickView(it),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),
            //ExpenseLineID: new Field('ExpenseLineID', '', [''], true, 'hidden', {hideTable: true}),
        }

        if (item) {
            if (item.ChargeToContactID) {
                item.Advanced = 1
                item.ChargeTo = 1
            }
            if (item.ChargeToOrganizationID) {
                item.Advanced = 1
                item.ChargeTo = 2
            }
            if (item.PrepaidExpenseAccountID) {
                item.PrepaidExpense = 1
            }
        }

        if (Object.keys(item).length > 0) {
            const fieldsUpdate = fillFieldsFromData(fieldTemplates, item);

            if (item?.TaxAmount) {
                fieldsUpdate.TaxID.value.amount = item.TaxAmount;
            }

            return fieldsUpdate;
        }

        return fieldTemplates;
    }

    function getFieldsSelects() {
        return {
            RecurringType: RECURRING_TYPE,
            Cron: {
                1: "Daily",
                2: "Weekly",
                3: "Monthly"
            },
            OnDay: {
                "mon": "Monday",
                "tue": "Tuesday",
                "wed": "Wednesday",
                "thu": "Thursday",
                "fri": "Friday",
                "sat": "Saturday",
                "sun": "Sunday"
            },
            OnDate: {
                "1": "1st",
                "2": "2nd",
                "3": "3rd",
                "4": "4th",
                "5": "5th",
                "6": "6th",
                "7": "7th",
                "8": "8th",
                "9": "9th",
                "10": "10th",
                "11": "11th",
                "12": "12th",
                "13": "13th",
                "14": "14th",
                "15": "15th",
                "16": "16th",
                "17": "17th",
                "18": "18th",
                "19": "19th",
                "20": "20th",
                "21": "21st",
                "22": "22nd",
                "23": "23rd",
                "24": "24th",
                "25": "25th",
                "26": "26th",
                "27": "27th",
                "28-31": "Last day of the month"
            },
            ChargeTo: {
                0: translate('btn.Contact'),
                1: translate('btn.Organization')
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            OrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName + " - " + (item.AddressName ?? "")
                        + ", " + (item.CityName ?? "")
                        + " " + (getLookup('State')[item.StateID] ?? "") + " "
                        + (item.PostalCode ?? ""),
                    value: item.OrganizationID
                })
            },
            ContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email,
                    value: it.ContactID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        }
    }

    useEffect(() => {
        if (show) {
            setFields(getFields(selectedItem ?? {}))
        }
    }, [show, selectedItem]);

    useEffect(() => {
        if (!isEditMode && show) {
            setItems([]);
        }

        if (isEditMode && fetchOnMount && !!show) {
            fetchOnMount();
        }
    }, [show]);

    useEffect(() => {
        if (!isLoading && itemList && isEditMode) {
            setItems(itemList.map(it => {
                return getIncomeItemField(it);
            }))
        }
    }, [itemList]);

    return (
        <ModalDefault
            show={show}
            title={isEditMode
                ? isCreatingFromTemplate
                    ? translate('text.create_income_from_template')
                    : translate('text.EditRecurringIncome')
                : translate('text.CreateRecurringIncome')}
            widthClass={'max-w-[100rem]'}
            buttonLabel={isCreatingFromTemplate
                ? translate('btn.create_invoice')
                : translate('btn.save')}
            onButtonClick={handleSubmitButtonClick}
            buttonDisabled={(items ?? []).length === 0 || !checkPerm(Resources.ExpenseRecurring, CREATE_PERM)}
            onClose={onClose}
            closeButtonLabel={translate('btn.close')}
            translate={translate}
        >
            <div>
                {isLoading && (
                    <LoaderLarge/>
                )}

                <div className="p-6 grid grid-cols-12 gap-6">
                    {/* Left side */}
                    <div className="gap-4 col-span-7 grid grid-cols-12">
                        <FieldsForm
                            fieldsState={fields}
                            onChange={handleInputChange}
                            excludeFields={['InternalNotes']}
                            isLoading={isLoading}
                            selects={getFieldsSelects()}
                            translate={translate}
                        />
                    </div>

                    {/* Right side */}
                    <div className="col-span-5">
                        <div className="mb-4">
                            <TotalAmount
                                totalAmount={totalAmount}
                                translate={translate}
                            />
                        </div>

                        <FieldsForm
                            fieldsState={fields}
                            onChange={handleInputChange}
                            includeFields={['InternalNotes']}
                            isLoading={isLoading}
                            translate={translate}
                        />
                    </div>
                </div>

                <div className="bg-inverse mt-6 p-6">
                    <div
                        className="col-12 bg-tm-gray-100 py-5 rounded-card shadow-card border border-tm-gray-300 relative"
                    >
                        {isLoading && (
                            <LoaderLarge/>
                        )}

                        <RecurringIncomeItemTable
                            items={(items ?? [])}
                            getFields={getIncomeItemField}
                            setItems={setItems}
                            translate={translate}
                        />

                        {!!items.length && (
                            <div
                                className="text-right my-5 mx-6"
                            >
                                <Button
                                    onClick={handleAddInlineClick}
                                >
                                    Add and edit inline
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
}


function TotalAmount({totalAmount, translate}) {
    return <div className="text-right col-start-9 col-span-4">
        <div className="font-bold">
            <div
                className="text-lg">{translate('field.total_amount')}</div>
        </div>

        <div className="text-lg">{numberWithCommas(totalAmount)}</div>
    </div>
}