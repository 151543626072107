import React, {Component} from 'react'
import Layout from '../../../common/components/layout-public'
import connect from 'react-redux/es/connect/connect'
import {Link} from 'react-router-dom'
import {Field, FieldsManager} from '../../../data/services/fields'
import {resetPasswordConfirm} from '../../../data/actions/user'
import PasswordStrengthMeter from '../../../common/components/fields/field-password/passwordStrengthMeter'
import AuthLayout from "../auth-layout";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";
import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon";
import FieldText from "../../../common/components/fields/field-text";
import FieldPassword from "../../../common/components/fields/field-password";
import InfoBar from "../../../common/components/info-paragraph/info-bar";

class ResetPasswordView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            no_match: false,
            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            },
            isPasswordVisible: false,
            isPasswordConfirmVisible: false,
            is_new: this.props.match.params && !!this.props.match.params.is_new
        }
    }

    handleInputChange = (name, value) => {
        const fieldsClone = this.state.fields;
        fieldsClone[name].errorMessage = "";
        this.setState({
            fields: FieldsManager.updateField(fieldsClone, name, value)
        });
    }

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event)
        }
    }

    submit = (event) => {
        event && event.preventDefault()

        const email = this.props.match.params && this.props.match.params.email
        const token = this.props.match.params && this.props.match.params.token
        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.state.fields.password.value === this.state.fields.password_confirm.value) {
                    this.props.dispatch(resetPasswordConfirm({
                        username: email,
                        token: token,
                        password: this.state.fields.password.value
                    }))
                } else {
                    this.setState({
                        no_match: true
                    })
                }
            }
        })
    }

    render() {
        const {translate} = this.props
        const {isPasswordVisible, isPasswordConfirmVisible} = this.state

        return (
            <Layout {...this.props}>

                <AuthLayout>
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        {!this.props.user.resetConfirm && (
                            <React.Fragment>
                                <div>
                                    <img
                                        className="h-14 w-auto"
                                        src="/images/logo.png"
                                        alt="Your Company"
                                    />
                                    <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight">
                                        {this.state.is_new ? translate('text.create_password') : translate('text.reset_password')}
                                    </h2>
                                </div>

                                <form className="space-y-6 mt-10" onKeyDown={this.submitForm}>
                                    <div className="relative">
                                        <label htmlFor="password"
                                               className="block text-sm font-medium leading-6">
                                            Password
                                        </label>

                                        <div className="relative">
                                            {!isPasswordVisible && (
                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password}
                                                    onFocus={false}
                                                    addClass="form-control"
                                                />
                                            )}

                                            {!!isPasswordVisible && (
                                                <FieldText
                                                    className="form-control"
                                                    onFocus={false}
                                                    onChange={this.handleInputChange} {...this.state.fields.password}
                                                    placeholder={''} addClass={'form-control'}
                                                />
                                            )}


                                            <div
                                                onClick={() => this.setState({
                                                    isPasswordVisible: !this.state.isPasswordVisible
                                                })}
                                                className="absolute opacity-75 cursor-pointer text-tm-gray-600 right-4 top-2"
                                            >
                                                {
                                                    !isPasswordVisible
                                                        ? <EyeIcon className="w-5 h-5"/>
                                                        : <EyeSlashIcon className="w-5 h-5"/>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="relative">
                                        <label htmlFor="password_confirm"
                                               className="block text-sm font-medium leading-6">
                                            Password
                                        </label>

                                        <div className="relative">
                                            {!isPasswordConfirmVisible && (
                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                    onFocus={false}
                                                    addClass="form-control"
                                                />
                                            )}

                                            {!!isPasswordConfirmVisible && (
                                                <FieldText
                                                    className="form-control"
                                                    onFocus={false}
                                                    onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                    placeholder={''} addClass={'form-control'}
                                                />
                                            )}


                                            <div
                                                onClick={() => this.setState({
                                                    isPasswordConfirmVisible: !this.state.isPasswordConfirmVisible
                                                })}
                                                className="absolute opacity-75 cursor-pointer text-tm-gray-600 right-4 top-2"
                                            >
                                                {
                                                    !isPasswordConfirmVisible
                                                        ? <EyeIcon className="w-5 h-5"/>
                                                        : <EyeSlashIcon className="w-5 h-5"/>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="font-semibold">
                                        <PasswordStrengthMeter
                                            password={this.state.fields.password.value}/>
                                    </div>


                                    <div className="flex justify-content-end items-center">
                                        <button
                                            className="btn btn-primary w-full justify-center disabled:bg-transparent disabled:border-primary"
                                            disabled={this.props.user.isLoading}
                                            onClick={this.submit}
                                        >
                                            {this.state.is_new ? translate('btn.create_password') : translate('btn.reset_password')}
                                        </button>
                                    </div>
                                </form>

                                <div className="min-h-[2.5rem] mt-4">
                                    {this.state.no_match && (
                                        <InfoBar
                                            type="danger"
                                            addClass="justify-content-center"
                                        >
                                            {translate('reset_password.no_match')}
                                        </InfoBar>
                                    )}

                                    {this.props.user.error && (
                                        <InfoBar type="danger" addClass="justify-content-center">
                                            {translate(this.props.user.errorMessage)}
                                        </InfoBar>
                                    )}
                                </div>

                                <div className="flex justify-content-end items-center">
                                    <Link className="btn btn-text justify-center w-full font-semibold"
                                          to={"/login"}
                                    >
                                        <ChevronLeftIcon className="w-5 h-5 -ml-1 mr-1"/>
                                        {translate('btn.back_to_login')}
                                    </Link>
                                </div>

                            </React.Fragment>
                        )}

                        {this.props.user.resetConfirm && (
                            <React.Fragment>
                                <div className="mx-auto w-full max-w-sm lg:w-96 text-center">
                                    <div>
                                        <img
                                            className="h-14 w-auto inline"
                                            src="/images/logo.png"
                                            alt="Your Company"
                                        />
                                        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight">
                                            {this.state.is_new ? translate('create_password.success') : translate('reset_password.successfully')}
                                        </h2>
                                    </div>

                                    <div className="flex justify-content-end items-center mt-20">
                                        <Link className="btn btn-primary w-full justify-center"
                                              to={"/login"}
                                        >
                                            <ChevronLeftIcon className="w-5 h-5 -ml-1 mr-1"/>
                                            {translate('btn.back_to_login')}
                                        </Link>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </AuthLayout>
            </Layout>
        )
    }
}

export default connect(state => state)(ResetPasswordView)
